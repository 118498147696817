import * as R from 'ramda'
import { connect } from 'react-redux'
import { lifecycle } from 'recompose'
import { setAppBarTitle } from '../../modules/ui'

export const withTitle = title => BaseComponent =>
  R.compose(
    connect(
      undefined,
      { setAppBarTitle },
    ),
    lifecycle({
      componentDidMount() {
        this.props.setAppBarTitle(title)
      },
    }),
  )(BaseComponent)
