import * as R from 'ramda'
import {
  all,
  put,
  takeLatest,
  call,
  select,
  debounce,
} from 'redux-saga/effects'
import { startSubmit, stopSubmit, initialize } from 'redux-form'
import { navigate } from '../navigation'
import { fetchGroup, getGroupsForSelect } from '../groups'
import * as UsersManager from './UsersManager'
import { createFormData } from '../../utils/query'
import { getToken } from '../auth'
import {
  fetchUserRequest,
  fetchUserSuccess,
  fetchUserFailure,
  fetchStudents,
  setStudents,
  fetchSystemUsers,
  setSystemUsers,
  fetchSaveUser,
  fetchDeleteUser,
  fetchActiveUser,
  setSeletedGroups,
  uploadUsers,
} from './duck'
import { FORMS, ROUTES } from '../../constants'
import { handleErrors } from '../../aspects'
import { NotificationError, NotificationSuccess } from '../../services'
import { userFormatting, systemUserFormatting } from './mappers'
import { getUserStatisticsByGroup } from '../statistic'
import { getCurrentPage, getUserRole } from '../profile'

const handleAuthRole = handleErrors({
  '401': function*() {
    yield call(NotificationError('Вы не авторизованны'))
  },
  '403': function*() {
    yield call(NotificationError('У вас нет прав\nОбратитесь к администратору'))
  },
})

//GET
const fetchStudentsSaga = handleAuthRole(function*({ payload }) {
  const token = yield select(getToken)
  const [users, count] = yield UsersManager.getUsers(token, payload)
  yield put(setStudents(R.merge({ users }, { count })))
})

const fetchSystemUsersSaga = handleAuthRole(function*({ payload }) {
  const token = yield select(getToken)
  const [users, count] = yield UsersManager.getUsersSystem(token, payload)
  yield put(setSystemUsers(R.assoc('count', count, { users })))
})

const fetchUserSaga = handleErrors({
  anyError: function*() {
    yield call(NotificationError())
    yield put(fetchUserFailure())
  },
})(function*({ payload }) {
  const token = yield select(getToken)
  const profile = yield UsersManager.getUser(token, payload)
  const form =
    profile.user.role === 'MOBILE' ? FORMS.USER_FORM : FORMS.USER_SYSTEM_FORM
  const groupIds = profile.groups.map(R.prop('id'))
  const formFields = {
    ...profile,
    email: profile.user.email,
    role: profile.user.role,
    group: groupIds[0],
    groups: groupIds,
    phone: !!profile.phone ? profile.phone : undefined,
  }

  yield put(
    getUserStatisticsByGroup({ profileId: profile.id, groupId: groupIds[0] }),
  )
  const role = yield select(getUserRole)
  const allGroup = (yield select(getGroupsForSelect)).map(R.prop('value'))
  yield put(
    setSeletedGroups(
      role === 'TEACHER'
        ? profile.groups.filter(x => R.contains(x.id, allGroup))
        : profile.groups,
    ),
  )
  yield put(fetchUserSuccess(formFields))
  yield put(initialize(form, formFields))
})

//SAVE
const fetchSaveUserSaga = handleErrors({
  anyError: function*() {
    yield put(stopSubmit(FORMS.USER_FORM))
    yield put(stopSubmit(FORMS.USER_SYSTEM_FORM))
  },
  '500': function*() {
    yield call(NotificationError('Пользователь с такой почтой уже существует'))
    yield put(stopSubmit(FORMS.USER_FORM))
    yield put(stopSubmit(FORMS.USER_SYSTEM_FORM))
  },
})(function*({ payload }) {
  const isMobile = payload.role === 'MOBILE' || !payload.role
  const form = isMobile ? FORMS.USER_FORM : FORMS.USER_SYSTEM_FORM
  yield put(startSubmit(form))
  const token = yield select(getToken)
  const user = isMobile
    ? userFormatting(payload)
    : systemUserFormatting(payload)
  yield call(UsersManager.saveUser, token, user)
  yield put(stopSubmit(form))
  yield call(NotificationSuccess())
  yield put(navigate(ROUTES.USERS))
})

const uploadUsersSaga = handleErrors({
  anyError: function*() {
    yield call(NotificationError('Пользователь с такой почтой уже существует'))
  },
})(function*({ payload }) {
  const token = yield select(getToken)
  yield call(UsersManager.importUsers, token, createFormData(payload))
  yield put(fetchGroup(payload.groupId))
  yield call(NotificationSuccess())
})

//DELETE
const fetchDeleteUserSaga = handleErrors({
  anyError: function*() {
    yield call(NotificationError())
    yield put(stopSubmit(FORMS.USER_FORM))
    yield put(stopSubmit(FORMS.USER_SYSTEM_FORM))
  },
})(function*({ payload }) {
  const token = yield select(getToken)
  yield UsersManager.deleteUser(token, payload)
  yield call(NotificationSuccess())
  const currentPage = yield select(getCurrentPage)
  yield call(fetchSystemUsersSaga, { payload: currentPage })
  yield call(fetchStudentsSaga, { payload: currentPage })
})

//ACTIVE
const fetchActiveUserSaga = handleErrors({
  anyError: function*() {
    yield call(NotificationError())
  },
})(function*({ payload }) {
  const token = yield select(getToken)
  yield call(UsersManager.activeUser, token, payload)
  yield call(NotificationSuccess('Ссылка для входа в приложение отправлена'))
})

//FIND
const fetchCheckExistEmailStudentFromGroupSaga = handleErrors({
  anyError: function*() {
    yield put(stopSubmit(FORMS.ADD_STUDENT_FROM))
  },
})(function*({ payload }) {
  yield put(startSubmit(FORMS.ADD_STUDENT_FROM))
  const token = yield select(getToken)
  const studentFromGroup = yield UsersManager.checkExistEmailStudentFromGroup(
    token,
    payload,
  )
  if (studentFromGroup.length)
    yield put(
      initialize(
        FORMS.ADD_STUDENT_FROM,
        studentFromGroup.map(({ id, firstName, lastName, phone, user }) => ({
          id: user.role === 'TRIAL' ? undefined : id,
          phone,
          lastName,
          firstName,
          email: user.email,
          user: {
            ...user,
            role: 'MOBILE',
            id: user.role === 'TRIAL' ? undefined : user.id,
          },
        }))[0],
      ),
    )
  yield put(stopSubmit(FORMS.ADD_STUDENT_FROM))
})

const usersSaga = function*() {
  yield all([
    takeLatest(fetchUserRequest, fetchUserSaga),
    takeLatest(fetchStudents, fetchStudentsSaga),
    takeLatest(fetchSystemUsers, fetchSystemUsersSaga),
    takeLatest(fetchSaveUser, fetchSaveUserSaga),
    takeLatest(fetchDeleteUser, fetchDeleteUserSaga),
    takeLatest(fetchActiveUser, fetchActiveUserSaga),
    takeLatest(uploadUsers, uploadUsersSaga),
    debounce(
      1500,
      ({ type, meta: { field, form } = {} }) =>
        type === '@@redux-form/CHANGE' &&
        field === 'email' &&
        form === FORMS.ADD_STUDENT_FROM,
      fetchCheckExistEmailStudentFromGroupSaga,
    ),
  ])
}

export default usersSaga
