import * as React from 'react'
import * as R from 'ramda'
import { withStateHandlers } from 'recompose'
import styled from 'styled-components'
import { Button } from '../commons'
import {
  Modal as MaterialModal,
  Paper,
  Typography,
  Input,
} from '@material-ui/core'

const Modal = styled(MaterialModal)`
  z-index: 99999;
`

const Container = styled(Paper)`
  display: flex;
  width: 500px;
  margin: auto;
  margin-top: 200px;
  padding: 40px;
  flex-direction: column;
  justify-content: space-between;
`

const ButtonsContainer = styled.div`
  margin-top: 20px;
`

const ButtonCancel = styled(Button)`
  margin-left: 20px;
`

const ActivationModal = ({
  onDoneClick,
  onCancelClick,
  email,
  userId,
  onChange,
}) => (
  <Modal open={true} onClose={onCancelClick}>
    <Container>
      <Typography variant="h6">Получить доступ к профилю ученика</Typography>
      <Typography>Введите email</Typography>
      <Input onChange={onChange} />
      <ButtonsContainer>
        <Button
          name={'Отправить'}
          onClick={() => onDoneClick({ email, userId })}
        />
        <ButtonCancel name={'Закрыть'} onClick={onCancelClick} />
      </ButtonsContainer>
    </Container>
  </Modal>
)

export default R.compose(
  withStateHandlers(
    { email: '' },
    { onChange: () => ({ target: { value } }) => ({ email: value }) },
  ),
)(ActivationModal)
