import React from 'react'
import { Grid, Divider as DividerMaterial } from '@material-ui/core'
import styled from 'styled-components'

const Container = styled(Grid).attrs({
  container: true,
  alignItems: 'center',
})``

const DividerLine = styled(DividerMaterial)`
  margin: 20px 0;
  background: #000;
`
const Divider = ({ title, children }) => (
  <>
    <Container>{children}</Container>
    <DividerLine />
  </>
)

export default Divider
