import React from 'react'
import { Button, TableSort } from '../commons'
import * as R from 'ramda'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { InputLabel, Input as InputBase } from '@material-ui/core'

import { UsersManager } from '../../../modules/users'
import { getToken } from '../../../modules/auth'
import { formatDate } from '../../../utils/formatDate'
import { getUserRole, ADMIN } from '../../../modules/profile'
import { HTTP_HOST } from '../../../api'
import DateTimePicker from 'react-datetime-picker'

const getSettings = token =>
  fetch(`${HTTP_HOST}/setting`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(x => x.json())

const patchSettings = (token, body) =>
  fetch(`${HTTP_HOST}/setting`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })

const Input = styled(InputBase)`
  margin: 0 10px;
`

const GiftLink = connect(R.applySpec({ token: getToken }))(({ token }) => {
  const [link, setLink] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(true)
  React.useEffect(() => {
    if (!token) return
    getSettings(token)
      .then(({ data: { giftUrl } }) => {
        setLink(giftUrl)
        setIsLoading(false)
      })
      .catch(x => x)
  }, [token])
  return (
    <InputLabel>
      Ссылка на лэндинг
      {!isLoading && (
        <Input value={link} onChange={event => setLink(event.target.value)} />
      )}
      <Button
        loading={isLoading}
        onClick={() => {
          setIsLoading(true)
          patchSettings(token, { giftUrl: link })
            .finally(() => setIsLoading(false))
            .catch(x => x)
        }}
      >
        Сохранить
      </Button>
    </InputLabel>
  )
})

const dataHeaderUsersTrial = [
  {
    name: 'ФИ',
    keySort: 'profile.firstName',
  },
  {
    name: 'Почта',
    keySort: 'user.email',
  },
  {
    name: 'Прошел хотя бы 1 раунд',
  },
  {
    name: 'Дата первого входа',
    keySort: 'profile.firstEntry',
  },
  {
    name: 'Дата последнего входа',
    keySort: 'profile.lastEntry',
  },
]

const Link = styled.a`
  padding: 6px 16px;
  color: inherit;
  text-decoration: none;
`

const ButtonDownload = styled(Button)`
  padding: 0;
  margin-left: 20px;
`

const Container = styled.div`
  margin-top: 20px;
`

const yesterday = () => {
  const date = new Date()
  date.setDate(date.getDate() - 1)
  return date
}

const dateOnRequest = date => Math.floor(+new Date(date) / 1000)

const DownloadUsers = () => {
  const [afterDate, setAfterDate] = React.useState(yesterday())
  const [beforeDate, setBeforeDate] = React.useState(new Date())
  return (
    <Container>
      После{' '}
      <DateTimePicker
        locale={'ru-RU'}
        showLeadingZeros={true}
        onChange={setAfterDate}
        value={afterDate}
      />{' '}
      до{' '}
      <DateTimePicker
        locale={'ru-RU'}
        showLeadingZeros={true}
        onChange={setBeforeDate}
        value={beforeDate}
      />
      <ButtonDownload>
        <Link
          href={`${
            process.env.REACT_APP_HOST
          }api/v1/profile/trial/csv?createdAtAfter=${dateOnRequest(
            afterDate,
          )}&createdAtBefore=${dateOnRequest(beforeDate)}`}
          download
        >
          Скачать триальных пользователей
        </Link>
      </ButtonDownload>
    </Container>
  )
}

const TableTrialUsers = ({ search, token, role }) => {
  const [filter, setFilter] = React.useState({})
  const [countTrialUsers, setCountTrialUsers] = React.useState(0)
  const [trialUsersBase, setTrialUsers] = React.useState([])
  const trialUsers = React.useMemo(
    () =>
      trialUsersBase.map(
        ({ firstName, lastName, email, firstEntry, lastEntry, passed }) => ({
          fullName: `${firstName} ${lastName}`,
          email,
          firstEntry,
          lastEntry,
          passed,
        }),
      ),
    [trialUsersBase],
  )
  React.useEffect(() => {
    if (!token || R.isNil(filter.limit) || R.isNil(filter.offset)) return
    UsersManager.getUsersTrial(token, filter).then(([users, count]) => {
      setTrialUsers(users)
      setCountTrialUsers(count)
    })
  }, [filter, token])
  return (
    <>
      <GiftLink />
      <DownloadUsers />
      <TableSort
        search={search}
        data={trialUsers}
        count={countTrialUsers}
        dataHeader={
          role === ADMIN
            ? dataHeaderUsersTrial
            : R.remove(1, 1, dataHeaderUsersTrial)
        }
        onChange={setFilter}
        map={R.pipe(
          R.evolve({
            email: email => <a href={`mailto:${email}`}>{email}</a>,
            passed: x => (x ? '+' : '-'),
            firstEntry: date =>
              date === null ? 'Не входил' : formatDate(date),
            lastEntry: date => (date === null ? 'Не входил' : formatDate(date)),
          }),
          R.props(
            R.when(() => role !== ADMIN, R.remove(1, 1), [
              'fullName',
              'email',
              'passed',
              'firstEntry',
              'lastEntry',
            ]),
          ),
        )}
      />
    </>
  )
}

export default connect(
  R.applySpec({
    token: getToken,
    role: getUserRole,
  }),
)(TableTrialUsers)
