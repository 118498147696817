import React from 'react'
import * as R from 'ramda'
import * as Yup from 'yup'
import { withProps } from 'recompose'
import styled from 'styled-components'
import { Field, reduxForm } from 'redux-form'
import { reduxFormYup } from '../../../utils'
import { TextInputField, withRole } from '../commons'
import { FORMS } from '../../../constants'
import { connect } from 'react-redux'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
`

const Input = styled(TextInputField)`
  width: 400px;
`

const FieldWithRole = withRole(Field, true)

const UserSystemFormScheme = Yup.object().shape({
  lastName: Yup.string()
    .max(50)
    .required(),
  firstName: Yup.string()
    .max(50)
    .required(),
  phone: Yup.string().matches(/\d{11}/),
  email: Yup.string()
    .email()
    .required(),
  password: Yup.string()
    .max(50)
    .required(),
  groups: Yup.string(),
  role: Yup.string().required(),
})

const UserSystemFormSchemeWithoutPassword = Yup.object().shape({
  lastName: Yup.string()
    .max(50)
    .required(),
  firstName: Yup.string()
    .max(50)
    .required(),
  phone: Yup.string().matches(/\d{11}/),
  email: Yup.string()
    .email()
    .required(),
  groups: Yup.string(),
  role: Yup.string().required(),
})

const UserSystemForm = ({ role, submitting, roles, groups }) => (
  <Form>
    <FieldWithRole
      name="lastName"
      label="Фамилия"
      component={Input}
      disable={submitting}
    />
    <FieldWithRole
      name="firstName"
      label="Имя"
      component={Input}
      disable={submitting}
    />
    <FieldWithRole
      name="phone"
      type="phone"
      label="Телефон"
      component={Input}
      disable={submitting}
    />
    <FieldWithRole
      name="email"
      label="Почта"
      component={Input}
      disable={submitting}
    />
    <FieldWithRole
      name="password"
      type="password"
      label="Пароль"
      component={Input}
      disable={submitting}
    />
    <FieldWithRole
      name="role"
      label="Тип пользователя"
      select
      options={roles}
      component={Input}
      disable={submitting}
      placeholder="Выбрать тип пользователя"
    />
    {role === 'TEACHER' && (
      <FieldWithRole
        name="groups"
        label="Группа"
        select
        isMulti
        options={groups}
        component={Input}
        disable={submitting}
        placeholder="Выбрать группу"
      />
    )}
  </Form>
)

export default R.compose(
  reduxForm({
    form: FORMS.USER_SYSTEM_FORM,
    validate: (values, { currentUserId }) =>
      reduxFormYup(
        currentUserId
          ? UserSystemFormSchemeWithoutPassword
          : UserSystemFormScheme,
      )(values),
  }),
  connect(
    R.applySpec({
      role: R.pathOr('', ['form', FORMS.USER_SYSTEM_FORM, 'values', 'role']),
    }),
  ),
  withProps({
    roles: [
      { value: 'TEACHER', label: 'Преподaватель' },
      { value: 'ADMIN', label: 'Администратор' },
      { value: 'MENTOR', label: 'Ментор' },
    ],
  }),
)(UserSystemForm)
