import React from 'react'
import * as R from 'ramda'
import {
  Typography,
  Toolbar as ToolbarMaterial,
  IconButton,
} from '@material-ui/core/'
import { connect } from 'react-redux'
import { Menu } from '@material-ui/icons'
import styled from 'styled-components'
import { getBarTitle } from '../../../modules/ui'

const Humberger = styled(IconButton).attrs({
  children: <Menu style={{ color: '#fff' }} />,
})`
  margin-right: 20px;
`

const Toolbar = styled(ToolbarMaterial)`
  top: 0;
  bottom: auto;
  background-color: rgb(241, 77, 27);
`

const AppBarDamp = ({ title, disableGutters, onClick }) => (
  <Toolbar position="fixed" disableGutters={disableGutters}>
    <Humberger aria-label="Open drawer" onClick={onClick} />
    <Typography variant="h6" style={{ color: '#fff' }} noWrap>
      {title}
    </Typography>
  </Toolbar>
)

const AppBar = connect(R.applySpec({ title: getBarTitle }))(AppBarDamp)

export default AppBar
