import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { withState, withHandlers, withStateHandlers } from 'recompose'
import {
  ButtonBase,
  Grid,
  Table,
  TableBody,
  TableCell as TableCellMaterial,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { Delete, Edit, Close, PlayCircleOutline } from '@material-ui/icons'
import { Player } from '../../../services'
import WordImage from './WordImage'
import { deleteWord, getWords } from '../../../modules/wordSets'
import { FORMS } from '../../../constants'
import { Confirm, withRole } from '../../components/commons'
import { connect } from 'react-redux'
import { initialize } from 'redux-form'

const TableCell = styled(TableCellMaterial)`
  padding: 10px 5px;
`
const Image = styled(ButtonBase)`
  width: 60px;
  height: 50px;
  background-size: contain;
  background-image: url(${({ url }) => url});
`

const withButtonBase = BaseComponent => props => (
  <ButtonBase {...props}>
    <BaseComponent />
  </ButtonBase>
)

const EditButton = withRole(withButtonBase(Edit), true)
const DeleteButton = withRole(withButtonBase(Delete), true)

const PlayButton = styled(ButtonBase).attrs({
  children: <PlayCircleOutline />,
})``
const CloseButton = styled(ButtonBase).attrs({
  children: <Close color="error" />,
})``

const dataHeader = [
  'Слово',
  'Перевод',
  'Определение',
  'Транскрипция',
  'Контекст',
  'Аудио',
  'Картинка',
  '',
  '',
]

const TableWordDamp = ({
  words,
  active,
  playAudio,
  selectWord,
  removeWord,
  onDelete,
  onClose,
  isDeleting,
  onCloseImage,
  openImage,
  currentImage,
  selectImage,
  abilityToAdd,
}) => (
  <Grid item sm={11}>
    <Table>
      <TableHead>
        <TableRow>
          {dataHeader.map(name => (
            <TableCell>{name}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {words.map(word => (
          <TableRow key={word.id}>
            <TableCell>{word.word}</TableCell>
            <TableCell>{word.translation}</TableCell>
            <TableCell>{word.interpretation}</TableCell>
            <TableCell>{word.transcription}</TableCell>
            <TableCell>{word.example}</TableCell>
            <TableCell>
              {!!word.audio ? (
                <PlayButton onClick={() => playAudio(word.id)} />
              ) : (
                <CloseButton disabled={true} />
              )}
            </TableCell>
            <TableCell>
              <Image onClick={() => selectImage(word.image)} url={word.image} />
            </TableCell>
            <TableCell>
              <EditButton onClick={() => selectWord(word)} />
            </TableCell>
            <TableCell>
              {abilityToAdd && !active && (
                <DeleteButton onClick={() => removeWord(word.id)} />
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <WordImage src={currentImage} open={openImage} onClose={onCloseImage} />
    {isDeleting && <Confirm onCancelClick={onClose} onDoneClick={onDelete} />}
  </Grid>
)

const TableWord = R.compose(
  connect(
    R.applySpec({ words: getWords }),
    { deleteWord, initialize },
  ),
  withState('currentTrack', 'setCurrentTrack', null),
  withState('currentImage', 'setCurrentImage', null),
  withState('openImage', 'setOpenImage', false),
  withState('wordId', 'setWordId', null),
  withStateHandlers(
    { isDeleting: false },
    {
      onDelete: (_, { deleteWord, wordId }) => () => {
        deleteWord(wordId)
        return { isDeleting: false }
      },
      removeWord: (_, { setWordId }) => id => {
        setWordId(id)
        return { isDeleting: true }
      },
      onClose: () => () => ({ isDeleting: false }),
    },
  ),
  withHandlers({
    selectWord: ({ onOpen, initialize }) => word => {
      onOpen()
      initialize(FORMS.WORD, word)
    },
    selectImage: ({ setOpenImage, setCurrentImage }) => url => {
      setCurrentImage(url)
      setOpenImage(true)
    },
    onCloseImage: ({ setOpenImage, setCurrentImage }) => () => {
      setCurrentImage(null)
      setOpenImage(false)
    },
    playAudio: ({ currentTrack, setCurrentTrack, words }) => id => {
      if (currentTrack !== id || Player.paused) {
        const { audio } = words.find(R.propEq('id', id))
        if (Player.src !== audio) Player.src = audio
        Player.play()
        setCurrentTrack(id)
      } else {
        Player.pause()
        setCurrentTrack(null)
      }
    },
  }),
)(TableWordDamp)

export default TableWord
