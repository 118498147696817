import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  fetchWordSet,
  publishWordSet,
  getWordSet,
} from '../../modules/wordSets'
import { saveStage, setStageTwo } from '../../modules/stageTwo'
import {
  Grid,
  Paper,
  AppBar as AppBarMaterial,
  Tabs as TabsMaterial,
  Tab,
} from '@material-ui/core'
import {
  withState,
  withHandlers,
  withProps,
  lifecycle,
  withStateHandlers,
} from 'recompose'
import { withTitle } from './withTitle'
import { FillGaps, Audition, MakeSentence } from '../components/stageTwo'
import { ROUTES } from '../../constants'
import { Button, Confirm, withRole } from '../components/commons'

const Container = styled(Paper)`
  padding-top: 20px;
`

const PublicButton = styled(Button)`
  margin-left: 20px;
  margin-right: 20px;
`

const SaveButton = styled(Button)`
  margin-left: 20px;
`

const CancelButton = styled(Button)`
  margin-left: auto;
`

const Header = styled(Grid)`
  margin-bottom: 20px;
`

const AppBar = styled(AppBarMaterial).attrs({
  position: 'static',
  color: 'default',
})``

const Tabs = styled(TabsMaterial).attrs({
  indicatorColor: 'primary',
  textColor: 'primary',
  fullWidth: true,
})``

const SaveButtonWithRole = withRole(SaveButton, true)
const PublicButtonWithRole = withRole(PublicButton, true)

const StageTwoDamp = ({
  id,
  save,
  cancel,
  currentTab,
  setCurrentTab,
  isPublishing,
  onPublish,
  onClose,
  wordSet,
  publishWordSet,
}) => (
  <Container>
    <Header container direction="row">
      <CancelButton name="Этап 1" onClick={() => cancel()} />
      <SaveButtonWithRole name="Сохранить" onClick={() => save()} />
      <PublicButtonWithRole
        name="Опубликовать"
        onClick={() => publishWordSet(id)}
      />
    </Header>
    <AppBar>
      <Tabs
        value={currentTab}
        onChange={(_, numberTab) => setCurrentTab(numberTab)}
      >
        <Tab label="Задание на аудирование" />
        <Tab label="Задание 'Fill the gaps'" />
        <Tab label="Задание 'Составить предложение'" />
      </Tabs>
    </AppBar>
    {currentTab === 0 && <Audition active={wordSet.active} />}
    {currentTab === 1 && <FillGaps active={wordSet.active} />}
    {currentTab === 2 && <MakeSentence active={wordSet.active} />}
    {isPublishing && (
      <Confirm onCancelClick={onClose} onDoneClick={onPublish} />
    )}
  </Container>
)

const StageTwo = R.compose(
  withRouter,
  withTitle('Этап 2'),
  withState('currentTab', 'setCurrentTab', 0),
  connect(
    R.applySpec({ wordSet: getWordSet }),
    { fetchWordSet, saveStage, setStageTwo, publishWordSet },
  ),
  withProps(({ match: { params: { id } } }) => ({ id })),
  withStateHandlers(
    { isPublishing: false },
    {
      onPublish: (_, { id, publishWordSet }) => () => {
        publishWordSet(id)
        return { isPublishing: false }
      },
      publish: () => () => ({ isPublishing: true }),
      onClose: () => () => ({ isPublishing: false }),
    },
  ),
  withHandlers({
    save: ({ saveStage }) => () => saveStage(),
    getStage: ({ id, fetchWordSet }) => () => fetchWordSet(id),
    cancel: ({ history: { push: navigate }, id }) => () =>
      navigate(`${ROUTES.WORD_SETS}/${id}`),
  }),
  lifecycle({
    componentDidMount() {
      this.props.setStageTwo([])
      this.props.getStage()
    },
  }),
)(StageTwoDamp)

export default StageTwo
