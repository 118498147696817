import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import styled from 'styled-components'

import { ROUTES } from '../../../constants'
import { getIsSignedin } from '../../../modules/auth'

const mapStateToProps = R.applySpec({
  isSignedin: getIsSignedin,
})

const Indicator = styled(CircularProgress)`
  margin: auto;
`

const withAuthorization = BaseComponent => {
  const WithAuthorizationDamp = ({ isSignedin, ...props }) => (
    <>
      {isSignedin === undefined ? (
        <Indicator size={20} />
      ) : !isSignedin ? (
        <Redirect to={ROUTES.SIGNIN} />
      ) : (
        <BaseComponent {...props} />
      )}
    </>
  )
  const WithAuthorization = R.compose(
    withRouter,
    connect(mapStateToProps),
  )(WithAuthorizationDamp)
  return WithAuthorization
}

export default withAuthorization
