import * as R from 'ramda'
import { handleStatuses } from '../../aspects'
import API from '../../api'
import { auth, query } from '../../utils/query'

export const getUsersSystem = R.pipeP(
  (token, data) =>
    fetch(`${API.PROFILE}${query(data)}&role=system`, auth(token)),
  handleStatuses(),
  res => Promise.all([res.json(), parseInt(res.headers.get('count'))]),
  R.adjust(R.prop('data'), 0),
)

export const getUsersTrial = R.pipeP(
  (token, data) =>
    fetch(`${API.PROFILE}${query(data)}&role=trial`, auth(token)),
  handleStatuses(),
  res => Promise.all([res.json(), parseInt(res.headers.get('count'))]),
  R.adjust(R.prop('data'), 0),
)

export const getUsers = R.pipeP(
  (token, data) => fetch(`${API.GET_PROFILES_STAT}${query(data)}`, auth(token)),
  handleStatuses(),
  res => Promise.all([res.json(), parseInt(res.headers.get('count'))]),
  R.adjust(R.prop('data'), 0),
)

export const getUser = R.pipeP(
  (token, userId) => fetch(`${API.getProfile(userId)}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const saveUser = R.pipeP(
  (token, user) =>
    fetch(API.PROFILE, {
      method: user.id ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(user),
    }),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const deleteUser = R.pipeP(
  (token, userId) =>
    fetch(`${API.PROFILE}/${userId}`, {
      headers: { Authorization: `Bearer ${token}` },
      method: 'DELETE',
    }),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const getUserGroup = R.pipeP(
  (token, groupId) =>
    fetch(`${API.PROFILE}${query({ groupId, limit: 100000 })}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const checkExistEmailStudentFromGroup = R.pipeP(
  (token, email) => fetch(`${API.PROFILE}${query({ email })}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const getTeachers = R.pipeP(
  token => fetch(`${API.GET_TEACHERS}&limit=10000`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
  R.filter(({ user: { role } }) => role === 'TEACHER'),
)

export const activeUser = R.pipeP(
  (token, data) => fetch(`${API.USER}/active${query(data)}`, auth(token)),
  handleStatuses(),
  res => res.json(),
)

export const importUsers = R.pipeP(
  (token, body) =>
    fetch(`${API.USERS_UPLOAD}`, {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      body,
    }),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)
