import React from 'react'
import * as R from 'ramda'
import { Paper } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { withTitle } from './withTitle'
import { withProps, lifecycle, withHandlers } from 'recompose'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { Header, Button, Preloader, withRole } from '../components/commons'
import { CourseForm as Form } from '../components/courses'
import {
  fetchCourse,
  saveCourse,
  getLessons,
  getSelectedLessons,
  getIsFetching,
} from '../../modules/courses'
import { FORMS, ROUTES } from '../../constants'
import styled from 'styled-components'

const Container = styled(Paper)`
  padding: 20px;
  padding-bottom: 80px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`

const ButtonCancel = styled(Button).attrs({
  name: 'Отменить',
})`
  margin-right: 10px;
`

const ButtonSave = styled(Button).attrs({
  name: 'Сохранить',
})`
  margin-right: 10px;
`

const ButtonSaveWithRole = withRole(ButtonSave, true)

const CourseFormDamp = ({
  lessons,
  selectedLessons,
  save,
  cancel,
  isFetching,
}) =>
  isFetching ? (
    <Preloader />
  ) : (
    <Container>
      <Header
        title="Создание курса"
        children={
          <ButtonContainer>
            <ButtonCancel onClick={() => cancel()} />
            <ButtonSaveWithRole onClick={() => save()} />
          </ButtonContainer>
        }
      />
      <Form lessons={lessons} selectedLessons={selectedLessons} />
    </Container>
  )

const CourseForm = R.compose(
  withRouter,
  withTitle('Курс'),
  reduxForm({ form: FORMS.COURSE_FORM }),
  connect(
    R.applySpec({
      lessons: getLessons,
      selectedLessons: getSelectedLessons,
      isFetching: getIsFetching,
    }),
    { fetchCourse, saveCourse },
  ),
  withProps(({ match: { params: { id } } }) => ({ courseId: id })),
  lifecycle({
    componentDidMount() {
      const { fetchCourse, courseId } = this.props
      fetchCourse(courseId)
    },
  }),
  withHandlers({
    save: ({ handleSubmit, saveCourse, courseId }) =>
      handleSubmit(data =>
        saveCourse({
          id: courseId,
          ...data,
          lessons: !!data.lessons ? R.uniq(data.lessons) : undefined,
        }),
      ),
    cancel: ({ history: { push: navigate } }) => () => navigate(ROUTES.COURSES),
  }),
)(CourseFormDamp)

export default CourseForm
