export const HTTP_HOST = '/api/v1'

const API = {
  SIGNIN: `${HTTP_HOST}/user/getAdminToken`,
  GROUP: `${HTTP_HOST}/group`,
  USER: `${HTTP_HOST}/user`,
  COURSES: `${HTTP_HOST}/course`,
  COPY_COURSES: `${HTTP_HOST}/course/copy`,
  PROFILE: `${HTTP_HOST}/profile`,
  WORD_SET: `${HTTP_HOST}/wordSet`,
  WORD_SET_STAGE2: `${HTTP_HOST}/wordSet/stage2`,
  WORD_SET_COPY: `${HTTP_HOST}/wordSet/copy`,
  publishWordSet: id => `${HTTP_HOST}/wordSet/${id}/publish`,
  SCHEDULE: `${HTTP_HOST}/schedule`,
  LESSONS: `${HTTP_HOST}/lesson`,
  WORD: `${HTTP_HOST}/word`,
  COUNT_WORD_BY_AUDIO: `${HTTP_HOST}/word/count/audio`,
  MAGIC_LINK: `${HTTP_HOST}/group/testUser`,
  SEARCH_AUDIO: `${HTTP_HOST}/word/searchAudio`,
  WORD_UPLOAD: `${HTTP_HOST}/word/upload`,
  AUDIO: `${HTTP_HOST}/word/audio`,
  MULTIPLE_AUDIO: `${HTTP_HOST}/word/multipleAudio`,
  RULES: `${HTTP_HOST}/rule`,
  RULES_UPLOAD: `${HTTP_HOST}/rule/upload`,
  USERS_UPLOAD: `${HTTP_HOST}/profile/upload`,
  GET_WORD_SET_STAT: `${HTTP_HOST}/wordSet/stat`,
  GET_WORD_SET_STAT_BY_USER: `${HTTP_HOST}/wordSet/shortStat`,
  GET_TEACHERS: `${HTTP_HOST}/profile?role=system`,
  GET_ME_PROFILE: `${HTTP_HOST}/profile/me`,
  GET_PROFILES_STAT: `${HTTP_HOST}/profile/stat`,
  GET_GROUPS_STAT: `${HTTP_HOST}/group/stat`,
  getProfile: id => `${HTTP_HOST}/profile/${id}`,
  getGroup: id => `${HTTP_HOST}/group/${id}`,
  getCourse: id => `${HTTP_HOST}/course/${id}`,
}

export default API
