import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { Paper } from '@material-ui/core'
import { withProps, lifecycle } from 'recompose'
import styled from 'styled-components'
import { withTitle } from './withTitle'
import { GroupMainInfo, GroupStatistics } from '../components/groups'
import {
  getGroupStatistic,
  getGroupInfo,
  getGroupStudents,
  getGroupWordSets,
  getIsFetchingGroup,
} from '../../modules/statistic'
import { Preloader } from '../components/commons'

const Container = styled(Paper)``

const GroupDetailsDamp = ({ info, students, wordSets, isFetching, groupId }) =>
  isFetching ? (
    <Preloader />
  ) : (
    <Container>
      <GroupMainInfo info={info} />
      <GroupStatistics
        groupId={groupId}
        students={students}
        wordSets={wordSets}
      />
    </Container>
  )

const GroupDetails = R.compose(
  withTitle('Информация о группе'),
  connect(
    R.applySpec({
      info: getGroupInfo,
      students: getGroupStudents,
      wordSets: getGroupWordSets,
      isFetching: getIsFetchingGroup,
    }),
    { getGroupStatistic },
  ),
  withProps(({ match: { params: { id } } }) => ({ groupId: id })),
  lifecycle({
    componentDidMount() {
      const { getGroupStatistic, groupId } = this.props
      getGroupStatistic(groupId)
    },
  }),
)(GroupDetailsDamp)

export default GroupDetails
