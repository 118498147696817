import * as R from 'ramda'
import { MODULES } from '../../constants'
import { handleAction, handleActions, createAction } from 'redux-actions'
import { combineReducers } from 'redux'

export const fetchWordSet = createAction(`${MODULES.WORD_SETS}/FETCH_WORD_SET`)
export const fetchWordSets = createAction(
  `${MODULES.WORD_SETS}/FETCH_WORD_SETS`,
)
export const fetchSaveWordSet = createAction(
  `${MODULES.WORD_SETS}/FETCH_SAVE_WORD_SET`,
)

export const setWordSets = createAction(`${MODULES.WORD_SETS}/SET_WORD_SETS`)
export const setWordSet = createAction(`${MODULES.WORD_SETS}/SET_WORD_SET`)
export const setWordSetsStudy = createAction(
  `${MODULES.WORD_SETS}/SET_WORD_SET_STUDY`,
)
export const setWords = createAction(`${MODULES.WORD_SETS}/SET_WORDS`)
export const setRules = createAction(`${MODULES.WORD_SETS}/SET_RULES`)
export const onChangeType = createAction(`${MODULES.WORD_SETS}/ON_CHANGE_TYPE`)

export const saveWordSet = createAction(`${MODULES.WORD_SETS}/SAVE_WORD_SET`)
export const saveStage2 = createAction(`${MODULES.WORD_SETS}/SAVE_STAGE_2`)
export const copyWordSet = createAction(`${MODULES.WORD_SETS}/COPY_WORD_SET`)
export const deleteWordSet = createAction(
  `${MODULES.WORD_SETS}/DELETE_WORD_SET`,
)
export const publishWordSet = createAction(
  `${MODULES.WORD_SETS}/PUBLISH_WORD_SET`,
)

export const selectWord = createAction(`${MODULES.WORD_SETS}/SELECT_WORD`)
export const saveWord = createAction(`${MODULES.WORD_SETS}/SAVE_WORD`)
export const createWord = createAction(`${MODULES.WORD_SETS}/CREATE_WORD`)
export const updateWord = createAction(`${MODULES.WORD_SETS}/UPDATE_WORD`)
export const deleteWord = createAction(`${MODULES.WORD_SETS}/DELETE_WORD`)

export const setSelectedIds = createAction(
  `${MODULES.WORD_SETS}/SET_INCLUDED_IDS`,
)
export const toggleWordSet = createAction(
  `${MODULES.WORD_SETS}/TOGGLE_WORD_SET`,
)

export const loadWords = createAction(`${MODULES.WORD_SETS}/LOAD_WORDS`)
export const loadRule = createAction(`${MODULES.WORD_SETS}/LOAD_RULE`)
export const uploadRule = createAction(`${MODULES.WORD_SETS}/UPLOAD_RULE`)
export const fetchRules = createAction(`${MODULES.WORD_SETS}/FETCH_RULES`)
export const removeRule = createAction(`${MODULES.WORD_SETS}/REMOVE_RULE`)
export const fetchWordSetCount = createAction(`${MODULES.WORD_SETS}/WS_COUNT`)
export const setWordSetCount = createAction(`${MODULES.WORD_SETS}/SET_WS_COUNT`)

export const setDumpCourses = createAction(
  `${MODULES.WORD_SETS}/SET_DUMP_COURSES`,
)
export const getWordSetByCourseId = createAction(
  `${MODULES.WORD_SETS}/GET_WORD_SET_BY_COURSE_ID`,
)

const wordSets = handleAction(setWordSets, (_, { payload }) => payload, {})

const currentWordSet = handleActions(
  {
    [setWordSet]: (_, { payload }) => payload,
    [onChangeType]: (state, { payload }) => ({ ...state, testType: payload }),
  },
  {},
)

const isFetching = handleActions(
  {
    [fetchWordSet]: R.T,
    [setWordSet]: R.F,
  },
  false,
)

const words = handleActions(
  {
    [setWords]: (_, { payload }) => payload,
    [deleteWord]: (state, { payload: id }) =>
      R.filter(word => word.id !== id)(state),
    [createWord]: (state, { payload }) => R.append(payload, state),
    [updateWord]: (state, { payload }) =>
      R.pipe(
        R.findIndex(R.propEq('id', payload.id)),
        R.update(R.__, payload, state),
      )(state),
  },
  [],
)

const selectedWordSets = handleActions(
  {
    [setSelectedIds]: (_, { payload }) => payload,
    [toggleWordSet]: (state, { payload: id }) =>
      R.ifElse(R.contains(id), R.filter(i => i !== id), R.append(id))(state),
  },
  [],
)

const rules = handleAction(setRules, (_, { payload }) => payload, [])

const wordSetsStudy = handleAction(
  setWordSetsStudy,
  (_, { payload }) => payload,
  [],
)

const isFetchingWordSetCount = handleActions(
  { [fetchWordSetCount]: R.T, [setWordSetCount]: R.F },
  false,
)

const wordSetCount = handleAction(
  setWordSetCount,
  (_, { payload }) => payload,
  0,
)

const dumpCourses = handleAction(setDumpCourses, (_, { payload }) => payload, 0)

const wordSetReducer = combineReducers({
  dumpCourses,
  selectedWordSets,
  wordSetsStudy,
  wordSets,
  isFetching,
  currentWordSet,
  words,
  rules,
  wordSetCount,
  isFetchingWordSetCount,
})

export default wordSetReducer
