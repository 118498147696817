import { setLocale } from 'yup'

setLocale({
  mixed: {
    required: 'Не может быть пустым',
    notType: ({ path, type, value, originalValue }) => {
      if (type === 'number') return 'Должно быть число'
    },
  },
  string: {
    min: 'Символов должно быть не менее ${min}',
    max: 'Символов должно быть не более ${max}',
    email: 'Не корректный email',
    matches: 'Формат должен быть 7 999 999 99 99',
  },
  date: {
    min: 'Дата должна быть в будущем',
  },
})
