import React from 'react'
import withRole from './withRole'
import { Input, InputLabel, FormControl } from '@material-ui/core'
import { AddCircleOutline } from '@material-ui/icons'
import Button from './Button'
import { Divider } from '.'
import styled from 'styled-components'

const SearchLabel = styled(InputLabel)`
  margin-right: 5px;
`

const SearchInput = styled(Input)`
  margin-right: 5px;
`

const IconAdd = styled(AddCircleOutline)`
  margin-right: 10px;
`

const ButtonAdd = styled(Button).attrs({
  variant: 'contained',
})`
  margin-left: auto;
`

const ButtonAddWithRole = withRole(ButtonAdd)

const Header = ({ searchName, buttonName, onClick, children, ...props }) => (
  <Divider>
    {searchName && (
      <FormControl style={{ width: '30%' }}>
        <SearchLabel htmlFor="search">{searchName}</SearchLabel>
        <SearchInput type="search" id="search" {...props} />
      </FormControl>
    )}
    {buttonName && (
      <ButtonAddWithRole onClick={onClick}>
        <IconAdd />
        {buttonName}
      </ButtonAddWithRole>
    )}
    {children && children}
  </Divider>
)

export default Header
