import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  Table,
  TableBody,
  TableFooter,
  TableRow,
  TablePagination,
  TableHead,
  TableSortLabel,
} from '@material-ui/core'

import {
  withStateHandlers,
  lifecycle,
  shallowEqual,
  withPropsOnChange,
} from 'recompose'

import { TableCell } from './'
import { setPage } from '../../../modules/profile'
import { debounce, searchToObject, mergeSearch } from '../../../utils'

const TableRowClick = styled(TableRow)`
  transition: 0.224s;
  &:active {
    opacity: 0.4;
    background-color: #d1d1d1;
  }
`

const TableSortDamp = ({
  data,
  dataHeader,
  map,
  currentPage,
  setCurrentPage,
  percentIndexes = [],
  rowsPerPage,
  setRowsPerPage,
  count,
  handlerSortClick,
  sortDirection,
  isCurrentSortKey,
  onClick,
}) => (
  <Table>
    <TableHead>
      <TableRow>
        {dataHeader.map(({ name, keySort, style }) => (
          <TableCell key={name} style={style}>
            <TableSortLabel
              active={keySort !== '' && isCurrentSortKey(keySort)}
              direction={
                isCurrentSortKey(keySort) && !sortDirection ? 'asc' : 'desc'
              }
              onClick={() => handlerSortClick(keySort)}
            >
              {name}
            </TableSortLabel>
          </TableCell>
        ))}
        {R.repeat(<TableCell />, 1)}
      </TableRow>
    </TableHead>
    <TableBody>
      {data.map((x, i) => (
        <>
          {!!onClick && (
            <TableRowClick key={i} onClick={event => onClick(event, x)}>
              {map(x).map((y, i) => (
                <TableCell key={i}>{y}</TableCell>
              ))}
            </TableRowClick>
          )}
          {!onClick && (
            <TableRow key={i}>
              {map(x).map((y, i) => (
                <TableCell key={i}>
                  {R.contains(i, percentIndexes) && y !== '' ? `${y}%` : y}
                </TableCell>
              ))}
            </TableRow>
          )}
        </>
      ))}
    </TableBody>
    <TableFooter>
      <TableRow>
        <TablePagination
          count={count || rowsPerPage * (currentPage + 1)}
          rowsPerPageOptions={[10, 20, 30]}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onChangePage={setCurrentPage}
          onChangeRowsPerPage={setRowsPerPage}
        />
      </TableRow>
    </TableFooter>
  </Table>
)

const TableSort = R.compose(
  withRouter,
  connect(
    undefined,
    { setPage },
  ),
  withStateHandlers(
    () =>
      R.pipe(
        () => new URLSearchParams(window.location.search),
        searchToObject,
        R.applySpec({
          currentPage: R.propOr(0, 'page'),
          rowsPerPage: R.propOr(10, 'limit'),
          currentSortKey: R.prop('sortKey'),
          sortDirection: R.prop('sortDirection'),
        }),
      )(),
    {
      setCurrentPage: ({ rowsPerPage: limit }, { setPage }) => (
        _,
        currentPage,
      ) => {
        setPage({ limit, offset: limit * currentPage })
        return { currentPage }
      },
      setRowsPerPage: ({ currentPage }, { setPage }) => ({
        target: { value: rowsPerPage },
      }) => {
        setPage({ limit: rowsPerPage, offset: rowsPerPage * currentPage })
        return { rowsPerPage }
      },
      handlerSortClick: ({ currentSortKey, sortDirection }) => sortKey =>
        currentSortKey === sortKey
          ? { sortDirection: !sortDirection }
          : { sortDirection: false, currentSortKey: sortKey },
    },
  ),
  withPropsOnChange(
    ['currentPage', 'rowsPerPage', 'currentSortKey', 'sortDirection'],
    ({
      currentPage: page,
      rowsPerPage: limit,
      currentSortKey: sortKey,
      sortDirection,
      history,
    }) => {
      let query = { limit, offset: limit * page }
      if (!R.isNil(sortKey)) {
        query = R.assoc('sortKey', sortKey, query)
      }
      if (!R.isNil(sortDirection)) {
        query = R.assoc('sortDirection', sortDirection, query)
      }
      setPage(query)
      mergeSearch(history)({ page, limit, sortKey, sortDirection })
      return { query, isCurrentSortKey: R.equals(sortKey) }
    },
  ),
  lifecycle({
    componentDidMount() {
      const { onChange, search, query, setPage } = this.props
      setPage(query)
      onChange(search ? { search, ...query } : query)
      this.onChange = debounce(query => {
        mergeSearch(this.props.history)({ search: query.search })
        onChange(query)
      }, 1500)
    },
    componentDidUpdate({ query: oldQuery, search: oldSearch, setPage }) {
      const { currentSortKey, search, query } = this.props
      if (currentSortKey === '') return
      if (!shallowEqual(oldQuery, query)) {
        this.onChange(query)
      } else if (oldSearch !== search) {
        search ? this.onChange({ search, ...query }) : this.onChange(query)
      }
    },
  }),
)(TableSortDamp)

export default TableSort
