import { all, call, put, takeLatest } from 'redux-saga/effects'
import { startSubmit, stopSubmit } from 'redux-form'

import { handleErrors } from '../../aspects'
import * as AuthRepository from './AuthRepository'
import * as AuthManager from './AuthManager'
import { setToken, checkToken, signin, signout } from './duck'
import { ROUTES, FORMS } from '../../constants'
import { navigate } from '../navigation'
import { NotificationError } from '../../services'

const checkTokenSaga = function*() {
  const token = yield call(AuthRepository.getToken)
  yield put(setToken(token ? token : ''))
}

const signinSaga = handleErrors({
  anyError: function*() {
    yield put(stopSubmit(FORMS.SIGNIN))
  },
  '401': function*() {
    yield call(NotificationError('Пароль или почта введены не правильно'))
  },
})(function*({ payload: user }) {
  yield put(startSubmit(FORMS.SIGNIN))
  const token = yield call(AuthManager.signin, user)
  yield call(AuthRepository.setToken, token)
  yield put(setToken(token))
  yield put(stopSubmit(FORMS.SIGNIN))
  yield put(navigate(ROUTES.BASE))
})

const signoutSaga = function*() {
  yield call(AuthRepository.removeToken)
  yield put(setToken(''))
  yield put(navigate(ROUTES.SIGNIN))
}

const authSaga = function*() {
  yield all([
    takeLatest(checkToken, checkTokenSaga),
    takeLatest(signin, signinSaga),
    takeLatest(signout, signoutSaga),
  ])
}

export default authSaga
