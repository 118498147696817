import * as R from 'ramda'
import { all, put, takeLatest, select, call } from 'redux-saga/effects'
import * as CoursesManager from './CoursesManager'
import { getToken } from '../auth'
import {
  fetchCourses,
  setCourses,
  setCourse,
  deleteCourse,
  copyCourse,
  fetchCourse,
  setLessons,
  saveCourse,
} from './duck'
import { initialize } from 'redux-form'
import { FORMS, ROUTES } from '../../constants'
import { courseFormatting } from './mappers'
import { handleErrors } from '../../aspects'
import { NotificationError, NotificationSuccess } from '../../services'
import { navigate } from '../navigation'
import { getCurrentPage, TRIAL } from '../profile'

const anyError = handleErrors({
  anyError: function*() {
    yield call(NotificationError())
  },
})

const fetchCoursesSaga = anyError(function*({ payload }) {
  const token = yield select(getToken)
  const [courses, count] = yield call(CoursesManager.getCourses, token, payload)
  const items = courses.map(x => ({
    ...x,
    countWordSet: `${x.activeWordSets || 0}/${x.totalWordSets}`,
  }))
  yield put(setCourses(R.assoc('count', count, { items })))
})

const fetchCourseSaga = anyError(function*({ payload: id }) {
  const token = yield select(getToken)
  const lessons = yield call(CoursesManager.getLessons, token)
  yield put(setLessons(lessons || []))
  if (id) {
    const course = yield call(CoursesManager.getCourse, token, id)
    yield put(setCourse(course))
    yield put(
      initialize(FORMS.COURSE_FORM, {
        name: course.name,
        internalName: course.internalName,
        language: course.language,
        lessons:
          course.lessons
            .sort((a, b) => a.order - b.order)
            .map(({ id }) => id) || [],
        role: course.role === TRIAL,
      }),
    )
  } else {
    yield put(setCourse({}))
  }
})

const fetchSaveCourseSaga = anyError(function*({ payload }) {
  const token = yield select(getToken)

  const { id } = yield call(
    CoursesManager.saveCourse,
    token,
    courseFormatting(payload),
  )
  yield !payload.id && put(navigate(`${ROUTES.COURSES}/${id}`))
  yield put(fetchCourse(id))
  yield call(NotificationSuccess())
})

const deleteCourseSaga = anyError(function*({ payload }) {
  const token = yield select(getToken)
  yield call(CoursesManager.deleteCourse, token, payload)
  yield call(NotificationSuccess())
  const currentPage = yield select(getCurrentPage)
  yield call(fetchCoursesSaga, { payload: currentPage })
})

const copyWordSetSaga = anyError(function*({ payload: id }) {
  const token = yield select(getToken)
  yield call(CoursesManager.copyCourse, token, { id })
  yield call(NotificationSuccess())
  const currentPage = yield select(getCurrentPage)
  yield call(fetchCoursesSaga, { payload: currentPage })
})

const coursesSaga = function*() {
  yield all([
    takeLatest(fetchCourses, fetchCoursesSaga),
    takeLatest(fetchCourse, fetchCourseSaga),
    takeLatest(saveCourse, fetchSaveCourseSaga),
    takeLatest(deleteCourse, deleteCourseSaga),
    takeLatest(copyCourse, copyWordSetSaga),
  ])
}

export default coursesSaga
