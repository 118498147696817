import * as R from 'ramda'
import { FORMS } from '../../constants'

const getCourses = R.prop('courses')

const getCoursesForm = R.path(['form', FORMS.COURSE_FORM])

export const getCourseItems = R.pipe(
  getCourses,
  R.pathOr([], ['courses', 'items']),
)

export const getCourseCount = R.pipe(
  getCourses,
  R.pathOr(0, ['courses', 'count']),
)

export const getIsFetching = R.pipe(
  getCourses,
  R.prop('isFetching'),
)

export const getRelativeCourses = R.pipe(
  getCourses,
  R.prop('relativeItems'),
  R.map(({ id, internalName }) => ({ value: id, label: internalName })),
)

export const getLessons = R.pipe(
  getCourses,
  R.prop('lessons'),
  R.map(({ id, name }) => ({ value: id, label: name })),
)

export const getLessonsInForm = R.pipe(
  getCoursesForm,
  R.pathOr([], ['values', 'lessons']),
)

export const getSelectedLessons = R.converge(
  (lessons, selected) =>
    R.map(x => R.find(R.propEq('value', x), lessons), selected),
  [getLessons, getLessonsInForm],
)

export const getAvailableLessons = R.converge(
  (lessons, selected) => R.without(selected, lessons),
  [getLessons, getSelectedLessons],
)
