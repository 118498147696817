import React from 'react'
import styled from 'styled-components'
import { FormControl } from '@material-ui/core'
import Button from './Button'
import withRole from './withRole'

const Container = styled(FormControl)`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  height: 50px;
`

const OpenPickerButton = styled(Button)`
  height: 40px;
`

const Emoji = styled.img`
  margin-right: 30px;
  height: 40px;
  width: 40px;
`

const OpenPickerButtonWithRole = withRole(OpenPickerButton, true)

export const EmojiPickerDamp = ({ input, onClick, label }) => (
  <Container>
    <Emoji src={input.value} />
    <OpenPickerButtonWithRole onClick={onClick} name={label} />
  </Container>
)

const EmojiPicker = EmojiPickerDamp

export default EmojiPicker
