import React from 'react'
import { withRouter } from 'react-router-dom'
import { withHandlers, withStateHandlers } from 'recompose'
import * as R from 'ramda'
import { connect } from 'react-redux'
import {
  fetchSystemUsers,
  getSystemUsers,
  getSystemUserCount,
  fetchDeleteUser,
} from '../../../modules/users'
import { TableSort, EditButton, DeleteButton, Confirm } from '../commons'
import { ROUTES } from '../../../constants'

const dataHeaderSystem = [
  {
    name: 'ФИ',
    keySort: 'profile.lastName',
  },
  {
    name: 'Тип пользователя',
    keySort: 'user.role',
  },
  {
    name: 'Почта',
    keySort: 'user.email',
  },
  {
    name: 'Ред.',
    keySort: '',
  },
  {
    name: 'Удалить',
    keySort: '',
  },
]

const TableSystemUsers = ({
  remove,
  search,
  onClose,
  onDelete,
  isDeleting,
  systemUsers,
  countSystemUsers,
  fetchSystemUsers,
  navigateToUser,
}) => (
  <>
    <TableSort
      search={search}
      data={systemUsers}
      count={countSystemUsers}
      dataHeader={dataHeaderSystem}
      onChange={fetchSystemUsers}
      map={user =>
        R.pipe(
          R.props(['fullName', 'role', 'email']),
          R.append(<EditButton onClick={() => navigateToUser(user.id)} />),
          R.append(<DeleteButton onClick={() => remove(user.id)} />),
        )(user)
      }
    />
    {isDeleting && <Confirm onCancelClick={onClose} onDoneClick={onDelete} />}
  </>
)

export default R.compose(
  withRouter,
  connect(
    R.applySpec({
      systemUsers: getSystemUsers,
      countSystemUsers: getSystemUserCount,
    }),
    { fetchSystemUsers, fetchDeleteUser },
  ),
  withHandlers({
    navigateToUser: ({ history: { push: navigate } }) => id => {
      navigate(ROUTES.USERS + '/' + id)
    },
  }),
  withStateHandlers(
    { isDeleting: false, selectedId: null },
    {
      onClose: () => () => ({ isDeleting: false }),
      remove: () => id => ({ isDeleting: true, selectedId: id }),
      onDelete: ({ selectedId }, { fetchDeleteUser }) => () => {
        fetchDeleteUser(selectedId)
        return { isDeleting: false }
      },
    },
  ),
)(TableSystemUsers)
