import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { withTitle } from './withTitle'
import { Paper, Grid } from '@material-ui/core'
import { withStateHandlers, withHandlers } from 'recompose'
import { Header } from '../components/commons'
import styled from 'styled-components'
import { ROUTES } from '../../constants'
import { TableCourse } from '../components/courses'
import { fetchCourses } from '../../modules/courses'
import { searchToObject } from '../../utils'

const Container = styled(Paper)`
  padding: 20px;
`

const CourseScreenDamp = ({ setSearch, search, addCourse }) => (
  <Container>
    <Grid container direction="column">
      <Header
        title="Курсы"
        searchName="Поиск по курсам"
        buttonName="Добавить курс"
        onChange={setSearch}
        onClick={addCourse}
        value={search}
      />
      <TableCourse search={search} />
    </Grid>
  </Container>
)

const CourseScreen = R.compose(
  withTitle('Курсы'),
  connect(
    undefined,
    { fetchCourses },
  ),
  withStateHandlers(
    () =>
      R.pipe(
        () => new URLSearchParams(window.location.search),
        searchToObject,
        ({ search }) => ({ search: search || '' }),
      )(),
    { setSearch: () => ({ target: { value: search } }) => ({ search }) },
  ),
  withHandlers({
    addCourse: ({ history: { push: navigate } }) => () =>
      navigate(ROUTES.COURSE_FORM),
    getCourses: ({ fetchCourses }) => options => fetchCourses(options),
    navigateCourseForm: ({ history: { push: navigateTo } }) => () =>
      navigateTo(ROUTES.COURSE_FORM),
  }),
)(CourseScreenDamp)

export default CourseScreen
