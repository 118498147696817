import * as R from 'ramda'

export const debounce = (fn, ms) => {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => fn(...args), ms)
  }
}

export const searchToObject = x =>
  R.pipe(
    () => [...x.entries()],
    R.reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: !acc[key]
          ? value
          : Array.isArray(acc[key])
          ? [...acc[key], value]
          : [acc[key], value],
      }),
      {},
    ),
    R.map(x => (Array.isArray(x) ? R.map(strToType, x) : strToType(x))),
  )()
const strToType = str =>
  typeof str !== 'string'
    ? str
    : R.pipe(
        () => str,
        R.when(R.test(/^\d+$/), x => parseInt(x)),
        R.when(x => x === 'true', R.T),
        R.when(x => x === 'false', R.F),
      )()

export const objectToSearch = x =>
  R.pipe(
    () => Object.entries(x),
    R.reduce((acc, [key, value]) => {
      if (value === undefined) return acc
      if (Array.isArray(value)) {
        R.forEach(x => acc.append(key, x), value)
        return acc
      }
      acc.set(key, value)
      return acc
    }, new URLSearchParams()),
  )()

export const mergeSearch = history => obj =>
  R.pipe(
    () => new URLSearchParams(window.location.search),
    searchToObject,
    R.merge(R.__, obj),
    objectToSearch,
    x => history.replace(`${window.location.pathname}?${x}`),
  )()
