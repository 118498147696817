export const userFormatting = ({
  id,
  firstName,
  lastName,
  email,
  phone,
  groups,
}) =>
  id
    ? {
        id,
        firstName,
        lastName,
        phone: phone ? phone : null,
        groups,
        user: { id, email, role: 'MOBILE' },
      }
    : {
        firstName,
        lastName,
        phone: phone ? phone : null,
        groups,
        user: { email, role: 'MOBILE' },
      }

export const systemUserFormatting = ({
  id,
  firstName,
  lastName,
  email,
  phone,
  role,
  groups,
  password,
}) =>
  id
    ? {
        id,
        firstName,
        lastName,
        phone: phone ? phone : null,
        groups,
        user: { id, email, role, password },
      }
    : {
        firstName,
        lastName,
        phone: phone ? phone : null,
        groups,
        user: { email, role, password },
      }
