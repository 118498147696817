import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { Table, TableBody, TableRow, TableHead } from '@material-ui/core'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import {
  uploadUsers,
  addRelativeUsers,
  editRelativeUsers,
  removeRelativeUsers,
  getRelativeStudents,
  fetchStudentFromGroup,
} from '../../../modules/users'
import {
  withState,
  withHandlers,
  withStateHandlers,
  lifecycle,
} from 'recompose'
import * as Yup from 'yup'

import { reduxFormYup } from '../../../utils'
import { FORMS } from '../../../constants'

import {
  TextInputField,
  Divider,
  TableCell,
  DeleteButton,
  EditButton,
  Indent,
  Modal,
  AddButton,
  FileInput,
  Button,
  withRole,
} from '../../components/commons'
import { getUserRole } from '../../../modules/profile'

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const ImportButton = styled(FileInput)`
  margin-left: 20px;
`

const AddButtonWithRole = withRole(AddButton, true)
const EditButtonWithRole = withRole(EditButton, true)
const DeleteButtonWithRole = withRole(DeleteButton, true)

export const StudentScheme = Yup.object().shape({
  firstName: Yup.string()
    .max(50)
    .required(),
  lastName: Yup.string()
    .max(50)
    .required(),
  email: Yup.string()
    .email()
    .required(),
})

const StudentDamp = ({
  isEditing,
  studentsFromGroup: students,
  isOpenModal,
  enableModal,
  submitting,
  startEdit,
  disableModal,
  uploadUsers,
  role,
  groupId,
  removeRelativeUsers,
  submitEdit,
  submitAdd,
  initialize,
}) => (
  <>
    <Divider />
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Имя</TableCell>
          <TableCell>Фамилия</TableCell>
          <TableCell>Почта</TableCell>
          <TableCell>Телефон</TableCell>
          <TableCell />
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {students.map(({ id, firstName, lastName, email, phone }, index) => (
          <TableRow>
            <TableCell>{id}</TableCell>
            <TableCell>{firstName}</TableCell>
            <TableCell>{lastName}</TableCell>
            <TableCell>{email}</TableCell>
            <TableCell>{phone}</TableCell>
            {!R.contains('@test.com', email) ? (
              <>
                <TableCell>
                  <EditButtonWithRole
                    onClick={() => startEdit(students[index], index)}
                  />
                </TableCell>
                <TableCell>
                  <DeleteButtonWithRole
                    onClick={() => removeRelativeUsers(email)}
                  />
                </TableCell>
              </>
            ) : (
              <>
                <TableCell />
                <TableCell />
              </>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <Modal open={isOpenModal} onClose={disableModal} title="Ученик">
      <Form>
        <Indent />
        <Field name="email" label="Почта" component={TextInputField} />
        <Field
          name="firstName"
          label="Имя"
          component={TextInputField}
          disable={submitting}
        />
        <Field
          name="lastName"
          label="Фамилия"
          component={TextInputField}
          disable={submitting}
        />
        <Field
          name="phone"
          type="phone"
          label="Телефон"
          component={TextInputField}
          disable={submitting}
        />
        <Indent />
        {!isEditing && (
          <AddButton
            name="Добавить ученика"
            loading={submitting}
            onClick={submitAdd}
          />
        )}
        {!!isEditing && (
          <Button
            name="Сохранить изменнения"
            loading={submitting}
            onClick={submitEdit}
          />
        )}
      </Form>
    </Modal>
    <Indent />
    <RowContainer>
      <AddButtonWithRole
        name="Добавить ученика"
        onClick={() => (initialize({}), enableModal())}
      />
      {groupId && (
        <ImportButton
          input={{}}
          label="Импорт учеников"
          accept=".csv"
          onChange={file => uploadUsers({ file, groupId })}
          disabled={!R.isEmpty(students.filter(x => !x.id)) || role !== 'ADMIN'}
        />
      )}
    </RowContainer>
  </>
)

const Student = R.compose(
  connect(
    R.applySpec({ studentsFromGroup: getRelativeStudents, role: getUserRole }),
    {
      uploadUsers,
      addRelativeUsers,
      editRelativeUsers,
      removeRelativeUsers,
      fetchStudentFromGroup,
    },
  ),
  withState('isEditing', 'setIsEditing', false),
  withState('indexEditingStudent', 'setIndexEditingStudent'),
  reduxForm({
    form: FORMS.ADD_STUDENT_FROM,
    validate: (
      values,
      { studentsFromGroup, isEditing, indexEditingStudent },
    ) => {
      let validate = {}
      const tmp = R.findIndex(R.propEq('email', values.email))(
        studentsFromGroup,
      )
      if (
        (!isEditing &&
          R.filter(R.propEq('email', values.email))(studentsFromGroup)
            .length) ||
        (isEditing && tmp !== indexEditingStudent && tmp !== -1)
      )
        validate = { email: 'Почта должна быть уникальной' }
      return { ...reduxFormYup(StudentScheme)(values), ...validate }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { groupId, fetchStudentFromGroup } = this.props
      groupId && fetchStudentFromGroup(groupId)
    },
  }),
  withStateHandlers(
    { isOpenModal: false },
    {
      disableModal: (_, { setIsEditing }) => () => {
        setIsEditing(false)
        return { isOpenModal: false }
      },
      enableModal: () => () => ({ isOpenModal: true }),
    },
  ),
  withHandlers({
    startEdit: ({
      setIsEditing,
      enableModal,
      initialize,
      setIndexEditingStudent,
    }) => (data, index) => {
      setIsEditing(true)
      enableModal()
      initialize(data)
      setIndexEditingStudent(index)
    },
    submitEdit: ({
      handleSubmit,
      disableModal,
      editRelativeUsers,
      indexEditingStudent,
    }) =>
      handleSubmit(data => {
        disableModal()
        editRelativeUsers({ ...data, index: indexEditingStudent })
      }),
    submitAdd: ({ handleSubmit, disableModal, addRelativeUsers }) =>
      handleSubmit(data => {
        disableModal()
        if (!data.id || data.role === 'MOBILE' || data.user.role === 'MOBILE') {
          addRelativeUsers(data)
        }
      }),
  }),
)(StudentDamp)

export default Student
