import React from 'react'
import moment from 'moment'
import * as R from 'ramda'
import { connect } from 'react-redux'
import DateTimePicker from 'react-datetime-picker'
import { withHandlers } from 'recompose'
import { getUserRole } from '../../../modules/profile'

const DatePickerDumb = ({ input: { value }, onChangeDate, role }) =>
  role !== 'ADMIN' ? (
    <span>{moment.unix(value.timestamp).format('DD-MM-YYYY')}</span>
  ) : (
    <DateTimePicker
      locale={'ru-RU'}
      showLeadingZeros={true}
      onChange={onChangeDate}
      value={value.date}
    />
  )

const DatePicker = R.compose(
  connect(
    R.applySpec({
      role: getUserRole,
    }),
  ),
  withHandlers({
    onChangeDate: ({ input: { onChange } }) => date => {
      if (!!date && (date.getHours() === 0 && date.getMinutes() === 0)) {
        const newDate = new Date(date.setHours(23, 59))
        onChange({ date: newDate, timestamp: moment(newDate).format('X') })
      } else {
        !!date
          ? onChange({ date, timestamp: moment(date).format('X') })
          : onChange(null)
      }
    },
  }),
)(DatePickerDumb)

export default DatePicker
