import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { Field, reduxForm, FieldArray } from 'redux-form'
import { Grid } from '@material-ui/core'
import * as Yup from 'yup'
import { TextInputField, withRole } from '../commons'
import { FORMS } from '../../../constants'
import { connect } from 'react-redux'
import { reduxFormYup } from '../../../utils'
import { fetchGroupSchedule, getSchedule } from '../../../modules/groups'
import { getRelativeCourses } from '../../../modules/courses'
import { getTeacherForSelect } from '../../../modules/users'
import ScheduleForm from './ScheduleForm'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`

const GroupFormScheme = Yup.object().shape({
  name: Yup.string()
    .max(64)
    .required(),
})

const FieldWithRole = withRole(Field, true)

const GroupForm = ({
  submitting,
  groupId,
  teachers,
  courses,
  fetchGroupSchedule,
  schedule,
}) => (
  <Grid item sm={5}>
    <Form>
      <FieldWithRole
        name="name"
        label="Кодификатор"
        component={TextInputField}
        disable={submitting}
      />
      <FieldWithRole
        name="teacherId"
        label="Преподаватель"
        select
        options={teachers}
        component={TextInputField}
        disable={submitting}
        placeholder="Выбрать преподователя"
      />
      <FieldWithRole
        name="courseId"
        label="Курс"
        select
        options={courses}
        component={TextInputField}
        onChangeInput={(_, courseId) =>
          fetchGroupSchedule({ groupId, courseId })
        }
        placeholder="Выбрать курс"
        disable={!groupId || submitting}
      />
      <FieldArray
        name="schedule"
        component={ScheduleForm}
        schedule={schedule}
      />
    </Form>
  </Grid>
)

export default R.compose(
  reduxForm({
    form: FORMS.GROUP_FROM,
    validate: reduxFormYup(GroupFormScheme),
  }),
  connect(
    R.applySpec({
      schedule: getSchedule,
      courses: getRelativeCourses,
      teachers: getTeacherForSelect,
    }),
    { fetchGroupSchedule },
  ),
)(GroupForm)
