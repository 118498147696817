import React from 'react'
import styled from 'styled-components'
import { formatDate } from '../../../utils/formatDate'
import { Grid, Typography } from '@material-ui/core'

const Text = styled(Typography)`
  padding: 3px;
`

const UserInfo = ({ info }) => (
  <>
    <Grid item sm={3}>
      {!!info ? (
        <>
          <Text>Был Триал: {info.wasTrial ? 'Да' : 'Нет'}</Text>
          <Text>Рейтинг: {info.rating || 0}</Text>
          <Text>Процент выполнения: {info.percentCompletion || 0}%</Text>
          <Text>Общий процент ошибок: {info.errorsPercentage || 0}%</Text>
          <Text>Кол-во выученных наборов: {info.learned || 0}</Text>
          <Text>Кол-во просроченых наборов: {info.overdue || 0}</Text>
          <Text>Кол-во повторенных наборов: {info.repeated || 0}</Text>
        </>
      ) : (
        <Text>Ученик пока не проходил тесты</Text>
      )}
    </Grid>
    <Grid item sm={3}>
      <Text align="center">Дата регистрации</Text>
      <Text align="center">{info && formatDate(info.createdAt)}</Text>
    </Grid>
  </>
)

export default UserInfo
