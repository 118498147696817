import React from 'react'
import * as R from 'ramda'
import { Paper } from '@material-ui/core'
import { connect } from 'react-redux'
import { lifecycle, withProps } from 'recompose'
import styled from 'styled-components'
import { withTitle } from './withTitle'
import { WordSetMainInfo } from '../components/wordSets'
import {
  getWordSetStatistic,
  getIsFetchingStages,
  getStage1WithoutAvg,
  getStage1ForAvg,
  getStage2Stat,
  getHeadersStage2,
  getHeadersStage1,
  getWordSetInfo,
} from '../../modules/statistic'
import { StageOneInfo, StageTwoInfo } from '../components/users'
import { Preloader } from '../components/commons'

const Container = styled(Paper)``

const WordSetCourseDetailsDamp = ({
  isFetchingStages,
  stage1,
  stage1Avg,
  stage2,
  tasks,
  info,
  headers,
}) =>
  isFetchingStages ? (
    <Preloader />
  ) : (
    <Container>
      <WordSetMainInfo info={info} />
      <StageOneInfo data={stage1} tasks={tasks} avg={stage1Avg} />
      <StageTwoInfo data={stage2} headers={headers} />
    </Container>
  )

const WordSetCourseDetails = R.compose(
  withTitle('Статистика по набору в курсе'),
  connect(
    R.applySpec({
      isFetchingStages: getIsFetchingStages,
      stage1: getStage1WithoutAvg,
      stage1Avg: getStage1ForAvg,
      info: getWordSetInfo,
      stage2: getStage2Stat,
      tasks: getHeadersStage1,
      headers: getHeadersStage2,
    }),
    { getWordSetStatistic },
  ),
  withProps(({ match: { params: { id, courseId } } }) => ({
    wordSetId: id,
    courseId,
  })),
  lifecycle({
    componentDidMount() {
      const { getWordSetStatistic, wordSetId, courseId } = this.props
      getWordSetStatistic({ wordSetId, courseId })
    },
  }),
)(WordSetCourseDetailsDamp)

export default WordSetCourseDetails
