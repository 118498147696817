import React from 'react'
import * as R from 'ramda'
import * as Yup from 'yup'
import styled from 'styled-components'
import { Modal } from '@material-ui/core'
import { connect } from 'react-redux'
import { Field, reduxForm, initialize } from 'redux-form'
import { withHandlers } from 'recompose'
import { FORMS } from '../../../constants'
import { saveWord, getWordSetId } from '../../../modules/wordSets'
import { reduxFormYup } from '../../../utils'
import { Button, TextInputField } from '../commons'

const Form = styled.form`
  display: flex;
  margin: auto;
  margin-top: 10%;
  padding: 25px;
  border-radius: 10px;
  width: 50%;
  flex-direction: column;
  background-color: #fff;
`

const AddWordScheme = Yup.object().shape({
  word: Yup.string().required(),
  translation: Yup.string().required(),
  interpretation: Yup.string().required(),
  transcription: Yup.string().required(),
  example: Yup.string().required(),
  image: Yup.mixed().required(),
  audio: Yup.mixed().required(),
})

const WordFormDamp = ({ submitWord, submitting, open, onClose }) => (
  <Modal open={open} onClose={onClose}>
    <Form>
      <Field name="word" label="Слово" component={TextInputField} />
      <Field name="translation" label="Перевод" component={TextInputField} />
      <Field
        name="interpretation"
        label="Определение"
        component={TextInputField}
      />
      <Field
        name="transcription"
        label="Транскрипция"
        component={TextInputField}
      />
      <Field name="example" label="Контекст" component={TextInputField} />
      <Field
        name="audio"
        type="file"
        label="Добавить аудио"
        accept={'.mp3'}
        component={TextInputField}
      />
      <Field
        name="image"
        type="file"
        label="Добавить картинку"
        accept={'image/*'}
        component={TextInputField}
      />
      <Button type="submit" onClick={submitWord} loading={submitting}>
        {'Отправить'}
      </Button>
    </Form>
  </Modal>
)

const WordForm = R.compose(
  connect(
    R.applySpec({ wordSetId: getWordSetId }),
    { saveWord, initialize },
  ),
  reduxForm({
    form: FORMS.WORD,
    validate: reduxFormYup(AddWordScheme),
  }),
  withHandlers({
    submitWord: ({ handleSubmit, saveWord, wordSetId }) =>
      handleSubmit(data => saveWord({ wordSetId, ...data })),
  }),
)(WordFormDamp)

export default WordForm
