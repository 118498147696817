import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { Paper, Grid } from '@material-ui/core'
import { reduxForm } from 'redux-form'
import { withHandlers, lifecycle } from 'recompose'
import { Divider, Button, withRole } from '../components/commons'
import { UserSystemForm as Form } from '../components/users'
import { FORMS } from '../../constants'
import { withTitle } from './withTitle'
import { connect } from 'react-redux'
import { fetchSaveUser, getSelectedUserId } from '../../modules/users'
import { fetchGroups, getGroupsForSystemSelect } from '../../modules/groups'

const Container = styled(Paper)`
  padding-top: 20px;
`

const SaveButton = styled(Button)`
  margin-left: auto;
  margin-right: 20px;
`

const SaveButtonWithRole = withRole(SaveButton, true)

const UserSystemFormDamp = ({ submitSave, submitting, groups }) => {
  return (
    <Container>
      <Grid container direction="column">
        <Divider>
          <SaveButtonWithRole
            onClick={submitSave}
            loading={submitting}
            name="Сохранить"
          />
        </Divider>
        <Form groups={groups} />
      </Grid>
    </Container>
  )
}

const UserSystemForm = R.compose(
  withTitle('Системный пользователь'),
  reduxForm({ form: FORMS.USER_SYSTEM_FORM }),
  connect(
    R.applySpec({
      currentUserId: getSelectedUserId,
      groups: getGroupsForSystemSelect,
    }),
    { fetchGroups, fetchSaveUser },
  ),
  withHandlers({
    submitSave: ({ handleSubmit, fetchSaveUser, currentUserId: id }) =>
      handleSubmit(user => fetchSaveUser({ id, ...user })),
  }),
  lifecycle({
    componentDidMount() {
      const { fetchGroups } = this.props
      fetchGroups()
    },
  }),
)(UserSystemFormDamp)

export default UserSystemForm
