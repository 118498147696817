import { MODULES } from '../../constants'
import { handleAction, createAction } from 'redux-actions'
import { combineReducers } from 'redux'

export const checkToken = createAction(`${MODULES.AUTH}/CHECK_TOKEN`)
export const setToken = createAction(`${MODULES.AUTH}/SET_TOKEN`)
export const signin = createAction(`${MODULES.AUTH}/SIGNIN`)
export const signout = createAction(`${MODULES.AUTH}/SIGNOUT`)

const token = handleAction(setToken, (state, { payload }) => payload, null)

const auth = combineReducers({ token })

export default auth
