import React from 'react'
import styled from 'styled-components'
import { formatDate, getTime } from '../../../utils/formatDate'
import { Grid, Typography } from '@material-ui/core'

const ContainerMainInfo = styled.div`
  padding: 20px;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const Info = styled.div`
  width: 50%;
`

const Text = styled(Typography)`
  padding: 10px;
`

const WordSetMainInfo = ({ group, info = {} }) => (
  <ContainerMainInfo>
    <Grid container direction={'column'}>
      <RowContainer>
        <Info item sm={3}>
          <Text>{`${group ? 'Кодификатор' : 'Название курса'}: ${
            info.name
          }`}</Text>
          <Text>{`Название набора: ${info.title}`}</Text>
          <Text>{`Среднее время прохождения: ${getTime(info.time || 0)}`}</Text>
          <Text>{`Этап 1: ${getTime(info.stage1Time || 0)}`}</Text>
          <Text>{`Этап 2: ${getTime(info.stage2Time || 0)}`}</Text>
        </Info>
        <Info item sm={3}>
          <Text>{`Выполняемость: ${info.executability || 0}%`}</Text>
          <Text>{`Повторяемость набора: ${info.repeatability}%`}</Text>
          <Text />
          <Text>{`Набор создан: ${formatDate(info.createdAt)} by ${
            info.creator
          }`}</Text>
          <Text>{`Набор обновлен: ${formatDate(info.updatedAt)} by ${
            info.editor
          }`}</Text>
        </Info>
      </RowContainer>
    </Grid>
  </ContainerMainInfo>
)

export default WordSetMainInfo
