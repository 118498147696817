import * as R from 'ramda'
import API from '../../api'
import { handleStatuses } from '../../aspects'
import { auth, query } from '../../utils/query'

export const getWordSets = R.pipeP(
  (token, data) => fetch(`${API.GET_WORD_SET_STAT}${query(data)}`, auth(token)),
  handleStatuses(),
  res => Promise.all([res.json(), parseInt(res.headers.get('count'))]),
  R.adjust(R.prop('data'), 0),
)

export const getWordSet = R.pipeP(
  (token, id) => fetch(`${API.WORD_SET}/${id}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const publishWordSet = R.pipeP(
  (token, id) =>
    fetch(`${API.publishWordSet(id)}`, {
      method: 'PUT',
      headers: { Authorization: `Bearer ${token}` },
    }),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const getWordSetsByCourseId = R.pipeP(
  (token, data) => fetch(`${API.WORD_SET}${query(data)}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const getRules = R.pipeP(
  (token, data) => fetch(`${API.RULES}${query(data)}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const saveWordSet = R.pipeP(
  (token, wordSet) =>
    fetch(API.WORD_SET, {
      method: wordSet.id ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(wordSet),
    }),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const saveStage2 = R.pipeP(
  (token, data) =>
    fetch(API.WORD_SET_STAGE2, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }),
  handleStatuses(),
  res => res.json(),
)

export const copyWordSet = R.pipeP(
  (token, wordSet) =>
    fetch(`${API.WORD_SET_COPY}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(wordSet),
    }),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const deleteWordSet = R.pipeP(
  (token, id) =>
    fetch(`${API.WORD_SET}/${id}`, {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${token}` },
    }),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const deleteRule = R.pipeP(
  (token, id) =>
    fetch(`${API.RULES}/${id}`, {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${token}` },
    }),
  handleStatuses(),
  res => res.json(),
)

export const getWordSetCount = R.pipeP(
  (token, data) => fetch(`${API.WORD_SET}/count${query(data)}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.pathOr(0, ['data', 'wordSetsCount']),
)

export const saveWord = R.pipeP(
  (token, body) =>
    fetch(API.WORD, {
      method: body.get('id') ? 'PUT' : 'POST',
      headers: { Authorization: `Bearer ${token}` },
      body,
    }),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const deleteWord = R.pipeP(
  (token, id) =>
    fetch(`${API.WORD}/${id}`, {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${token}` },
    }),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const loadWords = R.pipeP(
  (token, body) =>
    fetch(`${API.WORD_UPLOAD}`, {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      body,
    }),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const loadRule = R.pipeP(
  (token, body) =>
    fetch(`${API.RULES_UPLOAD}`, {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      body,
    }),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const loadAudition = R.pipeP(
  (token, body) =>
    fetch(`${API.AUDIO}`, {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      body,
    }),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const findAudioByName = R.pipeP(
  (token, data) => fetch(`${API.SEARCH_AUDIO}${query(data)}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)
