import * as R from 'ramda'
import { ROLE, FORMS } from '../../constants'
import { getFormValues } from 'redux-form'

const getUsers = R.prop('users')

export const getStudents = R.pipe(
  getUsers,
  R.pathOr([], ['students', 'users']),
  R.map(profile =>
    R.merge(profile, {
      fullName: `${profile.firstName} ${profile.lastName}`,
      groups: R.intersperse(', ', profile.groups.map(R.prop('name'))),
      percentCompletion: profile.percentCompletion || 0,
    }),
  ),
)

export const getSystemUsers = R.pipe(
  getUsers,
  R.pathOr([], ['systemUsers', 'users']),
  R.map(profile =>
    R.merge(profile, {
      fullName: `${profile.firstName} ${profile.lastName}`,
      role: ROLE[profile.user.role],
      email: profile.user.email,
    }),
  ),
)

export const getStudentCount = R.pipe(
  getUsers,
  R.pathOr(0, ['students', 'count']),
)

export const getSystemUserCount = R.pipe(
  getUsers,
  R.pathOr(0, ['systemUsers', 'count']),
)

export const getRelativeUsers = R.pipe(
  getUsers,
  R.prop('relativeUsers'),
)

export const getSeletedGroups = R.pipe(
  getUsers,
  R.prop('seletedGroups'),
  R.map(({ id, name }) => ({ value: id, label: name })),
)

export const getRelativeStudents = R.pipe(
  getUsers,
  R.prop('relativeUsers'),
  R.filter(x => !x.id || (x.user && x.user.role === 'MOBILE')),
  R.map(profile =>
    profile.id
      ? R.assoc('email', profile.user.email, profile)
      : R.assoc('id', '', profile),
  ),
)

export const getTeacherForSelect = R.pipe(
  getUsers,
  R.prop('relativeUsersSystem'),
  R.map(({ id, lastName, firstName }) => ({
    value: id,
    label: `${lastName} ${firstName}`,
  })),
  R.append({ value: null, label: 'Без преподавателя' }),
)

export const getRelativeUsersSystem = R.pipe(
  getUsers,
  R.prop('relativeUsersSystem'),
)

export const getCurrentUserId = R.pipe(
  getUsers,
  R.prop('currentUserId'),
)

export const getIsUserFetching = R.pipe(
  getUsers,
  R.prop('isFetching'),
)

export const getSelectedUser = R.pipe(
  getUsers,
  R.prop('selectedUser'),
)

export const getSelectedUserRoles = R.pipe(
  getSelectedUser,
  R.prop('role'),
)

export const getSelectedUserId = R.pipe(
  getSelectedUser,
  R.prop('id'),
)

export const getSelectedGroup = R.pipe(
  getFormValues(FORMS.USER_FORM),
  R.prop('group'),
)
