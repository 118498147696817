import React from 'react'
import * as R from 'ramda'
import { Grid, Paper, Typography } from '@material-ui/core'
import { Field as FieldReduxForm, reduxForm } from 'redux-form'
import { Redirect } from 'react-router-dom'
import * as Yup from 'yup'
import styled from 'styled-components'
import { signin, getIsSignedin } from '../../modules/auth'
import { connect } from 'react-redux'
import { reduxFormYup } from '../../utils'
import { ROUTES, FORMS } from '../../constants'
import { TextInputField, Button } from '../components/commons'

const Container = styled(Grid).attrs({ container: true })`
  justify-content: center;
  align-items: center;
  flex: 1;
`

const SigninPaper = styled(Paper).attrs({ elevation: 1 })`
  padding: 30px;
  min-width: 300px;
  max-width: 400px;
`

const ContinueButton = styled(Button).attrs({
  fullWidth: true,
  size: 'large',
})`
  margin-top: 20px;
`

const Field = styled(FieldReduxForm).attrs({
  margin: 'dense',
  fullWidth: true,
})``

const Title = styled(Typography).attrs({
  variant: 'h5',
  gutterBottom: true,
  align: 'center',
})``

const SigninScheme = Yup.object().shape({
  email: Yup.string()
    .email()
    .max(50)
    .required(),
  password: Yup.string()
    .max(50)
    .required(),
})

const SigninDamp = ({ submitting, handleSubmit, isSignedin, signin }) => (
  <>
    {!!isSignedin && <Redirect to={ROUTES.BASE} />}
    {!isSignedin && (
      <Container>
        <SigninPaper>
          <Title>Авторизация</Title>
          <form onSubmit={handleSubmit(signin)}>
            <Field name="email" label="Логин" component={TextInputField} />
            <Field
              name="password"
              type="password"
              label="Пароль"
              component={TextInputField}
            />
            <ContinueButton type="submit" loading={submitting} name="Войти" />
          </form>
        </SigninPaper>
      </Container>
    )}
  </>
)

const Signin = R.compose(
  reduxForm({
    form: FORMS.SIGNIN,
    validate: reduxFormYup(SigninScheme),
  }),
  connect(
    R.applySpec({ isSignedin: getIsSignedin }),
    { signin },
  ),
)(SigninDamp)

export default Signin
