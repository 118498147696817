import { withRouter } from 'react-router-dom'
import { createFactory } from 'react'

export const withNavigate = (propName, nameScreen) => {
  function withNavigateWrapper(BaseComponent) {
    const factory = createFactory(BaseComponent)
    const WithNavigate = withRouter(props =>
      factory({
        [propName]: options =>
          props.history.push({ pathname: nameScreen, ...options }),
        ...props,
      }),
    )
    return WithNavigate
  }
  withNavigateWrapper.toString = () => nameScreen
  return withNavigateWrapper
}
