import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { connect } from 'react-redux'
import {
  Grid,
  Tab,
  AppBar as AppBarMaterial,
  Tabs as TabsMaterial,
  CircularProgress,
} from '@material-ui/core'
import {
  getRatingTable,
  getWordSetStatByUser,
  getStage1WithoutAvg,
  getStage1ForAvg,
  getStage2Stat,
  getHeadersStage1,
  getHeadersStage2,
  getIsFetchingStages,
  getIsFetchingUser,
} from '../../../modules/statistic'
import { withState } from 'recompose'
import { WordSetInfo, StageOneInfo, StageTwoInfo, MainStatistic } from '.'

const AppBar = styled(AppBarMaterial).attrs({
  position: 'static',
})`
  background-color: rgb(241, 77, 27);
  margin-bottom: 10px;
`

const Tabs = styled(TabsMaterial).attrs({
  indicatorColor: 'primary',
  fullWidth: true,
})`
  color: #fff;
`

const Title = styled.span`
  padding: 30px 0 15px 0;
  font-size: 20px;
`

const Loader = styled(CircularProgress)`
  margin: 20px auto;
`

const UserStatisticsDamp = ({
  tab,
  userId,
  stage1,
  stage2,
  tasks,
  headers,
  stage1Avg,
  ratingTable,
  setCurrentTab,
  currentWordSet,
  setCurrentWordSet,
  isFetchingUser,
  isFetchingStages,
  wordSetStats,
}) => (
  <Grid style={{ paddingTop: 60 }} sm={12} container>
    <AppBar>
      <Tabs value={tab} onChange={(_, numberTab) => setCurrentTab(numberTab)}>
        <Tab label="Наборы и Этапы" />
        <Tab label="Рейтинг" />
      </Tabs>
    </AppBar>
    {tab === 0 &&
      (!isFetchingUser ? (
        <Grid sm={12} container justify="space-around">
          <Title>Наборы</Title>
          <WordSetInfo
            userId={userId}
            statistic={wordSetStats}
            currentWordSet={currentWordSet}
            isFetchingStages={isFetchingStages}
            setCurrentWordSet={setCurrentWordSet}
          />
          <StageOneInfo data={stage1} tasks={tasks} avg={stage1Avg} />
          <StageTwoInfo data={stage2} headers={headers} />
        </Grid>
      ) : (
        <Loader />
      ))}
    {tab === 1 && <MainStatistic rating={ratingTable} />}
  </Grid>
)

const UserStatistics = R.compose(
  connect(
    R.applySpec({
      ratingTable: getRatingTable,
      wordSetStats: getWordSetStatByUser,
      stage1: getStage1WithoutAvg,
      stage1Avg: getStage1ForAvg,
      stage2: getStage2Stat,
      tasks: getHeadersStage1,
      headers: getHeadersStage2,
      isFetchingStages: getIsFetchingStages,
      isFetchingUser: getIsFetchingUser,
    }),
  ),
  withState('tab', 'setCurrentTab', 0),
)(UserStatisticsDamp)

export default UserStatistics
