import * as R from 'ramda'

const getStatistic = R.prop('statistic')

const getUserStatistic = R.pipe(
  getStatistic,
  R.prop('user'),
)

const getUserStisticsByGroup = R.pipe(
  getUserStatistic,
  R.prop('userStisticsByGroup'),
)

const getStatisticGroup = R.pipe(
  getStatistic,
  R.prop('group'),
)

const getStatisticCourse = R.pipe(
  getStatistic,
  R.prop('course'),
)

const getStatisticWordSet = R.pipe(
  getStatistic,
  R.prop('wordSet'),
)

export const getIsFetchingUser = R.pipe(
  getStatistic,
  R.prop('isFetchingUser'),
)

export const getIsFetchingStages = R.pipe(
  getStatistic,
  R.prop('isFetchingStages'),
)

export const getIsFetchingGroup = R.pipe(
  getStatistic,
  R.prop('isFetchingGroup'),
)

export const getIsFetchingCourse = R.pipe(
  getStatistic,
  R.prop('isFetchingCourse'),
)

export const getWordSetStatByUser = R.pipe(
  getUserStisticsByGroup,
  R.propOr([], 'wordSetStats'),
)

const getStageStatistic = R.pipe(
  getUserStatistic,
  R.prop('stageStatistics'),
)

const getStage1 = R.pipe(
  getStageStatistic,
  R.prop('stage1'),
)

const getStage2 = R.pipe(
  getStageStatistic,
  R.prop('stage2'),
)

export const getStage1WithoutAvg = R.pipe(
  getStage1,
  R.propOr([], 'statistics'),
  x => R.groupBy(x => x.word)(x),
)

export const getStage1ForAvg = R.pipe(
  getStage1,
  R.propOr([], 'statistics'),
  x => R.groupBy(x => x.testType)(x),
)

export const getHeadersStage1 = R.pipe(
  getStage1,
  R.propOr([], 'headers'),
)

export const getStage2Stat = R.pipe(
  getStage2,
  R.propOr([], 'statistics'),
)

export const getHeadersStage2 = R.pipe(
  getStage2,
  R.propOr([], 'headers'),
)

export const getProfileInfo = R.pipe(
  getUserStisticsByGroup,
  R.prop('detailedProfile'),
)

export const getRatingTable = R.pipe(
  getUserStisticsByGroup,
  R.propOr({}, 'ratingTable'),
  rating =>
    Object.keys(rating).reduce(
      (acc, key) =>
        R.contains('Score', key)
          ? acc
          : [
              ...acc,
              {
                type: key,
                count: rating[key],
                score: rating[key + 'Score'],
              },
            ],
      [],
    ),
)

export const getGroupInfo = R.pipe(
  getStatisticGroup,
  R.propOr({}, 'groupInfo'),
  info => ({
    ...info,
    wordSets: `${info.activeWordSets || 0}/${info.totalWordSets || 0}`,
    teacher: `${R.pathOr(
      'Без преподавателя',
      ['teacher', 'lastName'],
      info,
    )} ${R.pathOr('', ['teacher', 'firstName'], info)}`,
    creator: `${R.pathOr('', ['creator', 'lastName'], info)} ${R.pathOr(
      '',
      ['creator', 'firstName'],
      info,
    )}`,
    editor: `${R.pathOr('', ['editor', 'lastName'], info)} ${R.pathOr(
      '',
      ['editor', 'firstName'],
      info,
    )}`,
  }),
)

export const getWordSetInfo = R.pipe(
  getStatisticWordSet,
  R.propOr({}, 'wordSetInfo'),
  info => ({
    ...info,
    creator: `${R.pathOr('', ['creator', 'lastName'], info)} ${R.pathOr(
      '',
      ['creator', 'firstName'],
      info,
    )}`,
    editor: `${R.pathOr('', ['editor', 'lastName'], info)} ${R.pathOr(
      '',
      ['editor', 'firstName'],
      info,
    )}`,
  }),
)

export const getGroupStudents = R.pipe(
  getStatisticGroup,
  R.propOr([], 'groupStudents'),
)

export const getGroupWordSets = R.pipe(
  getStatisticGroup,
  R.propOr([], 'groupWordSets'),
)

export const getCourseInfo = R.pipe(
  getStatisticCourse,
  R.propOr({}, 'courseInfo'),
  info => ({
    ...info,
    wordSets: `${info.activeWordSets || 0}/${info.totalWordSets || 0}`,
    creator: `${R.pathOr('', ['creator', 'lastName'], info)} ${R.pathOr(
      '',
      ['creator', 'firstName'],
      info,
    )}`,
    editor: `${R.pathOr('', ['editor', 'lastName'], info)} ${R.pathOr(
      '',
      ['editor', 'firstName'],
      info,
    )}`,
  }),
)

export const getCourseGroups = R.pipe(
  getStatisticCourse,
  R.propOr([], 'courseGroups'),
  R.map(x => ({
    ...x,
    wordSets: `${x.activeWordSets}/${x.totalWordSets}`,
    teacher: x.lastName ? `${x.lastName} ${x.firstName}` : 'Без преподавателя',
  })),
)

export const getCourseWordSets = R.pipe(
  getStatisticCourse,
  R.propOr([], 'courseWordSets'),
)
