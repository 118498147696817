import { MODULES } from '../../constants'
import { handleAction, createAction, handleActions } from 'redux-actions'
import { combineReducers } from 'redux'
import * as R from 'ramda'

export const fetchUserRequest = createAction(
  `${MODULES.USERS}/FETCH_USER_REQUEST`,
)
export const fetchUserSuccess = createAction(
  `${MODULES.USERS}/FETCH_USERS_SUCCESS`,
)
export const fetchUserFailure = createAction(
  `${MODULES.USERS}/FETCH_USERS_FAILURE`,
)

export const resetSelectedUser = createAction(
  `${MODULES.USERS}/RESET_SELECTED_USER`,
)

export const fetchStudents = createAction(`${MODULES.USERS}/FETCH_STUDENTS`)
export const fetchSystemUsers = createAction(
  `${MODULES.USERS}/FETCH_SYSYTEM_USERS`,
)

export const setStudents = createAction(`${MODULES.USERS}/SET_STUDENTS`)
export const setSystemUsers = createAction(`${MODULES.USERS}/SET_SYSTEM_USERS`)

export const fetchSaveUser = createAction(`${MODULES.USERS}/FETCH_SAVE_USER`)
export const fetchDeleteUser = createAction(
  `${MODULES.USERS}/FETCH_DELETE_USER`,
)

export const fetchActiveUser = createAction(
  `${MODULES.USERS}/FETCH_ACTIVE_USER`,
)

export const fetchStudentFromGroup = createAction(
  `${MODULES.USERS}/FETCH_STUDENT_FROM_GROUP`,
)

export const fetchUserStatistics = createAction(
  `${MODULES.USERS}/FETCH_USER_STATISTICS`,
)

export const setCurrentUser = createAction(`${MODULES.USERS}/SET_CURRENT_USER`)

export const setRelativeUsers = createAction(
  `${MODULES.USERS}/SET_RELATIVE_USERS`,
)
export const editRelativeUsers = createAction(
  `${MODULES.USERS}/EDIT_RELATIVE_USERS`,
)
export const removeRelativeUsers = createAction(
  `${MODULES.USERS}/REMOVE_RELATIVE_USERS`,
)
export const addRelativeUsers = createAction(
  `${MODULES.USERS}/ADD_RELATIVE_USERS`,
)

export const setRelativeUsersSystem = createAction(
  `${MODULES.USERS}/SET_RELATIVE_USERS_SYSTEM`,
)

export const setSeletedGroups = createAction(
  `${MODULES.USERS}/SET_SELECTED_GROUPS`,
)

export const uploadUsers = createAction(`${MODULES.USERS}/UPLOAD_USERS`)

const students = handleAction(setStudents, (_, { payload }) => payload, [])

const systemUsers = handleAction(
  setSystemUsers,
  (_, { payload }) => payload,
  [],
)

const currentUser = handleAction(
  setCurrentUser,
  (_, { payload }) => payload,
  null,
)

const relativeUsersSystem = handleAction(
  setRelativeUsersSystem,
  (_, { payload }) => payload,
  [],
)

const relativeUsers = handleActions(
  {
    [setRelativeUsers]: (_, { payload }) => payload,
    [editRelativeUsers]: (state, { payload: { index, ...rest } }) =>
      R.ifElse(
        R.allPass([R.find(R.propEq('id', rest.id)), () => rest.id]),
        R.adjust(R.always(rest), R.findIndex(R.propEq('id', rest.id), state)),
        R.adjust(R.merge(R.__, rest), index),
      )(state),
    [removeRelativeUsers]: (state, { payload: email }) =>
      R.filter(
        R.pipe(
          R.ifElse(
            R.prop('user'),
            R.pathEq(['user', 'email'], email),
            R.propEq('email', email),
          ),
          R.not,
        ),
      )(state),
    [addRelativeUsers]: (state, { payload }) =>
      R.ifElse(
        R.allPass([R.find(R.propEq('id', payload.id)), () => payload.id]),
        R.adjust(
          R.always(payload),
          R.findIndex(R.propEq('id', payload.id), state),
        ),
        R.append(payload),
      )(state),
  },
  [],
)

const isFetching = handleActions(
  {
    [fetchUserRequest]: R.T,
    [fetchUserSuccess]: R.F,
    [fetchUserFailure]: R.F,
  },
  false,
)

const selectedUser = handleActions(
  {
    [fetchUserSuccess]: R.pipe(
      R.nthArg(1),
      R.prop('payload'),
    ),
    [resetSelectedUser]: R.always({}),
  },
  {},
)

const seletedGroups = handleActions(
  {
    [setSeletedGroups]: (_, { payload }) => payload,
  },
  [],
)

const users = combineReducers({
  students,
  systemUsers,
  currentUser,
  relativeUsers,
  relativeUsersSystem,
  isFetching,
  seletedGroups,
  selectedUser,
})

export default users
