import * as R from 'ramda'
import API from '../../api'
import { handleStatuses } from '../../aspects'

export const getProfile = R.pipeP(
  token =>
    fetch(API.GET_ME_PROFILE, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  handleStatuses(),
  res => res.json(),
  R.propOr({}, 'data'),
)
