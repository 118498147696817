import React from 'react'
import styled from 'styled-components'
import { formatDate, getTime } from '../../../utils/formatDate'
import { Grid, Typography } from '@material-ui/core'

const ContainerMainInfo = styled.div`
  padding: 20px;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const Info = styled.div`
  width: 50%;
`

const Text = styled(Typography)`
  padding: 10px;
`

const CourseMainInfo = ({ info }) => (
  <ContainerMainInfo>
    <Grid container direction={'column'}>
      <RowContainer>
        <Info item sm={3}>
          <Text>{`Название курса: ${info.internalName} ${info.name}`}</Text>
          <Text>{`Количество наборов: ${info.wordSets}`}</Text>
          <Text>{`Язык курса: ${info.language}`}</Text>
          <Text>{`Количество групп на курсе: ${info.countGroup}`}</Text>
          <Text>{`Средний процент выполнения: ${info.percentCompletion ||
            0}%`}</Text>
        </Info>
        <Info item sm={3}>
          <Text>{`Среднее время прохождения: ${getTime(info.time || 0)}`}</Text>
          <Text />
          <Text>{`Курс создан: ${formatDate(info.createdAt)} by ${
            info.creator
          }`}</Text>
          <Text>{`Курс обновлен: ${formatDate(info.updatedAt)} by ${
            info.editor
          }`}</Text>
        </Info>
      </RowContainer>
    </Grid>
  </ContainerMainInfo>
)

export default CourseMainInfo
