import * as R from 'ramda'
import { MODULES } from '../../constants'
import { combineReducers } from 'redux'
import { handleAction, handleActions, createAction } from 'redux-actions'

export const fetchCourses = createAction(`${MODULES.COURSES}/FETCH_COURSES`)
export const setCourses = createAction(`${MODULES.COURSES}/SET_COURSES`)
export const setCourse = createAction(`${MODULES.COURSES}/SET_COURSE`)

export const fetchCourse = createAction(`${MODULES.COURSES}/FETCH_COURSE`)
export const saveCourse = createAction(`${MODULES.COURSES}/SAVE_COURSE`)

export const copyCourse = createAction(`${MODULES.COURSES}/COPY_COURSE`)
export const deleteCourse = createAction(`${MODULES.COURSES}/DELETE_COURSE`)

export const setLessons = createAction(`${MODULES.COURSES}/SET_LESSONS`)

export const setRelativeCourses = createAction(
  `${MODULES.COURSES}/SET_RELATIVE_COURSES`,
)

const courses = handleAction(setCourses, (_, { payload }) => payload, {})

const isFetching = handleActions(
  { [fetchCourse]: R.T, [setCourse]: R.F },
  false,
)

const relativeItems = handleAction(
  setRelativeCourses,
  (_, { payload }) => payload,
  [],
)

const lessons = handleAction(setLessons, (_, { payload }) => payload, [])

const course = combineReducers({ courses, isFetching, relativeItems, lessons })

export default course
