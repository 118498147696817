import React from 'react'
import * as R from 'ramda'
import { withStateHandlers } from 'recompose'
import styled from 'styled-components'
import { Grid, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import { Button } from '../commons'
import { formatDate, getTime } from '../../../utils/formatDate'
import { fetchActiveUser } from '../../../modules/users'
import { TestUserModal } from '.'

const ContainerMainInfo = styled.div`
  padding: 20px;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const Info = styled.div`
  width: 50%;
`

const Text = styled(Typography)`
  padding: 10px;
`

const GroupMainInfoDumb = ({
  info,
  fetchActiveUser,
  isActivating,
  requestActive,
  onClose,
}) => (
  <ContainerMainInfo>
    <Grid container direction={'column'}>
      <RowContainer>
        <Info item sm={3}>
          <Text>{`Название группы: ${info.name}`}</Text>
          <Text>{`Преподаватель: ${info.teacher}`}</Text>
          <Text>{`Количество учеников в группе: ${info.studentCount}`}</Text>
          <Text>{`Средний процент выполнения: ${info.percentCompletion ||
            0}%`}</Text>
        </Info>
        <Info item sm={3}>
          <Text>{`Среднее время прохождения: ${getTime(info.time || 0)}`}</Text>
          <Text>{`Наборов доступно ученикам: ${info.wordSets}`}</Text>
          <Text />
          <Text>{`Группа создана: ${formatDate(info.createdAt)} by ${
            info.creator
          }`}</Text>
          <Text>{`Группа обновлена: ${formatDate(info.updatedAt)} by ${
            info.editor
          }`}</Text>
          <Text />
          {info.userId ? (
            <Button name="Тестовый ученик" onClick={requestActive} />
          ) : (
            <Text>Нет тестового ученика</Text>
          )}
        </Info>
      </RowContainer>
    </Grid>
    {isActivating && (
      <TestUserModal
        userId={info.userId}
        qr={info.qr}
        onCancelClick={onClose}
        onDoneClick={fetchActiveUser}
      />
    )}
  </ContainerMainInfo>
)

const GroupMainInfo = R.compose(
  connect(
    undefined,
    { fetchActiveUser },
  ),
  withStateHandlers(
    { isActivating: false },
    {
      requestActive: () => () => ({ isActivating: true }),
      onClose: () => () => ({ isActivating: false }),
    },
  ),
)(GroupMainInfoDumb)

export default GroupMainInfo
