import React from 'react'
import * as R from 'ramda'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { connect } from 'react-redux'
import TableWord from './TableWord'
import WordSets from './WordSets'
import WordForm from './WordForm'
import { loadWords } from '../../../modules/wordSets'
import { withHandlers, withStateHandlers } from 'recompose'
import { FileInput, Button as ButtonMaterial } from '../commons'
import { initialize } from 'redux-form'
import { FORMS } from '../../../constants'
import { getUserRole } from '../../../modules/profile'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const AddWordButton = styled(ButtonMaterial)`
  margin: 20px auto;
`

const AddWordsButton = styled(ButtonMaterial)`
  margin: 0 20px 20px auto;
`

const LoadWords = styled(FileInput)`
  margin: 0 20px 20px auto;
`

const WordsDamp = ({
  active,
  wordSetId,
  openWordForm,
  closeWordForm,
  isOpenWordForm,
  isOpenWordSets,
  abilityToAdd,
  openCleanModal,
  closeWordSets,
  openWordSets,
  loadWords,
  onSubmit,
  role,
}) => (
  <Grid container justify="center">
    <Container>
      {abilityToAdd ? (
        <LoadWords
          input={{}}
          label="Загрузить слова"
          accept=".docx"
          onChange={file => loadWords({ file, wordSetId })}
          disabled={active || role !== 'ADMIN'}
        />
      ) : (
        <AddWordsButton
          name="Добавить слова"
          onClick={() => openWordSets()}
          disabled={active || active || role !== 'ADMIN'}
        />
      )}
    </Container>
    <Grid container justify="center">
      <TableWord
        active={active}
        onOpen={openWordForm}
        abilityToAdd={abilityToAdd}
      />
      <WordForm open={isOpenWordForm} onClose={closeWordForm} />
      <WordSets
        open={isOpenWordSets}
        onClose={closeWordSets}
        onSubmit={onSubmit}
      />
    </Grid>
    {abilityToAdd && (
      <AddWordButton
        name="Новое слово"
        onClick={() => openCleanModal()}
        disabled={active || active || role !== 'ADMIN'}
      />
    )}
  </Grid>
)

const Words = R.compose(
  connect(
    R.applySpec({ role: getUserRole }),
    { initialize, loadWords },
  ),
  withStateHandlers(
    {
      isOpenWordForm: false,
      isOpenWordSets: false,
    },
    {
      openWordForm: () => () => ({ isOpenWordForm: true }),
      closeWordForm: () => () => ({ isOpenWordForm: false }),
      openWordSets: () => () => ({ isOpenWordSets: true }),
      closeWordSets: () => () => ({ isOpenWordSets: false }),
    },
  ),
  withHandlers({
    openCleanModal: ({ openWordForm, initialize }) => () => {
      initialize(FORMS.WORD, {})
      openWordForm()
    },
  }),
)(WordsDamp)

export default Words
