import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import * as R from 'ramda'
import * as WordSetManager from './WordSetManager'
import * as CoursesManager from '../courses/CoursesManager'
import { getToken } from '../auth'
import { navigate } from '../navigation'
import { getCurrentPage } from '../profile'
import { setStageTwo } from '../stageTwo'
import {
  fetchWordSets,
  fetchWordSet,
  setWordSets,
  setWordSet,
  saveWordSet,
  saveWord,
  createWord,
  updateWord,
  setWords,
  setRules,
  deleteWord,
  deleteWordSet,
  loadWords,
  loadRule,
  fetchRules,
  copyWordSet,
  publishWordSet,
  setSelectedIds,
  setWordSetsStudy,
  removeRule,
  fetchWordSetCount,
  uploadRule,
  setWordSetCount,
  getWordSetByCourseId,
  setDumpCourses,
} from './duck'
import { ROUTES, FORMS } from '../../constants'
import { handleErrors } from '../../aspects'
import { NotificationError, NotificationSuccess } from '../../services'
import { initialize, startSubmit, stopSubmit, change } from 'redux-form'
import { createFormData } from '../../utils/query'
import { getWords, getWordSet, getSelectedWordSets } from './selectors'

const anyError = handleErrors({
  anyError: function*() {
    yield call(NotificationError())
  },
})

const fetchRulesSaga = anyError(function*() {
  const token = yield select(getToken)
  const words = yield select(getWords)
  if (words) {
    const rules = yield call(WordSetManager.getRules, token, {
      wordsNumber: words.length,
    })
    yield put(setRules(rules))
  } else {
    yield put(setRules([]))
  }
})

const fetchAllRulesSaga = anyError(function*() {
  const token = yield select(getToken)
  const rules = yield call(WordSetManager.getRules, token)
  yield put(setRules(rules))
})

const resetWordSet = anyError(function*(id) {
  yield call(saveWordSetSaga, {
    payload: { id, ruleId: null, rule: null, active: false },
  })
})

const initWordSet = anyError(function*(wordSet) {
  yield put(initialize(FORMS.WORD_SET_FORM, wordSet))
  yield put(setWordSet(wordSet))
})

const fetchWordSetsSaga = anyError(function*({ payload }) {
  const token = yield select(getToken)
  const [wordSets, count] = yield call(
    WordSetManager.getWordSets,
    token,
    payload,
  )
  yield put(setWordSets(R.assoc('count', count, { items: wordSets })))
})

const fetchWordSetSaga = anyError(function*({ payload: id }) {
  const token = yield select(getToken)
  let wordSet = {}
  if (id) {
    wordSet = yield call(WordSetManager.getWordSet, token, id)
    yield put(setWords(wordSet.words))
    if (wordSet.testType === 'REPEAT') {
      yield put(setSelectedIds(wordSet.wordSets.map(x => x.id)))
      yield put(setWordSetsStudy([]))
      const course = yield call(CoursesManager.getCoursesDumb, token)
      yield put(setDumpCourses(course))
    } else if (!!wordSet.stage2) {
      const stage2 = R.pipe(
        () => JSON.parse(wordSet.stage2),
        R.map(
          R.when(R.propEq('testType', '10'), x => ({
            ...x,
            text: R.pipe(
              R.split('//'),
              R.addIndex(R.chain)((y, i, { length }) =>
                i === length - 1 ? y : [y, `/${x.rightAnswer[i]}/`],
              ),
              R.join(''),
            )(x.text),
          })),
        ),
      )()
      yield put(setStageTwo(stage2))
    }
  }
  yield call(initWordSet, { ...wordSet, user: undefined })
  yield call(fetchRulesSaga)
})

const saveWordSetSaga = anyError(function*({ payload }) {
  const token = yield select(getToken)
  if (!payload.id) yield put(setSelectedIds([]))

  const selectedWordSets = yield select(getSelectedWordSets)
  const wordSet = yield call(WordSetManager.saveWordSet, token, {
    ...payload,
    words: undefined,
    courseId: undefined,
    stage2: payload.stage2 ? payload.stage2 : undefined,
    wordSets: payload.testType === 'REPEAT' ? selectedWordSets : undefined,
  })
  yield call(initWordSet, { ...wordSet, courseId: undefined, user: undefined })
  yield put(setWordSetsStudy([]))
  yield call(NotificationSuccess())
  if (!payload.id) {
    yield put(setSelectedIds([]))
    yield put(navigate(`${ROUTES.WORD_SETS}/${wordSet.id}`))
  }
  yield call(fetchWordSetSaga, { payload: payload.id || wordSet.id })
})

const getWordSetStudyByCourseIdSaga = anyError(function*({ payload }) {
  const token = yield select(getToken)
  const wordSetsStudy = yield call(
    WordSetManager.getWordSetsByCourseId,
    token,
    payload ? { testType: 'STUDY', courseId: payload } : { testType: 'STUDY' },
  )
  yield put(setWordSetsStudy(wordSetsStudy))
})

const deleteWordSetSaga = anyError(function*({ payload }) {
  const token = yield select(getToken)
  yield call(WordSetManager.deleteWordSet, token, payload)
  const currentPage = yield select(getCurrentPage)
  yield call(fetchWordSetsSaga, { payload: currentPage })
  yield call(NotificationSuccess())
})

const saveWordSaga = anyError(function*({ payload }) {
  yield put(startSubmit(FORMS.WORD))
  const token = yield select(getToken)
  const word = yield call(
    WordSetManager.saveWord,
    token,
    createFormData(payload),
  )
  yield put(payload.id ? updateWord(word) : createWord(word))
  yield put(initialize(FORMS.WORD, {}))
  yield put(stopSubmit(FORMS.WORD))
  yield call(fetchRulesSaga)
  const { id } = yield select(getWordSet)
  yield !payload.id && call(resetWordSet, id)
})

const deleteWordSaga = anyError(function*({ payload }) {
  const token = yield select(getToken)
  yield call(WordSetManager.deleteWord, token, payload)
  yield call(fetchRulesSaga)
  const { id } = yield select(getWordSet)
  yield call(resetWordSet, id)
})

const copyWordSetSaga = anyError(function*({ payload: id }) {
  const token = yield select(getToken)
  yield call(WordSetManager.copyWordSet, token, { id })
  yield call(NotificationSuccess())
  const currentPage = yield select(getCurrentPage)
  yield call(fetchWordSetsSaga, { payload: currentPage })
})

const publishWordSetSaga = handleErrors({
  '400': function*() {
    yield call(NotificationError('Не заполнены необходимые поля'))
  },
  '500': function*() {
    yield call(NotificationError())
  },
})(function*({ payload: id }) {
  const token = yield select(getToken)
  yield call(WordSetManager.publishWordSet, token, id)
  yield call(NotificationSuccess())
  yield put(navigate(`${ROUTES.WORD_SETS}/${id}`))
  yield call(fetchWordSetSaga, { payload: id })
})

const findAudioSaga = anyError(function*({ payload }) {
  const token = yield select(getToken)
  if (!!payload) {
    const audio = yield call(WordSetManager.findAudioByName, token, {
      word: payload.toLowerCase(),
    })
    yield put(change(FORMS.WORD, 'audio', !!audio ? audio : null))
  }
})

const loadWordsSaga = anyError(function*({ payload }) {
  const token = yield select(getToken)
  const words = yield call(
    WordSetManager.loadWords,
    token,
    createFormData(payload),
  )
  const oldWords = yield select(getWords)
  yield put(setWords([...oldWords, ...words]))
  yield call(fetchRulesSaga)
  yield call(resetWordSet, payload.wordSetId)
  yield put(change(FORMS.WORD_SET_FORM, 'ruleId', undefined)) //TODO
})

const loadRuleSaga = anyError(function*({ payload }) {
  const token = yield select(getToken)
  yield call(WordSetManager.loadRule, token, createFormData(payload))
  yield call(fetchRulesSaga)
  yield call(NotificationSuccess())
})

const uploadRuleSaga = anyError(function*({ payload }) {
  const token = yield select(getToken)
  yield call(WordSetManager.loadRule, token, createFormData(payload))
  yield call(fetchAllRulesSaga)
  yield call(NotificationSuccess())
})

const removeRuleSaga = anyError(function*({ payload }) {
  const token = yield select(getToken)
  yield call(WordSetManager.deleteRule, token, payload)
  yield call(fetchAllRulesSaga)
  yield call(NotificationSuccess())
})

const fetchWordSetCountSaga = anyError(function*({ payload }) {
  const token = yield select(getToken)
  const wordSetsCount = yield call(WordSetManager.getWordSetCount, token, {
    ruleId: payload,
  })
  yield put(setWordSetCount(wordSetsCount))
})

const wordSetSaga = function*() {
  yield all([
    takeLatest(fetchWordSets, fetchWordSetsSaga),
    takeLatest(getWordSetByCourseId, getWordSetStudyByCourseIdSaga),
    takeLatest(fetchWordSet, fetchWordSetSaga),
    takeLatest(fetchRules, fetchAllRulesSaga),
    takeLatest(saveWordSet, saveWordSetSaga),
    takeLatest(deleteWordSet, deleteWordSetSaga),
    takeLatest(saveWord, saveWordSaga),
    takeLatest(deleteWord, deleteWordSaga),
    takeLatest(fetchWordSetCount, fetchWordSetCountSaga),
    takeLatest(copyWordSet, copyWordSetSaga),
    takeLatest(publishWordSet, publishWordSetSaga),
    takeLatest(loadWords, loadWordsSaga),
    takeLatest(loadRule, loadRuleSaga),
    takeLatest(uploadRule, uploadRuleSaga),
    takeLatest(removeRule, removeRuleSaga),
    takeLatest(
      ({ type, meta: { field, form } = {} }) =>
        type === '@@redux-form/CHANGE' &&
        field === 'word' &&
        form === FORMS.WORD,
      findAudioSaga,
    ),
  ])
}

export default wordSetSaga
