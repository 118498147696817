import { withNavigate } from '../../../utils/withNavigate'

export const withNavigateUsers = withNavigate('navigateUsers', '/users')

export const withNavigateUserSystemForm = withNavigate(
  'navigateUserSystemForm',
  '/userSystemForm',
)

export const withNavigateUserForm = withNavigate(
  'navigateUserForm',
  '/userForm',
)

export const withNavigateGroupForm = withNavigate(
  'navigateGroupForm',
  '/groupForm',
)

export const withNavigateGroups = withNavigate('navigateGroups', '/groups')

export const withNavigateGroupStatistic = withNavigate(
  'navigateGroupStatistic',
  '/groupStatistic',
)
