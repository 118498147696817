import * as R from 'ramda'
import { handleAction, handleActions, createAction } from 'redux-actions'
import { combineReducers } from 'redux'

const AUDIO = 'AUDIO'

export const getAudio = createAction(`${AUDIO}/GET_AUDIO`)
export const setAudio = createAction(`${AUDIO}/SET_AUDIO`)
export const addAudio = createAction(`${AUDIO}/ADD_AUDIO`)
export const uploadAudios = createAction(`${AUDIO}/UPLOAD_AUDIOS`)

export const renameAudio = createAction(`${AUDIO}/RENAME_AUDIO`)
export const deleteAudio = createAction(`${AUDIO}/DELETE_AUDIO`)

export const fetchWordCount = createAction(`${AUDIO}/IS_FETCHING_WORD_COUNT`)
export const setWordCount = createAction(`${AUDIO}/SET_WORD_COUNT`)

const isFetchingWordCount = handleActions(
  { [fetchWordCount]: R.T, [setWordCount]: R.F },
  false,
)

const items = handleAction(setAudio, (_, { payload }) => payload, [])
const wordCount = handleAction(setWordCount, (_, { payload }) => payload, null)

const audio = combineReducers({ items, wordCount, isFetchingWordCount })

export default audio
