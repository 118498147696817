import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { handleErrors } from '../../aspects'
import * as AudioManager from './AudioManager'
import {
  addAudio,
  getAudio,
  setAudio,
  deleteAudio,
  renameAudio,
  uploadAudios,
  fetchWordCount,
  setWordCount,
} from './duck'
import { getToken } from '../auth'
import { createFormData, createArrayFormData } from '../../utils/query'
import { NotificationError, NotificationSuccess } from '../../services'

const anyError = handleErrors({
  anyError: function*() {
    yield call(NotificationError())
  },
})

const getAudioSaga = anyError(function*({ payload }) {
  const token = yield select(getToken)
  const audio = yield call(AudioManager.getAudio, token, payload)
  yield put(setAudio(audio))
})

const addAudioSaga = handleErrors({
  '400': function*() {
    yield call(NotificationError('Аудиозапись с таким именем уже существует'))
  },
  '500': function*() {
    yield call(NotificationError())
  },
})(function*({ payload }) {
  const token = yield select(getToken)
  yield call(AudioManager.addAudio, token, createFormData(payload))
  const audio = yield call(AudioManager.getAudio, token)
  yield put(setAudio(audio))
  yield call(NotificationSuccess())
})

const uploadAudiosSaga = anyError(function*({ payload }) {
  const token = yield select(getToken)
  yield call(AudioManager.uploadAudios, token, createArrayFormData(payload))
  const audio = yield call(AudioManager.getAudio, token)
  yield put(setAudio(audio))
  yield call(NotificationSuccess())
})

const deleteAudioSaga = anyError(function*({ payload }) {
  const token = yield select(getToken)
  yield call(AudioManager.deleteAudio, token, { url: payload })
  const audio = yield call(AudioManager.getAudio, token)
  yield put(setAudio(audio))
  yield call(NotificationSuccess())
})

const renameAudioSaga = anyError(function*({ payload }) {
  const token = yield select(getToken)
  yield call(AudioManager.renameAudio, token, payload)
  const audio = yield call(AudioManager.getAudio, token)
  yield put(setAudio(audio))
  yield call(NotificationSuccess())
})

const fetchWordCountSaga = anyError(function*({ payload: url }) {
  const token = yield select(getToken)
  const wordCount = yield call(AudioManager.getWordCountByUrl, token, { url })
  yield put(setWordCount(wordCount))
})

const audioSaga = function*() {
  yield all([
    takeLatest(getAudio, getAudioSaga),
    takeLatest(addAudio, addAudioSaga),
    takeLatest(uploadAudios, uploadAudiosSaga),
    takeLatest(deleteAudio, deleteAudioSaga),
    takeLatest(fetchWordCount, fetchWordCountSaga),
    takeLatest(renameAudio, renameAudioSaga),
  ])
}

export default audioSaga
