import * as R from 'ramda'
import { MODULES } from '../../constants'
import { handleAction, handleActions, createAction } from 'redux-actions'
import { combineReducers } from 'redux'

export const getUserStatisticsByGroup = createAction(
  `${MODULES.STATISTIC}/GET_USER_STAT`,
)

export const getStagesStat = createAction(
  `${MODULES.STATISTIC}/GET_STAGES_STAT`,
)

export const getCourseStatistic = createAction(
  `${MODULES.STATISTIC}/GET_COURSE_STAT`,
)

export const getGroupStatistic = createAction(
  `${MODULES.STATISTIC}/GET_GROUP_STAT`,
)

export const getWordSetStatistic = createAction(
  `${MODULES.STATISTIC}/GET_WORD_SET_STAT`,
)

export const setCourseStatistic = createAction(
  `${MODULES.STATISTIC}/SET_COURSE_STAT`,
)

export const setGroupStatistic = createAction(
  `${MODULES.STATISTIC}/SET_GROUP_STAT`,
)

export const setUserStisticsByGroup = createAction(
  `${MODULES.STATISTIC}/SET_USER_STAT`,
)

export const setWordSetStatistic = createAction(
  `${MODULES.STATISTIC}/SET_WORD_SET_STAT`,
)

export const setStageStatistics = createAction(
  `${MODULES.STATISTIC}/SET_STAGES_STATS`,
)

const userStisticsByGroup = handleAction(
  setUserStisticsByGroup,
  (_, { payload }) => payload,
  {},
)

const stageStatistics = handleAction(
  setStageStatistics,
  (_, { payload }) => payload,
  {},
)

const course = handleAction(setCourseStatistic, (_, { payload }) => payload, {})

const group = handleAction(setGroupStatistic, (_, { payload }) => payload, {})

const wordSet = handleAction(
  setWordSetStatistic,
  (_, { payload }) => payload,
  {},
)

const isFetchingUser = handleActions(
  {
    [getUserStatisticsByGroup]: R.T,
    [setUserStisticsByGroup]: R.F,
  },
  false,
)

const isFetchingStages = handleActions(
  {
    [getStagesStat]: R.T,
    [getWordSetStatistic]: R.T,
    [setStageStatistics]: R.F,
  },
  false,
)

const isFetchingCourse = handleActions(
  {
    [getCourseStatistic]: R.T,
    [setCourseStatistic]: R.F,
  },
  false,
)

const isFetchingGroup = handleActions(
  {
    [getGroupStatistic]: R.T,
    [setGroupStatistic]: R.F,
  },
  false,
)

const user = combineReducers({
  userStisticsByGroup,
  stageStatistics,
})

const statistics = combineReducers({
  user,
  course,
  group,
  wordSet,
  isFetchingUser,
  isFetchingStages,
  isFetchingGroup,
  isFetchingCourse,
})

export default statistics
