import React from 'react'
import * as R from 'ramda'
import { TableCell } from '../commons'
import {
  Grid,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Typography,
} from '@material-ui/core'
import styled from 'styled-components'

const Title = styled(Typography)`
  font-size: 22px;
  text-align: center;
  margin: 10px 0;
`

const Tooltip = styled(Typography)`
  font-size: 14px;
  text-align: center;
  margin: 0 0 15px 0;
`

const StageTwoInfo = ({ headers = [], data = [] }) => (
  <Grid style={{ marginTop: 25 }} container justify="center">
    <Grid sm={9}>
      <Title>Этап 2</Title>
      <Tooltip>При трех ошибках задание не выполнено</Tooltip>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Номер задания</TableCell>
            {headers.map((key, index) => (
              <TableCell key={index}>{key}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Кол-во ошибок</TableCell>
            {headers.map((key, index) => {
              const a = R.find(R.propEq('testType', key))(data)
              return <TableCell key={index}>{a ? a.errors : 0}</TableCell>
            })}
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  </Grid>
)

export default StageTwoInfo
