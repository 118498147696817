import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { startSubmit, stopSubmit, initialize, change } from 'redux-form'
import * as R from 'ramda'
import * as GroupsManager from './GroupsManager'
import * as CoursesManager from '../courses/CoursesManager'
import * as WordSetManager from '../wordSets/WordSetManager'
import * as UsersManager from '../users/UsersManager'
import { getToken } from '../auth'
import { setRelativeCourses } from '../courses'
import {
  setRelativeUsersSystem,
  setRelativeUsers,
  getRelativeUsers,
} from '../users/'
import {
  fetchGroups,
  fetchGroup,
  fetchSaveGroup,
  fetchGroupSchedule,
  setGroups,
  setSchedule,
  deleteGroup,
} from './duck'
import { FORMS, ROUTES } from '../../constants'
import { handleErrors } from '../../aspects'
import { NotificationError, NotificationSuccess } from '../../services'
import { navigate } from '../navigation'
import { getCurrentPage } from '../profile'

const anyError = handleErrors({
  anyError: function*() {
    yield call(NotificationError())
  },
})

const fetchGroupsSaga = anyError(function*({ payload }) {
  const token = yield select(getToken)
  const [groups, count] = yield GroupsManager.getGroups(token, payload)
  const items = groups.map(group => ({
    ...group,
    countWordSet: `${group.activeWordSets || 0}/${group.totalWordSets}`,
  }))
  yield put(setGroups(R.assoc('count', count, { items })))
})

const fetchGroupSaga = handleErrors({
  anyError: function*() {
    yield call(NotificationError())
    yield put(stopSubmit(FORMS.ADD_STUDENT_FROM))
    yield put(stopSubmit(FORMS.GROUP_FROM))
  },
})(function*({ payload: id }) {
  yield put(startSubmit(FORMS.GROUP_FROM))
  yield put(startSubmit(FORMS.ADD_STUDENT_FROM))
  const token = yield select(getToken)

  const courses = yield CoursesManager.getCoursesDumb(token)
  yield put(setRelativeCourses(courses))

  const teachers = yield UsersManager.getTeachers(token)
  yield put(setRelativeUsersSystem(teachers))

  if (id) {
    const { name, course } = yield GroupsManager.getGroup(token, id)
    const courseId = R.propOr('', 'id')(course)
    if (courseId) {
      yield put(fetchGroupSchedule({ courseId, groupId: id }))
    }
    const userGroup = yield UsersManager.getUserGroup(token, id)
    yield put(setRelativeUsers(userGroup))

    const teacherId = userGroup
      .filter(({ user: { role } }) => role === 'TEACHER')
      .map(({ id }) => id)[0]

    yield put(initialize(FORMS.GROUP_FROM, { name, courseId, teacherId }))
  }
  yield put(stopSubmit(FORMS.ADD_STUDENT_FROM))
  yield put(stopSubmit(FORMS.GROUP_FROM))
})

const fetchGroupScheduleSaga = handleErrors({
  anyError: function*() {
    yield call(NotificationError())
    yield put(stopSubmit(FORMS.GROUP_FROM))
  },
})(function*({ payload: { courseId, groupId } }) {
  if (!groupId) return
  yield put(startSubmit(FORMS.GROUP_FROM))
  const token = yield select(getToken)
  let schedule = []
  if (!!courseId) {
    const wordSets = (yield call(WordSetManager.getWordSetsByCourseId, token, {
      courseId,
    }))
      .map(({ id, title, order }) => ({
        wordSetId: id,
        title,
        groupId,
        order,
      }))
      .sort(({ order: aOrder }, { order: bOrder }) => aOrder - bOrder)

    const currenSchedule = yield call(GroupsManager.getSchedule, token, {
      courseId,
      groupId,
    })

    schedule = [
      ...currenSchedule,
      ...R.differenceWith(
        (a, b) => a.wordSetId === b.wordSetId,
        wordSets,
        currenSchedule,
      ),
    ].map(x => ({
      ...x,
      endDate: x.endDate
        ? { date: new Date(x.endDate * 1000), timestamp: x.endDate }
        : undefined,
      title: R.find(R.propEq('wordSetId', x.wordSetId))(wordSets).title,
    }))
  }
  yield put(change(FORMS.GROUP_FROM, 'schedule', schedule))

  yield put(setSchedule(schedule))
  yield put(stopSubmit(FORMS.GROUP_FROM))
})

// please don't cry, i will rewrite this someday))0)
const fetchSaveGroupSaga = handleErrors({
  anyError: function*() {
    yield call(NotificationError())
    yield put(stopSubmit(FORMS.ADD_STUDENT_FROM))
    yield put(stopSubmit(FORMS.GROUP_FROM))
  },
})(function*({
  payload: { name, groupId: id, courseId, teacherId, schedule },
}) {
  yield put(startSubmit(FORMS.GROUP_FROM))
  yield put(startSubmit(FORMS.ADD_STUDENT_FROM))
  const token = yield select(getToken)
  const studentFromGroup = yield select(getRelativeUsers)

  let data = { name }

  if (id) data = { id: parseInt(id), ...data }
  if (courseId) data = { ...data, courseId }
  if (schedule) {
    data = {
      ...data,
      schedule: schedule.reduce(
        (acc, x) =>
          x.endDate
            ? [
                ...acc,
                {
                  wordSetId: x.wordSetId,
                  endDate: x.endDate.timestamp,
                },
              ]
            : acc,
        [],
      ),
    }
  }
  let profiles = []
  if (!R.isNil(studentFromGroup))
    profiles = [
      ...studentFromGroup
        .filter(x => !x.id || x.user.role !== 'TEACHER')
        .map(({ email, phone, passed, ...rest }) =>
          rest.id
            ? {
                ...rest,
                id: rest.id,
                role: undefined,
                user: { email, id: rest.id, role: 'MOBILE' },
                phone: phone ? phone : null,
              }
            : {
                ...rest,
                role: undefined,
                user: { email, role: 'MOBILE' },
                phone: phone ? phone : null,
              },
        )
        .filter(x => !x.id || (x.user && x.user.role === 'MOBILE')),
    ]
  if (!R.isNil(teacherId)) profiles = [...profiles, { id: teacherId }]
  if (!R.isEmpty(profiles)) data = { ...data, profiles }

  const group = yield call(GroupsManager.saveGroup, token, data)

  yield put(stopSubmit(FORMS.GROUP_FROM))
  yield put(stopSubmit(FORMS.ADD_STUDENT_FROM))
  yield id || put(navigate(ROUTES.GROUPS + '/' + group.id))
  yield call(fetchGroupSaga, { payload: group.id })
  yield call(NotificationSuccess())
})

const deleteGroupSaga = anyError(function*({ payload }) {
  const token = yield select(getToken)
  yield call(GroupsManager.deleteGroup, token, payload)
  yield call(NotificationSuccess())
  const currentPage = yield select(getCurrentPage)
  yield call(fetchGroupsSaga, { payload: currentPage })
})

const groupsSaga = function*() {
  yield all([
    takeLatest(fetchGroups, fetchGroupsSaga),
    takeLatest(fetchGroup, fetchGroupSaga),
    takeLatest(fetchGroupSchedule, fetchGroupScheduleSaga),
    takeLatest(fetchSaveGroup, fetchSaveGroupSaga),
    takeLatest(deleteGroup, deleteGroupSaga),
  ])
}

export default groupsSaga
