import React from 'react'
import * as R from 'ramda'
import { Paper } from '@material-ui/core'
import { connect } from 'react-redux'
import { lifecycle, withProps } from 'recompose'
import styled from 'styled-components'
import { withTitle } from './withTitle'
import { CourseStatistics, CourseMainInfo } from '../components/courses'
import {
  getCourseStatistic,
  getCourseInfo,
  getCourseGroups,
  getCourseWordSets,
  getIsFetchingCourse,
} from '../../modules/statistic'
import { Preloader } from '../components/commons'

const Container = styled(Paper)``

const CourseDetailsDamp = ({ info, groups, wordSets, isFetching, courseId }) =>
  isFetching ? (
    <Preloader />
  ) : (
    <Container>
      <CourseMainInfo info={info} />
      <CourseStatistics
        courseId={courseId}
        groups={groups}
        wordSets={wordSets}
      />
    </Container>
  )

const CourseDetails = R.compose(
  withTitle('Информация о курсе'),
  connect(
    R.applySpec({
      info: getCourseInfo,
      groups: getCourseGroups,
      wordSets: getCourseWordSets,
      isFetching: getIsFetchingCourse,
    }),
    { getCourseStatistic },
  ),
  withProps(({ match: { params: { id } } }) => ({ courseId: id })),
  lifecycle({
    componentDidMount() {
      const { getCourseStatistic, courseId } = this.props
      getCourseStatistic(courseId)
    },
  }),
)(CourseDetailsDamp)

export default CourseDetails
