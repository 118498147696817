import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  Users,
  Groups,
  GroupForm,
  CourseForm,
  Course,
  WordSets,
  WordSetForm,
  User,
  Audio,
  Rules,
  StageTwo,
  CourseDetails,
  GroupDeails,
  WordSetCourseDetails,
  WordSetGroupDetails,
} from '../screens'
import { ROUTES } from '../../constants'

const Routes = () => (
  <Switch>
    <Route
      path={ROUTES.WORD_SET_COURSE_DETAILS}
      component={WordSetCourseDetails}
    />
    <Route
      path={ROUTES.WORD_SET_GROUP_DETAILS}
      component={WordSetGroupDetails}
    />
    <Route exact path={ROUTES.COURSES} component={Course} />
    <Route path={ROUTES.COURSE_FORM} component={CourseForm} />
    <Route path={ROUTES.SELECTED_COURSE} component={CourseForm} />
    <Route exact path={ROUTES.GROUPS} component={Groups} />
    <Route path={ROUTES.GROUP_FORM} component={GroupForm} />
    <Route path={ROUTES.SELECTED_GROUP} component={GroupForm} />
    <Route exact path={ROUTES.USERS} component={Users} />
    <Route path={ROUTES.USER_FORM} component={User} />
    <Route path={ROUTES.SELECTED_USER} component={User} />
    <Route exact path={ROUTES.WORD_SETS} component={WordSets} />
    <Route exact path={ROUTES.AUDIO} component={Audio} />
    <Route path={ROUTES.WORD_SETS_FORM} component={WordSetForm} />
    <Route path={ROUTES.SELECTED_WORD_SET} component={WordSetForm} />
    <Route path={ROUTES.SELECTED_WORD_SET_STAGE_TWO} component={StageTwo} />
    <Route path={ROUTES.COURSE_DETAILS} component={CourseDetails} />
    <Route path={ROUTES.GROUP_DETAILS} component={GroupDeails} />
    <Route path={ROUTES.RULES} component={Rules} />
  </Switch>
)

export default Routes
