import React from 'react'
import {
  Modal as ModalMaterial,
  Paper as PaperMaterial,
  Typography,
} from '@material-ui/core'
import SvgRemove from '@material-ui/icons/Close'
import styled from 'styled-components'

const Container = styled(ModalMaterial)`
  display: flex;
`

const Paper = styled(PaperMaterial)`
  width: 500px;
  margin: auto;
  padding: 40px;
`

const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: -30px -30px 0 0;
`

const ButtonRemove = styled(SvgRemove)`
  cursor: pointer;
  transition: 0.225s;
  transform-origin: center center;
  &:hover {
    transform: scale(1.1);
  }
`

const Modal = ({ title, open, onClose, className, children }) => (
  <Container open={open} onClose={onClose}>
    <Paper className={className}>
      <CloseWrapper>
        <ButtonRemove onClick={onClose} />
      </CloseWrapper>
      {title && <Typography variant="h6">{title}</Typography>}
      {children}
    </Paper>
  </Container>
)

export default Modal
