import { MODULES } from '../../constants'
import * as R from 'ramda'
import { handleAction, createAction } from 'redux-actions'
import { combineReducers } from 'redux'

export const setAppBarTitle = createAction(`${MODULES.UI}/SET_APP_BAR_TITLE`)

const barTitle = handleAction(
  setAppBarTitle,
  R.pipe(
    R.nthArg(1),
    R.prop('payload'),
  ),
  '',
)

export default combineReducers({ barTitle })
