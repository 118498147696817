import React from 'react'
import * as R from 'ramda'
import { Table, TableBody, TableRow, TableHead } from '@material-ui/core'
import { Field, getFormValues } from 'redux-form'
import { connect } from 'react-redux'
import { FileInput, TableCell } from '../commons'
import DatePicker from './DatePicker'
import styled from 'styled-components'
import * as WordSetManager from '../../../modules/wordSets/WordSetManager'
import { getToken } from '../../../modules/auth'
import { FORMS } from '../../../constants'

const ImportButton = styled(FileInput)`
  margin-top: 20px;
`

const ScheduleForm = ({ fields, token, values }) => {
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Дата</TableCell>
            <TableCell>Название набора слов</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((name, i) => (
            <TableRow key={i}>
              <TableCell>{R.inc(i)}.</TableCell>
              <TableCell>
                <Field name={`${name}.endDate`} component={DatePicker} />
              </TableCell>
              <TableCell>
                <Field
                  name={`${name}.title`}
                  component={({ input: { value } }) => <span>{value}</span>}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ImportButton
        input={{}}
        label="Импорт расписания"
        accept=".csv"
        onChange={file => {
          const reader = new FileReader()
          reader.onload = function(event) {
            WordSetManager.getWordSetsByCourseId(token, {
              courseId: values.courseId,
            }).then(wordSets => {
              fields.removeAll()
              try {
                event.target.result
                  .split('\n')
                  .slice(0, -1)
                  .forEach(row => {
                    const [date, title] = row.split(';')
                    fields.push({
                      wordSetId: R.pipe(
                        R.find(R.propEq('title', title)),
                        R.prop('id'),
                      )(wordSets),
                      endDate: {
                        date,
                        timestamp: Math.floor(+new Date(date) / 1000),
                      },
                      title,
                    })
                  })
              } catch (e) {
                // eslint-disable-next-line no-console
                console.warn('error read csv file')
              }
            })
          }
          reader.readAsText(file)
        }}
      />
    </>
  )
}

export default connect(
  R.applySpec({ token: getToken, values: getFormValues(FORMS.GROUP_FROM) }),
)(ScheduleForm)
