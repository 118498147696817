import { navigate } from './duck'
import { all, takeLatest, call } from 'redux-saga/effects'

let history

export const setHistory = x => (history = x)

const navigateSaga = function*({ payload: path }) {
  yield call(history.push, path)
}

const navigation = function*() {
  yield all([takeLatest(navigate, navigateSaga)])
}

export default navigation
