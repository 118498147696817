import React from 'react'
import * as R from 'ramda'
import { withRouter } from 'react-router-dom'
import { withTitle } from './withTitle'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import { withStateHandlers, lifecycle } from 'recompose'
import {
  fetchRules,
  getRulesTable,
  removeRule,
  fetchWordSetCount,
  selectWordSetCount,
  getIsFetchingWordSetCount,
  uploadRule,
} from '../../modules/wordSets'
import {
  DeleteButton,
  Confirm,
  FileInput,
  withRole,
} from '../components/commons'

const Container = styled(Paper)`
  padding: 20px;
`

const LoadRule = styled(FileInput)`
  margin: 20px auto;
`

const LoadRuleWithRole = withRole(LoadRule, true)
const DeleteButtonWithRole = withRole(DeleteButton, true)

const ContentMessage = ({ isFetchingWordSetCount, wordSetCount }) => (
  <>
    Вы уверены?
    {isFetchingWordSetCount ? (
      <CircularProgress size={12} />
    ) : (
      <Typography>
        {wordSetCount !== 0 && 'Этот порядок используется в'}
        {wordSetCount !== 0 && (
          <span style={{ fontWeight: 800 }}> ({wordSetCount})</span>
        )}
        {wordSetCount !== 0 &&
          (wordSetCount % 10 === 1 ? ' наборе' : ' наборах')}
      </Typography>
    )}
  </>
)

const RuleDamp = ({
  rules = [],
  deleteRule,
  isDeleting,
  isFetchingWordSetCount,
  wordSetCount,
  onDelete,
  onClose,
  uploadRule,
}) => (
  <Container>
    <Grid container direction="column">
      <Grid item sm={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{'Название'}</TableCell>
              <TableCell>{''}</TableCell>
              <TableCell>{''}</TableCell>
              <TableCell>{''}</TableCell>
              <TableCell>{'Удалить'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rules.map((rule, i) => (
              <TableRow key={i}>
                <TableCell>{rule.name}</TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell>
                  <DeleteButtonWithRole onClick={() => deleteRule(rule.id)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <LoadRuleWithRole
        input={{}}
        label="Загрузить порядок"
        accept=".docx"
        onChange={file => uploadRule({ file })}
      />
    </Grid>
    {isDeleting && (
      <Confirm
        content={
          <ContentMessage
            wordSetCount={wordSetCount}
            isFetchingWordSetCount={isFetchingWordSetCount}
          />
        }
        onCancelClick={onClose}
        onDoneClick={onDelete}
      />
    )}
  </Container>
)

const Rule = R.compose(
  withRouter,
  withTitle('Порядки заданий'),
  connect(
    R.applySpec({
      rules: getRulesTable,
      wordSetCount: selectWordSetCount,
      isFetchingWordSetCount: getIsFetchingWordSetCount,
    }),
    { fetchRules, removeRule, fetchWordSetCount, uploadRule },
  ),
  withStateHandlers(
    { isDeleting: false, selectedRule: null },
    {
      onDelete: ({ selectedRule }, { removeRule }) => () => {
        removeRule(selectedRule)
        return { isDeleting: false }
      },
      deleteRule: (_, { fetchWordSetCount }) => id => {
        fetchWordSetCount(id)
        return {
          isDeleting: true,
          selectedRule: id,
        }
      },
      onClose: () => () => ({ isDeleting: false }),
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchRules()
    },
  }),
)(RuleDamp)

export default Rule
