import React from 'react'
import * as R from 'ramda'
import { Grid } from '@material-ui/core'
import { withStateHandlers } from 'recompose'
import { TableMakeSetence, MakeSentenceForm } from '.'

const MakeSentenceDamp = ({
  active,
  isOpenModal,
  disableModal,
  enableModal,
}) => (
  <Grid container justify="center">
    <TableMakeSetence active={active} enableModal={enableModal} />
    <MakeSentenceForm open={isOpenModal} onClose={disableModal} />
  </Grid>
)

const MakeSentence = R.compose(
  withStateHandlers(
    { isOpenModal: false },
    {
      disableModal: () => () => ({ isOpenModal: false }),
      enableModal: () => () => ({ isOpenModal: true }),
    },
  ),
)(MakeSentenceDamp)

export default MakeSentence
