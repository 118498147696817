import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withHandlers } from 'recompose'
import { Grid, Table, TableHead, TableRow, TableBody } from '@material-ui/core'
import { Player } from '../../../services'
import {
  Button,
  TableCell,
  EditButton,
  DeleteButton,
  PlayButton,
  withRole,
} from '../commons'
import {
  getAuditionTable,
  deleteAudition,
  selectStage,
} from '../../../modules/stageTwo'

const AddTaskButton = styled(Button)`
  margin: 20px auto;
`

const Cell = styled(TableCell)`
  text-align: start;
`

const EditButtonWithRole = withRole(EditButton, true)
const AddTaskButtonWithRole = withRole(AddTaskButton, true)

const TableAuditionDamp = ({
  active,
  audition = [],
  openModal,
  playAudio,
  remove,
}) => (
  <Grid container spacing={16} justify="space-around">
    <Grid item sm={11}>
      <Table>
        <TableHead>
          <TableRow>
            <Cell>Текст</Cell>
            <Cell>Лишние слова</Cell>
            <TableCell>Аудио</TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {audition.map(({ audio, testType, text, variants }, index) => (
            <TableRow key={index}>
              <Cell>{text}</Cell>
              <Cell>{variants}</Cell>
              <TableCell>
                <PlayButton onClick={() => playAudio(audio)} />
              </TableCell>
              <TableCell>
                <EditButtonWithRole
                  onClick={() => openModal({ index, testType })}
                />
              </TableCell>

              <TableCell>
                <DeleteButton onClick={() => remove(index)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>

    <Grid item sm={2}>
      <AddTaskButtonWithRole onClick={() => openModal(null)}>
        Добавить задание
      </AddTaskButtonWithRole>
    </Grid>
  </Grid>
)

const TableAudition = R.compose(
  connect(
    R.applySpec({ audition: getAuditionTable }),
    { selectStage, deleteAudition },
  ),
  withHandlers({
    remove: ({ deleteAudition }) => index => deleteAudition(index),
    openModal: ({ selectStage, enableModal }) => payload => {
      selectStage(payload)
      enableModal()
    },
    playAudio: () => audio => {
      if (Player.src !== audio || Player.paused) {
        Player.src = audio
        Player.play()
      } else {
        Player.pause()
      }
    },
  }),
)(TableAuditionDamp)

export default TableAudition
