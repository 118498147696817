import React from 'react'
import { Link } from 'react-router-dom'
import { ListItem, ListItemText } from '@material-ui/core/'
import styled from 'styled-components'

const Container = styled(Link)`
  padding: 10px;
  text-decoration: none;
`

const DrawerButton = ({ title, path, onClick }) => (
  <Container to={path}>
    <ListItem button onClick={onClick}>
      <ListItemText>{title}</ListItemText>
    </ListItem>
  </Container>
)
export default DrawerButton
