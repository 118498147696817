import React from 'react'
import * as R from 'ramda'
import {
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  Button,
} from '@material-ui/core'
import styled from 'styled-components'
import ReactSelect from 'react-select'
import { withHandlers, withProps } from 'recompose'
import InputMask from 'react-input-mask'

const normilizePhone = phone => phone.replace(/\D+/g, '')

const Container = styled(FormControl)`
  ${({ multiline }) =>
    multiline ? '' : ({ select }) => 'height:' + (select ? 'none' : 61) + 'px'};
`

const Select = styled(ReactSelect)`
  margin-top: 50px;
`

const SelectedFile = styled.div`
  display: inline;
  text-align: center;
`

const Error = styled(FormHelperText)`
  height: 5%;
`

const InputFile = styled.input.attrs({ type: 'file' })`
  display: none;
`

const FileInputDamp = ({
  className,
  label,
  disabled,
  handleChange,
  accept,
  input = {},
  value,
  multiple,
  onClick,
}) => (
  <Button
    disabled={disabled}
    className={className}
    variant="contained"
    component="label"
    onClick={onClick}
  >
    <InputFile
      multiple={multiple}
      onChange={handleChange}
      accept={accept}
      value={input.value ? undefined : ''}
    />
    <SelectedFile>
      {value ||
        (input.value
          ? input.value.name
            ? input.value.name
            : input.value
          : label)}
    </SelectedFile>
  </Button>
)

export const FileInput = R.compose(
  withHandlers({
    handleChange: ({ onChange, multiple }) => ({
      nativeEvent: {
        target: { files },
      },
    }) =>
      onChange &&
      onChange(
        multiple
          ? files
          : files &&
              new File([files[0]], files[0].name, {
                type: files[0].type,
              }),
      ),
  }),
)(FileInputDamp)

const TextInputFieldDamp = ({
  input,
  meta: { touched, error },
  label,
  fullWidth,
  multiline,
  margin,
  select,
  onChangeSelect,
  type,
  disable,
  disabled,
  selectValue,
  style,
  noOptionsMessage,
  onChangeText,
  ...rest
}) => (
  <Container
    multiline={multiline}
    select={select}
    error={touched && !!error}
    fullWidth={fullWidth}
    margin={margin}
    style={style}
  >
    {type !== 'file' && <InputLabel>{label}</InputLabel>}
    {!!select && (
      <Select
        isDisabled={disabled}
        onChange={onChangeSelect}
        value={selectValue}
        noOptionsMessage={noOptionsMessage}
        placeholder="Выбрать"
        {...rest}
      />
    )}
    {!select &&
      (type === 'file' ? (
        <FileInput
          {...rest}
          input={input}
          label={label}
          onChange={input.onChange}
        />
      ) : type === 'phone' ? (
        <InputMask
          mask="+9 (999) 999 99 99"
          maskChar={'*'}
          value={input.value}
          disabled={disabled}
          onChange={onChangeText}
          {...rest}
        >
          {inputProps => (
            <Input disabled={disabled} {...inputProps} type="tel" />
          )}
        </InputMask>
      ) : (
        <Input
          multiline={multiline}
          type={type}
          disabled={disabled}
          {...input}
          {...rest}
        />
      ))}
    {touched && error ? <Error>{error}</Error> : <Error />}
  </Container>
)

const TextInputField = R.compose(
  withHandlers({
    onChangeSelect: ({ input: { onChange }, isMulti, onChangeInput }) => {
      if (isMulti) return values => onChange(values.map(R.prop('value')))
      return ({ value }) => (
        onChange(value), onChangeInput && onChangeInput(value, value)
      )
    },

    onChangeText: ({ input: { onChange } }) => e =>
      onChange(normilizePhone(e.target.value)),
  }),
  withProps(({ input: { value: values }, select, isMulti, options }) => {
    if (select) {
      if (isMulti) {
        return {
          selectValue: options.filter(({ value }) =>
            values ? values.find(v => v === value) !== undefined : false,
          ),
        }
      }
      return {
        selectValue: options.find(R.propEq('value', values)),
      }
    }
  }),
)(TextInputFieldDamp)

export default TextInputField
