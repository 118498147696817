import { all, call } from 'redux-saga/effects'
import { navigationSaga } from './navigation'
import { authSaga } from './auth'
import { audioSaga } from './audio'
import { usersSaga } from './users'
import { coursesSaga } from './courses'
import { groupsSaga } from './groups'
import { profileSaga } from './profile'
import { wordSetSaga } from './wordSets'
import { stageTwoSaga } from './stageTwo'
import { statisticSaga } from './statistic'

import { NotificationError } from '../services'
import { handleErrors } from '../aspects'

const rootSaga = handleErrors({
  anyError: function*() {
    yield call(NotificationError())
    yield all([
      call(navigationSaga),
      call(authSaga),
      call(audioSaga),
      call(usersSaga),
      call(coursesSaga),
      call(groupsSaga),
      call(profileSaga),
      call(wordSetSaga),
      call(stageTwoSaga),
      call(statisticSaga),
    ])
  },
  '401': function*() {
    yield call(NotificationError('Вы не авторизованны'))
  },
  '403': function*() {
    yield call(NotificationError('Нет прав, обратитесь к администратору'))
  },
})(function*() {
  yield all([
    call(navigationSaga),
    call(authSaga),
    call(audioSaga),
    call(usersSaga),
    call(coursesSaga),
    call(groupsSaga),
    call(profileSaga),
    call(wordSetSaga),
    call(stageTwoSaga),
    call(statisticSaga),
  ])
})

export default rootSaga
