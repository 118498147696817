import * as R from 'ramda'
import API from '../../api'
import { handleStatuses } from '../../aspects'
import { auth, query } from '../../utils/query'

export const getCourseStatistic = R.pipeP(
  (token, data) => fetch(`${API.COURSES}${query(data)}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const getDetailedProfile = R.pipeP(
  (token, data) =>
    fetch(`${API.PROFILE}/detailedStat${query(data)}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const getGroupMagicLink = R.pipeP(
  (token, data) => fetch(`${API.MAGIC_LINK}/${data}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const getRatingTable = R.pipeP(
  (token, data) =>
    fetch(`${API.PROFILE}/ratingTable${query(data)}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.propOr([], 'data'),
)

export const getWordSetStatByUser = R.pipeP(
  (token, data) =>
    fetch(`${API.WORD_SET}/shortStat${query(data)}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.propOr([], 'data'),
)

export const getStage1Stat = R.pipeP(
  (token, data) =>
    fetch(`${API.WORD_SET}/stage1Stat${query(data)}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.propOr([], 'data'),
)

export const getTasks = R.pipeP(
  (token, data) => fetch(`${API.RULES}/wordSet/${data}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.propOr([], 'data'),
)

export const getStage2Stat = R.pipeP(
  (token, data) =>
    fetch(`${API.WORD_SET}/stage2Stat${query(data)}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.propOr({}, 'data'),
)

export const getGroupMainInfo = R.pipeP(
  (token, data) => fetch(`${API.GROUP}/detailedStat/${data}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.propOr({}, 'data'),
)

export const getDetailedWordSet = R.pipeP(
  (token, data) =>
    fetch(
      `${API.WORD_SET}/${data.wordSetId}/statistics${query(
        data.groupId ? { groupId: data.groupId } : { courseId: data.courseId },
      )}`,
      auth(token),
    ),
  handleStatuses(),
  res => res.json(),
  R.propOr({}, 'data'),
)

export const getGroupStudents = R.pipeP(
  (token, data) => fetch(`${API.PROFILE}/group/${data}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.propOr([{}], 'data'),
)

export const getGroupWordSets = R.pipeP(
  (token, data) =>
    fetch(`${API.WORD_SET}/statistics${query({ groupId: data })}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.propOr([], 'data'),
)

export const getCourseMainInfo = R.pipeP(
  (token, data) => fetch(`${API.COURSES}/detailedStat/${data}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.propOr({}, 'data'),
)

export const getCourseGroup = R.pipeP(
  (token, data) => fetch(`${API.GROUP}/course/${data}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.propOr([], 'data'),
)

export const getCourseWordSets = R.pipeP(
  (token, data) =>
    fetch(
      `${API.WORD_SET}/statistics${query({ courseId: data })}`,
      auth(token),
    ),
  handleStatuses(),
  res => res.json(),
  R.propOr([], 'data'),
)
