import * as R from 'ramda'

const getStage = R.prop('stageTwo')

export const getSelectedIndex = R.pipe(
  getStage,
  R.prop('selectedIndex'),
)

export const composeStageTwo = R.pipe(
  getStage,
  R.evolve({
    fillGaps: R.map(
      R.evolve({
        text: R.replace(/\/.+?\//g, '//'),
      }),
    ),
  }),
  ({ audition, fillGaps, makeSentence }) => [
    ...audition,
    ...fillGaps,
    ...makeSentence,
  ],
  R.map(R.omit(['index'])),
)

export const getAudition = R.pipe(
  getStage,
  R.prop('audition'),
)

export const getMakeSentence = R.pipe(
  getStage,
  R.prop('makeSentence'),
)

export const getFillGaps = R.pipe(
  getStage,
  R.prop('fillGaps'),
)

export const getAuditionTable = R.pipe(
  getAudition,
  R.map(({ audio, testType, rightAnswer, variants }) => ({
    audio,
    testType,
    text: rightAnswer.join('/'),
    variants: R.without(rightAnswer, variants).join('/'),
  })),
)

export const getFillGapsTable = R.pipe(
  getFillGaps,
  R.map(({ text, variants, testType }) => ({
    text,
    testType,
    variants: variants.join('/'),
  })),
)

export const getMakeSentenceTable = R.pipe(
  getMakeSentence,
  R.map(({ testType, rightAnswer, variants, text }) => ({
    testType,
    rusText: text,
    enText: rightAnswer.join('/'),
    variants: R.without(rightAnswer, variants).join('/'),
  })),
)

export const getSelectedAudition = R.pipe(
  R.converge(
    (audition, index) => {
      if (index === null) return {}
      const { audio, rightAnswer, variants } = audition[index]
      return {
        audio,
        aRightAnswer: rightAnswer.join('/'),
        aVariants: R.without(rightAnswer, variants).join('/'),
      }
    },
    [getAudition, getSelectedIndex],
  ),
)

export const getSelectedFillGaps = R.pipe(
  R.converge(
    (fillGaps, index) => {
      if (!fillGaps[index]) return {}
      const { text, rightAnswer, variants } = fillGaps[index]
      return {
        fgText: text,
        fgRightAnswer: rightAnswer.join('/'),
        fgVariants: R.without(rightAnswer, variants).join('/'),
      }
    },
    [getFillGaps, getSelectedIndex],
  ),
)

export const getSelectedMakeSentence = R.pipe(
  R.converge(
    (makeSentence, index) => {
      if (!makeSentence[index]) return {}
      const { text, rightAnswer, variants } = makeSentence[index]
      return {
        msText: text,
        msRightAnswer: rightAnswer.join('/'),
        msVariants: R.without(rightAnswer, variants).join('/'),
      }
    },
    [getMakeSentence, getSelectedIndex],
  ),
)
