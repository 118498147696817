import * as R from 'ramda'

const getAudio = R.prop('audio')

export const getAudioTable = R.pipe(
  getAudio,
  R.prop('items'),
)

export const getIsFetchingWordCount = R.pipe(
  getAudio,
  R.prop('isFetchingWordCount'),
)

export const getWordCount = R.pipe(
  getAudio,
  R.prop('wordCount'),
)

export const getAudioForm = R.path(['form', 'AUDIO', 'values'])
