import React from 'react'
import * as R from 'ramda'
import { withHandlers } from 'recompose'
import { withRouter } from 'react-router-dom'
import { ROUTES } from '../../../constants'
import { Table, TableBody, TableRow, TableHead, Grid } from '@material-ui/core'
import { TableCell } from '../commons'

const GroupStatiscticDamp = ({ data, navigateToGroup }) => (
  <Grid container justify="center">
    <Grid sm={11} item>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Кодификатор</TableCell>
            <TableCell>Преподаватель</TableCell>
            <TableCell>Кол-во человек</TableCell>
            <TableCell>Наборов доступно ученикам</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(({ id, name, teacher, studentCount, wordSets }, index) => (
            <TableRow onClick={() => navigateToGroup(id)} key={index}>
              <TableCell>{name}</TableCell>
              <TableCell>{teacher}</TableCell>
              <TableCell>{studentCount}</TableCell>
              <TableCell>{wordSets}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  </Grid>
)

const GroupStatisctic = R.compose(
  withRouter,
  withHandlers({
    navigateToGroup: ({ history: { push: navigate } }) => id =>
      navigate(`${ROUTES.DETAILS}${ROUTES.GROUPS}/${id}`),
  }),
)(GroupStatiscticDamp)

export default GroupStatisctic
