import React from 'react'
import * as R from 'ramda'
import * as Yup from 'yup'
import styled from 'styled-components'
import { Modal } from '@material-ui/core'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { withHandlers } from 'recompose'
import { FORMS } from '../../../constants'
import { saveMakeSentence } from '../../../modules/stageTwo'
import { toArray } from '../../../utils/stringFormater'
import { Button, TextInputField } from '../commons'
import { reduxFormYup } from '../../../utils'

const Form = styled.form`
  display: flex;
  margin: auto;
  margin-top: 10%;
  padding: 25px;
  border-radius: 10px;
  width: 50%;
  flex-direction: column;
  background-color: #fff;
`

const MakeSentenceFormDamp = ({ submitMakeSentence, open, onClose }) => (
  <Modal open={open} onClose={onClose}>
    <Form>
      <Field
        name="msText"
        label="Русское предложение"
        multiline
        component={TextInputField}
      />
      <Field
        name="msRightAnswer"
        label="Английское предложение"
        multiline
        component={TextInputField}
      />
      <Field
        name="msVariants"
        label="Лишние слова"
        component={TextInputField}
      />
      <Button type="submit" onClick={submitMakeSentence}>
        Отправить
      </Button>
    </Form>
  </Modal>
)

const MakeSentenceFormScheme = Yup.object().shape({
  msText: Yup.string()
    .max(250)
    .required(),
  msRightAnswer: Yup.string()
    .max(250)
    .required(),
  msVariants: Yup.string()
    .max(250)
    .required(),
})

const MakeSentenceForm = R.compose(
  connect(
    R.applySpec({}),
    { saveMakeSentence },
  ),
  reduxForm({
    form: FORMS.STAGE_TWO,
    validate: reduxFormYup(MakeSentenceFormScheme),
  }),
  withHandlers({
    submitMakeSentence: ({ handleSubmit, saveMakeSentence, onClose }) =>
      handleSubmit(
        ({ msText, msRightAnswer, msVariants }) =>
          saveMakeSentence({
            text: msText,
            testType: '11',
            rightAnswer: toArray(msRightAnswer),
            variants: R.concat(toArray(msRightAnswer), toArray(msVariants)),
          }),
        onClose(),
      ),
  }),
)(MakeSentenceFormDamp)

export default MakeSentenceForm
