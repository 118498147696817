import React from 'react'
import * as R from 'ramda'
import { TableCell } from '../commons'
import styled from 'styled-components'
import {
  Grid,
  Table,
  TableRow,
  TableHead,
  TableBody,
  Typography,
} from '@material-ui/core'

const Title = styled(Typography)`
  font-size: 22px;
  text-align: center;
  margin: 15px 0;
`

const StageOneInfoDamb = ({ data = [], tasks = [], avg = [] }) =>
  !data || tasks.length === 0 ? (
    <Grid container direction="column" justify="center">
      <Title>{'Этап 1'}</Title>
      <Title>{'По набору пока нет показателей'}</Title>
    </Grid>
  ) : (
    <Grid container justify="center">
      <Grid sm={9}>
        <Title>{'Этап 1'}</Title>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Слово</TableCell>
              {tasks.map(x => (
                <TableCell>{x}</TableCell>
              ))}
              <TableCell>Average</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {R.keys(data).map((word, index) => (
              <TableRow key={index}>
                <TableCell>{word}</TableCell>
                {tasks.map(x => {
                  const a = R.find(R.propEq('testType', x))(data[word])
                  return <TableCell>{a ? a.errors : 0}</TableCell>
                })}
                <TableCell>
                  {data[word]
                    ? (
                        data[word].reduce(
                          (acc, { errors }) => acc + errors,
                          0,
                        ) / tasks.length
                      ).toFixed(2)
                    : '0.00'}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>Average</TableCell>
              {tasks.map((x, i) => (
                <TableCell key={i}>
                  {avg[x]
                    ? (
                        avg[x].reduce((acc, { errors }) => acc + errors, 0) /
                        R.keys(data).length
                      ).toFixed(2)
                    : '0.00'}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )

const StageOneInfo = StageOneInfoDamb
export default StageOneInfo
