import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import {
  Grid,
  Tab,
  AppBar as AppBarMaterial,
  Tabs as TabsMaterial,
} from '@material-ui/core'
import { data } from '../../../mock/users'
import { withState, withProps } from 'recompose'
import { GroupStatisctic } from '.'
import { WordSetStatisctic } from '../wordSets'

const AppBar = styled(AppBarMaterial).attrs({
  position: 'static',
})`
  background-color: rgb(241, 77, 27);
  margin-bottom: 10px;
`

const Tabs = styled(TabsMaterial).attrs({
  indicatorColor: 'primary',
  fullWidth: true,
})`
  color: #fff;
`

const CourseStatisticsDamp = ({
  tab,
  setCurrentTab,
  groups,
  wordSets,
  courseId,
}) => (
  <Grid sm={12} container>
    <AppBar>
      <Tabs value={tab} onChange={(_, numberTab) => setCurrentTab(numberTab)}>
        <Tab label="Группы курса" />
        <Tab label="Наборы курса" />
      </Tabs>
    </AppBar>
    {tab === 0 && <GroupStatisctic data={groups} />}
    {tab === 1 && <WordSetStatisctic courseId={courseId} data={wordSets} />}
  </Grid>
)

const CourseStatistics = R.compose(
  withState('tab', 'setCurrentTab', 0),
  withProps(() => ({ ...data })),
)(CourseStatisticsDamp)

export default CourseStatistics
