import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { Paper, Grid } from '@material-ui/core'
import {
  withHandlers,
  withState,
  lifecycle,
  withStateHandlers,
} from 'recompose'
import {
  UserInfo,
  UserFrom as Form,
  ActivationModal,
  UserStatistics,
} from '../components/users'
import { Divider, Button, withRole } from '../components/commons'
import { reduxForm } from 'redux-form'
import { FORMS } from '../../constants'

import { connect } from 'react-redux'
import { withTitle } from './withTitle'
import {
  fetchSaveUser,
  fetchActiveUser,
  fetchUserStatistics,
  getSelectedGroup,
  getSelectedUserId,
} from '../../modules/users'
import { fetchGroups, getGroupsForSelect } from '../../modules/groups'
import { getProfileInfo } from '../../modules/statistic'

const Container = styled(Paper)`
  padding-top: 20px;
`

const ActiveButton = styled(Button)`
  margin-left: auto;
  margin-right: 20px;
`

const SaveButton = styled(Button)`
  margin-right: 20px;
`

const SaveButtonWithRole = withRole(SaveButton, true)
const ActiveButtonWithRole = withRole(ActiveButton)

const UserFormDamp = ({
  submitSave,
  submitting,
  groups,
  currentUserId,
  isActivating,
  onClose,
  requestActive,
  fetchActiveUser,
  info,
}) => (
  <>
    <Container>
      <Grid container direction="column">
        <Divider>
          {currentUserId && (
            <ActiveButtonWithRole
              onClick={requestActive}
              loading={submitting}
              name="Активировать"
            />
          )}
          <SaveButtonWithRole
            onClick={submitSave}
            loading={submitting}
            name="Сохранить"
            style={{ marginLeft: !currentUserId ? 'auto' : 20 }}
          />
        </Divider>
        <Grid container direction="row">
          <Grid style={{ zIndex: 9999 }} item sm={currentUserId ? 6 : 12}>
            <Form userId={currentUserId} groups={groups} />
          </Grid>
          {currentUserId && <UserInfo info={info} />}
          {currentUserId && <UserStatistics userId={currentUserId} />}
        </Grid>
      </Grid>
    </Container>
    {isActivating && (
      <ActivationModal
        userId={currentUserId}
        onCancelClick={onClose}
        onDoneClick={fetchActiveUser}
      />
    )}
  </>
)

const UserForm = R.compose(
  withTitle('Ученик'),
  reduxForm({ form: FORMS.USER_FORM }),
  connect(
    R.applySpec({
      currentUserId: getSelectedUserId,
      groupId: getSelectedGroup,
      groups: getGroupsForSelect,
      info: getProfileInfo,
    }),
    { fetchGroups, fetchSaveUser, fetchActiveUser, fetchUserStatistics },
  ),
  withState('currentWordSet', 'setCurrentWordSet', null),
  withHandlers({
    submitSave: ({ handleSubmit, fetchSaveUser, currentUserId: id }) =>
      handleSubmit(user => fetchSaveUser({ id, ...user })),
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchGroups()
    },
  }),
  withStateHandlers(
    { isActivating: false },
    {
      requestActive: () => () => ({ isActivating: true }),
      onClose: () => () => ({ isActivating: false }),
    },
  ),
)(UserFormDamp)

export default UserForm
