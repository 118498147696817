export const MODULES = {
  NAVIGATION: 'NAVIGATION',
  PROFILE: 'PROFILE',
  AUTH: 'AUTH',
  USERS: 'USERS',
  GROUPS: 'GROUPS',
  COURSES: 'COURSES',
  WORD_SETS: 'WORD_SETS',
  STATISTIC: 'STATISTIC',
  UI: ' UI',
}

export const TEST_TYPE = {
  STUDY: 'Изучения',
  REPEAT: 'Повторения',
}

export const STORAGE = { TOKEN: 'TOKEN' }

export const ROLE = {
  ADMIN: 'Администратор',
  TEACHER: 'Учитель',
  MENTOR: 'Ментор',
}

export const ROUTES = {
  BASE: '/',
  SIGNIN: '/signin',
  DETAILS: '/details',

  WORD_SETS: '/wordSets',
  WORD_SETS_FORM: '/wordSets/new',
  WORD_SET_STAGE_TWO: '/stageTwo',
  SELECTED_WORD_SET: '/wordSets/:id',
  SELECTED_WORD_SET_STAGE_TWO: '/stageTwo/:id',
  WORD_SET_COURSE_DETAILS: '/details/courses/:courseId/wordSets/:id',
  WORD_SET_GROUP_DETAILS: '/details/groups/:groupId/wordSets/:id',

  USERS: '/users',
  USER_FORM: '/users/new',
  SELECTED_USER: '/users/:id',

  COURSES: '/courses',
  COURSE_FORM: '/courses/new',
  SELECTED_COURSE: '/courses/:id',
  COURSE_DETAILS: '/details/courses/:id',

  GROUPS: '/groups',
  GROUP_FORM: '/groups/new',
  SELECTED_GROUP: '/groups/:id',
  GROUP_DETAILS: '/details/groups/:id',

  AUDIO: '/audio',
  RULES: '/rules',
}

export const FORMS = {
  SIGNIN: 'SIGNIN',
  WORD: 'WORD',
  AUDIO: 'AUDIO',
  ADD_STUDENT_FROM: 'ADD_STUDENT_FROM',

  GROUP_FROM: 'GROUP_FROM',

  USER_FORM: 'USER_FORM',
  USER_SYSTEM_FORM: 'USER_SYSTEM_FORM',
  COURSE_FORM: 'COURSE_FORM',
  WORD_SET_FORM: 'WORD_SETS_FORM',
  STAGE_TWO: 'STAGE_TWO',
}

export const EMOJI = [
  {
    name: 'repeat_1',
    short_names: ['maximum_repeat_1'],
    imageUrl: '/files/emoji/r1.png',
  },
  {
    name: 'repeat_2',
    short_names: ['maximum_repeat_2'],
    imageUrl: '/files/emoji/r2.png',
  },
  {
    name: 'repeat_3',
    short_names: ['maximum_repeat_3'],
    imageUrl: '/files/emoji/r3.png',
  },
  {
    name: 'repeat_4',
    short_names: ['maximum_repeat_4'],
    imageUrl: '/files/emoji/r4.png',
  },
  {
    name: 'repeat_5',
    short_names: ['maximum_repeat_5'],
    imageUrl: '/files/emoji/r5.png',
  },
  {
    name: 'repeat_6',
    short_names: ['maximum_repeat_6'],
    imageUrl: '/files/emoji/r6.png',
  },
  {
    name: 'repeat_7',
    short_names: ['maximum_repeat_7'],
    imageUrl: '/files/emoji/r7.png',
  },
  {
    name: 'repeat_8',
    short_names: ['maximum_repeat_8'],
    imageUrl: '/files/emoji/r8.png',
  },
  {
    name: 'repeat_9',
    short_names: ['maximum_repeat_9'],
    imageUrl: '/files/emoji/r9.png',
  },
  {
    name: 'repeat_10',
    short_names: ['maximum_repeat_10'],
    imageUrl: '/files/emoji/r10.png',
  },
  {
    name: 'repeat_11',
    short_names: ['maximum_repeat_11'],
    imageUrl: '/files/emoji/r11.png',
  },
  {
    name: 'repeat_12',
    short_names: ['maximum_repeat_12'],
    imageUrl: '/files/emoji/r12.png',
  },
  {
    name: 'activity_1',
    short_names: ['maximum_activity_1'],
    imageUrl: '/files/emoji/activity_1.png',
  },
  {
    name: 'activity_2',
    short_names: ['maximum_activity_2'],
    imageUrl: '/files/emoji/activity_2.png',
  },
  {
    name: 'activity_3',
    short_names: ['maximum_activity_3'],
    imageUrl: '/files/emoji/activity_3.png',
  },
  {
    name: 'activity_4',
    short_names: ['maximum_activity_4'],
    imageUrl: '/files/emoji/activity_4.png',
  },
  {
    name: 'activity_5',
    short_names: ['maximum_activity_5'],
    imageUrl: '/files/emoji/activity_5.png',
  },
  {
    name: 'activity_6',
    short_names: ['maximum_activity_6'],
    imageUrl: '/files/emoji/activity_6.png',
  },
  {
    name: 'activity_7',
    short_names: ['maximum_activity_7'],
    imageUrl: '/files/emoji/activity_7.png',
  },
  {
    name: 'activity_8',
    short_names: ['maximum_activity_8'],
    imageUrl: '/files/emoji/activity_8.png',
  },
  {
    name: 'activity_9',
    short_names: ['maximum_activity_9'],
    imageUrl: '/files/emoji/activity_9.png',
  },
  {
    name: 'activity_10',
    short_names: ['maximum_activity_10'],
    imageUrl: '/files/emoji/activity_10.png',
  },
  {
    name: 'activity_11',
    short_names: ['maximum_activity_11'],
    imageUrl: '/files/emoji/activity_11.png',
  },
  {
    name: 'activity_12',
    short_names: ['maximum_activity_12'],
    imageUrl: '/files/emoji/activity_12.png',
  },
  {
    name: 'activity_13',
    short_names: ['maximum_activity_13'],
    imageUrl: '/files/emoji/activity_13.png',
  },
  {
    name: 'activity_14',
    short_names: ['maximum_activity_14'],
    imageUrl: '/files/emoji/activity_14.png',
  },
  {
    name: 'activity_15',
    short_names: ['maximum_activity_15'],
    imageUrl: '/files/emoji/activity_15.png',
  },
  {
    name: 'activity_16',
    short_names: ['maximum_activity_16'],
    imageUrl: '/files/emoji/activity_16.png',
  },
  {
    name: 'activity_17',
    short_names: ['maximum_activity_17'],
    imageUrl: '/files/emoji/activity_17.png',
  },
  {
    name: 'activity_18',
    short_names: ['maximum_activity_18'],
    imageUrl: '/files/emoji/activity_18.png',
  },
  {
    name: 'activity_19',
    short_names: ['maximum_activity_19'],
    imageUrl: '/files/emoji/activity_19.png',
  },
  {
    name: 'activity_20',
    short_names: ['maximum_activity_20'],
    imageUrl: '/files/emoji/activity_20.png',
  },
  {
    name: 'activity_21',
    short_names: ['maximum_activity_21'],
    imageUrl: '/files/emoji/activity_21.png',
  },
  {
    name: 'activity_22',
    short_names: ['maximum_activity_22'],
    imageUrl: '/files/emoji/activity_22.png',
  },
  {
    name: 'activity_23',
    short_names: ['maximum_activity_23'],
    imageUrl: '/files/emoji/activity_23.png',
  },
  {
    name: 'activity_24',
    short_names: ['maximum_activity_24'],
    imageUrl: '/files/emoji/activity_24.png',
  },
  {
    name: 'activity_25',
    short_names: ['maximum_activity_25'],
    imageUrl: '/files/emoji/activity_25.png',
  },
  {
    name: 'activity_26',
    short_names: ['maximum_activity_26'],
    imageUrl: '/files/emoji/activity_26.png',
  },
  {
    name: 'activity_27',
    short_names: ['maximum_activity_27'],
    imageUrl: '/files/emoji/activity_27.png',
  },
  {
    name: 'activity_28',
    short_names: ['maximum_activity_28'],
    imageUrl: '/files/emoji/activity_28.png',
  },
  {
    name: 'activity_29',
    short_names: ['maximum_activity_29'],
    imageUrl: '/files/emoji/activity_29.png',
  },
  {
    name: 'activity_30',
    short_names: ['maximum_activity_30'],
    imageUrl: '/files/emoji/activity_30.png',
  },
  {
    name: 'activity_31',
    short_names: ['maximum_activity_31'],
    imageUrl: '/files/emoji/activity_31.png',
  },
  {
    name: 'activity_32',
    short_names: ['maximum_activity_32'],
    imageUrl: '/files/emoji/activity_32.png',
  },
  {
    name: 'activity_33',
    short_names: ['maximum_activity_33'],
    imageUrl: '/files/emoji/activity_33.png',
  },
  {
    name: 'activity_34',
    short_names: ['maximum_activity_34'],
    imageUrl: '/files/emoji/activity_34.png',
  },
  {
    name: 'activity_35',
    short_names: ['maximum_activity_35'],
    imageUrl: '/files/emoji/activity_35.png',
  },
  {
    name: 'activity_36',
    short_names: ['maximum_activity_36'],
    imageUrl: '/files/emoji/activity_36.png',
  },
  {
    name: 'activity_37',
    short_names: ['maximum_activity_37'],
    imageUrl: '/files/emoji/activity_37.png',
  },
  {
    name: 'activity_38',
    short_names: ['maximum_activity_38'],
    imageUrl: '/files/emoji/activity_38.png',
  },
  {
    name: 'activity_39',
    short_names: ['maximum_activity_39'],
    imageUrl: '/files/emoji/activity_39.png',
  },
  {
    name: 'activity_40',
    short_names: ['maximum_activity_40'],
    imageUrl: '/files/emoji/activity_40.png',
  },
  {
    name: 'activity_41',
    short_names: ['maximum_activity_41'],
    imageUrl: '/files/emoji/activity_41.png',
  },
  {
    name: 'activity_42',
    short_names: ['maximum_activity_42'],
    imageUrl: '/files/emoji/activity_42.png',
  },
  {
    name: 'activity_43',
    short_names: ['maximum_activity_43'],
    imageUrl: '/files/emoji/activity_43.png',
  },
  {
    name: 'activity_44',
    short_names: ['maximum_activity_44'],
    imageUrl: '/files/emoji/activity_44.png',
  },
  {
    name: 'activity_45',
    short_names: ['maximum_activity_45'],
    imageUrl: '/files/emoji/activity_45.png',
  },
  {
    name: 'activity_46',
    short_names: ['maximum_activity_46'],
    imageUrl: '/files/emoji/activity_46.png',
  },
  {
    name: 'activity_47',
    short_names: ['maximum_activity_47'],
    imageUrl: '/files/emoji/activity_47.png',
  },
  {
    name: 'activity_48',
    short_names: ['maximum_activity_48'],
    imageUrl: '/files/emoji/activity_48.png',
  },
  {
    name: 'activity_49',
    short_names: ['maximum_activity_49'],
    imageUrl: '/files/emoji/activity_49.png',
  },
  {
    name: 'activity_50',
    short_names: ['maximum_activity_50'],
    imageUrl: '/files/emoji/activity_50.png',
  },
  {
    name: 'activity_51',
    short_names: ['maximum_activity_51'],
    imageUrl: '/files/emoji/activity_51.png',
  },
  {
    name: 'activity_52',
    short_names: ['maximum_activity_52'],
    imageUrl: '/files/emoji/activity_52.png',
  },
  {
    name: 'activity_53',
    short_names: ['maximum_activity_53'],
    imageUrl: '/files/emoji/activity_53.png',
  },
  {
    name: 'activity_54',
    short_names: ['maximum_activity_54'],
    imageUrl: '/files/emoji/activity_54.png',
  },
  {
    name: 'activity_55',
    short_names: ['maximum_activity_55'],
    imageUrl: '/files/emoji/activity_55.png',
  },
  {
    name: 'activity_56',
    short_names: ['maximum_activity_56'],
    imageUrl: '/files/emoji/activity_56.png',
  },
  {
    name: 'activity_57',
    short_names: ['maximum_activity_57'],
    imageUrl: '/files/emoji/activity_57.png',
  },
  {
    name: 'activity_58',
    short_names: ['maximum_activity_58'],
    imageUrl: '/files/emoji/activity_58.png',
  },
  {
    name: 'activity_59',
    short_names: ['maximum_activity_59'],
    imageUrl: '/files/emoji/activity_59.png',
  },
  {
    name: 'activity_60',
    short_names: ['maximum_activity_60'],
    imageUrl: '/files/emoji/activity_60.png',
  },
  {
    name: 'activity_61',
    short_names: ['maximum_activity_61'],
    imageUrl: '/files/emoji/activity_61.png',
  },
  {
    name: 'activity_62',
    short_names: ['maximum_activity_62'],
    imageUrl: '/files/emoji/activity_62.png',
  },
  {
    name: 'activity_63',
    short_names: ['maximum_activity_63'],
    imageUrl: '/files/emoji/activity_63.png',
  },
  {
    name: 'activity_64',
    short_names: ['maximum_activity_64'],
    imageUrl: '/files/emoji/activity_64.png',
  },
  {
    name: 'activity_65',
    short_names: ['maximum_activity_65'],
    imageUrl: '/files/emoji/activity_65.png',
  },
  {
    name: 'activity_66',
    short_names: ['maximum_activity_66'],
    imageUrl: '/files/emoji/activity_66.png',
  },
  {
    name: 'activity_67',
    short_names: ['maximum_activity_67'],
    imageUrl: '/files/emoji/activity_67.png',
  },
  {
    name: 'activity_68',
    short_names: ['maximum_activity_68'],
    imageUrl: '/files/emoji/activity_68.png',
  },
  {
    name: 'activity_69',
    short_names: ['maximum_activity_69'],
    imageUrl: '/files/emoji/activity_69.png',
  },
  {
    name: 'activity_70',
    short_names: ['maximum_activity_70'],
    imageUrl: '/files/emoji/activity_70.png',
  },
  {
    name: 'activity_71',
    short_names: ['maximum_activity_71'],
    imageUrl: '/files/emoji/activity_71.png',
  },
  {
    name: 'activity_72',
    short_names: ['maximum_activity_72'],
    imageUrl: '/files/emoji/activity_72.png',
  },
  {
    name: 'activity_73',
    short_names: ['maximum_activity_73'],
    imageUrl: '/files/emoji/activity_73.png',
  },
  {
    name: 'activity_74',
    short_names: ['maximum_activity_74'],
    imageUrl: '/files/emoji/activity_74.png',
  },
  {
    name: 'activity_75',
    short_names: ['maximum_activity_75'],
    imageUrl: '/files/emoji/activity_75.png',
  },
  {
    name: 'activity_76',
    short_names: ['maximum_activity_76'],
    imageUrl: '/files/emoji/activity_76.png',
  },
  {
    name: 'activity_77',
    short_names: ['maximum_activity_77'],
    imageUrl: '/files/emoji/activity_77.png',
  },
  {
    name: 'activity_78',
    short_names: ['maximum_activity_78'],
    imageUrl: '/files/emoji/activity_78.png',
  },
  {
    name: 'activity_79',
    short_names: ['maximum_activity_79'],
    imageUrl: '/files/emoji/activity_79.png',
  },
  {
    name: 'activity_80',
    short_names: ['maximum_activity_80'],
    imageUrl: '/files/emoji/activity_80.png',
  },
  {
    name: 'activity_81',
    short_names: ['maximum_activity_81'],
    imageUrl: '/files/emoji/activity_81.png',
  },
  {
    name: 'activity_82',
    short_names: ['maximum_activity_82'],
    imageUrl: '/files/emoji/activity_82.png',
  },
  {
    name: 'activity_83',
    short_names: ['maximum_activity_83'],
    imageUrl: '/files/emoji/activity_83.png',
  },
  {
    name: 'activity_84',
    short_names: ['maximum_activity_84'],
    imageUrl: '/files/emoji/activity_84.png',
  },
  {
    name: 'activity_85',
    short_names: ['maximum_activity_85'],
    imageUrl: '/files/emoji/activity_85.png',
  },
  {
    name: 'activity_86',
    short_names: ['maximum_activity_86'],
    imageUrl: '/files/emoji/activity_86.png',
  },
  {
    name: 'activity_87',
    short_names: ['maximum_activity_87'],
    imageUrl: '/files/emoji/activity_87.png',
  },
  {
    name: 'activity_88',
    short_names: ['maximum_activity_88'],
    imageUrl: '/files/emoji/activity_88.png',
  },
  {
    name: 'activity_89',
    short_names: ['maximum_activity_89'],
    imageUrl: '/files/emoji/activity_89.png',
  },
  {
    name: 'activity_90',
    short_names: ['maximum_activity_90'],
    imageUrl: '/files/emoji/activity_90.png',
  },
  {
    name: 'activity_91',
    short_names: ['maximum_activity_91'],
    imageUrl: '/files/emoji/activity_91.png',
  },
  {
    name: 'activity_92',
    short_names: ['maximum_activity_92'],
    imageUrl: '/files/emoji/activity_92.png',
  },
  {
    name: 'activity_93',
    short_names: ['maximum_activity_93'],
    imageUrl: '/files/emoji/activity_93.png',
  },
  {
    name: 'activity_94',
    short_names: ['maximum_activity_94'],
    imageUrl: '/files/emoji/activity_94.png',
  },
  {
    name: 'activity_95',
    short_names: ['maximum_activity_95'],
    imageUrl: '/files/emoji/activity_95.png',
  },
  {
    name: 'activity_96',
    short_names: ['maximum_activity_96'],
    imageUrl: '/files/emoji/activity_96.png',
  },
  {
    name: 'activity_97',
    short_names: ['maximum_activity_97'],
    imageUrl: '/files/emoji/activity_97.png',
  },
  {
    name: 'activity_98',
    short_names: ['maximum_activity_98'],
    imageUrl: '/files/emoji/activity_98.png',
  },
  {
    name: 'activity_99',
    short_names: ['maximum_activity_99'],
    imageUrl: '/files/emoji/activity_99.png',
  },
  {
    name: 'activity_100',
    short_names: ['maximum_activity_100'],
    imageUrl: '/files/emoji/activity_100.png',
  },
  {
    name: 'activity_101',
    short_names: ['maximum_activity_101'],
    imageUrl: '/files/emoji/activity_101.png',
  },
  {
    name: 'activity_102',
    short_names: ['maximum_activity_102'],
    imageUrl: '/files/emoji/activity_102.png',
  },
  {
    name: 'activity_103',
    short_names: ['maximum_activity_103'],
    imageUrl: '/files/emoji/activity_103.png',
  },
  {
    name: 'activity_104',
    short_names: ['maximum_activity_104'],
    imageUrl: '/files/emoji/activity_104.png',
  },
  {
    name: 'activity_105',
    short_names: ['maximum_activity_105'],
    imageUrl: '/files/emoji/activity_105.png',
  },
  {
    name: 'activity_106',
    short_names: ['maximum_activity_106'],
    imageUrl: '/files/emoji/activity_106.png',
  },
  {
    name: 'activity_107',
    short_names: ['maximum_activity_107'],
    imageUrl: '/files/emoji/activity_107.png',
  },
  {
    name: 'activity_108',
    short_names: ['maximum_activity_108'],
    imageUrl: '/files/emoji/activity_108.png',
  },
  {
    name: 'activity_109',
    short_names: ['maximum_activity_109'],
    imageUrl: '/files/emoji/activity_109.png',
  },
  {
    name: 'activity_110',
    short_names: ['maximum_activity_110'],
    imageUrl: '/files/emoji/activity_110.png',
  },
  {
    name: 'activity_111',
    short_names: ['maximum_activity_111'],
    imageUrl: '/files/emoji/activity_111.png',
  },
  {
    name: 'activity_112',
    short_names: ['maximum_activity_112'],
    imageUrl: '/files/emoji/activity_112.png',
  },
  {
    name: 'activity_113',
    short_names: ['maximum_activity_113'],
    imageUrl: '/files/emoji/activity_113.png',
  },
  {
    name: 'activity_114',
    short_names: ['maximum_activity_114'],
    imageUrl: '/files/emoji/activity_114.png',
  },
  {
    name: 'activity_115',
    short_names: ['maximum_activity_115'],
    imageUrl: '/files/emoji/activity_115.png',
  },
  {
    name: 'activity_116',
    short_names: ['maximum_activity_116'],
    imageUrl: '/files/emoji/activity_116.png',
  },
  {
    name: 'activity_117',
    short_names: ['maximum_activity_117'],
    imageUrl: '/files/emoji/activity_117.png',
  },
  {
    name: 'activity_118',
    short_names: ['maximum_activity_118'],
    imageUrl: '/files/emoji/activity_118.png',
  },
  {
    name: 'activity_119',
    short_names: ['maximum_activity_119'],
    imageUrl: '/files/emoji/activity_119.png',
  },
  {
    name: 'activity_120',
    short_names: ['maximum_activity_120'],
    imageUrl: '/files/emoji/activity_120.png',
  },
  {
    name: 'activity_121',
    short_names: ['maximum_activity_121'],
    imageUrl: '/files/emoji/activity_121.png',
  },
  {
    name: 'activity_122',
    short_names: ['maximum_activity_122'],
    imageUrl: '/files/emoji/activity_122.png',
  },
  {
    name: 'activity_123',
    short_names: ['maximum_activity_123'],
    imageUrl: '/files/emoji/activity_123.png',
  },
  {
    name: 'activity_124',
    short_names: ['maximum_activity_124'],
    imageUrl: '/files/emoji/activity_124.png',
  },
  {
    name: 'activity_125',
    short_names: ['maximum_activity_125'],
    imageUrl: '/files/emoji/activity_125.png',
  },
  {
    name: 'activity_126',
    short_names: ['maximum_activity_126'],
    imageUrl: '/files/emoji/activity_126.png',
  },
  {
    name: 'activity_127',
    short_names: ['maximum_activity_127'],
    imageUrl: '/files/emoji/activity_127.png',
  },
  {
    name: 'activity_128',
    short_names: ['maximum_activity_128'],
    imageUrl: '/files/emoji/activity_128.png',
  },
  {
    name: 'activity_129',
    short_names: ['maximum_activity_129'],
    imageUrl: '/files/emoji/activity_129.png',
  },
  {
    name: 'activity_130',
    short_names: ['maximum_activity_130'],
    imageUrl: '/files/emoji/activity_130.png',
  },
  {
    name: 'activity_131',
    short_names: ['maximum_activity_131'],
    imageUrl: '/files/emoji/activity_131.png',
  },
  {
    name: 'activity_132',
    short_names: ['maximum_activity_132'],
    imageUrl: '/files/emoji/activity_132.png',
  },
  {
    name: 'activity_133',
    short_names: ['maximum_activity_133'],
    imageUrl: '/files/emoji/activity_133.png',
  },
  {
    name: 'activity_134',
    short_names: ['maximum_activity_134'],
    imageUrl: '/files/emoji/activity_134.png',
  },
  {
    name: 'activity_135',
    short_names: ['maximum_activity_135'],
    imageUrl: '/files/emoji/activity_135.png',
  },
  {
    name: 'activity_136',
    short_names: ['maximum_activity_136'],
    imageUrl: '/files/emoji/activity_136.png',
  },
  {
    name: 'activity_137',
    short_names: ['maximum_activity_137'],
    imageUrl: '/files/emoji/activity_137.png',
  },
  {
    name: 'activity_138',
    short_names: ['maximum_activity_138'],
    imageUrl: '/files/emoji/activity_138.png',
  },
  {
    name: 'activity_139',
    short_names: ['maximum_activity_139'],
    imageUrl: '/files/emoji/activity_139.png',
  },
  {
    name: 'activity_140',
    short_names: ['maximum_activity_140'],
    imageUrl: '/files/emoji/activity_140.png',
  },
  {
    name: 'activity_141',
    short_names: ['maximum_activity_141'],
    imageUrl: '/files/emoji/activity_141.png',
  },
  {
    name: 'activity_142',
    short_names: ['maximum_activity_142'],
    imageUrl: '/files/emoji/activity_142.png',
  },
  {
    name: 'activity_143',
    short_names: ['maximum_activity_143'],
    imageUrl: '/files/emoji/activity_143.png',
  },
  {
    name: 'activity_144',
    short_names: ['maximum_activity_144'],
    imageUrl: '/files/emoji/activity_144.png',
  },
  {
    name: 'activity_145',
    short_names: ['maximum_activity_145'],
    imageUrl: '/files/emoji/activity_145.png',
  },
  {
    name: 'activity_146',
    short_names: ['maximum_activity_146'],
    imageUrl: '/files/emoji/activity_146.png',
  },
  {
    name: 'activity_147',
    short_names: ['maximum_activity_147'],
    imageUrl: '/files/emoji/activity_147.png',
  },
  {
    name: 'activity_148',
    short_names: ['maximum_activity_148'],
    imageUrl: '/files/emoji/activity_148.png',
  },
  {
    name: 'activity_149',
    short_names: ['maximum_activity_149'],
    imageUrl: '/files/emoji/activity_149.png',
  },
  {
    name: 'activity_150',
    short_names: ['maximum_activity_150'],
    imageUrl: '/files/emoji/activity_150.png',
  },
  {
    name: 'activity_151',
    short_names: ['maximum_activity_151'],
    imageUrl: '/files/emoji/activity_151.png',
  },
  {
    name: 'activity_152',
    short_names: ['maximum_activity_152'],
    imageUrl: '/files/emoji/activity_152.png',
  },
  {
    name: 'activity_153',
    short_names: ['maximum_activity_153'],
    imageUrl: '/files/emoji/activity_153.png',
  },
  {
    name: 'activity_154',
    short_names: ['maximum_activity_154'],
    imageUrl: '/files/emoji/activity_154.png',
  },
  {
    name: 'activity_155',
    short_names: ['maximum_activity_155'],
    imageUrl: '/files/emoji/activity_155.png',
  },
  {
    name: 'activity_156',
    short_names: ['maximum_activity_156'],
    imageUrl: '/files/emoji/activity_156.png',
  },
  {
    name: 'activity_157',
    short_names: ['maximum_activity_157'],
    imageUrl: '/files/emoji/activity_157.png',
  },
  {
    name: 'activity_158',
    short_names: ['maximum_activity_158'],
    imageUrl: '/files/emoji/activity_158.png',
  },
  {
    name: 'activity_159',
    short_names: ['maximum_activity_159'],
    imageUrl: '/files/emoji/activity_159.png',
  },
  {
    name: 'activity_160',
    short_names: ['maximum_activity_160'],
    imageUrl: '/files/emoji/activity_160.png',
  },
  {
    name: 'activity_161',
    short_names: ['maximum_activity_161'],
    imageUrl: '/files/emoji/activity_161.png',
  },
  {
    name: 'activity_162',
    short_names: ['maximum_activity_162'],
    imageUrl: '/files/emoji/activity_162.png',
  },
  {
    name: 'activity_163',
    short_names: ['maximum_activity_163'],
    imageUrl: '/files/emoji/activity_163.png',
  },
  {
    name: 'activity_164',
    short_names: ['maximum_activity_164'],
    imageUrl: '/files/emoji/activity_164.png',
  },
  {
    name: 'activity_165',
    short_names: ['maximum_activity_165'],
    imageUrl: '/files/emoji/activity_165.png',
  },
  {
    name: 'activity_166',
    short_names: ['maximum_activity_166'],
    imageUrl: '/files/emoji/activity_166.png',
  },
  {
    name: 'activity_167',
    short_names: ['maximum_activity_167'],
    imageUrl: '/files/emoji/activity_167.png',
  },
  {
    name: 'activity_168',
    short_names: ['maximum_activity_168'],
    imageUrl: '/files/emoji/activity_168.png',
  },
  {
    name: 'activity_169',
    short_names: ['maximum_activity_169'],
    imageUrl: '/files/emoji/activity_169.png',
  },
  {
    name: 'activity_170',
    short_names: ['maximum_activity_170'],
    imageUrl: '/files/emoji/activity_170.png',
  },
  {
    name: 'activity_171',
    short_names: ['maximum_activity_171'],
    imageUrl: '/files/emoji/activity_171.png',
  },
  {
    name: 'activity_172',
    short_names: ['maximum_activity_172'],
    imageUrl: '/files/emoji/activity_172.png',
  },
  {
    name: 'activity_173',
    short_names: ['maximum_activity_173'],
    imageUrl: '/files/emoji/activity_173.png',
  },
  {
    name: 'activity_174',
    short_names: ['maximum_activity_174'],
    imageUrl: '/files/emoji/activity_174.png',
  },
  {
    name: 'activity_175',
    short_names: ['maximum_activity_175'],
    imageUrl: '/files/emoji/activity_175.png',
  },
  {
    name: 'activity_176',
    short_names: ['maximum_activity_176'],
    imageUrl: '/files/emoji/activity_176.png',
  },
  {
    name: 'activity_177',
    short_names: ['maximum_activity_177'],
    imageUrl: '/files/emoji/activity_177.png',
  },
  {
    name: 'activity_178',
    short_names: ['maximum_activity_178'],
    imageUrl: '/files/emoji/activity_178.png',
  },
  {
    name: 'activity_179',
    short_names: ['maximum_activity_179'],
    imageUrl: '/files/emoji/activity_179.png',
  },
  {
    name: 'activity_180',
    short_names: ['maximum_activity_180'],
    imageUrl: '/files/emoji/activity_180.png',
  },
  {
    name: 'activity_181',
    short_names: ['maximum_activity_181'],
    imageUrl: '/files/emoji/activity_181.png',
  },
  {
    name: 'activity_182',
    short_names: ['maximum_activity_182'],
    imageUrl: '/files/emoji/activity_182.png',
  },
  {
    name: 'activity_183',
    short_names: ['maximum_activity_183'],
    imageUrl: '/files/emoji/activity_183.png',
  },
  {
    name: 'activity_184',
    short_names: ['maximum_activity_184'],
    imageUrl: '/files/emoji/activity_184.png',
  },
  {
    name: 'activity_185',
    short_names: ['maximum_activity_185'],
    imageUrl: '/files/emoji/activity_185.png',
  },
  {
    name: 'animals_1',
    short_names: ['maximum_animals_1'],
    imageUrl: '/files/emoji/animals_1.png',
  },
  {
    name: 'animals_2',
    short_names: ['maximum_animals_2'],
    imageUrl: '/files/emoji/animals_2.png',
  },
  {
    name: 'animals_3',
    short_names: ['maximum_animals_3'],
    imageUrl: '/files/emoji/animals_3.png',
  },
  {
    name: 'animals_4',
    short_names: ['maximum_animals_4'],
    imageUrl: '/files/emoji/animals_4.png',
  },
  {
    name: 'animals_5',
    short_names: ['maximum_animals_5'],
    imageUrl: '/files/emoji/animals_5.png',
  },
  {
    name: 'animals_6',
    short_names: ['maximum_animals_6'],
    imageUrl: '/files/emoji/animals_6.png',
  },
  {
    name: 'animals_7',
    short_names: ['maximum_animals_7'],
    imageUrl: '/files/emoji/animals_7.png',
  },
  {
    name: 'animals_8',
    short_names: ['maximum_animals_8'],
    imageUrl: '/files/emoji/animals_8.png',
  },
  {
    name: 'animals_9',
    short_names: ['maximum_animals_9'],
    imageUrl: '/files/emoji/animals_9.png',
  },
  {
    name: 'animals_10',
    short_names: ['maximum_animals_10'],
    imageUrl: '/files/emoji/animals_10.png',
  },
  {
    name: 'animals_11',
    short_names: ['maximum_animals_11'],
    imageUrl: '/files/emoji/animals_11.png',
  },
  {
    name: 'animals_12',
    short_names: ['maximum_animals_12'],
    imageUrl: '/files/emoji/animals_12.png',
  },
  {
    name: 'animals_13',
    short_names: ['maximum_animals_13'],
    imageUrl: '/files/emoji/animals_13.png',
  },
  {
    name: 'animals_14',
    short_names: ['maximum_animals_14'],
    imageUrl: '/files/emoji/animals_14.png',
  },
  {
    name: 'animals_15',
    short_names: ['maximum_animals_15'],
    imageUrl: '/files/emoji/animals_15.png',
  },
  {
    name: 'animals_16',
    short_names: ['maximum_animals_16'],
    imageUrl: '/files/emoji/animals_16.png',
  },
  {
    name: 'animals_17',
    short_names: ['maximum_animals_17'],
    imageUrl: '/files/emoji/animals_17.png',
  },
  {
    name: 'animals_18',
    short_names: ['maximum_animals_18'],
    imageUrl: '/files/emoji/animals_18.png',
  },
  {
    name: 'animals_19',
    short_names: ['maximum_animals_19'],
    imageUrl: '/files/emoji/animals_19.png',
  },
  {
    name: 'animals_20',
    short_names: ['maximum_animals_20'],
    imageUrl: '/files/emoji/animals_20.png',
  },
  {
    name: 'animals_21',
    short_names: ['maximum_animals_21'],
    imageUrl: '/files/emoji/animals_21.png',
  },
  {
    name: 'animals_22',
    short_names: ['maximum_animals_22'],
    imageUrl: '/files/emoji/animals_22.png',
  },
  {
    name: 'animals_23',
    short_names: ['maximum_animals_23'],
    imageUrl: '/files/emoji/animals_23.png',
  },
  {
    name: 'animals_24',
    short_names: ['maximum_animals_24'],
    imageUrl: '/files/emoji/animals_24.png',
  },
  {
    name: 'animals_25',
    short_names: ['maximum_animals_25'],
    imageUrl: '/files/emoji/animals_25.png',
  },
  {
    name: 'animals_26',
    short_names: ['maximum_animals_26'],
    imageUrl: '/files/emoji/animals_26.png',
  },
  {
    name: 'animals_27',
    short_names: ['maximum_animals_27'],
    imageUrl: '/files/emoji/animals_27.png',
  },
  {
    name: 'animals_28',
    short_names: ['maximum_animals_28'],
    imageUrl: '/files/emoji/animals_28.png',
  },
  {
    name: 'animals_29',
    short_names: ['maximum_animals_29'],
    imageUrl: '/files/emoji/animals_29.png',
  },
  {
    name: 'animals_30',
    short_names: ['maximum_animals_30'],
    imageUrl: '/files/emoji/animals_30.png',
  },
  {
    name: 'animals_31',
    short_names: ['maximum_animals_31'],
    imageUrl: '/files/emoji/animals_31.png',
  },
  {
    name: 'animals_32',
    short_names: ['maximum_animals_32'],
    imageUrl: '/files/emoji/animals_32.png',
  },
  {
    name: 'animals_33',
    short_names: ['maximum_animals_33'],
    imageUrl: '/files/emoji/animals_33.png',
  },
  {
    name: 'animals_34',
    short_names: ['maximum_animals_34'],
    imageUrl: '/files/emoji/animals_34.png',
  },
  {
    name: 'animals_35',
    short_names: ['maximum_animals_35'],
    imageUrl: '/files/emoji/animals_35.png',
  },
  {
    name: 'animals_36',
    short_names: ['maximum_animals_36'],
    imageUrl: '/files/emoji/animals_36.png',
  },
  {
    name: 'animals_37',
    short_names: ['maximum_animals_37'],
    imageUrl: '/files/emoji/animals_37.png',
  },
  {
    name: 'animals_38',
    short_names: ['maximum_animals_38'],
    imageUrl: '/files/emoji/animals_38.png',
  },
  {
    name: 'animals_39',
    short_names: ['maximum_animals_39'],
    imageUrl: '/files/emoji/animals_39.png',
  },
  {
    name: 'animals_40',
    short_names: ['maximum_animals_40'],
    imageUrl: '/files/emoji/animals_40.png',
  },
  {
    name: 'animals_41',
    short_names: ['maximum_animals_41'],
    imageUrl: '/files/emoji/animals_41.png',
  },
  {
    name: 'animals_42',
    short_names: ['maximum_animals_42'],
    imageUrl: '/files/emoji/animals_42.png',
  },
  {
    name: 'animals_43',
    short_names: ['maximum_animals_43'],
    imageUrl: '/files/emoji/animals_43.png',
  },
  {
    name: 'animals_44',
    short_names: ['maximum_animals_44'],
    imageUrl: '/files/emoji/animals_44.png',
  },
  {
    name: 'animals_45',
    short_names: ['maximum_animals_45'],
    imageUrl: '/files/emoji/animals_45.png',
  },
  {
    name: 'animals_46',
    short_names: ['maximum_animals_46'],
    imageUrl: '/files/emoji/animals_46.png',
  },
  {
    name: 'animals_47',
    short_names: ['maximum_animals_47'],
    imageUrl: '/files/emoji/animals_47.png',
  },
  {
    name: 'animals_48',
    short_names: ['maximum_animals_48'],
    imageUrl: '/files/emoji/animals_48.png',
  },
  {
    name: 'animals_49',
    short_names: ['maximum_animals_49'],
    imageUrl: '/files/emoji/animals_49.png',
  },
  {
    name: 'animals_50',
    short_names: ['maximum_animals_50'],
    imageUrl: '/files/emoji/animals_50.png',
  },
  {
    name: 'animals_51',
    short_names: ['maximum_animals_51'],
    imageUrl: '/files/emoji/animals_51.png',
  },
  {
    name: 'animals_52',
    short_names: ['maximum_animals_52'],
    imageUrl: '/files/emoji/animals_52.png',
  },
  {
    name: 'animals_53',
    short_names: ['maximum_animals_53'],
    imageUrl: '/files/emoji/animals_53.png',
  },
  {
    name: 'animals_54',
    short_names: ['maximum_animals_54'],
    imageUrl: '/files/emoji/animals_54.png',
  },
  {
    name: 'animals_55',
    short_names: ['maximum_animals_55'],
    imageUrl: '/files/emoji/animals_55.png',
  },
  {
    name: 'animals_56',
    short_names: ['maximum_animals_56'],
    imageUrl: '/files/emoji/animals_56.png',
  },
  {
    name: 'animals_57',
    short_names: ['maximum_animals_57'],
    imageUrl: '/files/emoji/animals_57.png',
  },
  {
    name: 'animals_58',
    short_names: ['maximum_animals_58'],
    imageUrl: '/files/emoji/animals_58.png',
  },
  {
    name: 'animals_59',
    short_names: ['maximum_animals_59'],
    imageUrl: '/files/emoji/animals_59.png',
  },
  {
    name: 'animals_60',
    short_names: ['maximum_animals_60'],
    imageUrl: '/files/emoji/animals_60.png',
  },
  {
    name: 'animals_61',
    short_names: ['maximum_animals_61'],
    imageUrl: '/files/emoji/animals_61.png',
  },
  {
    name: 'animals_62',
    short_names: ['maximum_animals_62'],
    imageUrl: '/files/emoji/animals_62.png',
  },
  {
    name: 'animals_63',
    short_names: ['maximum_animals_63'],
    imageUrl: '/files/emoji/animals_63.png',
  },
  {
    name: 'animals_64',
    short_names: ['maximum_animals_64'],
    imageUrl: '/files/emoji/animals_64.png',
  },
  {
    name: 'animals_65',
    short_names: ['maximum_animals_65'],
    imageUrl: '/files/emoji/animals_65.png',
  },
  {
    name: 'animals_66',
    short_names: ['maximum_animals_66'],
    imageUrl: '/files/emoji/animals_66.png',
  },
  {
    name: 'animals_67',
    short_names: ['maximum_animals_67'],
    imageUrl: '/files/emoji/animals_67.png',
  },
  {
    name: 'animals_68',
    short_names: ['maximum_animals_68'],
    imageUrl: '/files/emoji/animals_68.png',
  },
  {
    name: 'animals_69',
    short_names: ['maximum_animals_69'],
    imageUrl: '/files/emoji/animals_69.png',
  },
  {
    name: 'animals_70',
    short_names: ['maximum_animals_70'],
    imageUrl: '/files/emoji/animals_70.png',
  },
  {
    name: 'animals_71',
    short_names: ['maximum_animals_71'],
    imageUrl: '/files/emoji/animals_71.png',
  },
  {
    name: 'animals_72',
    short_names: ['maximum_animals_72'],
    imageUrl: '/files/emoji/animals_72.png',
  },
  {
    name: 'animals_73',
    short_names: ['maximum_animals_73'],
    imageUrl: '/files/emoji/animals_73.png',
  },
  {
    name: 'animals_74',
    short_names: ['maximum_animals_74'],
    imageUrl: '/files/emoji/animals_74.png',
  },
  {
    name: 'animals_75',
    short_names: ['maximum_animals_75'],
    imageUrl: '/files/emoji/animals_75.png',
  },
  {
    name: 'animals_76',
    short_names: ['maximum_animals_76'],
    imageUrl: '/files/emoji/animals_76.png',
  },
  {
    name: 'animals_77',
    short_names: ['maximum_animals_77'],
    imageUrl: '/files/emoji/animals_77.png',
  },
  {
    name: 'animals_78',
    short_names: ['maximum_animals_78'],
    imageUrl: '/files/emoji/animals_78.png',
  },
  {
    name: 'animals_79',
    short_names: ['maximum_animals_79'],
    imageUrl: '/files/emoji/animals_79.png',
  },
  {
    name: 'animals_80',
    short_names: ['maximum_animals_80'],
    imageUrl: '/files/emoji/animals_80.png',
  },
  {
    name: 'animals_81',
    short_names: ['maximum_animals_81'],
    imageUrl: '/files/emoji/animals_81.png',
  },
  {
    name: 'animals_82',
    short_names: ['maximum_animals_82'],
    imageUrl: '/files/emoji/animals_82.png',
  },
  {
    name: 'animals_83',
    short_names: ['maximum_animals_83'],
    imageUrl: '/files/emoji/animals_83.png',
  },
  {
    name: 'animals_84',
    short_names: ['maximum_animals_84'],
    imageUrl: '/files/emoji/animals_84.png',
  },
  {
    name: 'animals_85',
    short_names: ['maximum_animals_85'],
    imageUrl: '/files/emoji/animals_85.png',
  },
  {
    name: 'animals_86',
    short_names: ['maximum_animals_86'],
    imageUrl: '/files/emoji/animals_86.png',
  },
  {
    name: 'animals_87',
    short_names: ['maximum_animals_87'],
    imageUrl: '/files/emoji/animals_87.png',
  },
  {
    name: 'animals_88',
    short_names: ['maximum_animals_88'],
    imageUrl: '/files/emoji/animals_88.png',
  },
  {
    name: 'animals_89',
    short_names: ['maximum_animals_89'],
    imageUrl: '/files/emoji/animals_89.png',
  },
  {
    name: 'animals_90',
    short_names: ['maximum_animals_90'],
    imageUrl: '/files/emoji/animals_90.png',
  },
  {
    name: 'animals_91',
    short_names: ['maximum_animals_91'],
    imageUrl: '/files/emoji/animals_91.png',
  },
  {
    name: 'animals_92',
    short_names: ['maximum_animals_92'],
    imageUrl: '/files/emoji/animals_92.png',
  },
  {
    name: 'animals_93',
    short_names: ['maximum_animals_93'],
    imageUrl: '/files/emoji/animals_93.png',
  },
  {
    name: 'animals_94',
    short_names: ['maximum_animals_94'],
    imageUrl: '/files/emoji/animals_94.png',
  },
  {
    name: 'animals_95',
    short_names: ['maximum_animals_95'],
    imageUrl: '/files/emoji/animals_95.png',
  },
  {
    name: 'animals_96',
    short_names: ['maximum_animals_96'],
    imageUrl: '/files/emoji/animals_96.png',
  },
  {
    name: 'animals_97',
    short_names: ['maximum_animals_97'],
    imageUrl: '/files/emoji/animals_97.png',
  },
  {
    name: 'animals_98',
    short_names: ['maximum_animals_98'],
    imageUrl: '/files/emoji/animals_98.png',
  },
  {
    name: 'animals_99',
    short_names: ['maximum_animals_99'],
    imageUrl: '/files/emoji/animals_99.png',
  },
  {
    name: 'animals_100',
    short_names: ['maximum_animals_100'],
    imageUrl: '/files/emoji/animals_100.png',
  },
  {
    name: 'animals_101',
    short_names: ['maximum_animals_101'],
    imageUrl: '/files/emoji/animals_101.png',
  },
  {
    name: 'animals_102',
    short_names: ['maximum_animals_102'],
    imageUrl: '/files/emoji/animals_102.png',
  },
  {
    name: 'animals_103',
    short_names: ['maximum_animals_103'],
    imageUrl: '/files/emoji/animals_103.png',
  },
  {
    name: 'animals_104',
    short_names: ['maximum_animals_104'],
    imageUrl: '/files/emoji/animals_104.png',
  },
  {
    name: 'animals_105',
    short_names: ['maximum_animals_105'],
    imageUrl: '/files/emoji/animals_105.png',
  },
  {
    name: 'animals_106',
    short_names: ['maximum_animals_106'],
    imageUrl: '/files/emoji/animals_106.png',
  },
  {
    name: 'animals_107',
    short_names: ['maximum_animals_107'],
    imageUrl: '/files/emoji/animals_107.png',
  },
  {
    name: 'animals_108',
    short_names: ['maximum_animals_108'],
    imageUrl: '/files/emoji/animals_108.png',
  },
  {
    name: 'animals_109',
    short_names: ['maximum_animals_109'],
    imageUrl: '/files/emoji/animals_109.png',
  },
  {
    name: 'animals_110',
    short_names: ['maximum_animals_110'],
    imageUrl: '/files/emoji/animals_110.png',
  },
  {
    name: 'animals_111',
    short_names: ['maximum_animals_111'],
    imageUrl: '/files/emoji/animals_111.png',
  },
  {
    name: 'animals_112',
    short_names: ['maximum_animals_112'],
    imageUrl: '/files/emoji/animals_112.png',
  },
  {
    name: 'animals_113',
    short_names: ['maximum_animals_113'],
    imageUrl: '/files/emoji/animals_113.png',
  },
  {
    name: 'animals_114',
    short_names: ['maximum_animals_114'],
    imageUrl: '/files/emoji/animals_114.png',
  },
  {
    name: 'animals_115',
    short_names: ['maximum_animals_115'],
    imageUrl: '/files/emoji/animals_115.png',
  },
  {
    name: 'animals_116',
    short_names: ['maximum_animals_116'],
    imageUrl: '/files/emoji/animals_116.png',
  },
  {
    name: 'animals_117',
    short_names: ['maximum_animals_117'],
    imageUrl: '/files/emoji/animals_117.png',
  },
  {
    name: 'animals_118',
    short_names: ['maximum_animals_118'],
    imageUrl: '/files/emoji/animals_118.png',
  },
  {
    name: 'animals_119',
    short_names: ['maximum_animals_119'],
    imageUrl: '/files/emoji/animals_119.png',
  },
  {
    name: 'animals_120',
    short_names: ['maximum_animals_120'],
    imageUrl: '/files/emoji/animals_120.png',
  },
  {
    name: 'animals_121',
    short_names: ['maximum_animals_121'],
    imageUrl: '/files/emoji/animals_121.png',
  },
  {
    name: 'animals_122',
    short_names: ['maximum_animals_122'],
    imageUrl: '/files/emoji/animals_122.png',
  },
  {
    name: 'animals_123',
    short_names: ['maximum_animals_123'],
    imageUrl: '/files/emoji/animals_123.png',
  },
  {
    name: 'animals_124',
    short_names: ['maximum_animals_124'],
    imageUrl: '/files/emoji/animals_124.png',
  },
  {
    name: 'animals_125',
    short_names: ['maximum_animals_125'],
    imageUrl: '/files/emoji/animals_125.png',
  },
  {
    name: 'animals_126',
    short_names: ['maximum_animals_126'],
    imageUrl: '/files/emoji/animals_126.png',
  },
  {
    name: 'animals_127',
    short_names: ['maximum_animals_127'],
    imageUrl: '/files/emoji/animals_127.png',
  },
  {
    name: 'animals_128',
    short_names: ['maximum_animals_128'],
    imageUrl: '/files/emoji/animals_128.png',
  },
  {
    name: 'animals_129',
    short_names: ['maximum_animals_129'],
    imageUrl: '/files/emoji/animals_129.png',
  },
  {
    name: 'animals_130',
    short_names: ['maximum_animals_130'],
    imageUrl: '/files/emoji/animals_130.png',
  },
  {
    name: 'animals_131',
    short_names: ['maximum_animals_131'],
    imageUrl: '/files/emoji/animals_131.png',
  },
  {
    name: 'animals_132',
    short_names: ['maximum_animals_132'],
    imageUrl: '/files/emoji/animals_132.png',
  },
  {
    name: 'animals_133',
    short_names: ['maximum_animals_133'],
    imageUrl: '/files/emoji/animals_133.png',
  },
  {
    name: 'animals_134',
    short_names: ['maximum_animals_134'],
    imageUrl: '/files/emoji/animals_134.png',
  },
  {
    name: 'animals_135',
    short_names: ['maximum_animals_135'],
    imageUrl: '/files/emoji/animals_135.png',
  },
  {
    name: 'animals_136',
    short_names: ['maximum_animals_136'],
    imageUrl: '/files/emoji/animals_136.png',
  },
  {
    name: 'animals_137',
    short_names: ['maximum_animals_137'],
    imageUrl: '/files/emoji/animals_137.png',
  },
  {
    name: 'animals_138',
    short_names: ['maximum_animals_138'],
    imageUrl: '/files/emoji/animals_138.png',
  },
  {
    name: 'animals_139',
    short_names: ['maximum_animals_139'],
    imageUrl: '/files/emoji/animals_139.png',
  },
  {
    name: 'animals_140',
    short_names: ['maximum_animals_140'],
    imageUrl: '/files/emoji/animals_140.png',
  },
  {
    name: 'animals_141',
    short_names: ['maximum_animals_141'],
    imageUrl: '/files/emoji/animals_141.png',
  },
  {
    name: 'animals_142',
    short_names: ['maximum_animals_142'],
    imageUrl: '/files/emoji/animals_142.png',
  },
  {
    name: 'animals_143',
    short_names: ['maximum_animals_143'],
    imageUrl: '/files/emoji/animals_143.png',
  },
  {
    name: 'animals_144',
    short_names: ['maximum_animals_144'],
    imageUrl: '/files/emoji/animals_144.png',
  },
  {
    name: 'animals_145',
    short_names: ['maximum_animals_145'],
    imageUrl: '/files/emoji/animals_145.png',
  },
  {
    name: 'animals_146',
    short_names: ['maximum_animals_146'],
    imageUrl: '/files/emoji/animals_146.png',
  },
  {
    name: 'animals_147',
    short_names: ['maximum_animals_147'],
    imageUrl: '/files/emoji/animals_147.png',
  },
  {
    name: 'animals_148',
    short_names: ['maximum_animals_148'],
    imageUrl: '/files/emoji/animals_148.png',
  },
  {
    name: 'animals_149',
    short_names: ['maximum_animals_149'],
    imageUrl: '/files/emoji/animals_149.png',
  },
  {
    name: 'animals_150',
    short_names: ['maximum_animals_150'],
    imageUrl: '/files/emoji/animals_150.png',
  },
  {
    name: 'animals_151',
    short_names: ['maximum_animals_151'],
    imageUrl: '/files/emoji/animals_151.png',
  },
  {
    name: 'animals_152',
    short_names: ['maximum_animals_152'],
    imageUrl: '/files/emoji/animals_152.png',
  },
  {
    name: 'animals_153',
    short_names: ['maximum_animals_153'],
    imageUrl: '/files/emoji/animals_153.png',
  },
  {
    name: 'animals_154',
    short_names: ['maximum_animals_154'],
    imageUrl: '/files/emoji/animals_154.png',
  },
  {
    name: 'animals_155',
    short_names: ['maximum_animals_155'],
    imageUrl: '/files/emoji/animals_155.png',
  },
  {
    name: 'animals_156',
    short_names: ['maximum_animals_156'],
    imageUrl: '/files/emoji/animals_156.png',
  },
  {
    name: 'animals_157',
    short_names: ['maximum_animals_157'],
    imageUrl: '/files/emoji/animals_157.png',
  },
  {
    name: 'animals_158',
    short_names: ['maximum_animals_158'],
    imageUrl: '/files/emoji/animals_158.png',
  },
  {
    name: 'animals_159',
    short_names: ['maximum_animals_159'],
    imageUrl: '/files/emoji/animals_159.png',
  },
  {
    name: 'animals_160',
    short_names: ['maximum_animals_160'],
    imageUrl: '/files/emoji/animals_160.png',
  },
  {
    name: 'animals_161',
    short_names: ['maximum_animals_161'],
    imageUrl: '/files/emoji/animals_161.png',
  },
  {
    name: 'animals_162',
    short_names: ['maximum_animals_162'],
    imageUrl: '/files/emoji/animals_162.png',
  },
  {
    name: 'animals_163',
    short_names: ['maximum_animals_163'],
    imageUrl: '/files/emoji/animals_163.png',
  },
  {
    name: 'animals_164',
    short_names: ['maximum_animals_164'],
    imageUrl: '/files/emoji/animals_164.png',
  },
  {
    name: 'animals_165',
    short_names: ['maximum_animals_165'],
    imageUrl: '/files/emoji/animals_165.png',
  },
  {
    name: 'animals_166',
    short_names: ['maximum_animals_166'],
    imageUrl: '/files/emoji/animals_166.png',
  },
  {
    name: 'animals_167',
    short_names: ['maximum_animals_167'],
    imageUrl: '/files/emoji/animals_167.png',
  },
  {
    name: 'animals_168',
    short_names: ['maximum_animals_168'],
    imageUrl: '/files/emoji/animals_168.png',
  },
  {
    name: 'animals_169',
    short_names: ['maximum_animals_169'],
    imageUrl: '/files/emoji/animals_169.png',
  },
  {
    name: 'animals_170',
    short_names: ['maximum_animals_170'],
    imageUrl: '/files/emoji/animals_170.png',
  },
  {
    name: 'animals_171',
    short_names: ['maximum_animals_171'],
    imageUrl: '/files/emoji/animals_171.png',
  },
  {
    name: 'animals_172',
    short_names: ['maximum_animals_172'],
    imageUrl: '/files/emoji/animals_172.png',
  },
  {
    name: 'animals_173',
    short_names: ['maximum_animals_173'],
    imageUrl: '/files/emoji/animals_173.png',
  },
  {
    name: 'animals_174',
    short_names: ['maximum_animals_174'],
    imageUrl: '/files/emoji/animals_174.png',
  },
  {
    name: 'animals_175',
    short_names: ['maximum_animals_175'],
    imageUrl: '/files/emoji/animals_175.png',
  },
  {
    name: 'animals_176',
    short_names: ['maximum_animals_176'],
    imageUrl: '/files/emoji/animals_176.png',
  },
  {
    name: 'animals_177',
    short_names: ['maximum_animals_177'],
    imageUrl: '/files/emoji/animals_177.png',
  },
  {
    name: 'animals_178',
    short_names: ['maximum_animals_178'],
    imageUrl: '/files/emoji/animals_178.png',
  },
  {
    name: 'animals_179',
    short_names: ['maximum_animals_179'],
    imageUrl: '/files/emoji/animals_179.png',
  },
  {
    name: 'animals_180',
    short_names: ['maximum_animals_180'],
    imageUrl: '/files/emoji/animals_180.png',
  },
  {
    name: 'animals_181',
    short_names: ['maximum_animals_181'],
    imageUrl: '/files/emoji/animals_181.png',
  },
  {
    name: 'animals_182',
    short_names: ['maximum_animals_182'],
    imageUrl: '/files/emoji/animals_182.png',
  },
  {
    name: 'animals_183',
    short_names: ['maximum_animals_183'],
    imageUrl: '/files/emoji/animals_183.png',
  },
  {
    name: 'animals_184',
    short_names: ['maximum_animals_184'],
    imageUrl: '/files/emoji/animals_184.png',
  },
  {
    name: 'animals_185',
    short_names: ['maximum_animals_185'],
    imageUrl: '/files/emoji/animals_185.png',
  },
  {
    name: 'animals_186',
    short_names: ['maximum_animals_186'],
    imageUrl: '/files/emoji/animals_186.png',
  },
  {
    name: 'animals_187',
    short_names: ['maximum_animals_187'],
    imageUrl: '/files/emoji/animals_187.png',
  },
  {
    name: 'animals_188',
    short_names: ['maximum_animals_188'],
    imageUrl: '/files/emoji/animals_188.png',
  },
  {
    name: 'animals_189',
    short_names: ['maximum_animals_189'],
    imageUrl: '/files/emoji/animals_189.png',
  },
  {
    name: 'animals_190',
    short_names: ['maximum_animals_190'],
    imageUrl: '/files/emoji/animals_190.png',
  },
  {
    name: 'animals_191',
    short_names: ['maximum_animals_191'],
    imageUrl: '/files/emoji/animals_191.png',
  },
  {
    name: 'animals_192',
    short_names: ['maximum_animals_192'],
    imageUrl: '/files/emoji/animals_192.png',
  },
  {
    name: 'animals_193',
    short_names: ['maximum_animals_193'],
    imageUrl: '/files/emoji/animals_193.png',
  },
  {
    name: 'animals_194',
    short_names: ['maximum_animals_194'],
    imageUrl: '/files/emoji/animals_194.png',
  },
  {
    name: 'animals_195',
    short_names: ['maximum_animals_195'],
    imageUrl: '/files/emoji/animals_195.png',
  },
  {
    name: 'animals_196',
    short_names: ['maximum_animals_196'],
    imageUrl: '/files/emoji/animals_196.png',
  },
  {
    name: 'animals_197',
    short_names: ['maximum_animals_197'],
    imageUrl: '/files/emoji/animals_197.png',
  },
  {
    name: 'animals_198',
    short_names: ['maximum_animals_198'],
    imageUrl: '/files/emoji/animals_198.png',
  },
  {
    name: 'animals_199',
    short_names: ['maximum_animals_199'],
    imageUrl: '/files/emoji/animals_199.png',
  },
  {
    name: 'animals_200',
    short_names: ['maximum_animals_200'],
    imageUrl: '/files/emoji/animals_200.png',
  },
  {
    name: 'animals_201',
    short_names: ['maximum_animals_201'],
    imageUrl: '/files/emoji/animals_201.png',
  },
  {
    name: 'animals_202',
    short_names: ['maximum_animals_202'],
    imageUrl: '/files/emoji/animals_202.png',
  },
  {
    name: 'animals_203',
    short_names: ['maximum_animals_203'],
    imageUrl: '/files/emoji/animals_203.png',
  },
  {
    name: 'animals_204',
    short_names: ['maximum_animals_204'],
    imageUrl: '/files/emoji/animals_204.png',
  },
  {
    name: 'animals_205',
    short_names: ['maximum_animals_205'],
    imageUrl: '/files/emoji/animals_205.png',
  },
  {
    name: 'animals_206',
    short_names: ['maximum_animals_206'],
    imageUrl: '/files/emoji/animals_206.png',
  },
  {
    name: 'animals_207',
    short_names: ['maximum_animals_207'],
    imageUrl: '/files/emoji/animals_207.png',
  },
  {
    name: 'animals_208',
    short_names: ['maximum_animals_208'],
    imageUrl: '/files/emoji/animals_208.png',
  },
  {
    name: 'animals_209',
    short_names: ['maximum_animals_209'],
    imageUrl: '/files/emoji/animals_209.png',
  },
  {
    name: 'buildings_1',
    short_names: ['maximum_buildings_1'],
    imageUrl: '/files/emoji/buildings_1.png',
  },
  {
    name: 'buildings_2',
    short_names: ['maximum_buildings_2'],
    imageUrl: '/files/emoji/buildings_2.png',
  },
  {
    name: 'buildings_3',
    short_names: ['maximum_buildings_3'],
    imageUrl: '/files/emoji/buildings_3.png',
  },
  {
    name: 'buildings_4',
    short_names: ['maximum_buildings_4'],
    imageUrl: '/files/emoji/buildings_4.png',
  },
  {
    name: 'buildings_5',
    short_names: ['maximum_buildings_5'],
    imageUrl: '/files/emoji/buildings_5.png',
  },
  {
    name: 'buildings_6',
    short_names: ['maximum_buildings_6'],
    imageUrl: '/files/emoji/buildings_6.png',
  },
  {
    name: 'buildings_7',
    short_names: ['maximum_buildings_7'],
    imageUrl: '/files/emoji/buildings_7.png',
  },
  {
    name: 'buildings_8',
    short_names: ['maximum_buildings_8'],
    imageUrl: '/files/emoji/buildings_8.png',
  },
  {
    name: 'buildings_9',
    short_names: ['maximum_buildings_9'],
    imageUrl: '/files/emoji/buildings_9.png',
  },
  {
    name: 'buildings_10',
    short_names: ['maximum_buildings_10'],
    imageUrl: '/files/emoji/buildings_10.png',
  },
  {
    name: 'buildings_11',
    short_names: ['maximum_buildings_11'],
    imageUrl: '/files/emoji/buildings_11.png',
  },
  {
    name: 'buildings_12',
    short_names: ['maximum_buildings_12'],
    imageUrl: '/files/emoji/buildings_12.png',
  },
  {
    name: 'buildings_13',
    short_names: ['maximum_buildings_13'],
    imageUrl: '/files/emoji/buildings_13.png',
  },
  {
    name: 'buildings_14',
    short_names: ['maximum_buildings_14'],
    imageUrl: '/files/emoji/buildings_14.png',
  },
  {
    name: 'buildings_15',
    short_names: ['maximum_buildings_15'],
    imageUrl: '/files/emoji/buildings_15.png',
  },
  {
    name: 'buildings_16',
    short_names: ['maximum_buildings_16'],
    imageUrl: '/files/emoji/buildings_16.png',
  },
  {
    name: 'buildings_17',
    short_names: ['maximum_buildings_17'],
    imageUrl: '/files/emoji/buildings_17.png',
  },
  {
    name: 'buildings_18',
    short_names: ['maximum_buildings_18'],
    imageUrl: '/files/emoji/buildings_18.png',
  },
  {
    name: 'buildings_19',
    short_names: ['maximum_buildings_19'],
    imageUrl: '/files/emoji/buildings_19.png',
  },
  {
    name: 'buildings_20',
    short_names: ['maximum_buildings_20'],
    imageUrl: '/files/emoji/buildings_20.png',
  },
  {
    name: 'buildings_21',
    short_names: ['maximum_buildings_21'],
    imageUrl: '/files/emoji/buildings_21.png',
  },
  {
    name: 'buildings_22',
    short_names: ['maximum_buildings_22'],
    imageUrl: '/files/emoji/buildings_22.png',
  },
  {
    name: 'buildings_23',
    short_names: ['maximum_buildings_23'],
    imageUrl: '/files/emoji/buildings_23.png',
  },
  {
    name: 'buildings_24',
    short_names: ['maximum_buildings_24'],
    imageUrl: '/files/emoji/buildings_24.png',
  },
  {
    name: 'buildings_25',
    short_names: ['maximum_buildings_25'],
    imageUrl: '/files/emoji/buildings_25.png',
  },
  {
    name: 'buildings_26',
    short_names: ['maximum_buildings_26'],
    imageUrl: '/files/emoji/buildings_26.png',
  },
  {
    name: 'buildings_27',
    short_names: ['maximum_buildings_27'],
    imageUrl: '/files/emoji/buildings_27.png',
  },
  {
    name: 'buildings_28',
    short_names: ['maximum_buildings_28'],
    imageUrl: '/files/emoji/buildings_28.png',
  },
  {
    name: 'buildings_29',
    short_names: ['maximum_buildings_29'],
    imageUrl: '/files/emoji/buildings_29.png',
  },
  {
    name: 'buildings_30',
    short_names: ['maximum_buildings_30'],
    imageUrl: '/files/emoji/buildings_30.png',
  },
  {
    name: 'buildings_31',
    short_names: ['maximum_buildings_31'],
    imageUrl: '/files/emoji/buildings_31.png',
  },
  {
    name: 'buildings_32',
    short_names: ['maximum_buildings_32'],
    imageUrl: '/files/emoji/buildings_32.png',
  },
  {
    name: 'buildings_33',
    short_names: ['maximum_buildings_33'],
    imageUrl: '/files/emoji/buildings_33.png',
  },
  {
    name: 'buildings_34',
    short_names: ['maximum_buildings_34'],
    imageUrl: '/files/emoji/buildings_34.png',
  },
  {
    name: 'buildings_35',
    short_names: ['maximum_buildings_35'],
    imageUrl: '/files/emoji/buildings_35.png',
  },
  {
    name: 'buildings_36',
    short_names: ['maximum_buildings_36'],
    imageUrl: '/files/emoji/buildings_36.png',
  },
  {
    name: 'buildings_37',
    short_names: ['maximum_buildings_37'],
    imageUrl: '/files/emoji/buildings_37.png',
  },
  {
    name: 'buildings_38',
    short_names: ['maximum_buildings_38'],
    imageUrl: '/files/emoji/buildings_38.png',
  },
  {
    name: 'buildings_39',
    short_names: ['maximum_buildings_39'],
    imageUrl: '/files/emoji/buildings_39.png',
  },
  {
    name: 'buildings_40',
    short_names: ['maximum_buildings_40'],
    imageUrl: '/files/emoji/buildings_40.png',
  },
  {
    name: 'buildings_41',
    short_names: ['maximum_buildings_41'],
    imageUrl: '/files/emoji/buildings_41.png',
  },
  {
    name: 'buildings_42',
    short_names: ['maximum_buildings_42'],
    imageUrl: '/files/emoji/buildings_42.png',
  },
  {
    name: 'buildings_43',
    short_names: ['maximum_buildings_43'],
    imageUrl: '/files/emoji/buildings_43.png',
  },
  {
    name: 'buildings_44',
    short_names: ['maximum_buildings_44'],
    imageUrl: '/files/emoji/buildings_44.png',
  },
  {
    name: 'buildings_45',
    short_names: ['maximum_buildings_45'],
    imageUrl: '/files/emoji/buildings_45.png',
  },
  {
    name: 'buildings_46',
    short_names: ['maximum_buildings_46'],
    imageUrl: '/files/emoji/buildings_46.png',
  },
  {
    name: 'buildings_47',
    short_names: ['maximum_buildings_47'],
    imageUrl: '/files/emoji/buildings_47.png',
  },
  {
    name: 'buildings_48',
    short_names: ['maximum_buildings_48'],
    imageUrl: '/files/emoji/buildings_48.png',
  },
  {
    name: 'buildings_49',
    short_names: ['maximum_buildings_49'],
    imageUrl: '/files/emoji/buildings_49.png',
  },
  {
    name: 'buildings_50',
    short_names: ['maximum_buildings_50'],
    imageUrl: '/files/emoji/buildings_50.png',
  },
  {
    name: 'buildings_51',
    short_names: ['maximum_buildings_51'],
    imageUrl: '/files/emoji/buildings_51.png',
  },
  {
    name: 'buildings_52',
    short_names: ['maximum_buildings_52'],
    imageUrl: '/files/emoji/buildings_52.png',
  },
  {
    name: 'buildings_53',
    short_names: ['maximum_buildings_53'],
    imageUrl: '/files/emoji/buildings_53.png',
  },
  {
    name: 'buildings_54',
    short_names: ['maximum_buildings_54'],
    imageUrl: '/files/emoji/buildings_54.png',
  },
  {
    name: 'buildings_55',
    short_names: ['maximum_buildings_55'],
    imageUrl: '/files/emoji/buildings_55.png',
  },
  {
    name: 'buildings_56',
    short_names: ['maximum_buildings_56'],
    imageUrl: '/files/emoji/buildings_56.png',
  },
  {
    name: 'buildings_57',
    short_names: ['maximum_buildings_57'],
    imageUrl: '/files/emoji/buildings_57.png',
  },
  {
    name: 'buildings_58',
    short_names: ['maximum_buildings_58'],
    imageUrl: '/files/emoji/buildings_58.png',
  },
  {
    name: 'buildings_59',
    short_names: ['maximum_buildings_59'],
    imageUrl: '/files/emoji/buildings_59.png',
  },
  {
    name: 'buildings_60',
    short_names: ['maximum_buildings_60'],
    imageUrl: '/files/emoji/buildings_60.png',
  },
  {
    name: 'buildings_61',
    short_names: ['maximum_buildings_61'],
    imageUrl: '/files/emoji/buildings_61.png',
  },
  {
    name: 'buildings_62',
    short_names: ['maximum_buildings_62'],
    imageUrl: '/files/emoji/buildings_62.png',
  },
  {
    name: 'buildings_63',
    short_names: ['maximum_buildings_63'],
    imageUrl: '/files/emoji/buildings_63.png',
  },
  {
    name: 'buildings_64',
    short_names: ['maximum_buildings_64'],
    imageUrl: '/files/emoji/buildings_64.png',
  },
  {
    name: 'buildings_65',
    short_names: ['maximum_buildings_65'],
    imageUrl: '/files/emoji/buildings_65.png',
  },
  {
    name: 'buildings_66',
    short_names: ['maximum_buildings_66'],
    imageUrl: '/files/emoji/buildings_66.png',
  },
  {
    name: 'buildings_67',
    short_names: ['maximum_buildings_67'],
    imageUrl: '/files/emoji/buildings_67.png',
  },
  {
    name: 'buildings_68',
    short_names: ['maximum_buildings_68'],
    imageUrl: '/files/emoji/buildings_68.png',
  },
  {
    name: 'buildings_69',
    short_names: ['maximum_buildings_69'],
    imageUrl: '/files/emoji/buildings_69.png',
  },
  {
    name: 'buildings_70',
    short_names: ['maximum_buildings_70'],
    imageUrl: '/files/emoji/buildings_70.png',
  },
  {
    name: 'buildings_71',
    short_names: ['maximum_buildings_71'],
    imageUrl: '/files/emoji/buildings_71.png',
  },
  {
    name: 'buildings_72',
    short_names: ['maximum_buildings_72'],
    imageUrl: '/files/emoji/buildings_72.png',
  },
  {
    name: 'buildings_73',
    short_names: ['maximum_buildings_73'],
    imageUrl: '/files/emoji/buildings_73.png',
  },
  {
    name: 'buildings_74',
    short_names: ['maximum_buildings_74'],
    imageUrl: '/files/emoji/buildings_74.png',
  },
  {
    name: 'buildings_75',
    short_names: ['maximum_buildings_75'],
    imageUrl: '/files/emoji/buildings_75.png',
  },
  {
    name: 'buildings_76',
    short_names: ['maximum_buildings_76'],
    imageUrl: '/files/emoji/buildings_76.png',
  },
  {
    name: 'buildings_77',
    short_names: ['maximum_buildings_77'],
    imageUrl: '/files/emoji/buildings_77.png',
  },
  {
    name: 'buildings_78',
    short_names: ['maximum_buildings_78'],
    imageUrl: '/files/emoji/buildings_78.png',
  },
  {
    name: 'buildings_79',
    short_names: ['maximum_buildings_79'],
    imageUrl: '/files/emoji/buildings_79.png',
  },
  {
    name: 'buildings_80',
    short_names: ['maximum_buildings_80'],
    imageUrl: '/files/emoji/buildings_80.png',
  },
  {
    name: 'buildings_81',
    short_names: ['maximum_buildings_81'],
    imageUrl: '/files/emoji/buildings_81.png',
  },
  {
    name: 'buildings_82',
    short_names: ['maximum_buildings_82'],
    imageUrl: '/files/emoji/buildings_82.png',
  },
  {
    name: 'buildings_83',
    short_names: ['maximum_buildings_83'],
    imageUrl: '/files/emoji/buildings_83.png',
  },
  {
    name: 'buildings_84',
    short_names: ['maximum_buildings_84'],
    imageUrl: '/files/emoji/buildings_84.png',
  },
  {
    name: 'buildings_85',
    short_names: ['maximum_buildings_85'],
    imageUrl: '/files/emoji/buildings_85.png',
  },
  {
    name: 'buildings_86',
    short_names: ['maximum_buildings_86'],
    imageUrl: '/files/emoji/buildings_86.png',
  },
  {
    name: 'buildings_87',
    short_names: ['maximum_buildings_87'],
    imageUrl: '/files/emoji/buildings_87.png',
  },
  {
    name: 'buildings_88',
    short_names: ['maximum_buildings_88'],
    imageUrl: '/files/emoji/buildings_88.png',
  },
  {
    name: 'buildings_89',
    short_names: ['maximum_buildings_89'],
    imageUrl: '/files/emoji/buildings_89.png',
  },
  {
    name: 'buildings_90',
    short_names: ['maximum_buildings_90'],
    imageUrl: '/files/emoji/buildings_90.png',
  },
  {
    name: 'buildings_91',
    short_names: ['maximum_buildings_91'],
    imageUrl: '/files/emoji/buildings_91.png',
  },
  {
    name: 'buildings_92',
    short_names: ['maximum_buildings_92'],
    imageUrl: '/files/emoji/buildings_92.png',
  },
  {
    name: 'buildings_93',
    short_names: ['maximum_buildings_93'],
    imageUrl: '/files/emoji/buildings_93.png',
  },
  {
    name: 'buildings_94',
    short_names: ['maximum_buildings_94'],
    imageUrl: '/files/emoji/buildings_94.png',
  },
  {
    name: 'buildings_95',
    short_names: ['maximum_buildings_95'],
    imageUrl: '/files/emoji/buildings_95.png',
  },
  {
    name: 'buildings_96',
    short_names: ['maximum_buildings_96'],
    imageUrl: '/files/emoji/buildings_96.png',
  },
  {
    name: 'buildings_97',
    short_names: ['maximum_buildings_97'],
    imageUrl: '/files/emoji/buildings_97.png',
  },
  {
    name: 'buildings_98',
    short_names: ['maximum_buildings_98'],
    imageUrl: '/files/emoji/buildings_98.png',
  },
  {
    name: 'buildings_99',
    short_names: ['maximum_buildings_99'],
    imageUrl: '/files/emoji/buildings_99.png',
  },
  {
    name: 'buildings_100',
    short_names: ['maximum_buildings_100'],
    imageUrl: '/files/emoji/buildings_100.png',
  },
  {
    name: 'Food_Drink_1',
    short_names: ['maximum_Food_Drink_1'],
    imageUrl: '/files/emoji/Food_Drink_1.png',
  },
  {
    name: 'Food_Drink_2',
    short_names: ['maximum_Food_Drink_2'],
    imageUrl: '/files/emoji/Food_Drink_2.png',
  },
  {
    name: 'Food_Drink_3',
    short_names: ['maximum_Food_Drink_3'],
    imageUrl: '/files/emoji/Food_Drink_3.png',
  },
  {
    name: 'Food_Drink_4',
    short_names: ['maximum_Food_Drink_4'],
    imageUrl: '/files/emoji/Food_Drink_4.png',
  },
  {
    name: 'Food_Drink_5',
    short_names: ['maximum_Food_Drink_5'],
    imageUrl: '/files/emoji/Food_Drink_5.png',
  },
  {
    name: 'Food_Drink_6',
    short_names: ['maximum_Food_Drink_6'],
    imageUrl: '/files/emoji/Food_Drink_6.png',
  },
  {
    name: 'Food_Drink_7',
    short_names: ['maximum_Food_Drink_7'],
    imageUrl: '/files/emoji/Food_Drink_7.png',
  },
  {
    name: 'Food_Drink_8',
    short_names: ['maximum_Food_Drink_8'],
    imageUrl: '/files/emoji/Food_Drink_8.png',
  },
  {
    name: 'Food_Drink_9',
    short_names: ['maximum_Food_Drink_9'],
    imageUrl: '/files/emoji/Food_Drink_9.png',
  },
  {
    name: 'Food_Drink_10',
    short_names: ['maximum_Food_Drink_10'],
    imageUrl: '/files/emoji/Food_Drink_10.png',
  },
  {
    name: 'Food_Drink_11',
    short_names: ['maximum_Food_Drink_11'],
    imageUrl: '/files/emoji/Food_Drink_11.png',
  },
  {
    name: 'Food_Drink_12',
    short_names: ['maximum_Food_Drink_12'],
    imageUrl: '/files/emoji/Food_Drink_12.png',
  },
  {
    name: 'Food_Drink_13',
    short_names: ['maximum_Food_Drink_13'],
    imageUrl: '/files/emoji/Food_Drink_13.png',
  },
  {
    name: 'Food_Drink_14',
    short_names: ['maximum_Food_Drink_14'],
    imageUrl: '/files/emoji/Food_Drink_14.png',
  },
  {
    name: 'Food_Drink_15',
    short_names: ['maximum_Food_Drink_15'],
    imageUrl: '/files/emoji/Food_Drink_15.png',
  },
  {
    name: 'Food_Drink_16',
    short_names: ['maximum_Food_Drink_16'],
    imageUrl: '/files/emoji/Food_Drink_16.png',
  },
  {
    name: 'Food_Drink_17',
    short_names: ['maximum_Food_Drink_17'],
    imageUrl: '/files/emoji/Food_Drink_17.png',
  },
  {
    name: 'Food_Drink_18',
    short_names: ['maximum_Food_Drink_18'],
    imageUrl: '/files/emoji/Food_Drink_18.png',
  },
  {
    name: 'Food_Drink_19',
    short_names: ['maximum_Food_Drink_19'],
    imageUrl: '/files/emoji/Food_Drink_19.png',
  },
  {
    name: 'Food_Drink_20',
    short_names: ['maximum_Food_Drink_20'],
    imageUrl: '/files/emoji/Food_Drink_20.png',
  },
  {
    name: 'Food_Drink_21',
    short_names: ['maximum_Food_Drink_21'],
    imageUrl: '/files/emoji/Food_Drink_21.png',
  },
  {
    name: 'Food_Drink_22',
    short_names: ['maximum_Food_Drink_22'],
    imageUrl: '/files/emoji/Food_Drink_22.png',
  },
  {
    name: 'Food_Drink_23',
    short_names: ['maximum_Food_Drink_23'],
    imageUrl: '/files/emoji/Food_Drink_23.png',
  },
  {
    name: 'Food_Drink_24',
    short_names: ['maximum_Food_Drink_24'],
    imageUrl: '/files/emoji/Food_Drink_24.png',
  },
  {
    name: 'Food_Drink_25',
    short_names: ['maximum_Food_Drink_25'],
    imageUrl: '/files/emoji/Food_Drink_25.png',
  },
  {
    name: 'Food_Drink_26',
    short_names: ['maximum_Food_Drink_26'],
    imageUrl: '/files/emoji/Food_Drink_26.png',
  },
  {
    name: 'Food_Drink_27',
    short_names: ['maximum_Food_Drink_27'],
    imageUrl: '/files/emoji/Food_Drink_27.png',
  },
  {
    name: 'Food_Drink_28',
    short_names: ['maximum_Food_Drink_28'],
    imageUrl: '/files/emoji/Food_Drink_28.png',
  },
  {
    name: 'Food_Drink_29',
    short_names: ['maximum_Food_Drink_29'],
    imageUrl: '/files/emoji/Food_Drink_29.png',
  },
  {
    name: 'Food_Drink_30',
    short_names: ['maximum_Food_Drink_30'],
    imageUrl: '/files/emoji/Food_Drink_30.png',
  },
  {
    name: 'Food_Drink_31',
    short_names: ['maximum_Food_Drink_31'],
    imageUrl: '/files/emoji/Food_Drink_31.png',
  },
  {
    name: 'Food_Drink_32',
    short_names: ['maximum_Food_Drink_32'],
    imageUrl: '/files/emoji/Food_Drink_32.png',
  },
  {
    name: 'Food_Drink_33',
    short_names: ['maximum_Food_Drink_33'],
    imageUrl: '/files/emoji/Food_Drink_33.png',
  },
  {
    name: 'Food_Drink_34',
    short_names: ['maximum_Food_Drink_34'],
    imageUrl: '/files/emoji/Food_Drink_34.png',
  },
  {
    name: 'Food_Drink_35',
    short_names: ['maximum_Food_Drink_35'],
    imageUrl: '/files/emoji/Food_Drink_35.png',
  },
  {
    name: 'Food_Drink_36',
    short_names: ['maximum_Food_Drink_36'],
    imageUrl: '/files/emoji/Food_Drink_36.png',
  },
  {
    name: 'Food_Drink_37',
    short_names: ['maximum_Food_Drink_37'],
    imageUrl: '/files/emoji/Food_Drink_37.png',
  },
  {
    name: 'Food_Drink_38',
    short_names: ['maximum_Food_Drink_38'],
    imageUrl: '/files/emoji/Food_Drink_38.png',
  },
  {
    name: 'Food_Drink_39',
    short_names: ['maximum_Food_Drink_39'],
    imageUrl: '/files/emoji/Food_Drink_39.png',
  },
  {
    name: 'Food_Drink_40',
    short_names: ['maximum_Food_Drink_40'],
    imageUrl: '/files/emoji/Food_Drink_40.png',
  },
  {
    name: 'Food_Drink_41',
    short_names: ['maximum_Food_Drink_41'],
    imageUrl: '/files/emoji/Food_Drink_41.png',
  },
  {
    name: 'Food_Drink_42',
    short_names: ['maximum_Food_Drink_42'],
    imageUrl: '/files/emoji/Food_Drink_42.png',
  },
  {
    name: 'Food_Drink_43',
    short_names: ['maximum_Food_Drink_43'],
    imageUrl: '/files/emoji/Food_Drink_43.png',
  },
  {
    name: 'Food_Drink_44',
    short_names: ['maximum_Food_Drink_44'],
    imageUrl: '/files/emoji/Food_Drink_44.png',
  },
  {
    name: 'Food_Drink_45',
    short_names: ['maximum_Food_Drink_45'],
    imageUrl: '/files/emoji/Food_Drink_45.png',
  },
  {
    name: 'Food_Drink_46',
    short_names: ['maximum_Food_Drink_46'],
    imageUrl: '/files/emoji/Food_Drink_46.png',
  },
  {
    name: 'Food_Drink_47',
    short_names: ['maximum_Food_Drink_47'],
    imageUrl: '/files/emoji/Food_Drink_47.png',
  },
  {
    name: 'Food_Drink_48',
    short_names: ['maximum_Food_Drink_48'],
    imageUrl: '/files/emoji/Food_Drink_48.png',
  },
  {
    name: 'Food_Drink_49',
    short_names: ['maximum_Food_Drink_49'],
    imageUrl: '/files/emoji/Food_Drink_49.png',
  },
  {
    name: 'Food_Drink_50',
    short_names: ['maximum_Food_Drink_50'],
    imageUrl: '/files/emoji/Food_Drink_50.png',
  },
  {
    name: 'Food_Drink_51',
    short_names: ['maximum_Food_Drink_51'],
    imageUrl: '/files/emoji/Food_Drink_51.png',
  },
  {
    name: 'Food_Drink_52',
    short_names: ['maximum_Food_Drink_52'],
    imageUrl: '/files/emoji/Food_Drink_52.png',
  },
  {
    name: 'Food_Drink_53',
    short_names: ['maximum_Food_Drink_53'],
    imageUrl: '/files/emoji/Food_Drink_53.png',
  },
  {
    name: 'Food_Drink_54',
    short_names: ['maximum_Food_Drink_54'],
    imageUrl: '/files/emoji/Food_Drink_54.png',
  },
  {
    name: 'Food_Drink_55',
    short_names: ['maximum_Food_Drink_55'],
    imageUrl: '/files/emoji/Food_Drink_55.png',
  },
  {
    name: 'Food_Drink_56',
    short_names: ['maximum_Food_Drink_56'],
    imageUrl: '/files/emoji/Food_Drink_56.png',
  },
  {
    name: 'Food_Drink_57',
    short_names: ['maximum_Food_Drink_57'],
    imageUrl: '/files/emoji/Food_Drink_57.png',
  },
  {
    name: 'Food_Drink_58',
    short_names: ['maximum_Food_Drink_58'],
    imageUrl: '/files/emoji/Food_Drink_58.png',
  },
  {
    name: 'Food_Drink_59',
    short_names: ['maximum_Food_Drink_59'],
    imageUrl: '/files/emoji/Food_Drink_59.png',
  },
  {
    name: 'Food_Drink_60',
    short_names: ['maximum_Food_Drink_60'],
    imageUrl: '/files/emoji/Food_Drink_60.png',
  },
  {
    name: 'Food_Drink_61',
    short_names: ['maximum_Food_Drink_61'],
    imageUrl: '/files/emoji/Food_Drink_61.png',
  },
  {
    name: 'Food_Drink_62',
    short_names: ['maximum_Food_Drink_62'],
    imageUrl: '/files/emoji/Food_Drink_62.png',
  },
  {
    name: 'Food_Drink_63',
    short_names: ['maximum_Food_Drink_63'],
    imageUrl: '/files/emoji/Food_Drink_63.png',
  },
  {
    name: 'Food_Drink_64',
    short_names: ['maximum_Food_Drink_64'],
    imageUrl: '/files/emoji/Food_Drink_64.png',
  },
  {
    name: 'Food_Drink_65',
    short_names: ['maximum_Food_Drink_65'],
    imageUrl: '/files/emoji/Food_Drink_65.png',
  },
  {
    name: 'Food_Drink_66',
    short_names: ['maximum_Food_Drink_66'],
    imageUrl: '/files/emoji/Food_Drink_66.png',
  },
  {
    name: 'Food_Drink_67',
    short_names: ['maximum_Food_Drink_67'],
    imageUrl: '/files/emoji/Food_Drink_67.png',
  },
  {
    name: 'Food_Drink_68',
    short_names: ['maximum_Food_Drink_68'],
    imageUrl: '/files/emoji/Food_Drink_68.png',
  },
  {
    name: 'Food_Drink_69',
    short_names: ['maximum_Food_Drink_69'],
    imageUrl: '/files/emoji/Food_Drink_69.png',
  },
  {
    name: 'Food_Drink_70',
    short_names: ['maximum_Food_Drink_70'],
    imageUrl: '/files/emoji/Food_Drink_70.png',
  },
  {
    name: 'Food_Drink_71',
    short_names: ['maximum_Food_Drink_71'],
    imageUrl: '/files/emoji/Food_Drink_71.png',
  },
  {
    name: 'Food_Drink_72',
    short_names: ['maximum_Food_Drink_72'],
    imageUrl: '/files/emoji/Food_Drink_72.png',
  },
  {
    name: 'Food_Drink_73',
    short_names: ['maximum_Food_Drink_73'],
    imageUrl: '/files/emoji/Food_Drink_73.png',
  },
  {
    name: 'Food_Drink_74',
    short_names: ['maximum_Food_Drink_74'],
    imageUrl: '/files/emoji/Food_Drink_74.png',
  },
  {
    name: 'Food_Drink_75',
    short_names: ['maximum_Food_Drink_75'],
    imageUrl: '/files/emoji/Food_Drink_75.png',
  },
  {
    name: 'Food_Drink_76',
    short_names: ['maximum_Food_Drink_76'],
    imageUrl: '/files/emoji/Food_Drink_76.png',
  },
  {
    name: 'Food_Drink_77',
    short_names: ['maximum_Food_Drink_77'],
    imageUrl: '/files/emoji/Food_Drink_77.png',
  },
  {
    name: 'Food_Drink_78',
    short_names: ['maximum_Food_Drink_78'],
    imageUrl: '/files/emoji/Food_Drink_78.png',
  },
  {
    name: 'Food_Drink_79',
    short_names: ['maximum_Food_Drink_79'],
    imageUrl: '/files/emoji/Food_Drink_79.png',
  },
  {
    name: 'Food_Drink_80',
    short_names: ['maximum_Food_Drink_80'],
    imageUrl: '/files/emoji/Food_Drink_80.png',
  },
  {
    name: 'Food_Drink_81',
    short_names: ['maximum_Food_Drink_81'],
    imageUrl: '/files/emoji/Food_Drink_81.png',
  },
  {
    name: 'Food_Drink_82',
    short_names: ['maximum_Food_Drink_82'],
    imageUrl: '/files/emoji/Food_Drink_82.png',
  },
  {
    name: 'Food_Drink_83',
    short_names: ['maximum_Food_Drink_83'],
    imageUrl: '/files/emoji/Food_Drink_83.png',
  },
  {
    name: 'Food_Drink_84',
    short_names: ['maximum_Food_Drink_84'],
    imageUrl: '/files/emoji/Food_Drink_84.png',
  },
  {
    name: 'Food_Drink_85',
    short_names: ['maximum_Food_Drink_85'],
    imageUrl: '/files/emoji/Food_Drink_85.png',
  },
  {
    name: 'Food_Drink_86',
    short_names: ['maximum_Food_Drink_86'],
    imageUrl: '/files/emoji/Food_Drink_86.png',
  },
  {
    name: 'Food_Drink_87',
    short_names: ['maximum_Food_Drink_87'],
    imageUrl: '/files/emoji/Food_Drink_87.png',
  },
  {
    name: 'Food_Drink_88',
    short_names: ['maximum_Food_Drink_88'],
    imageUrl: '/files/emoji/Food_Drink_88.png',
  },
  {
    name: 'Food_Drink_89',
    short_names: ['maximum_Food_Drink_89'],
    imageUrl: '/files/emoji/Food_Drink_89.png',
  },
  {
    name: 'Food_Drink_90',
    short_names: ['maximum_Food_Drink_90'],
    imageUrl: '/files/emoji/Food_Drink_90.png',
  },
  {
    name: 'Food_Drink_91',
    short_names: ['maximum_Food_Drink_91'],
    imageUrl: '/files/emoji/Food_Drink_91.png',
  },
  {
    name: 'Food_Drink_92',
    short_names: ['maximum_Food_Drink_92'],
    imageUrl: '/files/emoji/Food_Drink_92.png',
  },
  {
    name: 'Food_Drink_93',
    short_names: ['maximum_Food_Drink_93'],
    imageUrl: '/files/emoji/Food_Drink_93.png',
  },
  {
    name: 'Food_Drink_94',
    short_names: ['maximum_Food_Drink_94'],
    imageUrl: '/files/emoji/Food_Drink_94.png',
  },
  {
    name: 'Food_Drink_95',
    short_names: ['maximum_Food_Drink_95'],
    imageUrl: '/files/emoji/Food_Drink_95.png',
  },
  {
    name: 'Food_Drink_96',
    short_names: ['maximum_Food_Drink_96'],
    imageUrl: '/files/emoji/Food_Drink_96.png',
  },
  {
    name: 'Food_Drink_97',
    short_names: ['maximum_Food_Drink_97'],
    imageUrl: '/files/emoji/Food_Drink_97.png',
  },
  {
    name: 'Food_Drink_98',
    short_names: ['maximum_Food_Drink_98'],
    imageUrl: '/files/emoji/Food_Drink_98.png',
  },
  {
    name: 'Food_Drink_99',
    short_names: ['maximum_Food_Drink_99'],
    imageUrl: '/files/emoji/Food_Drink_99.png',
  },
  {
    name: 'Food_Drink_100',
    short_names: ['maximum_Food_Drink_100'],
    imageUrl: '/files/emoji/Food_Drink_100.png',
  },
  {
    name: 'Food_Drink_101',
    short_names: ['maximum_Food_Drink_101'],
    imageUrl: '/files/emoji/Food_Drink_101.png',
  },
  {
    name: 'Food_Drink_102',
    short_names: ['maximum_Food_Drink_102'],
    imageUrl: '/files/emoji/Food_Drink_102.png',
  },
  {
    name: 'Food_Drink_103',
    short_names: ['maximum_Food_Drink_103'],
    imageUrl: '/files/emoji/Food_Drink_103.png',
  },
  {
    name: 'Food_Drink_104',
    short_names: ['maximum_Food_Drink_104'],
    imageUrl: '/files/emoji/Food_Drink_104.png',
  },
  {
    name: 'Food_Drink_105',
    short_names: ['maximum_Food_Drink_105'],
    imageUrl: '/files/emoji/Food_Drink_105.png',
  },
  {
    name: 'Food_Drink_106',
    short_names: ['maximum_Food_Drink_106'],
    imageUrl: '/files/emoji/Food_Drink_106.png',
  },
  {
    name: 'Food_Drink_107',
    short_names: ['maximum_Food_Drink_107'],
    imageUrl: '/files/emoji/Food_Drink_107.png',
  },
  {
    name: 'Food_Drink_108',
    short_names: ['maximum_Food_Drink_108'],
    imageUrl: '/files/emoji/Food_Drink_108.png',
  },
  {
    name: 'Food_Drink_109',
    short_names: ['maximum_Food_Drink_109'],
    imageUrl: '/files/emoji/Food_Drink_109.png',
  },
  {
    name: 'Food_Drink_110',
    short_names: ['maximum_Food_Drink_110'],
    imageUrl: '/files/emoji/Food_Drink_110.png',
  },
  {
    name: 'Food_Drink_111',
    short_names: ['maximum_Food_Drink_111'],
    imageUrl: '/files/emoji/Food_Drink_111.png',
  },
  {
    name: 'Food_Drink_112',
    short_names: ['maximum_Food_Drink_112'],
    imageUrl: '/files/emoji/Food_Drink_112.png',
  },
  {
    name: 'Food_Drink_113',
    short_names: ['maximum_Food_Drink_113'],
    imageUrl: '/files/emoji/Food_Drink_113.png',
  },
  {
    name: 'Food_Drink_114',
    short_names: ['maximum_Food_Drink_114'],
    imageUrl: '/files/emoji/Food_Drink_114.png',
  },
  {
    name: 'Food_Drink_115',
    short_names: ['maximum_Food_Drink_115'],
    imageUrl: '/files/emoji/Food_Drink_115.png',
  },
  {
    name: 'Food_Drink_116',
    short_names: ['maximum_Food_Drink_116'],
    imageUrl: '/files/emoji/Food_Drink_116.png',
  },
  {
    name: 'Food_Drink_117',
    short_names: ['maximum_Food_Drink_117'],
    imageUrl: '/files/emoji/Food_Drink_117.png',
  },
  {
    name: 'Food_Drink_118',
    short_names: ['maximum_Food_Drink_118'],
    imageUrl: '/files/emoji/Food_Drink_118.png',
  },
  {
    name: 'Food_Drink_119',
    short_names: ['maximum_Food_Drink_119'],
    imageUrl: '/files/emoji/Food_Drink_119.png',
  },
  {
    name: 'Food_Drink_120',
    short_names: ['maximum_Food_Drink_120'],
    imageUrl: '/files/emoji/Food_Drink_120.png',
  },
  {
    name: 'Food_Drink_121',
    short_names: ['maximum_Food_Drink_121'],
    imageUrl: '/files/emoji/Food_Drink_121.png',
  },
  {
    name: 'Food_Drink_122',
    short_names: ['maximum_Food_Drink_122'],
    imageUrl: '/files/emoji/Food_Drink_122.png',
  },
  {
    name: 'Food_Drink_123',
    short_names: ['maximum_Food_Drink_123'],
    imageUrl: '/files/emoji/Food_Drink_123.png',
  },
  {
    name: 'Food_Drink_124',
    short_names: ['maximum_Food_Drink_124'],
    imageUrl: '/files/emoji/Food_Drink_124.png',
  },
  {
    name: 'Food_Drink_125',
    short_names: ['maximum_Food_Drink_125'],
    imageUrl: '/files/emoji/Food_Drink_125.png',
  },
  {
    name: 'Food_Drink_126',
    short_names: ['maximum_Food_Drink_126'],
    imageUrl: '/files/emoji/Food_Drink_126.png',
  },
  {
    name: 'Food_Drink_127',
    short_names: ['maximum_Food_Drink_127'],
    imageUrl: '/files/emoji/Food_Drink_127.png',
  },
  {
    name: 'Food_Drink_128',
    short_names: ['maximum_Food_Drink_128'],
    imageUrl: '/files/emoji/Food_Drink_128.png',
  },
  {
    name: 'Food_Drink_129',
    short_names: ['maximum_Food_Drink_129'],
    imageUrl: '/files/emoji/Food_Drink_129.png',
  },
  {
    name: 'Food_Drink_130',
    short_names: ['maximum_Food_Drink_130'],
    imageUrl: '/files/emoji/Food_Drink_130.png',
  },
  {
    name: 'Food_Drink_131',
    short_names: ['maximum_Food_Drink_131'],
    imageUrl: '/files/emoji/Food_Drink_131.png',
  },
  {
    name: 'Food_Drink_132',
    short_names: ['maximum_Food_Drink_132'],
    imageUrl: '/files/emoji/Food_Drink_132.png',
  },
  {
    name: 'Food_Drink_133',
    short_names: ['maximum_Food_Drink_133'],
    imageUrl: '/files/emoji/Food_Drink_133.png',
  },
  {
    name: 'Food_Drink_134',
    short_names: ['maximum_Food_Drink_134'],
    imageUrl: '/files/emoji/Food_Drink_134.png',
  },
  {
    name: 'Food_Drink_135',
    short_names: ['maximum_Food_Drink_135'],
    imageUrl: '/files/emoji/Food_Drink_135.png',
  },
  {
    name: 'Food_Drink_136',
    short_names: ['maximum_Food_Drink_136'],
    imageUrl: '/files/emoji/Food_Drink_136.png',
  },
  {
    name: 'Food_Drink_137',
    short_names: ['maximum_Food_Drink_137'],
    imageUrl: '/files/emoji/Food_Drink_137.png',
  },
  {
    name: 'Food_Drink_138',
    short_names: ['maximum_Food_Drink_138'],
    imageUrl: '/files/emoji/Food_Drink_138.png',
  },
  {
    name: 'Food_Drink_139',
    short_names: ['maximum_Food_Drink_139'],
    imageUrl: '/files/emoji/Food_Drink_139.png',
  },
  {
    name: 'Food_Drink_140',
    short_names: ['maximum_Food_Drink_140'],
    imageUrl: '/files/emoji/Food_Drink_140.png',
  },
  {
    name: 'Food_Drink_141',
    short_names: ['maximum_Food_Drink_141'],
    imageUrl: '/files/emoji/Food_Drink_141.png',
  },
  {
    name: 'Food_Drink_142',
    short_names: ['maximum_Food_Drink_142'],
    imageUrl: '/files/emoji/Food_Drink_142.png',
  },
  {
    name: 'Food_Drink_143',
    short_names: ['maximum_Food_Drink_143'],
    imageUrl: '/files/emoji/Food_Drink_143.png',
  },
  {
    name: 'Food_Drink_144',
    short_names: ['maximum_Food_Drink_144'],
    imageUrl: '/files/emoji/Food_Drink_144.png',
  },
  {
    name: 'Food_Drink_145',
    short_names: ['maximum_Food_Drink_145'],
    imageUrl: '/files/emoji/Food_Drink_145.png',
  },
  {
    name: 'Food_Drink_146',
    short_names: ['maximum_Food_Drink_146'],
    imageUrl: '/files/emoji/Food_Drink_146.png',
  },
  {
    name: 'Food_Drink_147',
    short_names: ['maximum_Food_Drink_147'],
    imageUrl: '/files/emoji/Food_Drink_147.png',
  },
  {
    name: 'Food_Drink_148',
    short_names: ['maximum_Food_Drink_148'],
    imageUrl: '/files/emoji/Food_Drink_148.png',
  },
  {
    name: 'Food_Drink_149',
    short_names: ['maximum_Food_Drink_149'],
    imageUrl: '/files/emoji/Food_Drink_149.png',
  },
  {
    name: 'Food_Drink_150',
    short_names: ['maximum_Food_Drink_150'],
    imageUrl: '/files/emoji/Food_Drink_150.png',
  },
  {
    name: 'Food_Drink_151',
    short_names: ['maximum_Food_Drink_151'],
    imageUrl: '/files/emoji/Food_Drink_151.png',
  },
  {
    name: 'Food_Drink_152',
    short_names: ['maximum_Food_Drink_152'],
    imageUrl: '/files/emoji/Food_Drink_152.png',
  },
  {
    name: 'Food_Drink_153',
    short_names: ['maximum_Food_Drink_153'],
    imageUrl: '/files/emoji/Food_Drink_153.png',
  },
  {
    name: 'Food_Drink_154',
    short_names: ['maximum_Food_Drink_154'],
    imageUrl: '/files/emoji/Food_Drink_154.png',
  },
  {
    name: 'Food_Drink_155',
    short_names: ['maximum_Food_Drink_155'],
    imageUrl: '/files/emoji/Food_Drink_155.png',
  },
  {
    name: 'Food_Drink_156',
    short_names: ['maximum_Food_Drink_156'],
    imageUrl: '/files/emoji/Food_Drink_156.png',
  },
  {
    name: 'Food_Drink_157',
    short_names: ['maximum_Food_Drink_157'],
    imageUrl: '/files/emoji/Food_Drink_157.png',
  },
  {
    name: 'Food_Drink_158',
    short_names: ['maximum_Food_Drink_158'],
    imageUrl: '/files/emoji/Food_Drink_158.png',
  },
  {
    name: 'Food_Drink_159',
    short_names: ['maximum_Food_Drink_159'],
    imageUrl: '/files/emoji/Food_Drink_159.png',
  },
  {
    name: 'Food_Drink_160',
    short_names: ['maximum_Food_Drink_160'],
    imageUrl: '/files/emoji/Food_Drink_160.png',
  },
  {
    name: 'Food_Drink_161',
    short_names: ['maximum_Food_Drink_161'],
    imageUrl: '/files/emoji/Food_Drink_161.png',
  },
  {
    name: 'Food_Drink_162',
    short_names: ['maximum_Food_Drink_162'],
    imageUrl: '/files/emoji/Food_Drink_162.png',
  },
  {
    name: 'Food_Drink_163',
    short_names: ['maximum_Food_Drink_163'],
    imageUrl: '/files/emoji/Food_Drink_163.png',
  },
  {
    name: 'Food_Drink_164',
    short_names: ['maximum_Food_Drink_164'],
    imageUrl: '/files/emoji/Food_Drink_164.png',
  },
  {
    name: 'Food_Drink_165',
    short_names: ['maximum_Food_Drink_165'],
    imageUrl: '/files/emoji/Food_Drink_165.png',
  },
  {
    name: 'Food_Drink_166',
    short_names: ['maximum_Food_Drink_166'],
    imageUrl: '/files/emoji/Food_Drink_166.png',
  },
  {
    name: 'Food_Drink_167',
    short_names: ['maximum_Food_Drink_167'],
    imageUrl: '/files/emoji/Food_Drink_167.png',
  },
  {
    name: 'Food_Drink_168',
    short_names: ['maximum_Food_Drink_168'],
    imageUrl: '/files/emoji/Food_Drink_168.png',
  },
  {
    name: 'Food_Drink_169',
    short_names: ['maximum_Food_Drink_169'],
    imageUrl: '/files/emoji/Food_Drink_169.png',
  },
  {
    name: 'Food_Drink_170',
    short_names: ['maximum_Food_Drink_170'],
    imageUrl: '/files/emoji/Food_Drink_170.png',
  },
  {
    name: 'Food_Drink_171',
    short_names: ['maximum_Food_Drink_171'],
    imageUrl: '/files/emoji/Food_Drink_171.png',
  },
  {
    name: 'Food_Drink_172',
    short_names: ['maximum_Food_Drink_172'],
    imageUrl: '/files/emoji/Food_Drink_172.png',
  },
  {
    name: 'Food_Drink_173',
    short_names: ['maximum_Food_Drink_173'],
    imageUrl: '/files/emoji/Food_Drink_173.png',
  },
  {
    name: 'Food_Drink_174',
    short_names: ['maximum_Food_Drink_174'],
    imageUrl: '/files/emoji/Food_Drink_174.png',
  },
  {
    name: 'Food_Drink_175',
    short_names: ['maximum_Food_Drink_175'],
    imageUrl: '/files/emoji/Food_Drink_175.png',
  },
  {
    name: 'Food_Drink_176',
    short_names: ['maximum_Food_Drink_176'],
    imageUrl: '/files/emoji/Food_Drink_176.png',
  },
  {
    name: 'Food_Drink_177',
    short_names: ['maximum_Food_Drink_177'],
    imageUrl: '/files/emoji/Food_Drink_177.png',
  },
  {
    name: 'Food_Drink_178',
    short_names: ['maximum_Food_Drink_178'],
    imageUrl: '/files/emoji/Food_Drink_178.png',
  },
  {
    name: 'Food_Drink_179',
    short_names: ['maximum_Food_Drink_179'],
    imageUrl: '/files/emoji/Food_Drink_179.png',
  },
  {
    name: 'Food_Drink_180',
    short_names: ['maximum_Food_Drink_180'],
    imageUrl: '/files/emoji/Food_Drink_180.png',
  },
  {
    name: 'Food_Drink_181',
    short_names: ['maximum_Food_Drink_181'],
    imageUrl: '/files/emoji/Food_Drink_181.png',
  },
  {
    name: 'Food_Drink_182',
    short_names: ['maximum_Food_Drink_182'],
    imageUrl: '/files/emoji/Food_Drink_182.png',
  },
  {
    name: 'Food_Drink_183',
    short_names: ['maximum_Food_Drink_183'],
    imageUrl: '/files/emoji/Food_Drink_183.png',
  },
  {
    name: 'Food_Drink_184',
    short_names: ['maximum_Food_Drink_184'],
    imageUrl: '/files/emoji/Food_Drink_184.png',
  },
  {
    name: 'Food_Drink_185',
    short_names: ['maximum_Food_Drink_185'],
    imageUrl: '/files/emoji/Food_Drink_185.png',
  },
  {
    name: 'Food_Drink_186',
    short_names: ['maximum_Food_Drink_186'],
    imageUrl: '/files/emoji/Food_Drink_186.png',
  },
  {
    name: 'Food_Drink_187',
    short_names: ['maximum_Food_Drink_187'],
    imageUrl: '/files/emoji/Food_Drink_187.png',
  },
  {
    name: 'Food_Drink_188',
    short_names: ['maximum_Food_Drink_188'],
    imageUrl: '/files/emoji/Food_Drink_188.png',
  },
  {
    name: 'Food_Drink_189',
    short_names: ['maximum_Food_Drink_189'],
    imageUrl: '/files/emoji/Food_Drink_189.png',
  },
  {
    name: 'Food_Drink_190',
    short_names: ['maximum_Food_Drink_190'],
    imageUrl: '/files/emoji/Food_Drink_190.png',
  },
  {
    name: 'Food_Drink_191',
    short_names: ['maximum_Food_Drink_191'],
    imageUrl: '/files/emoji/Food_Drink_191.png',
  },
  {
    name: 'Food_Drink_192',
    short_names: ['maximum_Food_Drink_192'],
    imageUrl: '/files/emoji/Food_Drink_192.png',
  },
  {
    name: 'Food_Drink_193',
    short_names: ['maximum_Food_Drink_193'],
    imageUrl: '/files/emoji/Food_Drink_193.png',
  },
  {
    name: 'Food_Drink_194',
    short_names: ['maximum_Food_Drink_194'],
    imageUrl: '/files/emoji/Food_Drink_194.png',
  },
  {
    name: 'Food_Drink_195',
    short_names: ['maximum_Food_Drink_195'],
    imageUrl: '/files/emoji/Food_Drink_195.png',
  },
  {
    name: 'Food_Drink_196',
    short_names: ['maximum_Food_Drink_196'],
    imageUrl: '/files/emoji/Food_Drink_196.png',
  },
  {
    name: 'Food_Drink_197',
    short_names: ['maximum_Food_Drink_197'],
    imageUrl: '/files/emoji/Food_Drink_197.png',
  },
  {
    name: 'Food_Drink_198',
    short_names: ['maximum_Food_Drink_198'],
    imageUrl: '/files/emoji/Food_Drink_198.png',
  },
  {
    name: 'Food_Drink_199',
    short_names: ['maximum_Food_Drink_199'],
    imageUrl: '/files/emoji/Food_Drink_199.png',
  },
  {
    name: 'Food_Drink_200',
    short_names: ['maximum_Food_Drink_200'],
    imageUrl: '/files/emoji/Food_Drink_200.png',
  },
  {
    name: 'Food_Drink_201',
    short_names: ['maximum_Food_Drink_201'],
    imageUrl: '/files/emoji/Food_Drink_201.png',
  },
  {
    name: 'Food_Drink_202',
    short_names: ['maximum_Food_Drink_202'],
    imageUrl: '/files/emoji/Food_Drink_202.png',
  },
  {
    name: 'Food_Drink_203',
    short_names: ['maximum_Food_Drink_203'],
    imageUrl: '/files/emoji/Food_Drink_203.png',
  },
  {
    name: 'Food_Drink_204',
    short_names: ['maximum_Food_Drink_204'],
    imageUrl: '/files/emoji/Food_Drink_204.png',
  },
  {
    name: 'Food_Drink_205',
    short_names: ['maximum_Food_Drink_205'],
    imageUrl: '/files/emoji/Food_Drink_205.png',
  },
  {
    name: 'Food_Drink_206',
    short_names: ['maximum_Food_Drink_206'],
    imageUrl: '/files/emoji/Food_Drink_206.png',
  },
  {
    name: 'Food_Drink_207',
    short_names: ['maximum_Food_Drink_207'],
    imageUrl: '/files/emoji/Food_Drink_207.png',
  },
  {
    name: 'Food_Drink_208',
    short_names: ['maximum_Food_Drink_208'],
    imageUrl: '/files/emoji/Food_Drink_208.png',
  },
  {
    name: 'Food_Drink_209',
    short_names: ['maximum_Food_Drink_209'],
    imageUrl: '/files/emoji/Food_Drink_209.png',
  },
  {
    name: 'Food_Drink_210',
    short_names: ['maximum_Food_Drink_210'],
    imageUrl: '/files/emoji/Food_Drink_210.png',
  },
  {
    name: 'Food_Drink_211',
    short_names: ['maximum_Food_Drink_211'],
    imageUrl: '/files/emoji/Food_Drink_211.png',
  },
  {
    name: 'Food_Drink_212',
    short_names: ['maximum_Food_Drink_212'],
    imageUrl: '/files/emoji/Food_Drink_212.png',
  },
  {
    name: 'Food_Drink_213',
    short_names: ['maximum_Food_Drink_213'],
    imageUrl: '/files/emoji/Food_Drink_213.png',
  },
  {
    name: 'Food_Drink_214',
    short_names: ['maximum_Food_Drink_214'],
    imageUrl: '/files/emoji/Food_Drink_214.png',
  },
  {
    name: 'Food_Drink_215',
    short_names: ['maximum_Food_Drink_215'],
    imageUrl: '/files/emoji/Food_Drink_215.png',
  },
  {
    name: 'Food_Drink_216',
    short_names: ['maximum_Food_Drink_216'],
    imageUrl: '/files/emoji/Food_Drink_216.png',
  },
  {
    name: 'Food_Drink_217',
    short_names: ['maximum_Food_Drink_217'],
    imageUrl: '/files/emoji/Food_Drink_217.png',
  },
  {
    name: 'Food_Drink_218',
    short_names: ['maximum_Food_Drink_218'],
    imageUrl: '/files/emoji/Food_Drink_218.png',
  },
  {
    name: 'Food_Drink_219',
    short_names: ['maximum_Food_Drink_219'],
    imageUrl: '/files/emoji/Food_Drink_219.png',
  },
  {
    name: 'Food_Drink_220',
    short_names: ['maximum_Food_Drink_220'],
    imageUrl: '/files/emoji/Food_Drink_220.png',
  },
  {
    name: 'Food_Drink_221',
    short_names: ['maximum_Food_Drink_221'],
    imageUrl: '/files/emoji/Food_Drink_221.png',
  },
  {
    name: 'Food_Drink_222',
    short_names: ['maximum_Food_Drink_222'],
    imageUrl: '/files/emoji/Food_Drink_222.png',
  },
  {
    name: 'Food_Drink_223',
    short_names: ['maximum_Food_Drink_223'],
    imageUrl: '/files/emoji/Food_Drink_223.png',
  },
  {
    name: 'Food_Drink_224',
    short_names: ['maximum_Food_Drink_224'],
    imageUrl: '/files/emoji/Food_Drink_224.png',
  },
  {
    name: 'Food_Drink_225',
    short_names: ['maximum_Food_Drink_225'],
    imageUrl: '/files/emoji/Food_Drink_225.png',
  },
  {
    name: 'Food_Drink_226',
    short_names: ['maximum_Food_Drink_226'],
    imageUrl: '/files/emoji/Food_Drink_226.png',
  },
  {
    name: 'Food_Drink_227',
    short_names: ['maximum_Food_Drink_227'],
    imageUrl: '/files/emoji/Food_Drink_227.png',
  },
  {
    name: 'Food_Drink_228',
    short_names: ['maximum_Food_Drink_228'],
    imageUrl: '/files/emoji/Food_Drink_228.png',
  },
  {
    name: 'Food_Drink_229',
    short_names: ['maximum_Food_Drink_229'],
    imageUrl: '/files/emoji/Food_Drink_229.png',
  },
  {
    name: 'Food_Drink_230',
    short_names: ['maximum_Food_Drink_230'],
    imageUrl: '/files/emoji/Food_Drink_230.png',
  },
  {
    name: 'Food_Drink_231',
    short_names: ['maximum_Food_Drink_231'],
    imageUrl: '/files/emoji/Food_Drink_231.png',
  },
  {
    name: 'Food_Drink_232',
    short_names: ['maximum_Food_Drink_232'],
    imageUrl: '/files/emoji/Food_Drink_232.png',
  },
  {
    name: 'Food_Drink_233',
    short_names: ['maximum_Food_Drink_233'],
    imageUrl: '/files/emoji/Food_Drink_233.png',
  },
  {
    name: 'Food_Drink_234',
    short_names: ['maximum_Food_Drink_234'],
    imageUrl: '/files/emoji/Food_Drink_234.png',
  },
  {
    name: 'Food_Drink_235',
    short_names: ['maximum_Food_Drink_235'],
    imageUrl: '/files/emoji/Food_Drink_235.png',
  },
  {
    name: 'Food_Drink_236',
    short_names: ['maximum_Food_Drink_236'],
    imageUrl: '/files/emoji/Food_Drink_236.png',
  },
  {
    name: 'Food_Drink_237',
    short_names: ['maximum_Food_Drink_237'],
    imageUrl: '/files/emoji/Food_Drink_237.png',
  },
  {
    name: 'Food_Drink_238',
    short_names: ['maximum_Food_Drink_238'],
    imageUrl: '/files/emoji/Food_Drink_238.png',
  },
  {
    name: 'Food_Drink_239',
    short_names: ['maximum_Food_Drink_239'],
    imageUrl: '/files/emoji/Food_Drink_239.png',
  },
  {
    name: 'Food_Drink_240',
    short_names: ['maximum_Food_Drink_240'],
    imageUrl: '/files/emoji/Food_Drink_240.png',
  },
  {
    name: 'Food_Drink_241',
    short_names: ['maximum_Food_Drink_241'],
    imageUrl: '/files/emoji/Food_Drink_241.png',
  },
  {
    name: 'Food_Drink_242',
    short_names: ['maximum_Food_Drink_242'],
    imageUrl: '/files/emoji/Food_Drink_242.png',
  },
  {
    name: 'Food_Drink_243',
    short_names: ['maximum_Food_Drink_243'],
    imageUrl: '/files/emoji/Food_Drink_243.png',
  },
  {
    name: 'Food_Drink_244',
    short_names: ['maximum_Food_Drink_244'],
    imageUrl: '/files/emoji/Food_Drink_244.png',
  },
  {
    name: 'Food_Drink_245',
    short_names: ['maximum_Food_Drink_245'],
    imageUrl: '/files/emoji/Food_Drink_245.png',
  },
  {
    name: 'Food_Drink_246',
    short_names: ['maximum_Food_Drink_246'],
    imageUrl: '/files/emoji/Food_Drink_246.png',
  },
  {
    name: 'Food_Drink_247',
    short_names: ['maximum_Food_Drink_247'],
    imageUrl: '/files/emoji/Food_Drink_247.png',
  },
  {
    name: 'Food_Drink_248',
    short_names: ['maximum_Food_Drink_248'],
    imageUrl: '/files/emoji/Food_Drink_248.png',
  },
  {
    name: 'Food_Drink_249',
    short_names: ['maximum_Food_Drink_249'],
    imageUrl: '/files/emoji/Food_Drink_249.png',
  },
  {
    name: 'Food_Drink_250',
    short_names: ['maximum_Food_Drink_250'],
    imageUrl: '/files/emoji/Food_Drink_250.png',
  },
  {
    name: 'Food_Drink_251',
    short_names: ['maximum_Food_Drink_251'],
    imageUrl: '/files/emoji/Food_Drink_251.png',
  },
  {
    name: 'Food_Drink_252',
    short_names: ['maximum_Food_Drink_252'],
    imageUrl: '/files/emoji/Food_Drink_252.png',
  },
  {
    name: 'Food_Drink_253',
    short_names: ['maximum_Food_Drink_253'],
    imageUrl: '/files/emoji/Food_Drink_253.png',
  },
  {
    name: 'Food_Drink_254',
    short_names: ['maximum_Food_Drink_254'],
    imageUrl: '/files/emoji/Food_Drink_254.png',
  },
  {
    name: 'Food_Drink_255',
    short_names: ['maximum_Food_Drink_255'],
    imageUrl: '/files/emoji/Food_Drink_255.png',
  },
  {
    name: 'Food_Drink_256',
    short_names: ['maximum_Food_Drink_256'],
    imageUrl: '/files/emoji/Food_Drink_256.png',
  },
  {
    name: 'Food_Drink_257',
    short_names: ['maximum_Food_Drink_257'],
    imageUrl: '/files/emoji/Food_Drink_257.png',
  },
  {
    name: 'Food_Drink_258',
    short_names: ['maximum_Food_Drink_258'],
    imageUrl: '/files/emoji/Food_Drink_258.png',
  },
  {
    name: 'Food_Drink_259',
    short_names: ['maximum_Food_Drink_259'],
    imageUrl: '/files/emoji/Food_Drink_259.png',
  },
  {
    name: 'Food_Drink_260',
    short_names: ['maximum_Food_Drink_260'],
    imageUrl: '/files/emoji/Food_Drink_260.png',
  },
  {
    name: 'Food_Drink_261',
    short_names: ['maximum_Food_Drink_261'],
    imageUrl: '/files/emoji/Food_Drink_261.png',
  },
  {
    name: 'Food_Drink_262',
    short_names: ['maximum_Food_Drink_262'],
    imageUrl: '/files/emoji/Food_Drink_262.png',
  },
  {
    name: 'Food_Drink_263',
    short_names: ['maximum_Food_Drink_263'],
    imageUrl: '/files/emoji/Food_Drink_263.png',
  },
  {
    name: 'Food_Drink_264',
    short_names: ['maximum_Food_Drink_264'],
    imageUrl: '/files/emoji/Food_Drink_264.png',
  },
  {
    name: 'Food_Drink_265',
    short_names: ['maximum_Food_Drink_265'],
    imageUrl: '/files/emoji/Food_Drink_265.png',
  },
  {
    name: 'Food_Drink_266',
    short_names: ['maximum_Food_Drink_266'],
    imageUrl: '/files/emoji/Food_Drink_266.png',
  },
  {
    name: 'Food_Drink_267',
    short_names: ['maximum_Food_Drink_267'],
    imageUrl: '/files/emoji/Food_Drink_267.png',
  },
  {
    name: 'Food_Drink_268',
    short_names: ['maximum_Food_Drink_268'],
    imageUrl: '/files/emoji/Food_Drink_268.png',
  },
  {
    name: 'Food_Drink_269',
    short_names: ['maximum_Food_Drink_269'],
    imageUrl: '/files/emoji/Food_Drink_269.png',
  },
  {
    name: 'Food_Drink_270',
    short_names: ['maximum_Food_Drink_270'],
    imageUrl: '/files/emoji/Food_Drink_270.png',
  },
  {
    name: 'Food_Drink_271',
    short_names: ['maximum_Food_Drink_271'],
    imageUrl: '/files/emoji/Food_Drink_271.png',
  },
  {
    name: 'Food_Drink_272',
    short_names: ['maximum_Food_Drink_272'],
    imageUrl: '/files/emoji/Food_Drink_272.png',
  },
  {
    name: 'Food_Drink_273',
    short_names: ['maximum_Food_Drink_273'],
    imageUrl: '/files/emoji/Food_Drink_273.png',
  },
  {
    name: 'Food_Drink_274',
    short_names: ['maximum_Food_Drink_274'],
    imageUrl: '/files/emoji/Food_Drink_274.png',
  },
  {
    name: 'Food_Drink_275',
    short_names: ['maximum_Food_Drink_275'],
    imageUrl: '/files/emoji/Food_Drink_275.png',
  },
  {
    name: 'Food_Drink_276',
    short_names: ['maximum_Food_Drink_276'],
    imageUrl: '/files/emoji/Food_Drink_276.png',
  },
  {
    name: 'Food_Drink_277',
    short_names: ['maximum_Food_Drink_277'],
    imageUrl: '/files/emoji/Food_Drink_277.png',
  },
  {
    name: 'Food_Drink_278',
    short_names: ['maximum_Food_Drink_278'],
    imageUrl: '/files/emoji/Food_Drink_278.png',
  },
  {
    name: 'Food_Drink_279',
    short_names: ['maximum_Food_Drink_279'],
    imageUrl: '/files/emoji/Food_Drink_279.png',
  },
  {
    name: 'Food_Drink_280',
    short_names: ['maximum_Food_Drink_280'],
    imageUrl: '/files/emoji/Food_Drink_280.png',
  },
  {
    name: 'Food_Drink_281',
    short_names: ['maximum_Food_Drink_281'],
    imageUrl: '/files/emoji/Food_Drink_281.png',
  },
  {
    name: 'Food_Drink_282',
    short_names: ['maximum_Food_Drink_282'],
    imageUrl: '/files/emoji/Food_Drink_282.png',
  },
  {
    name: 'Food_Drink_283',
    short_names: ['maximum_Food_Drink_283'],
    imageUrl: '/files/emoji/Food_Drink_283.png',
  },
  {
    name: 'Food_Drink_284',
    short_names: ['maximum_Food_Drink_284'],
    imageUrl: '/files/emoji/Food_Drink_284.png',
  },
  {
    name: 'Food_Drink_285',
    short_names: ['maximum_Food_Drink_285'],
    imageUrl: '/files/emoji/Food_Drink_285.png',
  },
  {
    name: 'Food_Drink_286',
    short_names: ['maximum_Food_Drink_286'],
    imageUrl: '/files/emoji/Food_Drink_286.png',
  },
  {
    name: 'Food_Drink_287',
    short_names: ['maximum_Food_Drink_287'],
    imageUrl: '/files/emoji/Food_Drink_287.png',
  },
  {
    name: 'Food_Drink_288',
    short_names: ['maximum_Food_Drink_288'],
    imageUrl: '/files/emoji/Food_Drink_288.png',
  },
  {
    name: 'Food_Drink_289',
    short_names: ['maximum_Food_Drink_289'],
    imageUrl: '/files/emoji/Food_Drink_289.png',
  },
  {
    name: 'Food_Drink_290',
    short_names: ['maximum_Food_Drink_290'],
    imageUrl: '/files/emoji/Food_Drink_290.png',
  },
  {
    name: 'Food_Drink_291',
    short_names: ['maximum_Food_Drink_291'],
    imageUrl: '/files/emoji/Food_Drink_291.png',
  },
  {
    name: 'Food_Drink_292',
    short_names: ['maximum_Food_Drink_292'],
    imageUrl: '/files/emoji/Food_Drink_292.png',
  },
  {
    name: 'Food_Drink_293',
    short_names: ['maximum_Food_Drink_293'],
    imageUrl: '/files/emoji/Food_Drink_293.png',
  },
  {
    name: 'Food_Drink_294',
    short_names: ['maximum_Food_Drink_294'],
    imageUrl: '/files/emoji/Food_Drink_294.png',
  },
  {
    name: 'Food_Drink_295',
    short_names: ['maximum_Food_Drink_295'],
    imageUrl: '/files/emoji/Food_Drink_295.png',
  },
  {
    name: 'Food_Drink_296',
    short_names: ['maximum_Food_Drink_296'],
    imageUrl: '/files/emoji/Food_Drink_296.png',
  },
  {
    name: 'Food_Drink_297',
    short_names: ['maximum_Food_Drink_297'],
    imageUrl: '/files/emoji/Food_Drink_297.png',
  },
  {
    name: 'Food_Drink_298',
    short_names: ['maximum_Food_Drink_298'],
    imageUrl: '/files/emoji/Food_Drink_298.png',
  },
  {
    name: 'Food_Drink_299',
    short_names: ['maximum_Food_Drink_299'],
    imageUrl: '/files/emoji/Food_Drink_299.png',
  },
  {
    name: 'Food_Drink_300',
    short_names: ['maximum_Food_Drink_300'],
    imageUrl: '/files/emoji/Food_Drink_300.png',
  },
  {
    name: 'Food_Drink_301',
    short_names: ['maximum_Food_Drink_301'],
    imageUrl: '/files/emoji/Food_Drink_301.png',
  },
  {
    name: 'Food_Drink_302',
    short_names: ['maximum_Food_Drink_302'],
    imageUrl: '/files/emoji/Food_Drink_302.png',
  },
  {
    name: 'Food_Drink_303',
    short_names: ['maximum_Food_Drink_303'],
    imageUrl: '/files/emoji/Food_Drink_303.png',
  },
  {
    name: 'Food_Drink_304',
    short_names: ['maximum_Food_Drink_304'],
    imageUrl: '/files/emoji/Food_Drink_304.png',
  },
  {
    name: 'Food_Drink_305',
    short_names: ['maximum_Food_Drink_305'],
    imageUrl: '/files/emoji/Food_Drink_305.png',
  },
  {
    name: 'Food_Drink_306',
    short_names: ['maximum_Food_Drink_306'],
    imageUrl: '/files/emoji/Food_Drink_306.png',
  },
  {
    name: 'Food_Drink_307',
    short_names: ['maximum_Food_Drink_307'],
    imageUrl: '/files/emoji/Food_Drink_307.png',
  },
  {
    name: 'Food_Drink_308',
    short_names: ['maximum_Food_Drink_308'],
    imageUrl: '/files/emoji/Food_Drink_308.png',
  },
  {
    name: 'Food_Drink_309',
    short_names: ['maximum_Food_Drink_309'],
    imageUrl: '/files/emoji/Food_Drink_309.png',
  },
  {
    name: 'Food_Drink_310',
    short_names: ['maximum_Food_Drink_310'],
    imageUrl: '/files/emoji/Food_Drink_310.png',
  },
  {
    name: 'Food_Drink_311',
    short_names: ['maximum_Food_Drink_311'],
    imageUrl: '/files/emoji/Food_Drink_311.png',
  },
  {
    name: 'Food_Drink_312',
    short_names: ['maximum_Food_Drink_312'],
    imageUrl: '/files/emoji/Food_Drink_312.png',
  },
  {
    name: 'Food_Drink_313',
    short_names: ['maximum_Food_Drink_313'],
    imageUrl: '/files/emoji/Food_Drink_313.png',
  },
  {
    name: 'Food_Drink_314',
    short_names: ['maximum_Food_Drink_314'],
    imageUrl: '/files/emoji/Food_Drink_314.png',
  },
  {
    name: 'Food_Drink_315',
    short_names: ['maximum_Food_Drink_315'],
    imageUrl: '/files/emoji/Food_Drink_315.png',
  },
  {
    name: 'Food_Drink_316',
    short_names: ['maximum_Food_Drink_316'],
    imageUrl: '/files/emoji/Food_Drink_316.png',
  },
  {
    name: 'Food_Drink_317',
    short_names: ['maximum_Food_Drink_317'],
    imageUrl: '/files/emoji/Food_Drink_317.png',
  },
  {
    name: 'Food_Drink_318',
    short_names: ['maximum_Food_Drink_318'],
    imageUrl: '/files/emoji/Food_Drink_318.png',
  },
  {
    name: 'Food_Drink_319',
    short_names: ['maximum_Food_Drink_319'],
    imageUrl: '/files/emoji/Food_Drink_319.png',
  },
  {
    name: 'Food_Drink_320',
    short_names: ['maximum_Food_Drink_320'],
    imageUrl: '/files/emoji/Food_Drink_320.png',
  },
  {
    name: 'Food_Drink_321',
    short_names: ['maximum_Food_Drink_321'],
    imageUrl: '/files/emoji/Food_Drink_321.png',
  },
  {
    name: 'Food_Drink_322',
    short_names: ['maximum_Food_Drink_322'],
    imageUrl: '/files/emoji/Food_Drink_322.png',
  },
  {
    name: 'Food_Drink_323',
    short_names: ['maximum_Food_Drink_323'],
    imageUrl: '/files/emoji/Food_Drink_323.png',
  },
  {
    name: 'Food_Drink_324',
    short_names: ['maximum_Food_Drink_324'],
    imageUrl: '/files/emoji/Food_Drink_324.png',
  },
  {
    name: 'Food_Drink_325',
    short_names: ['maximum_Food_Drink_325'],
    imageUrl: '/files/emoji/Food_Drink_325.png',
  },
  {
    name: 'Food_Drink_326',
    short_names: ['maximum_Food_Drink_326'],
    imageUrl: '/files/emoji/Food_Drink_326.png',
  },
  {
    name: 'Food_Drink_327',
    short_names: ['maximum_Food_Drink_327'],
    imageUrl: '/files/emoji/Food_Drink_327.png',
  },
  {
    name: 'Food_Drink_328',
    short_names: ['maximum_Food_Drink_328'],
    imageUrl: '/files/emoji/Food_Drink_328.png',
  },
  {
    name: 'Food_Drink_329',
    short_names: ['maximum_Food_Drink_329'],
    imageUrl: '/files/emoji/Food_Drink_329.png',
  },
  {
    name: 'Food_Drink_330',
    short_names: ['maximum_Food_Drink_330'],
    imageUrl: '/files/emoji/Food_Drink_330.png',
  },
  {
    name: 'Food_Drink_331',
    short_names: ['maximum_Food_Drink_331'],
    imageUrl: '/files/emoji/Food_Drink_331.png',
  },
  {
    name: 'Food_Drink_332',
    short_names: ['maximum_Food_Drink_332'],
    imageUrl: '/files/emoji/Food_Drink_332.png',
  },
  {
    name: 'Food_Drink_333',
    short_names: ['maximum_Food_Drink_333'],
    imageUrl: '/files/emoji/Food_Drink_333.png',
  },
  {
    name: 'Food_Drink_334',
    short_names: ['maximum_Food_Drink_334'],
    imageUrl: '/files/emoji/Food_Drink_334.png',
  },
  {
    name: 'Food_Drink_335',
    short_names: ['maximum_Food_Drink_335'],
    imageUrl: '/files/emoji/Food_Drink_335.png',
  },
  {
    name: 'Food_Drink_336',
    short_names: ['maximum_Food_Drink_336'],
    imageUrl: '/files/emoji/Food_Drink_336.png',
  },
  {
    name: 'Food_Drink_337',
    short_names: ['maximum_Food_Drink_337'],
    imageUrl: '/files/emoji/Food_Drink_337.png',
  },
  {
    name: 'Food_Drink_338',
    short_names: ['maximum_Food_Drink_338'],
    imageUrl: '/files/emoji/Food_Drink_338.png',
  },
  {
    name: 'Food_Drink_339',
    short_names: ['maximum_Food_Drink_339'],
    imageUrl: '/files/emoji/Food_Drink_339.png',
  },
  {
    name: 'Food_Drink_340',
    short_names: ['maximum_Food_Drink_340'],
    imageUrl: '/files/emoji/Food_Drink_340.png',
  },
  {
    name: 'Food_Drink_341',
    short_names: ['maximum_Food_Drink_341'],
    imageUrl: '/files/emoji/Food_Drink_341.png',
  },
  {
    name: 'Food_Drink_342',
    short_names: ['maximum_Food_Drink_342'],
    imageUrl: '/files/emoji/Food_Drink_342.png',
  },
  {
    name: 'Food_Drink_343',
    short_names: ['maximum_Food_Drink_343'],
    imageUrl: '/files/emoji/Food_Drink_343.png',
  },
  {
    name: 'Food_Drink_344',
    short_names: ['maximum_Food_Drink_344'],
    imageUrl: '/files/emoji/Food_Drink_344.png',
  },
  {
    name: 'Food_Drink_345',
    short_names: ['maximum_Food_Drink_345'],
    imageUrl: '/files/emoji/Food_Drink_345.png',
  },
  {
    name: 'Food_Drink_346',
    short_names: ['maximum_Food_Drink_346'],
    imageUrl: '/files/emoji/Food_Drink_346.png',
  },
  {
    name: 'Food_Drink_347',
    short_names: ['maximum_Food_Drink_347'],
    imageUrl: '/files/emoji/Food_Drink_347.png',
  },
  {
    name: 'Food_Drink_348',
    short_names: ['maximum_Food_Drink_348'],
    imageUrl: '/files/emoji/Food_Drink_348.png',
  },
  {
    name: 'Food_Drink_349',
    short_names: ['maximum_Food_Drink_349'],
    imageUrl: '/files/emoji/Food_Drink_349.png',
  },
  {
    name: 'Food_Drink_350',
    short_names: ['maximum_Food_Drink_350'],
    imageUrl: '/files/emoji/Food_Drink_350.png',
  },
  {
    name: 'Food_Drink_351',
    short_names: ['maximum_Food_Drink_351'],
    imageUrl: '/files/emoji/Food_Drink_351.png',
  },
  {
    name: 'Food_Drink_352',
    short_names: ['maximum_Food_Drink_352'],
    imageUrl: '/files/emoji/Food_Drink_352.png',
  },
  {
    name: 'Food_Drink_353',
    short_names: ['maximum_Food_Drink_353'],
    imageUrl: '/files/emoji/Food_Drink_353.png',
  },
  {
    name: 'Food_Drink_354',
    short_names: ['maximum_Food_Drink_354'],
    imageUrl: '/files/emoji/Food_Drink_354.png',
  },
  {
    name: 'Food_Drink_355',
    short_names: ['maximum_Food_Drink_355'],
    imageUrl: '/files/emoji/Food_Drink_355.png',
  },
  {
    name: 'Food_Drink_356',
    short_names: ['maximum_Food_Drink_356'],
    imageUrl: '/files/emoji/Food_Drink_356.png',
  },
  {
    name: 'Food_Drink_357',
    short_names: ['maximum_Food_Drink_357'],
    imageUrl: '/files/emoji/Food_Drink_357.png',
  },
  {
    name: 'Food_Drink_358',
    short_names: ['maximum_Food_Drink_358'],
    imageUrl: '/files/emoji/Food_Drink_358.png',
  },
  {
    name: 'Food_Drink_359',
    short_names: ['maximum_Food_Drink_359'],
    imageUrl: '/files/emoji/Food_Drink_359.png',
  },
  {
    name: 'Food_Drink_360',
    short_names: ['maximum_Food_Drink_360'],
    imageUrl: '/files/emoji/Food_Drink_360.png',
  },
  {
    name: 'Food_Drink_361',
    short_names: ['maximum_Food_Drink_361'],
    imageUrl: '/files/emoji/Food_Drink_361.png',
  },
  {
    name: 'Food_Drink_362',
    short_names: ['maximum_Food_Drink_362'],
    imageUrl: '/files/emoji/Food_Drink_362.png',
  },
  {
    name: 'Food_Drink_363',
    short_names: ['maximum_Food_Drink_363'],
    imageUrl: '/files/emoji/Food_Drink_363.png',
  },
  {
    name: 'Food_Drink_364',
    short_names: ['maximum_Food_Drink_364'],
    imageUrl: '/files/emoji/Food_Drink_364.png',
  },
  {
    name: 'Food_Drink_365',
    short_names: ['maximum_Food_Drink_365'],
    imageUrl: '/files/emoji/Food_Drink_365.png',
  },
  {
    name: 'Food_Drink_366',
    short_names: ['maximum_Food_Drink_366'],
    imageUrl: '/files/emoji/Food_Drink_366.png',
  },
  {
    name: 'Food_Drink_367',
    short_names: ['maximum_Food_Drink_367'],
    imageUrl: '/files/emoji/Food_Drink_367.png',
  },
  {
    name: 'Food_Drink_368',
    short_names: ['maximum_Food_Drink_368'],
    imageUrl: '/files/emoji/Food_Drink_368.png',
  },
  {
    name: 'Food_Drink_369',
    short_names: ['maximum_Food_Drink_369'],
    imageUrl: '/files/emoji/Food_Drink_369.png',
  },
  {
    name: 'Food_Drink_370',
    short_names: ['maximum_Food_Drink_370'],
    imageUrl: '/files/emoji/Food_Drink_370.png',
  },
  {
    name: 'Food_Drink_371',
    short_names: ['maximum_Food_Drink_371'],
    imageUrl: '/files/emoji/Food_Drink_371.png',
  },
  {
    name: 'Food_Drink_372',
    short_names: ['maximum_Food_Drink_372'],
    imageUrl: '/files/emoji/Food_Drink_372.png',
  },
  {
    name: 'Food_Drink_373',
    short_names: ['maximum_Food_Drink_373'],
    imageUrl: '/files/emoji/Food_Drink_373.png',
  },
  {
    name: 'Food_Drink_374',
    short_names: ['maximum_Food_Drink_374'],
    imageUrl: '/files/emoji/Food_Drink_374.png',
  },
  {
    name: 'Food_Drink_375',
    short_names: ['maximum_Food_Drink_375'],
    imageUrl: '/files/emoji/Food_Drink_375.png',
  },
  {
    name: 'Food_Drink_376',
    short_names: ['maximum_Food_Drink_376'],
    imageUrl: '/files/emoji/Food_Drink_376.png',
  },
  {
    name: 'Food_Drink_377',
    short_names: ['maximum_Food_Drink_377'],
    imageUrl: '/files/emoji/Food_Drink_377.png',
  },
  {
    name: 'Food_Drink_378',
    short_names: ['maximum_Food_Drink_378'],
    imageUrl: '/files/emoji/Food_Drink_378.png',
  },
  {
    name: 'Food_Drink_379',
    short_names: ['maximum_Food_Drink_379'],
    imageUrl: '/files/emoji/Food_Drink_379.png',
  },
  {
    name: 'Food_Drink_380',
    short_names: ['maximum_Food_Drink_380'],
    imageUrl: '/files/emoji/Food_Drink_380.png',
  },
  {
    name: 'Food_Drink_381',
    short_names: ['maximum_Food_Drink_381'],
    imageUrl: '/files/emoji/Food_Drink_381.png',
  },
  {
    name: 'Food_Drink_382',
    short_names: ['maximum_Food_Drink_382'],
    imageUrl: '/files/emoji/Food_Drink_382.png',
  },
  {
    name: 'Food_Drink_383',
    short_names: ['maximum_Food_Drink_383'],
    imageUrl: '/files/emoji/Food_Drink_383.png',
  },
  {
    name: 'Food_Drink_384',
    short_names: ['maximum_Food_Drink_384'],
    imageUrl: '/files/emoji/Food_Drink_384.png',
  },
  {
    name: 'Food_Drink_385',
    short_names: ['maximum_Food_Drink_385'],
    imageUrl: '/files/emoji/Food_Drink_385.png',
  },
  {
    name: 'Food_Drink_386',
    short_names: ['maximum_Food_Drink_386'],
    imageUrl: '/files/emoji/Food_Drink_386.png',
  },
  {
    name: 'Food_Drink_387',
    short_names: ['maximum_Food_Drink_387'],
    imageUrl: '/files/emoji/Food_Drink_387.png',
  },
  {
    name: 'Food_Drink_388',
    short_names: ['maximum_Food_Drink_388'],
    imageUrl: '/files/emoji/Food_Drink_388.png',
  },
  {
    name: 'Food_Drink_389',
    short_names: ['maximum_Food_Drink_389'],
    imageUrl: '/files/emoji/Food_Drink_389.png',
  },
  {
    name: 'Food_Drink_390',
    short_names: ['maximum_Food_Drink_390'],
    imageUrl: '/files/emoji/Food_Drink_390.png',
  },
  {
    name: 'Food_Drink_391',
    short_names: ['maximum_Food_Drink_391'],
    imageUrl: '/files/emoji/Food_Drink_391.png',
  },
  {
    name: 'Food_Drink_392',
    short_names: ['maximum_Food_Drink_392'],
    imageUrl: '/files/emoji/Food_Drink_392.png',
  },
  {
    name: 'Food_Drink_393',
    short_names: ['maximum_Food_Drink_393'],
    imageUrl: '/files/emoji/Food_Drink_393.png',
  },
  {
    name: 'Food_Drink_394',
    short_names: ['maximum_Food_Drink_394'],
    imageUrl: '/files/emoji/Food_Drink_394.png',
  },
  {
    name: 'Food_Drink_395',
    short_names: ['maximum_Food_Drink_395'],
    imageUrl: '/files/emoji/Food_Drink_395.png',
  },
  {
    name: 'Food_Drink_396',
    short_names: ['maximum_Food_Drink_396'],
    imageUrl: '/files/emoji/Food_Drink_396.png',
  },
  {
    name: 'Food_Drink_397',
    short_names: ['maximum_Food_Drink_397'],
    imageUrl: '/files/emoji/Food_Drink_397.png',
  },
  {
    name: 'Food_Drink_398',
    short_names: ['maximum_Food_Drink_398'],
    imageUrl: '/files/emoji/Food_Drink_398.png',
  },
  {
    name: 'Food_Drink_399',
    short_names: ['maximum_Food_Drink_399'],
    imageUrl: '/files/emoji/Food_Drink_399.png',
  },
  {
    name: 'Food_Drink_400',
    short_names: ['maximum_Food_Drink_400'],
    imageUrl: '/files/emoji/Food_Drink_400.png',
  },
  {
    name: 'Food_Drink_401',
    short_names: ['maximum_Food_Drink_401'],
    imageUrl: '/files/emoji/Food_Drink_401.png',
  },
  {
    name: 'Food_Drink_402',
    short_names: ['maximum_Food_Drink_402'],
    imageUrl: '/files/emoji/Food_Drink_402.png',
  },
  {
    name: 'Food_Drink_403',
    short_names: ['maximum_Food_Drink_403'],
    imageUrl: '/files/emoji/Food_Drink_403.png',
  },
  {
    name: 'Food_Drink_404',
    short_names: ['maximum_Food_Drink_404'],
    imageUrl: '/files/emoji/Food_Drink_404.png',
  },
  {
    name: 'Food_Drink_405',
    short_names: ['maximum_Food_Drink_405'],
    imageUrl: '/files/emoji/Food_Drink_405.png',
  },
  {
    name: 'Food_Drink_406',
    short_names: ['maximum_Food_Drink_406'],
    imageUrl: '/files/emoji/Food_Drink_406.png',
  },
  {
    name: 'Food_Drink_407',
    short_names: ['maximum_Food_Drink_407'],
    imageUrl: '/files/emoji/Food_Drink_407.png',
  },
  {
    name: 'Food_Drink_408',
    short_names: ['maximum_Food_Drink_408'],
    imageUrl: '/files/emoji/Food_Drink_408.png',
  },
  {
    name: 'gestures_1',
    short_names: ['maximum_gestures1'],
    imageUrl: '/files/emoji/gestures_1.png',
  },
  {
    name: 'gestures_2',
    short_names: ['maximum_gestures2'],
    imageUrl: '/files/emoji/gestures_2.png',
  },
  {
    name: 'gestures_3',
    short_names: ['maximum_gestures3'],
    imageUrl: '/files/emoji/gestures_3.png',
  },
  {
    name: 'gestures_4',
    short_names: ['maximum_gestures4'],
    imageUrl: '/files/emoji/gestures_4.png',
  },
  {
    name: 'gestures_5',
    short_names: ['maximum_gestures5'],
    imageUrl: '/files/emoji/gestures_5.png',
  },
  {
    name: 'gestures_6',
    short_names: ['maximum_gestures6'],
    imageUrl: '/files/emoji/gestures_6.png',
  },
  {
    name: 'gestures_7',
    short_names: ['maximum_gestures7'],
    imageUrl: '/files/emoji/gestures_7.png',
  },
  {
    name: 'gestures_8',
    short_names: ['maximum_gestures8'],
    imageUrl: '/files/emoji/gestures_8.png',
  },
  {
    name: 'gestures_9',
    short_names: ['maximum_gestures9'],
    imageUrl: '/files/emoji/gestures_9.png',
  },
  {
    name: 'gestures_10',
    short_names: ['maximum_gestures10'],
    imageUrl: '/files/emoji/gestures_10.png',
  },
  {
    name: 'gestures_11',
    short_names: ['maximum_gestures11'],
    imageUrl: '/files/emoji/gestures_11.png',
  },
  {
    name: 'gestures_12',
    short_names: ['maximum_gestures12'],
    imageUrl: '/files/emoji/gestures_12.png',
  },
  {
    name: 'gestures_13',
    short_names: ['maximum_gestures13'],
    imageUrl: '/files/emoji/gestures_13.png',
  },
  {
    name: 'gestures_14',
    short_names: ['maximum_gestures14'],
    imageUrl: '/files/emoji/gestures_14.png',
  },
  {
    name: 'gestures_15',
    short_names: ['maximum_gestures15'],
    imageUrl: '/files/emoji/gestures_15.png',
  },
  {
    name: 'gestures_16',
    short_names: ['maximum_gestures16'],
    imageUrl: '/files/emoji/gestures_16.png',
  },
  {
    name: 'gestures_17',
    short_names: ['maximum_gestures17'],
    imageUrl: '/files/emoji/gestures_17.png',
  },
  {
    name: 'gestures_18',
    short_names: ['maximum_gestures18'],
    imageUrl: '/files/emoji/gestures_18.png',
  },
  {
    name: 'gestures_19',
    short_names: ['maximum_gestures19'],
    imageUrl: '/files/emoji/gestures_19.png',
  },
  {
    name: 'gestures_20',
    short_names: ['maximum_gestures20'],
    imageUrl: '/files/emoji/gestures_20.png',
  },
  {
    name: 'gestures_21',
    short_names: ['maximum_gestures21'],
    imageUrl: '/files/emoji/gestures_21.png',
  },
  {
    name: 'gestures_22',
    short_names: ['maximum_gestures22'],
    imageUrl: '/files/emoji/gestures_22.png',
  },
  {
    name: 'gestures_23',
    short_names: ['maximum_gestures23'],
    imageUrl: '/files/emoji/gestures_23.png',
  },
  {
    name: 'gestures_24',
    short_names: ['maximum_gestures24'],
    imageUrl: '/files/emoji/gestures_24.png',
  },
  {
    name: 'gestures_25',
    short_names: ['maximum_gestures25'],
    imageUrl: '/files/emoji/gestures_25.png',
  },
  {
    name: 'gestures_26',
    short_names: ['maximum_gestures26'],
    imageUrl: '/files/emoji/gestures_26.png',
  },
  {
    name: 'gestures_27',
    short_names: ['maximum_gestures27'],
    imageUrl: '/files/emoji/gestures_27.png',
  },
  {
    name: 'gestures_28',
    short_names: ['maximum_gestures28'],
    imageUrl: '/files/emoji/gestures_28.png',
  },
  {
    name: 'gestures_29',
    short_names: ['maximum_gestures29'],
    imageUrl: '/files/emoji/gestures_29.png',
  },
  {
    name: 'gestures_30',
    short_names: ['maximum_gestures30'],
    imageUrl: '/files/emoji/gestures_30.png',
  },
  {
    name: 'gestures_31',
    short_names: ['maximum_gestures31'],
    imageUrl: '/files/emoji/gestures_31.png',
  },
  {
    name: 'gestures_32',
    short_names: ['maximum_gestures32'],
    imageUrl: '/files/emoji/gestures_32.png',
  },
  {
    name: 'gestures_33',
    short_names: ['maximum_gestures33'],
    imageUrl: '/files/emoji/gestures_33.png',
  },
  {
    name: 'gestures_34',
    short_names: ['maximum_gestures34'],
    imageUrl: '/files/emoji/gestures_34.png',
  },
  {
    name: 'gestures_35',
    short_names: ['maximum_gestures35'],
    imageUrl: '/files/emoji/gestures_35.png',
  },
  {
    name: 'gestures_36',
    short_names: ['maximum_gestures36'],
    imageUrl: '/files/emoji/gestures_36.png',
  },
  {
    name: 'gestures_37',
    short_names: ['maximum_gestures37'],
    imageUrl: '/files/emoji/gestures_37.png',
  },
  {
    name: 'gestures_38',
    short_names: ['maximum_gestures38'],
    imageUrl: '/files/emoji/gestures_38.png',
  },
  {
    name: 'gestures_39',
    short_names: ['maximum_gestures39'],
    imageUrl: '/files/emoji/gestures_39.png',
  },
  {
    name: 'gestures_40',
    short_names: ['maximum_gestures40'],
    imageUrl: '/files/emoji/gestures_40.png',
  },
  {
    name: 'gestures_41',
    short_names: ['maximum_gestures41'],
    imageUrl: '/files/emoji/gestures_41.png',
  },
  {
    name: 'gestures_42',
    short_names: ['maximum_gestures42'],
    imageUrl: '/files/emoji/gestures_42.png',
  },
  {
    name: 'gestures_43',
    short_names: ['maximum_gestures43'],
    imageUrl: '/files/emoji/gestures_43.png',
  },
  {
    name: 'gestures_44',
    short_names: ['maximum_gestures44'],
    imageUrl: '/files/emoji/gestures_44.png',
  },
  {
    name: 'gestures_45',
    short_names: ['maximum_gestures45'],
    imageUrl: '/files/emoji/gestures_45.png',
  },
  {
    name: 'gestures_46',
    short_names: ['maximum_gestures46'],
    imageUrl: '/files/emoji/gestures_46.png',
  },
  {
    name: 'gestures_47',
    short_names: ['maximum_gestures47'],
    imageUrl: '/files/emoji/gestures_47.png',
  },
  {
    name: 'gestures_48',
    short_names: ['maximum_gestures48'],
    imageUrl: '/files/emoji/gestures_48.png',
  },
  {
    name: 'gestures_49',
    short_names: ['maximum_gestures49'],
    imageUrl: '/files/emoji/gestures_49.png',
  },
  {
    name: 'gestures_50',
    short_names: ['maximum_gestures50'],
    imageUrl: '/files/emoji/gestures_50.png',
  },
  {
    name: 'gestures_51',
    short_names: ['maximum_gestures51'],
    imageUrl: '/files/emoji/gestures_51.png',
  },
  {
    name: 'gestures_52',
    short_names: ['maximum_gestures52'],
    imageUrl: '/files/emoji/gestures_52.png',
  },
  {
    name: 'gestures_53',
    short_names: ['maximum_gestures53'],
    imageUrl: '/files/emoji/gestures_53.png',
  },
  {
    name: 'gestures_54',
    short_names: ['maximum_gestures54'],
    imageUrl: '/files/emoji/gestures_54.png',
  },
  {
    name: 'gestures_55',
    short_names: ['maximum_gestures55'],
    imageUrl: '/files/emoji/gestures_55.png',
  },
  {
    name: 'gestures_56',
    short_names: ['maximum_gestures56'],
    imageUrl: '/files/emoji/gestures_56.png',
  },
  {
    name: 'gestures_57',
    short_names: ['maximum_gestures57'],
    imageUrl: '/files/emoji/gestures_57.png',
  },
  {
    name: 'gestures_58',
    short_names: ['maximum_gestures58'],
    imageUrl: '/files/emoji/gestures_58.png',
  },
  {
    name: 'gestures_59',
    short_names: ['maximum_gestures59'],
    imageUrl: '/files/emoji/gestures_59.png',
  },
  {
    name: 'gestures_60',
    short_names: ['maximum_gestures60'],
    imageUrl: '/files/emoji/gestures_60.png',
  },
  {
    name: 'gestures_61',
    short_names: ['maximum_gestures61'],
    imageUrl: '/files/emoji/gestures_61.png',
  },
  {
    name: 'gestures_62',
    short_names: ['maximum_gestures62'],
    imageUrl: '/files/emoji/gestures_62.png',
  },
  {
    name: 'gestures_63',
    short_names: ['maximum_gestures63'],
    imageUrl: '/files/emoji/gestures_63.png',
  },
  {
    name: 'gestures_64',
    short_names: ['maximum_gestures64'],
    imageUrl: '/files/emoji/gestures_64.png',
  },
  {
    name: 'gestures_65',
    short_names: ['maximum_gestures65'],
    imageUrl: '/files/emoji/gestures_65.png',
  },
  {
    name: 'gestures_66',
    short_names: ['maximum_gestures66'],
    imageUrl: '/files/emoji/gestures_66.png',
  },
  {
    name: 'gestures_67',
    short_names: ['maximum_gestures67'],
    imageUrl: '/files/emoji/gestures_67.png',
  },
  {
    name: 'gestures_68',
    short_names: ['maximum_gestures68'],
    imageUrl: '/files/emoji/gestures_68.png',
  },
  {
    name: 'gestures_69',
    short_names: ['maximum_gestures69'],
    imageUrl: '/files/emoji/gestures_69.png',
  },
  {
    name: 'gestures_70',
    short_names: ['maximum_gestures70'],
    imageUrl: '/files/emoji/gestures_70.png',
  },
  {
    name: 'gestures_71',
    short_names: ['maximum_gestures71'],
    imageUrl: '/files/emoji/gestures_71.png',
  },
  {
    name: 'gestures_72',
    short_names: ['maximum_gestures72'],
    imageUrl: '/files/emoji/gestures_72.png',
  },
  {
    name: 'gestures_73',
    short_names: ['maximum_gestures73'],
    imageUrl: '/files/emoji/gestures_73.png',
  },
  {
    name: 'gestures_74',
    short_names: ['maximum_gestures74'],
    imageUrl: '/files/emoji/gestures_74.png',
  },
  {
    name: 'gestures_75',
    short_names: ['maximum_gestures75'],
    imageUrl: '/files/emoji/gestures_75.png',
  },
  {
    name: 'gestures_76',
    short_names: ['maximum_gestures76'],
    imageUrl: '/files/emoji/gestures_76.png',
  },
  {
    name: 'gestures_77',
    short_names: ['maximum_gestures77'],
    imageUrl: '/files/emoji/gestures_77.png',
  },
  {
    name: 'gestures_78',
    short_names: ['maximum_gestures78'],
    imageUrl: '/files/emoji/gestures_78.png',
  },
  {
    name: 'gestures_79',
    short_names: ['maximum_gestures79'],
    imageUrl: '/files/emoji/gestures_79.png',
  },
  {
    name: 'gestures_80',
    short_names: ['maximum_gestures80'],
    imageUrl: '/files/emoji/gestures_80.png',
  },
  {
    name: 'gestures_81',
    short_names: ['maximum_gestures81'],
    imageUrl: '/files/emoji/gestures_81.png',
  },
  {
    name: 'gestures_82',
    short_names: ['maximum_gestures82'],
    imageUrl: '/files/emoji/gestures_82.png',
  },
  {
    name: 'gestures_83',
    short_names: ['maximum_gestures83'],
    imageUrl: '/files/emoji/gestures_83.png',
  },
  {
    name: 'gestures_84',
    short_names: ['maximum_gestures84'],
    imageUrl: '/files/emoji/gestures_84.png',
  },
  {
    name: 'gestures_85',
    short_names: ['maximum_gestures85'],
    imageUrl: '/files/emoji/gestures_85.png',
  },
  {
    name: 'gestures_86',
    short_names: ['maximum_gestures86'],
    imageUrl: '/files/emoji/gestures_86.png',
  },
  {
    name: 'gestures_87',
    short_names: ['maximum_gestures87'],
    imageUrl: '/files/emoji/gestures_87.png',
  },
  {
    name: 'gestures_88',
    short_names: ['maximum_gestures88'],
    imageUrl: '/files/emoji/gestures_88.png',
  },
  {
    name: 'gestures_89',
    short_names: ['maximum_gestures89'],
    imageUrl: '/files/emoji/gestures_89.png',
  },
  {
    name: 'gestures_90',
    short_names: ['maximum_gestures90'],
    imageUrl: '/files/emoji/gestures_90.png',
  },
  {
    name: 'gestures_91',
    short_names: ['maximum_gestures91'],
    imageUrl: '/files/emoji/gestures_91.png',
  },
  {
    name: 'gestures_92',
    short_names: ['maximum_gestures92'],
    imageUrl: '/files/emoji/gestures_92.png',
  },
  {
    name: 'gestures_93',
    short_names: ['maximum_gestures93'],
    imageUrl: '/files/emoji/gestures_93.png',
  },
  {
    name: 'gestures_94',
    short_names: ['maximum_gestures94'],
    imageUrl: '/files/emoji/gestures_94.png',
  },
  {
    name: 'gestures_95',
    short_names: ['maximum_gestures95'],
    imageUrl: '/files/emoji/gestures_95.png',
  },
  {
    name: 'gestures_96',
    short_names: ['maximum_gestures96'],
    imageUrl: '/files/emoji/gestures_96.png',
  },
  {
    name: 'nature_1',
    short_names: ['maximum_nature_1'],
    imageUrl: '/files/emoji/nature_1.png',
  },
  {
    name: 'nature_2',
    short_names: ['maximum_nature_2'],
    imageUrl: '/files/emoji/nature_2.png',
  },
  {
    name: 'nature_3',
    short_names: ['maximum_nature_3'],
    imageUrl: '/files/emoji/nature_3.png',
  },
  {
    name: 'nature_4',
    short_names: ['maximum_nature_4'],
    imageUrl: '/files/emoji/nature_4.png',
  },
  {
    name: 'nature_5',
    short_names: ['maximum_nature_5'],
    imageUrl: '/files/emoji/nature_5.png',
  },
  {
    name: 'nature_6',
    short_names: ['maximum_nature_6'],
    imageUrl: '/files/emoji/nature_6.png',
  },
  {
    name: 'nature_7',
    short_names: ['maximum_nature_7'],
    imageUrl: '/files/emoji/nature_7.png',
  },
  {
    name: 'nature_8',
    short_names: ['maximum_nature_8'],
    imageUrl: '/files/emoji/nature_8.png',
  },
  {
    name: 'nature_9',
    short_names: ['maximum_nature_9'],
    imageUrl: '/files/emoji/nature_9.png',
  },
  {
    name: 'nature_10',
    short_names: ['maximum_nature_10'],
    imageUrl: '/files/emoji/nature_10.png',
  },
  {
    name: 'nature_11',
    short_names: ['maximum_nature_11'],
    imageUrl: '/files/emoji/nature_11.png',
  },
  {
    name: 'nature_12',
    short_names: ['maximum_nature_12'],
    imageUrl: '/files/emoji/nature_12.png',
  },
  {
    name: 'nature_13',
    short_names: ['maximum_nature_13'],
    imageUrl: '/files/emoji/nature_13.png',
  },
  {
    name: 'nature_14',
    short_names: ['maximum_nature_14'],
    imageUrl: '/files/emoji/nature_14.png',
  },
  {
    name: 'nature_15',
    short_names: ['maximum_nature_15'],
    imageUrl: '/files/emoji/nature_15.png',
  },
  {
    name: 'nature_16',
    short_names: ['maximum_nature_16'],
    imageUrl: '/files/emoji/nature_16.png',
  },
  {
    name: 'nature_17',
    short_names: ['maximum_nature_17'],
    imageUrl: '/files/emoji/nature_17.png',
  },
  {
    name: 'nature_18',
    short_names: ['maximum_nature_18'],
    imageUrl: '/files/emoji/nature_18.png',
  },
  {
    name: 'nature_19',
    short_names: ['maximum_nature_19'],
    imageUrl: '/files/emoji/nature_19.png',
  },
  {
    name: 'nature_20',
    short_names: ['maximum_nature_20'],
    imageUrl: '/files/emoji/nature_20.png',
  },
  {
    name: 'nature_21',
    short_names: ['maximum_nature_21'],
    imageUrl: '/files/emoji/nature_21.png',
  },
  {
    name: 'nature_22',
    short_names: ['maximum_nature_22'],
    imageUrl: '/files/emoji/nature_22.png',
  },
  {
    name: 'nature_23',
    short_names: ['maximum_nature_23'],
    imageUrl: '/files/emoji/nature_23.png',
  },
  {
    name: 'nature_24',
    short_names: ['maximum_nature_24'],
    imageUrl: '/files/emoji/nature_24.png',
  },
  {
    name: 'nature_25',
    short_names: ['maximum_nature_25'],
    imageUrl: '/files/emoji/nature_25.png',
  },
  {
    name: 'nature_26',
    short_names: ['maximum_nature_26'],
    imageUrl: '/files/emoji/nature_26.png',
  },
  {
    name: 'nature_27',
    short_names: ['maximum_nature_27'],
    imageUrl: '/files/emoji/nature_27.png',
  },
  {
    name: 'nature_28',
    short_names: ['maximum_nature_28'],
    imageUrl: '/files/emoji/nature_28.png',
  },
  {
    name: 'nature_29',
    short_names: ['maximum_nature_29'],
    imageUrl: '/files/emoji/nature_29.png',
  },
  {
    name: 'nature_30',
    short_names: ['maximum_nature_30'],
    imageUrl: '/files/emoji/nature_30.png',
  },
  {
    name: 'nature_31',
    short_names: ['maximum_nature_31'],
    imageUrl: '/files/emoji/nature_31.png',
  },
  {
    name: 'nature_32',
    short_names: ['maximum_nature_32'],
    imageUrl: '/files/emoji/nature_32.png',
  },
  {
    name: 'nature_33',
    short_names: ['maximum_nature_33'],
    imageUrl: '/files/emoji/nature_33.png',
  },
  {
    name: 'nature_34',
    short_names: ['maximum_nature_34'],
    imageUrl: '/files/emoji/nature_34.png',
  },
  {
    name: 'nature_35',
    short_names: ['maximum_nature_35'],
    imageUrl: '/files/emoji/nature_35.png',
  },
  {
    name: 'nature_36',
    short_names: ['maximum_nature_36'],
    imageUrl: '/files/emoji/nature_36.png',
  },
  {
    name: 'nature_37',
    short_names: ['maximum_nature_37'],
    imageUrl: '/files/emoji/nature_37.png',
  },
  {
    name: 'nature_38',
    short_names: ['maximum_nature_38'],
    imageUrl: '/files/emoji/nature_38.png',
  },
  {
    name: 'nature_39',
    short_names: ['maximum_nature_39'],
    imageUrl: '/files/emoji/nature_39.png',
  },
  {
    name: 'nature_40',
    short_names: ['maximum_nature_40'],
    imageUrl: '/files/emoji/nature_40.png',
  },
  {
    name: 'nature_41',
    short_names: ['maximum_nature_41'],
    imageUrl: '/files/emoji/nature_41.png',
  },
  {
    name: 'nature_42',
    short_names: ['maximum_nature_42'],
    imageUrl: '/files/emoji/nature_42.png',
  },
  {
    name: 'nature_43',
    short_names: ['maximum_nature_43'],
    imageUrl: '/files/emoji/nature_43.png',
  },
  {
    name: 'nature_44',
    short_names: ['maximum_nature_44'],
    imageUrl: '/files/emoji/nature_44.png',
  },
  {
    name: 'nature_45',
    short_names: ['maximum_nature_45'],
    imageUrl: '/files/emoji/nature_45.png',
  },
  {
    name: 'nature_46',
    short_names: ['maximum_nature_46'],
    imageUrl: '/files/emoji/nature_46.png',
  },
  {
    name: 'nature_47',
    short_names: ['maximum_nature_47'],
    imageUrl: '/files/emoji/nature_47.png',
  },
  {
    name: 'nature_48',
    short_names: ['maximum_nature_48'],
    imageUrl: '/files/emoji/nature_48.png',
  },
  {
    name: 'nature_49',
    short_names: ['maximum_nature_49'],
    imageUrl: '/files/emoji/nature_49.png',
  },
  {
    name: 'nature_50',
    short_names: ['maximum_nature_50'],
    imageUrl: '/files/emoji/nature_50.png',
  },
  {
    name: 'nature_51',
    short_names: ['maximum_nature_51'],
    imageUrl: '/files/emoji/nature_51.png',
  },
  {
    name: 'nature_52',
    short_names: ['maximum_nature_52'],
    imageUrl: '/files/emoji/nature_52.png',
  },
  {
    name: 'nature_53',
    short_names: ['maximum_nature_53'],
    imageUrl: '/files/emoji/nature_53.png',
  },
  {
    name: 'nature_54',
    short_names: ['maximum_nature_54'],
    imageUrl: '/files/emoji/nature_54.png',
  },
  {
    name: 'nature_55',
    short_names: ['maximum_nature_55'],
    imageUrl: '/files/emoji/nature_55.png',
  },
  {
    name: 'nature_56',
    short_names: ['maximum_nature_56'],
    imageUrl: '/files/emoji/nature_56.png',
  },
  {
    name: 'nature_57',
    short_names: ['maximum_nature_57'],
    imageUrl: '/files/emoji/nature_57.png',
  },
  {
    name: 'nature_58',
    short_names: ['maximum_nature_58'],
    imageUrl: '/files/emoji/nature_58.png',
  },
  {
    name: 'nature_59',
    short_names: ['maximum_nature_59'],
    imageUrl: '/files/emoji/nature_59.png',
  },
  {
    name: 'nature_60',
    short_names: ['maximum_nature_60'],
    imageUrl: '/files/emoji/nature_60.png',
  },
  {
    name: 'nature_61',
    short_names: ['maximum_nature_61'],
    imageUrl: '/files/emoji/nature_61.png',
  },
  {
    name: 'nature_62',
    short_names: ['maximum_nature_62'],
    imageUrl: '/files/emoji/nature_62.png',
  },
  {
    name: 'nature_63',
    short_names: ['maximum_nature_63'],
    imageUrl: '/files/emoji/nature_63.png',
  },
  {
    name: 'nature_64',
    short_names: ['maximum_nature_64'],
    imageUrl: '/files/emoji/nature_64.png',
  },
  {
    name: 'nature_65',
    short_names: ['maximum_nature_65'],
    imageUrl: '/files/emoji/nature_65.png',
  },
  {
    name: 'nature_66',
    short_names: ['maximum_nature_66'],
    imageUrl: '/files/emoji/nature_66.png',
  },
  {
    name: 'nature_67',
    short_names: ['maximum_nature_67'],
    imageUrl: '/files/emoji/nature_67.png',
  },
  {
    name: 'nature_68',
    short_names: ['maximum_nature_68'],
    imageUrl: '/files/emoji/nature_68.png',
  },
  {
    name: 'nature_69',
    short_names: ['maximum_nature_69'],
    imageUrl: '/files/emoji/nature_69.png',
  },
  {
    name: 'nature_70',
    short_names: ['maximum_nature_70'],
    imageUrl: '/files/emoji/nature_70.png',
  },
  {
    name: 'nature_71',
    short_names: ['maximum_nature_71'],
    imageUrl: '/files/emoji/nature_71.png',
  },
  {
    name: 'nature_72',
    short_names: ['maximum_nature_72'],
    imageUrl: '/files/emoji/nature_72.png',
  },
  {
    name: 'nature_73',
    short_names: ['maximum_nature_73'],
    imageUrl: '/files/emoji/nature_73.png',
  },
  {
    name: 'nature_74',
    short_names: ['maximum_nature_74'],
    imageUrl: '/files/emoji/nature_74.png',
  },
  {
    name: 'nature_75',
    short_names: ['maximum_nature_75'],
    imageUrl: '/files/emoji/nature_75.png',
  },
  {
    name: 'nature_76',
    short_names: ['maximum_nature_76'],
    imageUrl: '/files/emoji/nature_76.png',
  },
  {
    name: 'nature_77',
    short_names: ['maximum_nature_77'],
    imageUrl: '/files/emoji/nature_77.png',
  },
  {
    name: 'nature_78',
    short_names: ['maximum_nature_78'],
    imageUrl: '/files/emoji/nature_78.png',
  },
  {
    name: 'nature_79',
    short_names: ['maximum_nature_79'],
    imageUrl: '/files/emoji/nature_79.png',
  },
  {
    name: 'nature_80',
    short_names: ['maximum_nature_80'],
    imageUrl: '/files/emoji/nature_80.png',
  },
  {
    name: 'nature_81',
    short_names: ['maximum_nature_81'],
    imageUrl: '/files/emoji/nature_81.png',
  },
  {
    name: 'nature_82',
    short_names: ['maximum_nature_82'],
    imageUrl: '/files/emoji/nature_82.png',
  },
  {
    name: 'nature_83',
    short_names: ['maximum_nature_83'],
    imageUrl: '/files/emoji/nature_83.png',
  },
  {
    name: 'nature_84',
    short_names: ['maximum_nature_84'],
    imageUrl: '/files/emoji/nature_84.png',
  },
  {
    name: 'nature_85',
    short_names: ['maximum_nature_85'],
    imageUrl: '/files/emoji/nature_85.png',
  },
  {
    name: 'nature_86',
    short_names: ['maximum_nature_86'],
    imageUrl: '/files/emoji/nature_86.png',
  },
  {
    name: 'nature_87',
    short_names: ['maximum_nature_87'],
    imageUrl: '/files/emoji/nature_87.png',
  },
  {
    name: 'nature_88',
    short_names: ['maximum_nature_88'],
    imageUrl: '/files/emoji/nature_88.png',
  },
  {
    name: 'nature_89',
    short_names: ['maximum_nature_89'],
    imageUrl: '/files/emoji/nature_89.png',
  },
  {
    name: 'nature_90',
    short_names: ['maximum_nature_90'],
    imageUrl: '/files/emoji/nature_90.png',
  },
  {
    name: 'nature_91',
    short_names: ['maximum_nature_91'],
    imageUrl: '/files/emoji/nature_91.png',
  },
  {
    name: 'nature_92',
    short_names: ['maximum_nature_92'],
    imageUrl: '/files/emoji/nature_92.png',
  },
  {
    name: 'nature_93',
    short_names: ['maximum_nature_93'],
    imageUrl: '/files/emoji/nature_93.png',
  },
  {
    name: 'nature_94',
    short_names: ['maximum_nature_94'],
    imageUrl: '/files/emoji/nature_94.png',
  },
  {
    name: 'nature_95',
    short_names: ['maximum_nature_95'],
    imageUrl: '/files/emoji/nature_95.png',
  },
  {
    name: 'nature_96',
    short_names: ['maximum_nature_96'],
    imageUrl: '/files/emoji/nature_96.png',
  },
  {
    name: 'nature_97',
    short_names: ['maximum_nature_97'],
    imageUrl: '/files/emoji/nature_97.png',
  },
  {
    name: 'nature_98',
    short_names: ['maximum_nature_98'],
    imageUrl: '/files/emoji/nature_98.png',
  },
  {
    name: 'nature_99',
    short_names: ['maximum_nature_99'],
    imageUrl: '/files/emoji/nature_99.png',
  },
  {
    name: 'nature_100',
    short_names: ['maximum_nature_100'],
    imageUrl: '/files/emoji/nature_100.png',
  },
  {
    name: 'nature_101',
    short_names: ['maximum_nature_101'],
    imageUrl: '/files/emoji/nature_101.png',
  },
  {
    name: 'nature_102',
    short_names: ['maximum_nature_102'],
    imageUrl: '/files/emoji/nature_102.png',
  },
  {
    name: 'nature_103',
    short_names: ['maximum_nature_103'],
    imageUrl: '/files/emoji/nature_103.png',
  },
  {
    name: 'nature_104',
    short_names: ['maximum_nature_104'],
    imageUrl: '/files/emoji/nature_104.png',
  },
  {
    name: 'nature_105',
    short_names: ['maximum_nature_105'],
    imageUrl: '/files/emoji/nature_105.png',
  },
  {
    name: 'nature_106',
    short_names: ['maximum_nature_106'],
    imageUrl: '/files/emoji/nature_106.png',
  },
  {
    name: 'nature_107',
    short_names: ['maximum_nature_107'],
    imageUrl: '/files/emoji/nature_107.png',
  },
  {
    name: 'nature_108',
    short_names: ['maximum_nature_108'],
    imageUrl: '/files/emoji/nature_108.png',
  },
  {
    name: 'nature_109',
    short_names: ['maximum_nature_109'],
    imageUrl: '/files/emoji/nature_109.png',
  },
  {
    name: 'nature_110',
    short_names: ['maximum_nature_110'],
    imageUrl: '/files/emoji/nature_110.png',
  },
  {
    name: 'nature_111',
    short_names: ['maximum_nature_111'],
    imageUrl: '/files/emoji/nature_111.png',
  },
  {
    name: 'nature_112',
    short_names: ['maximum_nature_112'],
    imageUrl: '/files/emoji/nature_112.png',
  },
  {
    name: 'nature_113',
    short_names: ['maximum_nature_113'],
    imageUrl: '/files/emoji/nature_113.png',
  },
  {
    name: 'nature_114',
    short_names: ['maximum_nature_114'],
    imageUrl: '/files/emoji/nature_114.png',
  },
  {
    name: 'nature_115',
    short_names: ['maximum_nature_115'],
    imageUrl: '/files/emoji/nature_115.png',
  },
  {
    name: 'nature_116',
    short_names: ['maximum_nature_116'],
    imageUrl: '/files/emoji/nature_116.png',
  },
  {
    name: 'nature_117',
    short_names: ['maximum_nature_117'],
    imageUrl: '/files/emoji/nature_117.png',
  },
  {
    name: 'nature_118',
    short_names: ['maximum_nature_118'],
    imageUrl: '/files/emoji/nature_118.png',
  },
  {
    name: 'nature_119',
    short_names: ['maximum_nature_119'],
    imageUrl: '/files/emoji/nature_119.png',
  },
  {
    name: 'nature_120',
    short_names: ['maximum_nature_120'],
    imageUrl: '/files/emoji/nature_120.png',
  },
  {
    name: 'nature_121',
    short_names: ['maximum_nature_121'],
    imageUrl: '/files/emoji/nature_121.png',
  },
  {
    name: 'nature_122',
    short_names: ['maximum_nature_122'],
    imageUrl: '/files/emoji/nature_122.png',
  },
  {
    name: 'nature_123',
    short_names: ['maximum_nature_123'],
    imageUrl: '/files/emoji/nature_123.png',
  },
  {
    name: 'nature_124',
    short_names: ['maximum_nature_124'],
    imageUrl: '/files/emoji/nature_124.png',
  },
  {
    name: 'nature_125',
    short_names: ['maximum_nature_125'],
    imageUrl: '/files/emoji/nature_125.png',
  },
  {
    name: 'nature_126',
    short_names: ['maximum_nature_126'],
    imageUrl: '/files/emoji/nature_126.png',
  },
  {
    name: 'nature_127',
    short_names: ['maximum_nature_127'],
    imageUrl: '/files/emoji/nature_127.png',
  },
  {
    name: 'nature_128',
    short_names: ['maximum_nature_128'],
    imageUrl: '/files/emoji/nature_128.png',
  },
  {
    name: 'nature_129',
    short_names: ['maximum_nature_129'],
    imageUrl: '/files/emoji/nature_129.png',
  },
  {
    name: 'nature_130',
    short_names: ['maximum_nature_130'],
    imageUrl: '/files/emoji/nature_130.png',
  },
  {
    name: 'nature_131',
    short_names: ['maximum_nature_131'],
    imageUrl: '/files/emoji/nature_131.png',
  },
  {
    name: 'nature_132',
    short_names: ['maximum_nature_132'],
    imageUrl: '/files/emoji/nature_132.png',
  },
  {
    name: 'nature_133',
    short_names: ['maximum_nature_133'],
    imageUrl: '/files/emoji/nature_133.png',
  },
  {
    name: 'nature_134',
    short_names: ['maximum_nature_134'],
    imageUrl: '/files/emoji/nature_134.png',
  },
  {
    name: 'nature_135',
    short_names: ['maximum_nature_135'],
    imageUrl: '/files/emoji/nature_135.png',
  },
  {
    name: 'nature_136',
    short_names: ['maximum_nature_136'],
    imageUrl: '/files/emoji/nature_136.png',
  },
  {
    name: 'nature_137',
    short_names: ['maximum_nature_137'],
    imageUrl: '/files/emoji/nature_137.png',
  },
  {
    name: 'nature_138',
    short_names: ['maximum_nature_138'],
    imageUrl: '/files/emoji/nature_138.png',
  },
  {
    name: 'nature_139',
    short_names: ['maximum_nature_139'],
    imageUrl: '/files/emoji/nature_139.png',
  },
  {
    name: 'nature_140',
    short_names: ['maximum_nature_140'],
    imageUrl: '/files/emoji/nature_140.png',
  },
  {
    name: 'nature_141',
    short_names: ['maximum_nature_141'],
    imageUrl: '/files/emoji/nature_141.png',
  },
  {
    name: 'nature_142',
    short_names: ['maximum_nature_142'],
    imageUrl: '/files/emoji/nature_142.png',
  },
  {
    name: 'nature_143',
    short_names: ['maximum_nature_143'],
    imageUrl: '/files/emoji/nature_143.png',
  },
  {
    name: 'nature_144',
    short_names: ['maximum_nature_144'],
    imageUrl: '/files/emoji/nature_144.png',
  },
  {
    name: 'nature_145',
    short_names: ['maximum_nature_145'],
    imageUrl: '/files/emoji/nature_145.png',
  },
  {
    name: 'nature_146',
    short_names: ['maximum_nature_146'],
    imageUrl: '/files/emoji/nature_146.png',
  },
  {
    name: 'nature_147',
    short_names: ['maximum_nature_147'],
    imageUrl: '/files/emoji/nature_147.png',
  },
  {
    name: 'nature_148',
    short_names: ['maximum_nature_148'],
    imageUrl: '/files/emoji/nature_148.png',
  },
  {
    name: 'nature_149',
    short_names: ['maximum_nature_149'],
    imageUrl: '/files/emoji/nature_149.png',
  },
  {
    name: 'nature_150',
    short_names: ['maximum_nature_150'],
    imageUrl: '/files/emoji/nature_150.png',
  },
  {
    name: 'nature_151',
    short_names: ['maximum_nature_151'],
    imageUrl: '/files/emoji/nature_151.png',
  },
  {
    name: 'nature_152',
    short_names: ['maximum_nature_152'],
    imageUrl: '/files/emoji/nature_152.png',
  },
  {
    name: 'nature_153',
    short_names: ['maximum_nature_153'],
    imageUrl: '/files/emoji/nature_153.png',
  },
  {
    name: 'nature_154',
    short_names: ['maximum_nature_154'],
    imageUrl: '/files/emoji/nature_154.png',
  },
  {
    name: 'nature_155',
    short_names: ['maximum_nature_155'],
    imageUrl: '/files/emoji/nature_155.png',
  },
  {
    name: 'nature_156',
    short_names: ['maximum_nature_156'],
    imageUrl: '/files/emoji/nature_156.png',
  },
  {
    name: 'nature_157',
    short_names: ['maximum_nature_157'],
    imageUrl: '/files/emoji/nature_157.png',
  },
  {
    name: 'nature_158',
    short_names: ['maximum_nature_158'],
    imageUrl: '/files/emoji/nature_158.png',
  },
  {
    name: 'nature_159',
    short_names: ['maximum_nature_159'],
    imageUrl: '/files/emoji/nature_159.png',
  },
  {
    name: 'nature_160',
    short_names: ['maximum_nature_160'],
    imageUrl: '/files/emoji/nature_160.png',
  },
  {
    name: 'nature_161',
    short_names: ['maximum_nature_161'],
    imageUrl: '/files/emoji/nature_161.png',
  },
  {
    name: 'nature_162',
    short_names: ['maximum_nature_162'],
    imageUrl: '/files/emoji/nature_162.png',
  },
  {
    name: 'nature_163',
    short_names: ['maximum_nature_163'],
    imageUrl: '/files/emoji/nature_163.png',
  },
  {
    name: 'nature_164',
    short_names: ['maximum_nature_164'],
    imageUrl: '/files/emoji/nature_164.png',
  },
  {
    name: 'nature_165',
    short_names: ['maximum_nature_165'],
    imageUrl: '/files/emoji/nature_165.png',
  },
  {
    name: 'nature_166',
    short_names: ['maximum_nature_166'],
    imageUrl: '/files/emoji/nature_166.png',
  },
  {
    name: 'nature_167',
    short_names: ['maximum_nature_167'],
    imageUrl: '/files/emoji/nature_167.png',
  },
  {
    name: 'nature_168',
    short_names: ['maximum_nature_168'],
    imageUrl: '/files/emoji/nature_168.png',
  },
  {
    name: 'objects_1',
    short_names: ['maximum_objects1'],
    imageUrl: '/files/emoji/objects_1.png',
  },
  {
    name: 'objects_2',
    short_names: ['maximum_objects2'],
    imageUrl: '/files/emoji/objects_2.png',
  },
  {
    name: 'objects_3',
    short_names: ['maximum_objects3'],
    imageUrl: '/files/emoji/objects_3.png',
  },
  {
    name: 'objects_4',
    short_names: ['maximum_objects4'],
    imageUrl: '/files/emoji/objects_4.png',
  },
  {
    name: 'objects_5',
    short_names: ['maximum_objects5'],
    imageUrl: '/files/emoji/objects_5.png',
  },
  {
    name: 'objects_6',
    short_names: ['maximum_objects6'],
    imageUrl: '/files/emoji/objects_6.png',
  },
  {
    name: 'objects_7',
    short_names: ['maximum_objects7'],
    imageUrl: '/files/emoji/objects_7.png',
  },
  {
    name: 'objects_8',
    short_names: ['maximum_objects8'],
    imageUrl: '/files/emoji/objects_8.png',
  },
  {
    name: 'objects_9',
    short_names: ['maximum_objects9'],
    imageUrl: '/files/emoji/objects_9.png',
  },
  {
    name: 'objects_10',
    short_names: ['maximum_objects10'],
    imageUrl: '/files/emoji/objects_10.png',
  },
  {
    name: 'objects_11',
    short_names: ['maximum_objects11'],
    imageUrl: '/files/emoji/objects_11.png',
  },
  {
    name: 'objects_12',
    short_names: ['maximum_objects12'],
    imageUrl: '/files/emoji/objects_12.png',
  },
  {
    name: 'objects_13',
    short_names: ['maximum_objects13'],
    imageUrl: '/files/emoji/objects_13.png',
  },
  {
    name: 'objects_14',
    short_names: ['maximum_objects14'],
    imageUrl: '/files/emoji/objects_14.png',
  },
  {
    name: 'objects_15',
    short_names: ['maximum_objects15'],
    imageUrl: '/files/emoji/objects_15.png',
  },
  {
    name: 'objects_16',
    short_names: ['maximum_objects16'],
    imageUrl: '/files/emoji/objects_16.png',
  },
  {
    name: 'objects_17',
    short_names: ['maximum_objects17'],
    imageUrl: '/files/emoji/objects_17.png',
  },
  {
    name: 'objects_18',
    short_names: ['maximum_objects18'],
    imageUrl: '/files/emoji/objects_18.png',
  },
  {
    name: 'objects_19',
    short_names: ['maximum_objects19'],
    imageUrl: '/files/emoji/objects_19.png',
  },
  {
    name: 'objects_20',
    short_names: ['maximum_objects20'],
    imageUrl: '/files/emoji/objects_20.png',
  },
  {
    name: 'objects_21',
    short_names: ['maximum_objects21'],
    imageUrl: '/files/emoji/objects_21.png',
  },
  {
    name: 'objects_22',
    short_names: ['maximum_objects22'],
    imageUrl: '/files/emoji/objects_22.png',
  },
  {
    name: 'objects_23',
    short_names: ['maximum_objects23'],
    imageUrl: '/files/emoji/objects_23.png',
  },
  {
    name: 'objects_24',
    short_names: ['maximum_objects24'],
    imageUrl: '/files/emoji/objects_24.png',
  },
  {
    name: 'objects_25',
    short_names: ['maximum_objects25'],
    imageUrl: '/files/emoji/objects_25.png',
  },
  {
    name: 'objects_26',
    short_names: ['maximum_objects26'],
    imageUrl: '/files/emoji/objects_26.png',
  },
  {
    name: 'objects_27',
    short_names: ['maximum_objects27'],
    imageUrl: '/files/emoji/objects_27.png',
  },
  {
    name: 'objects_28',
    short_names: ['maximum_objects28'],
    imageUrl: '/files/emoji/objects_28.png',
  },
  {
    name: 'objects_29',
    short_names: ['maximum_objects29'],
    imageUrl: '/files/emoji/objects_29.png',
  },
  {
    name: 'objects_30',
    short_names: ['maximum_objects30'],
    imageUrl: '/files/emoji/objects_30.png',
  },
  {
    name: 'objects_31',
    short_names: ['maximum_objects31'],
    imageUrl: '/files/emoji/objects_31.png',
  },
  {
    name: 'objects_32',
    short_names: ['maximum_objects32'],
    imageUrl: '/files/emoji/objects_32.png',
  },
  {
    name: 'objects_33',
    short_names: ['maximum_objects33'],
    imageUrl: '/files/emoji/objects_33.png',
  },
  {
    name: 'objects_34',
    short_names: ['maximum_objects34'],
    imageUrl: '/files/emoji/objects_34.png',
  },
  {
    name: 'objects_35',
    short_names: ['maximum_objects35'],
    imageUrl: '/files/emoji/objects_35.png',
  },
  {
    name: 'objects_36',
    short_names: ['maximum_objects36'],
    imageUrl: '/files/emoji/objects_36.png',
  },
  {
    name: 'objects_37',
    short_names: ['maximum_objects37'],
    imageUrl: '/files/emoji/objects_37.png',
  },
  {
    name: 'objects_38',
    short_names: ['maximum_objects38'],
    imageUrl: '/files/emoji/objects_38.png',
  },
  {
    name: 'objects_39',
    short_names: ['maximum_objects39'],
    imageUrl: '/files/emoji/objects_39.png',
  },
  {
    name: 'objects_40',
    short_names: ['maximum_objects40'],
    imageUrl: '/files/emoji/objects_40.png',
  },
  {
    name: 'objects_41',
    short_names: ['maximum_objects41'],
    imageUrl: '/files/emoji/objects_41.png',
  },
  {
    name: 'objects_42',
    short_names: ['maximum_objects42'],
    imageUrl: '/files/emoji/objects_42.png',
  },
  {
    name: 'objects_43',
    short_names: ['maximum_objects43'],
    imageUrl: '/files/emoji/objects_43.png',
  },
  {
    name: 'objects_44',
    short_names: ['maximum_objects44'],
    imageUrl: '/files/emoji/objects_44.png',
  },
  {
    name: 'objects_45',
    short_names: ['maximum_objects45'],
    imageUrl: '/files/emoji/objects_45.png',
  },
  {
    name: 'objects_46',
    short_names: ['maximum_objects46'],
    imageUrl: '/files/emoji/objects_46.png',
  },
  {
    name: 'objects_47',
    short_names: ['maximum_objects47'],
    imageUrl: '/files/emoji/objects_47.png',
  },
  {
    name: 'objects_48',
    short_names: ['maximum_objects48'],
    imageUrl: '/files/emoji/objects_48.png',
  },
  {
    name: 'objects_49',
    short_names: ['maximum_objects49'],
    imageUrl: '/files/emoji/objects_49.png',
  },
  {
    name: 'objects_50',
    short_names: ['maximum_objects50'],
    imageUrl: '/files/emoji/objects_50.png',
  },
  {
    name: 'objects_51',
    short_names: ['maximum_objects51'],
    imageUrl: '/files/emoji/objects_51.png',
  },
  {
    name: 'objects_52',
    short_names: ['maximum_objects52'],
    imageUrl: '/files/emoji/objects_52.png',
  },
  {
    name: 'objects_53',
    short_names: ['maximum_objects53'],
    imageUrl: '/files/emoji/objects_53.png',
  },
  {
    name: 'objects_54',
    short_names: ['maximum_objects54'],
    imageUrl: '/files/emoji/objects_54.png',
  },
  {
    name: 'objects_55',
    short_names: ['maximum_objects55'],
    imageUrl: '/files/emoji/objects_55.png',
  },
  {
    name: 'objects_56',
    short_names: ['maximum_objects56'],
    imageUrl: '/files/emoji/objects_56.png',
  },
  {
    name: 'objects_57',
    short_names: ['maximum_objects57'],
    imageUrl: '/files/emoji/objects_57.png',
  },
  {
    name: 'objects_58',
    short_names: ['maximum_objects58'],
    imageUrl: '/files/emoji/objects_58.png',
  },
  {
    name: 'objects_59',
    short_names: ['maximum_objects59'],
    imageUrl: '/files/emoji/objects_59.png',
  },
  {
    name: 'objects_60',
    short_names: ['maximum_objects60'],
    imageUrl: '/files/emoji/objects_60.png',
  },
  {
    name: 'objects_61',
    short_names: ['maximum_objects61'],
    imageUrl: '/files/emoji/objects_61.png',
  },
  {
    name: 'objects_62',
    short_names: ['maximum_objects62'],
    imageUrl: '/files/emoji/objects_62.png',
  },
  {
    name: 'objects_63',
    short_names: ['maximum_objects63'],
    imageUrl: '/files/emoji/objects_63.png',
  },
  {
    name: 'objects_64',
    short_names: ['maximum_objects64'],
    imageUrl: '/files/emoji/objects_64.png',
  },
  {
    name: 'objects_65',
    short_names: ['maximum_objects65'],
    imageUrl: '/files/emoji/objects_65.png',
  },
  {
    name: 'objects_66',
    short_names: ['maximum_objects66'],
    imageUrl: '/files/emoji/objects_66.png',
  },
  {
    name: 'objects_67',
    short_names: ['maximum_objects67'],
    imageUrl: '/files/emoji/objects_67.png',
  },
  {
    name: 'objects_68',
    short_names: ['maximum_objects68'],
    imageUrl: '/files/emoji/objects_68.png',
  },
  {
    name: 'objects_69',
    short_names: ['maximum_objects69'],
    imageUrl: '/files/emoji/objects_69.png',
  },
  {
    name: 'objects_70',
    short_names: ['maximum_objects70'],
    imageUrl: '/files/emoji/objects_70.png',
  },
  {
    name: 'objects_71',
    short_names: ['maximum_objects71'],
    imageUrl: '/files/emoji/objects_71.png',
  },
  {
    name: 'objects_72',
    short_names: ['maximum_objects72'],
    imageUrl: '/files/emoji/objects_72.png',
  },
  {
    name: 'objects_73',
    short_names: ['maximum_objects73'],
    imageUrl: '/files/emoji/objects_73.png',
  },
  {
    name: 'objects_74',
    short_names: ['maximum_objects74'],
    imageUrl: '/files/emoji/objects_74.png',
  },
  {
    name: 'objects_75',
    short_names: ['maximum_objects75'],
    imageUrl: '/files/emoji/objects_75.png',
  },
  {
    name: 'objects_76',
    short_names: ['maximum_objects76'],
    imageUrl: '/files/emoji/objects_76.png',
  },
  {
    name: 'objects_77',
    short_names: ['maximum_objects77'],
    imageUrl: '/files/emoji/objects_77.png',
  },
  {
    name: 'objects_78',
    short_names: ['maximum_objects78'],
    imageUrl: '/files/emoji/objects_78.png',
  },
  {
    name: 'objects_79',
    short_names: ['maximum_objects79'],
    imageUrl: '/files/emoji/objects_79.png',
  },
  {
    name: 'objects_80',
    short_names: ['maximum_objects80'],
    imageUrl: '/files/emoji/objects_80.png',
  },
  {
    name: 'objects_81',
    short_names: ['maximum_objects81'],
    imageUrl: '/files/emoji/objects_81.png',
  },
  {
    name: 'objects_82',
    short_names: ['maximum_objects82'],
    imageUrl: '/files/emoji/objects_82.png',
  },
  {
    name: 'objects_83',
    short_names: ['maximum_objects83'],
    imageUrl: '/files/emoji/objects_83.png',
  },
  {
    name: 'objects_84',
    short_names: ['maximum_objects84'],
    imageUrl: '/files/emoji/objects_84.png',
  },
  {
    name: 'objects_85',
    short_names: ['maximum_objects85'],
    imageUrl: '/files/emoji/objects_85.png',
  },
  {
    name: 'objects_86',
    short_names: ['maximum_objects86'],
    imageUrl: '/files/emoji/objects_86.png',
  },
  {
    name: 'objects_87',
    short_names: ['maximum_objects87'],
    imageUrl: '/files/emoji/objects_87.png',
  },
  {
    name: 'objects_88',
    short_names: ['maximum_objects88'],
    imageUrl: '/files/emoji/objects_88.png',
  },
  {
    name: 'objects_89',
    short_names: ['maximum_objects89'],
    imageUrl: '/files/emoji/objects_89.png',
  },
  {
    name: 'objects_90',
    short_names: ['maximum_objects90'],
    imageUrl: '/files/emoji/objects_90.png',
  },
  {
    name: 'objects_91',
    short_names: ['maximum_objects91'],
    imageUrl: '/files/emoji/objects_91.png',
  },
  {
    name: 'objects_92',
    short_names: ['maximum_objects92'],
    imageUrl: '/files/emoji/objects_92.png',
  },
  {
    name: 'objects_93',
    short_names: ['maximum_objects93'],
    imageUrl: '/files/emoji/objects_93.png',
  },
  {
    name: 'objects_94',
    short_names: ['maximum_objects94'],
    imageUrl: '/files/emoji/objects_94.png',
  },
  {
    name: 'objects_95',
    short_names: ['maximum_objects95'],
    imageUrl: '/files/emoji/objects_95.png',
  },
  {
    name: 'objects_96',
    short_names: ['maximum_objects96'],
    imageUrl: '/files/emoji/objects_96.png',
  },
  {
    name: 'objects_97',
    short_names: ['maximum_objects97'],
    imageUrl: '/files/emoji/objects_97.png',
  },
  {
    name: 'objects_98',
    short_names: ['maximum_objects98'],
    imageUrl: '/files/emoji/objects_98.png',
  },
  {
    name: 'objects_99',
    short_names: ['maximum_objects99'],
    imageUrl: '/files/emoji/objects_99.png',
  },
  {
    name: 'objects_100',
    short_names: ['maximum_objects100'],
    imageUrl: '/files/emoji/objects_100.png',
  },
  {
    name: 'objects_101',
    short_names: ['maximum_objects101'],
    imageUrl: '/files/emoji/objects_101.png',
  },
  {
    name: 'objects_102',
    short_names: ['maximum_objects102'],
    imageUrl: '/files/emoji/objects_102.png',
  },
  {
    name: 'objects_103',
    short_names: ['maximum_objects103'],
    imageUrl: '/files/emoji/objects_103.png',
  },
  {
    name: 'objects_104',
    short_names: ['maximum_objects104'],
    imageUrl: '/files/emoji/objects_104.png',
  },
  {
    name: 'objects_105',
    short_names: ['maximum_objects105'],
    imageUrl: '/files/emoji/objects_105.png',
  },
  {
    name: 'objects_106',
    short_names: ['maximum_objects106'],
    imageUrl: '/files/emoji/objects_106.png',
  },
  {
    name: 'objects_107',
    short_names: ['maximum_objects107'],
    imageUrl: '/files/emoji/objects_107.png',
  },
  {
    name: 'objects_108',
    short_names: ['maximum_objects108'],
    imageUrl: '/files/emoji/objects_108.png',
  },
  {
    name: 'objects_109',
    short_names: ['maximum_objects109'],
    imageUrl: '/files/emoji/objects_109.png',
  },
  {
    name: 'objects_110',
    short_names: ['maximum_objects110'],
    imageUrl: '/files/emoji/objects_110.png',
  },
  {
    name: 'objects_111',
    short_names: ['maximum_objects111'],
    imageUrl: '/files/emoji/objects_111.png',
  },
  {
    name: 'objects_112',
    short_names: ['maximum_objects112'],
    imageUrl: '/files/emoji/objects_112.png',
  },
  {
    name: 'objects_113',
    short_names: ['maximum_objects113'],
    imageUrl: '/files/emoji/objects_113.png',
  },
  {
    name: 'objects_114',
    short_names: ['maximum_objects114'],
    imageUrl: '/files/emoji/objects_114.png',
  },
  {
    name: 'objects_115',
    short_names: ['maximum_objects115'],
    imageUrl: '/files/emoji/objects_115.png',
  },
  {
    name: 'objects_116',
    short_names: ['maximum_objects116'],
    imageUrl: '/files/emoji/objects_116.png',
  },
  {
    name: 'objects_117',
    short_names: ['maximum_objects117'],
    imageUrl: '/files/emoji/objects_117.png',
  },
  {
    name: 'objects_118',
    short_names: ['maximum_objects118'],
    imageUrl: '/files/emoji/objects_118.png',
  },
  {
    name: 'objects_119',
    short_names: ['maximum_objects119'],
    imageUrl: '/files/emoji/objects_119.png',
  },
  {
    name: 'objects_120',
    short_names: ['maximum_objects120'],
    imageUrl: '/files/emoji/objects_120.png',
  },
  {
    name: 'objects_121',
    short_names: ['maximum_objects121'],
    imageUrl: '/files/emoji/objects_121.png',
  },
  {
    name: 'objects_122',
    short_names: ['maximum_objects122'],
    imageUrl: '/files/emoji/objects_122.png',
  },
  {
    name: 'objects_123',
    short_names: ['maximum_objects123'],
    imageUrl: '/files/emoji/objects_123.png',
  },
  {
    name: 'objects_124',
    short_names: ['maximum_objects124'],
    imageUrl: '/files/emoji/objects_124.png',
  },
  {
    name: 'objects_125',
    short_names: ['maximum_objects125'],
    imageUrl: '/files/emoji/objects_125.png',
  },
  {
    name: 'objects_126',
    short_names: ['maximum_objects126'],
    imageUrl: '/files/emoji/objects_126.png',
  },
  {
    name: 'objects_127',
    short_names: ['maximum_objects127'],
    imageUrl: '/files/emoji/objects_127.png',
  },
  {
    name: 'objects_128',
    short_names: ['maximum_objects128'],
    imageUrl: '/files/emoji/objects_128.png',
  },
  {
    name: 'objects_129',
    short_names: ['maximum_objects129'],
    imageUrl: '/files/emoji/objects_129.png',
  },
  {
    name: 'objects_130',
    short_names: ['maximum_objects130'],
    imageUrl: '/files/emoji/objects_130.png',
  },
  {
    name: 'objects_131',
    short_names: ['maximum_objects131'],
    imageUrl: '/files/emoji/objects_131.png',
  },
  {
    name: 'objects_132',
    short_names: ['maximum_objects132'],
    imageUrl: '/files/emoji/objects_132.png',
  },
  {
    name: 'objects_133',
    short_names: ['maximum_objects133'],
    imageUrl: '/files/emoji/objects_133.png',
  },
  {
    name: 'objects_134',
    short_names: ['maximum_objects134'],
    imageUrl: '/files/emoji/objects_134.png',
  },
  {
    name: 'objects_135',
    short_names: ['maximum_objects135'],
    imageUrl: '/files/emoji/objects_135.png',
  },
  {
    name: 'objects_136',
    short_names: ['maximum_objects136'],
    imageUrl: '/files/emoji/objects_136.png',
  },
  {
    name: 'objects_137',
    short_names: ['maximum_objects137'],
    imageUrl: '/files/emoji/objects_137.png',
  },
  {
    name: 'objects_138',
    short_names: ['maximum_objects138'],
    imageUrl: '/files/emoji/objects_138.png',
  },
  {
    name: 'objects_139',
    short_names: ['maximum_objects139'],
    imageUrl: '/files/emoji/objects_139.png',
  },
  {
    name: 'objects_140',
    short_names: ['maximum_objects140'],
    imageUrl: '/files/emoji/objects_140.png',
  },
  {
    name: 'objects_141',
    short_names: ['maximum_objects141'],
    imageUrl: '/files/emoji/objects_141.png',
  },
  {
    name: 'objects_142',
    short_names: ['maximum_objects142'],
    imageUrl: '/files/emoji/objects_142.png',
  },
  {
    name: 'objects_143',
    short_names: ['maximum_objects143'],
    imageUrl: '/files/emoji/objects_143.png',
  },
  {
    name: 'objects_144',
    short_names: ['maximum_objects144'],
    imageUrl: '/files/emoji/objects_144.png',
  },
  {
    name: 'objects_145',
    short_names: ['maximum_objects145'],
    imageUrl: '/files/emoji/objects_145.png',
  },
  {
    name: 'objects_146',
    short_names: ['maximum_objects146'],
    imageUrl: '/files/emoji/objects_146.png',
  },
  {
    name: 'objects_147',
    short_names: ['maximum_objects147'],
    imageUrl: '/files/emoji/objects_147.png',
  },
  {
    name: 'objects_148',
    short_names: ['maximum_objects148'],
    imageUrl: '/files/emoji/objects_148.png',
  },
  {
    name: 'objects_149',
    short_names: ['maximum_objects149'],
    imageUrl: '/files/emoji/objects_149.png',
  },
  {
    name: 'objects_150',
    short_names: ['maximum_objects150'],
    imageUrl: '/files/emoji/objects_150.png',
  },
  {
    name: 'objects_151',
    short_names: ['maximum_objects151'],
    imageUrl: '/files/emoji/objects_151.png',
  },
  {
    name: 'objects_152',
    short_names: ['maximum_objects152'],
    imageUrl: '/files/emoji/objects_152.png',
  },
  {
    name: 'objects_153',
    short_names: ['maximum_objects153'],
    imageUrl: '/files/emoji/objects_153.png',
  },
  {
    name: 'objects_154',
    short_names: ['maximum_objects154'],
    imageUrl: '/files/emoji/objects_154.png',
  },
  {
    name: 'objects_155',
    short_names: ['maximum_objects155'],
    imageUrl: '/files/emoji/objects_155.png',
  },
  {
    name: 'objects_156',
    short_names: ['maximum_objects156'],
    imageUrl: '/files/emoji/objects_156.png',
  },
  {
    name: 'objects_157',
    short_names: ['maximum_objects157'],
    imageUrl: '/files/emoji/objects_157.png',
  },
  {
    name: 'objects_158',
    short_names: ['maximum_objects158'],
    imageUrl: '/files/emoji/objects_158.png',
  },
  {
    name: 'objects_159',
    short_names: ['maximum_objects159'],
    imageUrl: '/files/emoji/objects_159.png',
  },
  {
    name: 'objects_160',
    short_names: ['maximum_objects160'],
    imageUrl: '/files/emoji/objects_160.png',
  },
  {
    name: 'objects_161',
    short_names: ['maximum_objects161'],
    imageUrl: '/files/emoji/objects_161.png',
  },
  {
    name: 'objects_162',
    short_names: ['maximum_objects162'],
    imageUrl: '/files/emoji/objects_162.png',
  },
  {
    name: 'objects_163',
    short_names: ['maximum_objects163'],
    imageUrl: '/files/emoji/objects_163.png',
  },
  {
    name: 'objects_164',
    short_names: ['maximum_objects164'],
    imageUrl: '/files/emoji/objects_164.png',
  },
  {
    name: 'objects_165',
    short_names: ['maximum_objects165'],
    imageUrl: '/files/emoji/objects_165.png',
  },
  {
    name: 'objects_166',
    short_names: ['maximum_objects166'],
    imageUrl: '/files/emoji/objects_166.png',
  },
  {
    name: 'objects_167',
    short_names: ['maximum_objects167'],
    imageUrl: '/files/emoji/objects_167.png',
  },
  {
    name: 'objects_168',
    short_names: ['maximum_objects168'],
    imageUrl: '/files/emoji/objects_168.png',
  },
  {
    name: 'objects_169',
    short_names: ['maximum_objects169'],
    imageUrl: '/files/emoji/objects_169.png',
  },
  {
    name: 'objects_170',
    short_names: ['maximum_objects170'],
    imageUrl: '/files/emoji/objects_170.png',
  },
  {
    name: 'objects_171',
    short_names: ['maximum_objects171'],
    imageUrl: '/files/emoji/objects_171.png',
  },
  {
    name: 'objects_172',
    short_names: ['maximum_objects172'],
    imageUrl: '/files/emoji/objects_172.png',
  },
  {
    name: 'objects_173',
    short_names: ['maximum_objects173'],
    imageUrl: '/files/emoji/objects_173.png',
  },
  {
    name: 'objects_174',
    short_names: ['maximum_objects174'],
    imageUrl: '/files/emoji/objects_174.png',
  },
  {
    name: 'objects_175',
    short_names: ['maximum_objects175'],
    imageUrl: '/files/emoji/objects_175.png',
  },
  {
    name: 'objects_176',
    short_names: ['maximum_objects176'],
    imageUrl: '/files/emoji/objects_176.png',
  },
  {
    name: 'objects_177',
    short_names: ['maximum_objects177'],
    imageUrl: '/files/emoji/objects_177.png',
  },
  {
    name: 'objects_178',
    short_names: ['maximum_objects178'],
    imageUrl: '/files/emoji/objects_178.png',
  },
  {
    name: 'objects_179',
    short_names: ['maximum_objects179'],
    imageUrl: '/files/emoji/objects_179.png',
  },
  {
    name: 'objects_180',
    short_names: ['maximum_objects180'],
    imageUrl: '/files/emoji/objects_180.png',
  },
  {
    name: 'objects_181',
    short_names: ['maximum_objects181'],
    imageUrl: '/files/emoji/objects_181.png',
  },
  {
    name: 'objects_182',
    short_names: ['maximum_objects182'],
    imageUrl: '/files/emoji/objects_182.png',
  },
  {
    name: 'objects_183',
    short_names: ['maximum_objects183'],
    imageUrl: '/files/emoji/objects_183.png',
  },
  {
    name: 'objects_184',
    short_names: ['maximum_objects184'],
    imageUrl: '/files/emoji/objects_184.png',
  },
  {
    name: 'objects_185',
    short_names: ['maximum_objects185'],
    imageUrl: '/files/emoji/objects_185.png',
  },
  {
    name: 'objects_186',
    short_names: ['maximum_objects186'],
    imageUrl: '/files/emoji/objects_186.png',
  },
  {
    name: 'objects_187',
    short_names: ['maximum_objects187'],
    imageUrl: '/files/emoji/objects_187.png',
  },
  {
    name: 'objects_188',
    short_names: ['maximum_objects188'],
    imageUrl: '/files/emoji/objects_188.png',
  },
  {
    name: 'objects_189',
    short_names: ['maximum_objects189'],
    imageUrl: '/files/emoji/objects_189.png',
  },
  {
    name: 'objects_190',
    short_names: ['maximum_objects190'],
    imageUrl: '/files/emoji/objects_190.png',
  },
  {
    name: 'objects_191',
    short_names: ['maximum_objects191'],
    imageUrl: '/files/emoji/objects_191.png',
  },
  {
    name: 'objects_192',
    short_names: ['maximum_objects192'],
    imageUrl: '/files/emoji/objects_192.png',
  },
  {
    name: 'people_1',
    short_names: ['maximum_people1'],
    imageUrl: '/files/emoji/people_1.png',
  },
  {
    name: 'people_2',
    short_names: ['maximum_people2'],
    imageUrl: '/files/emoji/people_2.png',
  },
  {
    name: 'people_3',
    short_names: ['maximum_people3'],
    imageUrl: '/files/emoji/people_3.png',
  },
  {
    name: 'people_4',
    short_names: ['maximum_people4'],
    imageUrl: '/files/emoji/people_4.png',
  },
  {
    name: 'people_5',
    short_names: ['maximum_people5'],
    imageUrl: '/files/emoji/people_5.png',
  },
  {
    name: 'people_6',
    short_names: ['maximum_people6'],
    imageUrl: '/files/emoji/people_6.png',
  },
  {
    name: 'people_7',
    short_names: ['maximum_people7'],
    imageUrl: '/files/emoji/people_7.png',
  },
  {
    name: 'people_8',
    short_names: ['maximum_people8'],
    imageUrl: '/files/emoji/people_8.png',
  },
  {
    name: 'people_9',
    short_names: ['maximum_people9'],
    imageUrl: '/files/emoji/people_9.png',
  },
  {
    name: 'people_10',
    short_names: ['maximum_people10'],
    imageUrl: '/files/emoji/people_10.png',
  },
  {
    name: 'people_11',
    short_names: ['maximum_people11'],
    imageUrl: '/files/emoji/people_11.png',
  },
  {
    name: 'people_12',
    short_names: ['maximum_people12'],
    imageUrl: '/files/emoji/people_12.png',
  },
  {
    name: 'people_13',
    short_names: ['maximum_people13'],
    imageUrl: '/files/emoji/people_13.png',
  },
  {
    name: 'people_14',
    short_names: ['maximum_people14'],
    imageUrl: '/files/emoji/people_14.png',
  },
  {
    name: 'people_15',
    short_names: ['maximum_people15'],
    imageUrl: '/files/emoji/people_15.png',
  },
  {
    name: 'people_16',
    short_names: ['maximum_people16'],
    imageUrl: '/files/emoji/people_16.png',
  },
  {
    name: 'people_17',
    short_names: ['maximum_people17'],
    imageUrl: '/files/emoji/people_17.png',
  },
  {
    name: 'people_18',
    short_names: ['maximum_people18'],
    imageUrl: '/files/emoji/people_18.png',
  },
  {
    name: 'people_19',
    short_names: ['maximum_people19'],
    imageUrl: '/files/emoji/people_19.png',
  },
  {
    name: 'people_20',
    short_names: ['maximum_people20'],
    imageUrl: '/files/emoji/people_20.png',
  },
  {
    name: 'people_21',
    short_names: ['maximum_people21'],
    imageUrl: '/files/emoji/people_21.png',
  },
  {
    name: 'people_22',
    short_names: ['maximum_people22'],
    imageUrl: '/files/emoji/people_22.png',
  },
  {
    name: 'people_23',
    short_names: ['maximum_people23'],
    imageUrl: '/files/emoji/people_23.png',
  },
  {
    name: 'people_24',
    short_names: ['maximum_people24'],
    imageUrl: '/files/emoji/people_24.png',
  },
  {
    name: 'people_25',
    short_names: ['maximum_people25'],
    imageUrl: '/files/emoji/people_25.png',
  },
  {
    name: 'people_26',
    short_names: ['maximum_people26'],
    imageUrl: '/files/emoji/people_26.png',
  },
  {
    name: 'people_27',
    short_names: ['maximum_people27'],
    imageUrl: '/files/emoji/people_27.png',
  },
  {
    name: 'people_28',
    short_names: ['maximum_people28'],
    imageUrl: '/files/emoji/people_28.png',
  },
  {
    name: 'people_29',
    short_names: ['maximum_people29'],
    imageUrl: '/files/emoji/people_29.png',
  },
  {
    name: 'people_30',
    short_names: ['maximum_people30'],
    imageUrl: '/files/emoji/people_30.png',
  },
  {
    name: 'people_31',
    short_names: ['maximum_people31'],
    imageUrl: '/files/emoji/people_31.png',
  },
  {
    name: 'people_32',
    short_names: ['maximum_people32'],
    imageUrl: '/files/emoji/people_32.png',
  },
  {
    name: 'people_33',
    short_names: ['maximum_people33'],
    imageUrl: '/files/emoji/people_33.png',
  },
  {
    name: 'people_34',
    short_names: ['maximum_people34'],
    imageUrl: '/files/emoji/people_34.png',
  },
  {
    name: 'people_35',
    short_names: ['maximum_people35'],
    imageUrl: '/files/emoji/people_35.png',
  },
  {
    name: 'people_36',
    short_names: ['maximum_people36'],
    imageUrl: '/files/emoji/people_36.png',
  },
  {
    name: 'people_37',
    short_names: ['maximum_people37'],
    imageUrl: '/files/emoji/people_37.png',
  },
  {
    name: 'people_38',
    short_names: ['maximum_people38'],
    imageUrl: '/files/emoji/people_38.png',
  },
  {
    name: 'people_39',
    short_names: ['maximum_people39'],
    imageUrl: '/files/emoji/people_39.png',
  },
  {
    name: 'people_40',
    short_names: ['maximum_people40'],
    imageUrl: '/files/emoji/people_40.png',
  },
  {
    name: 'people_41',
    short_names: ['maximum_people41'],
    imageUrl: '/files/emoji/people_41.png',
  },
  {
    name: 'people_42',
    short_names: ['maximum_people42'],
    imageUrl: '/files/emoji/people_42.png',
  },
  {
    name: 'people_43',
    short_names: ['maximum_people43'],
    imageUrl: '/files/emoji/people_43.png',
  },
  {
    name: 'people_44',
    short_names: ['maximum_people44'],
    imageUrl: '/files/emoji/people_44.png',
  },
  {
    name: 'people_45',
    short_names: ['maximum_people45'],
    imageUrl: '/files/emoji/people_45.png',
  },
  {
    name: 'people_46',
    short_names: ['maximum_people46'],
    imageUrl: '/files/emoji/people_46.png',
  },
  {
    name: 'people_47',
    short_names: ['maximum_people47'],
    imageUrl: '/files/emoji/people_47.png',
  },
  {
    name: 'people_48',
    short_names: ['maximum_people48'],
    imageUrl: '/files/emoji/people_48.png',
  },
  {
    name: 'people_49',
    short_names: ['maximum_people49'],
    imageUrl: '/files/emoji/people_49.png',
  },
  {
    name: 'people_50',
    short_names: ['maximum_people50'],
    imageUrl: '/files/emoji/people_50.png',
  },
  {
    name: 'people_51',
    short_names: ['maximum_people51'],
    imageUrl: '/files/emoji/people_51.png',
  },
  {
    name: 'people_52',
    short_names: ['maximum_people52'],
    imageUrl: '/files/emoji/people_52.png',
  },
  {
    name: 'people_53',
    short_names: ['maximum_people53'],
    imageUrl: '/files/emoji/people_53.png',
  },
  {
    name: 'people_54',
    short_names: ['maximum_people54'],
    imageUrl: '/files/emoji/people_54.png',
  },
  {
    name: 'people_55',
    short_names: ['maximum_people55'],
    imageUrl: '/files/emoji/people_55.png',
  },
  {
    name: 'people_56',
    short_names: ['maximum_people56'],
    imageUrl: '/files/emoji/people_56.png',
  },
  {
    name: 'people_57',
    short_names: ['maximum_people57'],
    imageUrl: '/files/emoji/people_57.png',
  },
  {
    name: 'people_58',
    short_names: ['maximum_people58'],
    imageUrl: '/files/emoji/people_58.png',
  },
  {
    name: 'people_59',
    short_names: ['maximum_people59'],
    imageUrl: '/files/emoji/people_59.png',
  },
  {
    name: 'people_60',
    short_names: ['maximum_people60'],
    imageUrl: '/files/emoji/people_60.png',
  },
  {
    name: 'people_61',
    short_names: ['maximum_people61'],
    imageUrl: '/files/emoji/people_61.png',
  },
  {
    name: 'people_62',
    short_names: ['maximum_people62'],
    imageUrl: '/files/emoji/people_62.png',
  },
  {
    name: 'people_63',
    short_names: ['maximum_people63'],
    imageUrl: '/files/emoji/people_63.png',
  },
  {
    name: 'people_64',
    short_names: ['maximum_people64'],
    imageUrl: '/files/emoji/people_64.png',
  },
  {
    name: 'people_65',
    short_names: ['maximum_people65'],
    imageUrl: '/files/emoji/people_65.png',
  },
  {
    name: 'people_66',
    short_names: ['maximum_people66'],
    imageUrl: '/files/emoji/people_66.png',
  },
  {
    name: 'people_67',
    short_names: ['maximum_people67'],
    imageUrl: '/files/emoji/people_67.png',
  },
  {
    name: 'people_68',
    short_names: ['maximum_people68'],
    imageUrl: '/files/emoji/people_68.png',
  },
  {
    name: 'people_69',
    short_names: ['maximum_people69'],
    imageUrl: '/files/emoji/people_69.png',
  },
  {
    name: 'people_70',
    short_names: ['maximum_people70'],
    imageUrl: '/files/emoji/people_70.png',
  },
  {
    name: 'people_71',
    short_names: ['maximum_people71'],
    imageUrl: '/files/emoji/people_71.png',
  },
  {
    name: 'people_72',
    short_names: ['maximum_people72'],
    imageUrl: '/files/emoji/people_72.png',
  },
  {
    name: 'people_73',
    short_names: ['maximum_people73'],
    imageUrl: '/files/emoji/people_73.png',
  },
  {
    name: 'people_74',
    short_names: ['maximum_people74'],
    imageUrl: '/files/emoji/people_74.png',
  },
  {
    name: 'people_75',
    short_names: ['maximum_people75'],
    imageUrl: '/files/emoji/people_75.png',
  },
  {
    name: 'people_76',
    short_names: ['maximum_people76'],
    imageUrl: '/files/emoji/people_76.png',
  },
  {
    name: 'people_77',
    short_names: ['maximum_people77'],
    imageUrl: '/files/emoji/people_77.png',
  },
  {
    name: 'people_78',
    short_names: ['maximum_people78'],
    imageUrl: '/files/emoji/people_78.png',
  },
  {
    name: 'people_79',
    short_names: ['maximum_people79'],
    imageUrl: '/files/emoji/people_79.png',
  },
  {
    name: 'people_80',
    short_names: ['maximum_people80'],
    imageUrl: '/files/emoji/people_80.png',
  },
  {
    name: 'people_81',
    short_names: ['maximum_people81'],
    imageUrl: '/files/emoji/people_81.png',
  },
  {
    name: 'people_82',
    short_names: ['maximum_people82'],
    imageUrl: '/files/emoji/people_82.png',
  },
  {
    name: 'people_83',
    short_names: ['maximum_people83'],
    imageUrl: '/files/emoji/people_83.png',
  },
  {
    name: 'people_84',
    short_names: ['maximum_people84'],
    imageUrl: '/files/emoji/people_84.png',
  },
  {
    name: 'people_85',
    short_names: ['maximum_people85'],
    imageUrl: '/files/emoji/people_85.png',
  },
  {
    name: 'people_86',
    short_names: ['maximum_people86'],
    imageUrl: '/files/emoji/people_86.png',
  },
  {
    name: 'people_87',
    short_names: ['maximum_people87'],
    imageUrl: '/files/emoji/people_87.png',
  },
  {
    name: 'people_88',
    short_names: ['maximum_people88'],
    imageUrl: '/files/emoji/people_88.png',
  },
  {
    name: 'people_89',
    short_names: ['maximum_people89'],
    imageUrl: '/files/emoji/people_89.png',
  },
  {
    name: 'people_90',
    short_names: ['maximum_people90'],
    imageUrl: '/files/emoji/people_90.png',
  },
  {
    name: 'people_91',
    short_names: ['maximum_people91'],
    imageUrl: '/files/emoji/people_91.png',
  },
  {
    name: 'people_92',
    short_names: ['maximum_people92'],
    imageUrl: '/files/emoji/people_92.png',
  },
  {
    name: 'people_93',
    short_names: ['maximum_people93'],
    imageUrl: '/files/emoji/people_93.png',
  },
  {
    name: 'people_94',
    short_names: ['maximum_people94'],
    imageUrl: '/files/emoji/people_94.png',
  },
  {
    name: 'people_95',
    short_names: ['maximum_people95'],
    imageUrl: '/files/emoji/people_95.png',
  },
  {
    name: 'people_96',
    short_names: ['maximum_people96'],
    imageUrl: '/files/emoji/people_96.png',
  },
  {
    name: 'people_97',
    short_names: ['maximum_people97'],
    imageUrl: '/files/emoji/people_97.png',
  },
  {
    name: 'people_98',
    short_names: ['maximum_people98'],
    imageUrl: '/files/emoji/people_98.png',
  },
  {
    name: 'people_99',
    short_names: ['maximum_people99'],
    imageUrl: '/files/emoji/people_99.png',
  },
  {
    name: 'people_100',
    short_names: ['maximum_people100'],
    imageUrl: '/files/emoji/people_100.png',
  },
  {
    name: 'people_101',
    short_names: ['maximum_people101'],
    imageUrl: '/files/emoji/people_101.png',
  },
  {
    name: 'people_102',
    short_names: ['maximum_people102'],
    imageUrl: '/files/emoji/people_102.png',
  },
  {
    name: 'people_103',
    short_names: ['maximum_people103'],
    imageUrl: '/files/emoji/people_103.png',
  },
  {
    name: 'people_104',
    short_names: ['maximum_people104'],
    imageUrl: '/files/emoji/people_104.png',
  },
  {
    name: 'people_105',
    short_names: ['maximum_people105'],
    imageUrl: '/files/emoji/people_105.png',
  },
  {
    name: 'people_106',
    short_names: ['maximum_people106'],
    imageUrl: '/files/emoji/people_106.png',
  },
  {
    name: 'people_107',
    short_names: ['maximum_people107'],
    imageUrl: '/files/emoji/people_107.png',
  },
  {
    name: 'people_108',
    short_names: ['maximum_people108'],
    imageUrl: '/files/emoji/people_108.png',
  },
  {
    name: 'people_109',
    short_names: ['maximum_people109'],
    imageUrl: '/files/emoji/people_109.png',
  },
  {
    name: 'people_110',
    short_names: ['maximum_people110'],
    imageUrl: '/files/emoji/people_110.png',
  },
  {
    name: 'people_111',
    short_names: ['maximum_people111'],
    imageUrl: '/files/emoji/people_111.png',
  },
  {
    name: 'people_112',
    short_names: ['maximum_people112'],
    imageUrl: '/files/emoji/people_112.png',
  },
  {
    name: 'people_113',
    short_names: ['maximum_people113'],
    imageUrl: '/files/emoji/people_113.png',
  },
  {
    name: 'people_114',
    short_names: ['maximum_people114'],
    imageUrl: '/files/emoji/people_114.png',
  },
  {
    name: 'people_115',
    short_names: ['maximum_people115'],
    imageUrl: '/files/emoji/people_115.png',
  },
  {
    name: 'people_116',
    short_names: ['maximum_people116'],
    imageUrl: '/files/emoji/people_116.png',
  },
  {
    name: 'people_117',
    short_names: ['maximum_people117'],
    imageUrl: '/files/emoji/people_117.png',
  },
  {
    name: 'people_118',
    short_names: ['maximum_people118'],
    imageUrl: '/files/emoji/people_118.png',
  },
  {
    name: 'people_119',
    short_names: ['maximum_people119'],
    imageUrl: '/files/emoji/people_119.png',
  },
  {
    name: 'people_120',
    short_names: ['maximum_people120'],
    imageUrl: '/files/emoji/people_120.png',
  },
  {
    name: 'people_121',
    short_names: ['maximum_people121'],
    imageUrl: '/files/emoji/people_121.png',
  },
  {
    name: 'people_122',
    short_names: ['maximum_people122'],
    imageUrl: '/files/emoji/people_122.png',
  },
  {
    name: 'people_123',
    short_names: ['maximum_people123'],
    imageUrl: '/files/emoji/people_123.png',
  },
  {
    name: 'people_124',
    short_names: ['maximum_people124'],
    imageUrl: '/files/emoji/people_124.png',
  },
  {
    name: 'people_125',
    short_names: ['maximum_people125'],
    imageUrl: '/files/emoji/people_125.png',
  },
  {
    name: 'people_126',
    short_names: ['maximum_people126'],
    imageUrl: '/files/emoji/people_126.png',
  },
  {
    name: 'people_127',
    short_names: ['maximum_people127'],
    imageUrl: '/files/emoji/people_127.png',
  },
  {
    name: 'people_128',
    short_names: ['maximum_people128'],
    imageUrl: '/files/emoji/people_128.png',
  },
  {
    name: 'people_129',
    short_names: ['maximum_people129'],
    imageUrl: '/files/emoji/people_129.png',
  },
  {
    name: 'people_130',
    short_names: ['maximum_people130'],
    imageUrl: '/files/emoji/people_130.png',
  },
  {
    name: 'people_131',
    short_names: ['maximum_people131'],
    imageUrl: '/files/emoji/people_131.png',
  },
  {
    name: 'people_132',
    short_names: ['maximum_people132'],
    imageUrl: '/files/emoji/people_132.png',
  },
  {
    name: 'people_133',
    short_names: ['maximum_people133'],
    imageUrl: '/files/emoji/people_133.png',
  },
  {
    name: 'people_134',
    short_names: ['maximum_people134'],
    imageUrl: '/files/emoji/people_134.png',
  },
  {
    name: 'people_135',
    short_names: ['maximum_people135'],
    imageUrl: '/files/emoji/people_135.png',
  },
  {
    name: 'people_136',
    short_names: ['maximum_people136'],
    imageUrl: '/files/emoji/people_136.png',
  },
  {
    name: 'people_137',
    short_names: ['maximum_people137'],
    imageUrl: '/files/emoji/people_137.png',
  },
  {
    name: 'people_138',
    short_names: ['maximum_people138'],
    imageUrl: '/files/emoji/people_138.png',
  },
  {
    name: 'people_139',
    short_names: ['maximum_people139'],
    imageUrl: '/files/emoji/people_139.png',
  },
  {
    name: 'people_140',
    short_names: ['maximum_people140'],
    imageUrl: '/files/emoji/people_140.png',
  },
  {
    name: 'people_141',
    short_names: ['maximum_people141'],
    imageUrl: '/files/emoji/people_141.png',
  },
  {
    name: 'people_142',
    short_names: ['maximum_people142'],
    imageUrl: '/files/emoji/people_142.png',
  },
  {
    name: 'people_143',
    short_names: ['maximum_people143'],
    imageUrl: '/files/emoji/people_143.png',
  },
  {
    name: 'people_144',
    short_names: ['maximum_people144'],
    imageUrl: '/files/emoji/people_144.png',
  },
  {
    name: 'people_145',
    short_names: ['maximum_people145'],
    imageUrl: '/files/emoji/people_145.png',
  },
  {
    name: 'people_146',
    short_names: ['maximum_people146'],
    imageUrl: '/files/emoji/people_146.png',
  },
  {
    name: 'people_147',
    short_names: ['maximum_people147'],
    imageUrl: '/files/emoji/people_147.png',
  },
  {
    name: 'people_148',
    short_names: ['maximum_people148'],
    imageUrl: '/files/emoji/people_148.png',
  },
  {
    name: 'people_149',
    short_names: ['maximum_people149'],
    imageUrl: '/files/emoji/people_149.png',
  },
  {
    name: 'people_150',
    short_names: ['maximum_people150'],
    imageUrl: '/files/emoji/people_150.png',
  },
  {
    name: 'people_151',
    short_names: ['maximum_people151'],
    imageUrl: '/files/emoji/people_151.png',
  },
  {
    name: 'people_152',
    short_names: ['maximum_people152'],
    imageUrl: '/files/emoji/people_152.png',
  },
  {
    name: 'people_153',
    short_names: ['maximum_people153'],
    imageUrl: '/files/emoji/people_153.png',
  },
  {
    name: 'people_154',
    short_names: ['maximum_people154'],
    imageUrl: '/files/emoji/people_154.png',
  },
  {
    name: 'people_155',
    short_names: ['maximum_people155'],
    imageUrl: '/files/emoji/people_155.png',
  },
  {
    name: 'people_156',
    short_names: ['maximum_people156'],
    imageUrl: '/files/emoji/people_156.png',
  },
  {
    name: 'people_157',
    short_names: ['maximum_people157'],
    imageUrl: '/files/emoji/people_157.png',
  },
  {
    name: 'people_158',
    short_names: ['maximum_people158'],
    imageUrl: '/files/emoji/people_158.png',
  },
  {
    name: 'people_159',
    short_names: ['maximum_people159'],
    imageUrl: '/files/emoji/people_159.png',
  },
  {
    name: 'people_160',
    short_names: ['maximum_people160'],
    imageUrl: '/files/emoji/people_160.png',
  },
  {
    name: 'people_161',
    short_names: ['maximum_people161'],
    imageUrl: '/files/emoji/people_161.png',
  },
  {
    name: 'people_162',
    short_names: ['maximum_people162'],
    imageUrl: '/files/emoji/people_162.png',
  },
  {
    name: 'people_163',
    short_names: ['maximum_people163'],
    imageUrl: '/files/emoji/people_163.png',
  },
  {
    name: 'people_164',
    short_names: ['maximum_people164'],
    imageUrl: '/files/emoji/people_164.png',
  },
  {
    name: 'people_165',
    short_names: ['maximum_people165'],
    imageUrl: '/files/emoji/people_165.png',
  },
  {
    name: 'people_166',
    short_names: ['maximum_people166'],
    imageUrl: '/files/emoji/people_166.png',
  },
  {
    name: 'people_167',
    short_names: ['maximum_people167'],
    imageUrl: '/files/emoji/people_167.png',
  },
  {
    name: 'people_168',
    short_names: ['maximum_people168'],
    imageUrl: '/files/emoji/people_168.png',
  },
  {
    name: 'people_169',
    short_names: ['maximum_people169'],
    imageUrl: '/files/emoji/people_169.png',
  },
  {
    name: 'people_170',
    short_names: ['maximum_people170'],
    imageUrl: '/files/emoji/people_170.png',
  },
  {
    name: 'people_171',
    short_names: ['maximum_people171'],
    imageUrl: '/files/emoji/people_171.png',
  },
  {
    name: 'people_172',
    short_names: ['maximum_people172'],
    imageUrl: '/files/emoji/people_172.png',
  },
  {
    name: 'people_173',
    short_names: ['maximum_people173'],
    imageUrl: '/files/emoji/people_173.png',
  },
  {
    name: 'smile_1',
    short_names: ['maximum_smile1'],
    imageUrl: '/files/emoji/smile_1.png',
  },
  {
    name: 'smile_2',
    short_names: ['maximum_smile2'],
    imageUrl: '/files/emoji/smile_2.png',
  },
  {
    name: 'smile_3',
    short_names: ['maximum_smile3'],
    imageUrl: '/files/emoji/smile_3.png',
  },
  {
    name: 'smile_4',
    short_names: ['maximum_smile4'],
    imageUrl: '/files/emoji/smile_4.png',
  },
  {
    name: 'smile_5',
    short_names: ['maximum_smile5'],
    imageUrl: '/files/emoji/smile_5.png',
  },
  {
    name: 'smile_6',
    short_names: ['maximum_smile6'],
    imageUrl: '/files/emoji/smile_6.png',
  },
  {
    name: 'smile_7',
    short_names: ['maximum_smile7'],
    imageUrl: '/files/emoji/smile_7.png',
  },
  {
    name: 'smile_8',
    short_names: ['maximum_smile8'],
    imageUrl: '/files/emoji/smile_8.png',
  },
  {
    name: 'smile_9',
    short_names: ['maximum_smile9'],
    imageUrl: '/files/emoji/smile_9.png',
  },
  {
    name: 'smile_10',
    short_names: ['maximum_smile10'],
    imageUrl: '/files/emoji/smile_10.png',
  },
  {
    name: 'smile_11',
    short_names: ['maximum_smile11'],
    imageUrl: '/files/emoji/smile_11.png',
  },
  {
    name: 'smile_12',
    short_names: ['maximum_smile12'],
    imageUrl: '/files/emoji/smile_12.png',
  },
  {
    name: 'smile_13',
    short_names: ['maximum_smile13'],
    imageUrl: '/files/emoji/smile_13.png',
  },
  {
    name: 'smile_14',
    short_names: ['maximum_smile14'],
    imageUrl: '/files/emoji/smile_14.png',
  },
  {
    name: 'smile_15',
    short_names: ['maximum_smile15'],
    imageUrl: '/files/emoji/smile_15.png',
  },
  {
    name: 'smile_16',
    short_names: ['maximum_smile16'],
    imageUrl: '/files/emoji/smile_16.png',
  },
  {
    name: 'smile_17',
    short_names: ['maximum_smile17'],
    imageUrl: '/files/emoji/smile_17.png',
  },
  {
    name: 'smile_18',
    short_names: ['maximum_smile18'],
    imageUrl: '/files/emoji/smile_18.png',
  },
  {
    name: 'smile_19',
    short_names: ['maximum_smile19'],
    imageUrl: '/files/emoji/smile_19.png',
  },
  {
    name: 'smile_20',
    short_names: ['maximum_smile20'],
    imageUrl: '/files/emoji/smile_20.png',
  },
  {
    name: 'smile_21',
    short_names: ['maximum_smile21'],
    imageUrl: '/files/emoji/smile_21.png',
  },
  {
    name: 'smile_22',
    short_names: ['maximum_smile22'],
    imageUrl: '/files/emoji/smile_22.png',
  },
  {
    name: 'smile_23',
    short_names: ['maximum_smile23'],
    imageUrl: '/files/emoji/smile_23.png',
  },
  {
    name: 'smile_24',
    short_names: ['maximum_smile24'],
    imageUrl: '/files/emoji/smile_24.png',
  },
  {
    name: 'smile_25',
    short_names: ['maximum_smile25'],
    imageUrl: '/files/emoji/smile_25.png',
  },
  {
    name: 'smile_26',
    short_names: ['maximum_smile26'],
    imageUrl: '/files/emoji/smile_26.png',
  },
  {
    name: 'smile_27',
    short_names: ['maximum_smile27'],
    imageUrl: '/files/emoji/smile_27.png',
  },
  {
    name: 'smile_28',
    short_names: ['maximum_smile28'],
    imageUrl: '/files/emoji/smile_28.png',
  },
  {
    name: 'smile_29',
    short_names: ['maximum_smile29'],
    imageUrl: '/files/emoji/smile_29.png',
  },
  {
    name: 'smile_30',
    short_names: ['maximum_smile30'],
    imageUrl: '/files/emoji/smile_30.png',
  },
  {
    name: 'smile_31',
    short_names: ['maximum_smile31'],
    imageUrl: '/files/emoji/smile_31.png',
  },
  {
    name: 'smile_32',
    short_names: ['maximum_smile32'],
    imageUrl: '/files/emoji/smile_32.png',
  },
  {
    name: 'smile_33',
    short_names: ['maximum_smile33'],
    imageUrl: '/files/emoji/smile_33.png',
  },
  {
    name: 'smile_34',
    short_names: ['maximum_smile34'],
    imageUrl: '/files/emoji/smile_34.png',
  },
  {
    name: 'smile_35',
    short_names: ['maximum_smile35'],
    imageUrl: '/files/emoji/smile_35.png',
  },
  {
    name: 'smile_36',
    short_names: ['maximum_smile36'],
    imageUrl: '/files/emoji/smile_36.png',
  },
  {
    name: 'smile_37',
    short_names: ['maximum_smile37'],
    imageUrl: '/files/emoji/smile_37.png',
  },
  {
    name: 'smile_38',
    short_names: ['maximum_smile38'],
    imageUrl: '/files/emoji/smile_38.png',
  },
  {
    name: 'smile_39',
    short_names: ['maximum_smile39'],
    imageUrl: '/files/emoji/smile_39.png',
  },
  {
    name: 'smile_40',
    short_names: ['maximum_smile40'],
    imageUrl: '/files/emoji/smile_40.png',
  },
  {
    name: 'smile_41',
    short_names: ['maximum_smile41'],
    imageUrl: '/files/emoji/smile_41.png',
  },
  {
    name: 'smile_42',
    short_names: ['maximum_smile42'],
    imageUrl: '/files/emoji/smile_42.png',
  },
  {
    name: 'smile_43',
    short_names: ['maximum_smile43'],
    imageUrl: '/files/emoji/smile_43.png',
  },
  {
    name: 'smile_44',
    short_names: ['maximum_smile44'],
    imageUrl: '/files/emoji/smile_44.png',
  },
  {
    name: 'smile_45',
    short_names: ['maximum_smile45'],
    imageUrl: '/files/emoji/smile_45.png',
  },
  {
    name: 'smile_46',
    short_names: ['maximum_smile46'],
    imageUrl: '/files/emoji/smile_46.png',
  },
  {
    name: 'smile_47',
    short_names: ['maximum_smile47'],
    imageUrl: '/files/emoji/smile_47.png',
  },
  {
    name: 'smile_48',
    short_names: ['maximum_smile48'],
    imageUrl: '/files/emoji/smile_48.png',
  },
  {
    name: 'smile_49',
    short_names: ['maximum_smile49'],
    imageUrl: '/files/emoji/smile_49.png',
  },
  {
    name: 'smile_50',
    short_names: ['maximum_smile50'],
    imageUrl: '/files/emoji/smile_50.png',
  },
  {
    name: 'smile_51',
    short_names: ['maximum_smile51'],
    imageUrl: '/files/emoji/smile_51.png',
  },
  {
    name: 'smile_52',
    short_names: ['maximum_smile52'],
    imageUrl: '/files/emoji/smile_52.png',
  },
  {
    name: 'smile_53',
    short_names: ['maximum_smile53'],
    imageUrl: '/files/emoji/smile_53.png',
  },
  {
    name: 'smile_54',
    short_names: ['maximum_smile54'],
    imageUrl: '/files/emoji/smile_54.png',
  },
  {
    name: 'smile_55',
    short_names: ['maximum_smile55'],
    imageUrl: '/files/emoji/smile_55.png',
  },
  {
    name: 'smile_56',
    short_names: ['maximum_smile56'],
    imageUrl: '/files/emoji/smile_56.png',
  },
  {
    name: 'smile_57',
    short_names: ['maximum_smile57'],
    imageUrl: '/files/emoji/smile_57.png',
  },
  {
    name: 'smile_58',
    short_names: ['maximum_smile58'],
    imageUrl: '/files/emoji/smile_58.png',
  },
  {
    name: 'smile_59',
    short_names: ['maximum_smile59'],
    imageUrl: '/files/emoji/smile_59.png',
  },
  {
    name: 'smile_60',
    short_names: ['maximum_smile60'],
    imageUrl: '/files/emoji/smile_60.png',
  },
  {
    name: 'smile_61',
    short_names: ['maximum_smile61'],
    imageUrl: '/files/emoji/smile_61.png',
  },
  {
    name: 'smile_62',
    short_names: ['maximum_smile62'],
    imageUrl: '/files/emoji/smile_62.png',
  },
  {
    name: 'smile_63',
    short_names: ['maximum_smile63'],
    imageUrl: '/files/emoji/smile_63.png',
  },
  {
    name: 'smile_64',
    short_names: ['maximum_smile64'],
    imageUrl: '/files/emoji/smile_64.png',
  },
  {
    name: 'smile_65',
    short_names: ['maximum_smile65'],
    imageUrl: '/files/emoji/smile_65.png',
  },
  {
    name: 'smile_66',
    short_names: ['maximum_smile66'],
    imageUrl: '/files/emoji/smile_66.png',
  },
  {
    name: 'smile_67',
    short_names: ['maximum_smile67'],
    imageUrl: '/files/emoji/smile_67.png',
  },
  {
    name: 'smile_68',
    short_names: ['maximum_smile68'],
    imageUrl: '/files/emoji/smile_68.png',
  },
  {
    name: 'smile_69',
    short_names: ['maximum_smile69'],
    imageUrl: '/files/emoji/smile_69.png',
  },
  {
    name: 'smile_70',
    short_names: ['maximum_smile70'],
    imageUrl: '/files/emoji/smile_70.png',
  },
  {
    name: 'smile_71',
    short_names: ['maximum_smile71'],
    imageUrl: '/files/emoji/smile_71.png',
  },
  {
    name: 'smile_72',
    short_names: ['maximum_smile72'],
    imageUrl: '/files/emoji/smile_72.png',
  },
  {
    name: 'smile_73',
    short_names: ['maximum_smile73'],
    imageUrl: '/files/emoji/smile_73.png',
  },
  {
    name: 'smile_74',
    short_names: ['maximum_smile74'],
    imageUrl: '/files/emoji/smile_74.png',
  },
  {
    name: 'smile_75',
    short_names: ['maximum_smile75'],
    imageUrl: '/files/emoji/smile_75.png',
  },
  {
    name: 'smile_76',
    short_names: ['maximum_smile76'],
    imageUrl: '/files/emoji/smile_76.png',
  },
  {
    name: 'smile_77',
    short_names: ['maximum_smile77'],
    imageUrl: '/files/emoji/smile_77.png',
  },
  {
    name: 'smile_78',
    short_names: ['maximum_smile78'],
    imageUrl: '/files/emoji/smile_78.png',
  },
  {
    name: 'smile_79',
    short_names: ['maximum_smile79'],
    imageUrl: '/files/emoji/smile_79.png',
  },
  {
    name: 'smile_80',
    short_names: ['maximum_smile80'],
    imageUrl: '/files/emoji/smile_80.png',
  },
  {
    name: 'smile_81',
    short_names: ['maximum_smile81'],
    imageUrl: '/files/emoji/smile_81.png',
  },
  {
    name: 'smile_82',
    short_names: ['maximum_smile82'],
    imageUrl: '/files/emoji/smile_82.png',
  },
  {
    name: 'smile_83',
    short_names: ['maximum_smile83'],
    imageUrl: '/files/emoji/smile_83.png',
  },
  {
    name: 'smile_84',
    short_names: ['maximum_smile84'],
    imageUrl: '/files/emoji/smile_84.png',
  },
  {
    name: 'smile_85',
    short_names: ['maximum_smile85'],
    imageUrl: '/files/emoji/smile_85.png',
  },
  {
    name: 'smile_86',
    short_names: ['maximum_smile86'],
    imageUrl: '/files/emoji/smile_86.png',
  },
  {
    name: 'smile_87',
    short_names: ['maximum_smile87'],
    imageUrl: '/files/emoji/smile_87.png',
  },
  {
    name: 'smile_88',
    short_names: ['maximum_smile88'],
    imageUrl: '/files/emoji/smile_88.png',
  },
  {
    name: 'smile_89',
    short_names: ['maximum_smile89'],
    imageUrl: '/files/emoji/smile_89.png',
  },
  {
    name: 'smile_90',
    short_names: ['maximum_smile90'],
    imageUrl: '/files/emoji/smile_90.png',
  },
  {
    name: 'smile_91',
    short_names: ['maximum_smile91'],
    imageUrl: '/files/emoji/smile_91.png',
  },
  {
    name: 'smile_92',
    short_names: ['maximum_smile92'],
    imageUrl: '/files/emoji/smile_92.png',
  },
  {
    name: 'smile_93',
    short_names: ['maximum_smile93'],
    imageUrl: '/files/emoji/smile_93.png',
  },
  {
    name: 'smile_94',
    short_names: ['maximum_smile94'],
    imageUrl: '/files/emoji/smile_94.png',
  },
  {
    name: 'smile_95',
    short_names: ['maximum_smile95'],
    imageUrl: '/files/emoji/smile_95.png',
  },
  {
    name: 'smile_96',
    short_names: ['maximum_smile96'],
    imageUrl: '/files/emoji/smile_96.png',
  },
  {
    name: 'smile_97',
    short_names: ['maximum_smile97'],
    imageUrl: '/files/emoji/smile_97.png',
  },
  {
    name: 'smile_98',
    short_names: ['maximum_smile98'],
    imageUrl: '/files/emoji/smile_98.png',
  },
  {
    name: 'smile_99',
    short_names: ['maximum_smile99'],
    imageUrl: '/files/emoji/smile_99.png',
  },
  {
    name: 'smile_100',
    short_names: ['maximum_smile100'],
    imageUrl: '/files/emoji/smile_100.png',
  },
  {
    name: 'smile_101',
    short_names: ['maximum_smile101'],
    imageUrl: '/files/emoji/smile_101.png',
  },
  {
    name: 'smile_102',
    short_names: ['maximum_smile102'],
    imageUrl: '/files/emoji/smile_102.png',
  },
  {
    name: 'smile_103',
    short_names: ['maximum_smile103'],
    imageUrl: '/files/emoji/smile_103.png',
  },
  {
    name: 'smile_104',
    short_names: ['maximum_smile104'],
    imageUrl: '/files/emoji/smile_104.png',
  },
  {
    name: 'smile_105',
    short_names: ['maximum_smile105'],
    imageUrl: '/files/emoji/smile_105.png',
  },
  {
    name: 'smile_106',
    short_names: ['maximum_smile106'],
    imageUrl: '/files/emoji/smile_106.png',
  },
  {
    name: 'smile_107',
    short_names: ['maximum_smile107'],
    imageUrl: '/files/emoji/smile_107.png',
  },
  {
    name: 'smile_108',
    short_names: ['maximum_smile108'],
    imageUrl: '/files/emoji/smile_108.png',
  },
  {
    name: 'smile_109',
    short_names: ['maximum_smile109'],
    imageUrl: '/files/emoji/smile_109.png',
  },
  {
    name: 'smile_110',
    short_names: ['maximum_smile110'],
    imageUrl: '/files/emoji/smile_110.png',
  },
  {
    name: 'smile_111',
    short_names: ['maximum_smile111'],
    imageUrl: '/files/emoji/smile_111.png',
  },
  {
    name: 'smile_112',
    short_names: ['maximum_smile112'],
    imageUrl: '/files/emoji/smile_112.png',
  },
  {
    name: 'smile_113',
    short_names: ['maximum_smile113'],
    imageUrl: '/files/emoji/smile_113.png',
  },
  {
    name: 'smile_114',
    short_names: ['maximum_smile114'],
    imageUrl: '/files/emoji/smile_114.png',
  },
  {
    name: 'smile_115',
    short_names: ['maximum_smile115'],
    imageUrl: '/files/emoji/smile_115.png',
  },
  {
    name: 'smile_116',
    short_names: ['maximum_smile116'],
    imageUrl: '/files/emoji/smile_116.png',
  },
  {
    name: 'smile_117',
    short_names: ['maximum_smile117'],
    imageUrl: '/files/emoji/smile_117.png',
  },
  {
    name: 'smile_118',
    short_names: ['maximum_smile118'],
    imageUrl: '/files/emoji/smile_118.png',
  },
  {
    name: 'smile_119',
    short_names: ['maximum_smile119'],
    imageUrl: '/files/emoji/smile_119.png',
  },
  {
    name: 'smile_120',
    short_names: ['maximum_smile120'],
    imageUrl: '/files/emoji/smile_120.png',
  },
  {
    name: 'smile_121',
    short_names: ['maximum_smile121'],
    imageUrl: '/files/emoji/smile_121.png',
  },
  {
    name: 'smile_122',
    short_names: ['maximum_smile122'],
    imageUrl: '/files/emoji/smile_122.png',
  },
  {
    name: 'smile_123',
    short_names: ['maximum_smile123'],
    imageUrl: '/files/emoji/smile_123.png',
  },
  {
    name: 'smile_124',
    short_names: ['maximum_smile124'],
    imageUrl: '/files/emoji/smile_124.png',
  },
  {
    name: 'smile_125',
    short_names: ['maximum_smile125'],
    imageUrl: '/files/emoji/smile_125.png',
  },
  {
    name: 'smile_126',
    short_names: ['maximum_smile126'],
    imageUrl: '/files/emoji/smile_126.png',
  },
  {
    name: 'smile_127',
    short_names: ['maximum_smile127'],
    imageUrl: '/files/emoji/smile_127.png',
  },
  {
    name: 'smile_128',
    short_names: ['maximum_smile128'],
    imageUrl: '/files/emoji/smile_128.png',
  },
  {
    name: 'smile_129',
    short_names: ['maximum_smile129'],
    imageUrl: '/files/emoji/smile_129.png',
  },
  {
    name: 'smile_130',
    short_names: ['maximum_smile130'],
    imageUrl: '/files/emoji/smile_130.png',
  },
  {
    name: 'smile_131',
    short_names: ['maximum_smile131'],
    imageUrl: '/files/emoji/smile_131.png',
  },
  {
    name: 'smile_132',
    short_names: ['maximum_smile132'],
    imageUrl: '/files/emoji/smile_132.png',
  },
  {
    name: 'smile_133',
    short_names: ['maximum_smile133'],
    imageUrl: '/files/emoji/smile_133.png',
  },
  {
    name: 'smile_134',
    short_names: ['maximum_smile134'],
    imageUrl: '/files/emoji/smile_134.png',
  },
  {
    name: 'smile_135',
    short_names: ['maximum_smile135'],
    imageUrl: '/files/emoji/smile_135.png',
  },
  {
    name: 'smile_136',
    short_names: ['maximum_smile136'],
    imageUrl: '/files/emoji/smile_136.png',
  },
  {
    name: 'smile_137',
    short_names: ['maximum_smile137'],
    imageUrl: '/files/emoji/smile_137.png',
  },
  {
    name: 'smile_138',
    short_names: ['maximum_smile138'],
    imageUrl: '/files/emoji/smile_138.png',
  },
  {
    name: 'smile_139',
    short_names: ['maximum_smile139'],
    imageUrl: '/files/emoji/smile_139.png',
  },
  {
    name: 'smile_140',
    short_names: ['maximum_smile140'],
    imageUrl: '/files/emoji/smile_140.png',
  },
  {
    name: 'smile_141',
    short_names: ['maximum_smile141'],
    imageUrl: '/files/emoji/smile_141.png',
  },
  {
    name: 'smile_142',
    short_names: ['maximum_smile142'],
    imageUrl: '/files/emoji/smile_142.png',
  },
  {
    name: 'smile_143',
    short_names: ['maximum_smile143'],
    imageUrl: '/files/emoji/smile_143.png',
  },
  {
    name: 'smile_144',
    short_names: ['maximum_smile144'],
    imageUrl: '/files/emoji/smile_144.png',
  },
  {
    name: 'smile_145',
    short_names: ['maximum_smile145'],
    imageUrl: '/files/emoji/smile_145.png',
  },
  {
    name: 'smile_146',
    short_names: ['maximum_smile146'],
    imageUrl: '/files/emoji/smile_146.png',
  },
  {
    name: 'smile_147',
    short_names: ['maximum_smile147'],
    imageUrl: '/files/emoji/smile_147.png',
  },
  {
    name: 'smile_148',
    short_names: ['maximum_smile148'],
    imageUrl: '/files/emoji/smile_148.png',
  },
  {
    name: 'smile_149',
    short_names: ['maximum_smile149'],
    imageUrl: '/files/emoji/smile_149.png',
  },
  {
    name: 'smile_150',
    short_names: ['maximum_smile150'],
    imageUrl: '/files/emoji/smile_150.png',
  },
  {
    name: 'smile_151',
    short_names: ['maximum_smile151'],
    imageUrl: '/files/emoji/smile_151.png',
  },
  {
    name: 'smile_152',
    short_names: ['maximum_smile152'],
    imageUrl: '/files/emoji/smile_152.png',
  },
  {
    name: 'smile_153',
    short_names: ['maximum_smile153'],
    imageUrl: '/files/emoji/smile_153.png',
  },
  {
    name: 'smile_154',
    short_names: ['maximum_smile154'],
    imageUrl: '/files/emoji/smile_154.png',
  },
  {
    name: 'smile_155',
    short_names: ['maximum_smile155'],
    imageUrl: '/files/emoji/smile_155.png',
  },
  {
    name: 'smile_156',
    short_names: ['maximum_smile156'],
    imageUrl: '/files/emoji/smile_156.png',
  },
  {
    name: 'smile_157',
    short_names: ['maximum_smile157'],
    imageUrl: '/files/emoji/smile_157.png',
  },
  {
    name: 'smile_158',
    short_names: ['maximum_smile158'],
    imageUrl: '/files/emoji/smile_158.png',
  },
  {
    name: 'smile_159',
    short_names: ['maximum_smile159'],
    imageUrl: '/files/emoji/smile_159.png',
  },
  {
    name: 'smile_160',
    short_names: ['maximum_smile160'],
    imageUrl: '/files/emoji/smile_160.png',
  },
  {
    name: 'smile_161',
    short_names: ['maximum_smile161'],
    imageUrl: '/files/emoji/smile_161.png',
  },
  {
    name: 'smile_162',
    short_names: ['maximum_smile162'],
    imageUrl: '/files/emoji/smile_162.png',
  },
  {
    name: 'smile_163',
    short_names: ['maximum_smile163'],
    imageUrl: '/files/emoji/smile_163.png',
  },
  {
    name: 'smile_164',
    short_names: ['maximum_smile164'],
    imageUrl: '/files/emoji/smile_164.png',
  },
  {
    name: 'smile_165',
    short_names: ['maximum_smile165'],
    imageUrl: '/files/emoji/smile_165.png',
  },
  {
    name: 'smile_166',
    short_names: ['maximum_smile166'],
    imageUrl: '/files/emoji/smile_166.png',
  },
  {
    name: 'smile_167',
    short_names: ['maximum_smile167'],
    imageUrl: '/files/emoji/smile_167.png',
  },
  {
    name: 'smile_168',
    short_names: ['maximum_smile168'],
    imageUrl: '/files/emoji/smile_168.png',
  },
  {
    name: 'smile_169',
    short_names: ['maximum_smile169'],
    imageUrl: '/files/emoji/smile_169.png',
  },
  {
    name: 'smile_170',
    short_names: ['maximum_smile170'],
    imageUrl: '/files/emoji/smile_170.png',
  },
  {
    name: 'smile_171',
    short_names: ['maximum_smile171'],
    imageUrl: '/files/emoji/smile_171.png',
  },
  {
    name: 'smile_172',
    short_names: ['maximum_smile172'],
    imageUrl: '/files/emoji/smile_172.png',
  },
  {
    name: 'smile_173',
    short_names: ['maximum_smile173'],
    imageUrl: '/files/emoji/smile_173.png',
  },
  {
    name: 'smile_174',
    short_names: ['maximum_smile174'],
    imageUrl: '/files/emoji/smile_174.png',
  },
  {
    name: 'smile_175',
    short_names: ['maximum_smile175'],
    imageUrl: '/files/emoji/smile_175.png',
  },
  {
    name: 'smile_176',
    short_names: ['maximum_smile176'],
    imageUrl: '/files/emoji/smile_176.png',
  },
  {
    name: 'smile_177',
    short_names: ['maximum_smile177'],
    imageUrl: '/files/emoji/smile_177.png',
  },
  {
    name: 'smile_178',
    short_names: ['maximum_smile178'],
    imageUrl: '/files/emoji/smile_178.png',
  },
  {
    name: 'smile_179',
    short_names: ['maximum_smile179'],
    imageUrl: '/files/emoji/smile_179.png',
  },
  {
    name: 'smile_180',
    short_names: ['maximum_smile180'],
    imageUrl: '/files/emoji/smile_180.png',
  },
  {
    name: 'smile_181',
    short_names: ['maximum_smile181'],
    imageUrl: '/files/emoji/smile_181.png',
  },
  {
    name: 'smile_182',
    short_names: ['maximum_smile182'],
    imageUrl: '/files/emoji/smile_182.png',
  },
  {
    name: 'smile_183',
    short_names: ['maximum_smile183'],
    imageUrl: '/files/emoji/smile_183.png',
  },
  {
    name: 'smile_184',
    short_names: ['maximum_smile184'],
    imageUrl: '/files/emoji/smile_184.png',
  },
  {
    name: 'smile_185',
    short_names: ['maximum_smile185'],
    imageUrl: '/files/emoji/smile_185.png',
  },
  {
    name: 'smile_186',
    short_names: ['maximum_smile186'],
    imageUrl: '/files/emoji/smile_186.png',
  },
  {
    name: 'smile_187',
    short_names: ['maximum_smile187'],
    imageUrl: '/files/emoji/smile_187.png',
  },
  {
    name: 'smile_188',
    short_names: ['maximum_smile188'],
    imageUrl: '/files/emoji/smile_188.png',
  },
  {
    name: 'smile_189',
    short_names: ['maximum_smile189'],
    imageUrl: '/files/emoji/smile_189.png',
  },
  {
    name: 'smile_190',
    short_names: ['maximum_smile190'],
    imageUrl: '/files/emoji/smile_190.png',
  },
  {
    name: 'smile_191',
    short_names: ['maximum_smile191'],
    imageUrl: '/files/emoji/smile_191.png',
  },
  {
    name: 'smile_192',
    short_names: ['maximum_smile192'],
    imageUrl: '/files/emoji/smile_192.png',
  },
  {
    name: 'smile_193',
    short_names: ['maximum_smile193'],
    imageUrl: '/files/emoji/smile_193.png',
  },
  {
    name: 'smile_194',
    short_names: ['maximum_smile194'],
    imageUrl: '/files/emoji/smile_194.png',
  },
  {
    name: 'smile_195',
    short_names: ['maximum_smile195'],
    imageUrl: '/files/emoji/smile_195.png',
  },
  {
    name: 'smile_196',
    short_names: ['maximum_smile196'],
    imageUrl: '/files/emoji/smile_196.png',
  },
  {
    name: 'smile_197',
    short_names: ['maximum_smile197'],
    imageUrl: '/files/emoji/smile_197.png',
  },
  {
    name: 'smile_198',
    short_names: ['maximum_smile198'],
    imageUrl: '/files/emoji/smile_198.png',
  },
  {
    name: 'smile_199',
    short_names: ['maximum_smile199'],
    imageUrl: '/files/emoji/smile_199.png',
  },
  {
    name: 'smile_200',
    short_names: ['maximum_smile200'],
    imageUrl: '/files/emoji/smile_200.png',
  },
  {
    name: 'smile_201',
    short_names: ['maximum_smile201'],
    imageUrl: '/files/emoji/smile_201.png',
  },
  {
    name: 'smile_202',
    short_names: ['maximum_smile202'],
    imageUrl: '/files/emoji/smile_202.png',
  },
  {
    name: 'smile_203',
    short_names: ['maximum_smile203'],
    imageUrl: '/files/emoji/smile_203.png',
  },
  {
    name: 'smile_204',
    short_names: ['maximum_smile204'],
    imageUrl: '/files/emoji/smile_204.png',
  },
  {
    name: 'smile_205',
    short_names: ['maximum_smile205'],
    imageUrl: '/files/emoji/smile_205.png',
  },
  {
    name: 'smile_206',
    short_names: ['maximum_smile206'],
    imageUrl: '/files/emoji/smile_206.png',
  },
  {
    name: 'smile_207',
    short_names: ['maximum_smile207'],
    imageUrl: '/files/emoji/smile_207.png',
  },
  {
    name: 'smile_208',
    short_names: ['maximum_smile208'],
    imageUrl: '/files/emoji/smile_208.png',
  },
  {
    name: 'smile_209',
    short_names: ['maximum_smile209'],
    imageUrl: '/files/emoji/smile_209.png',
  },
  {
    name: 'smile_210',
    short_names: ['maximum_smile210'],
    imageUrl: '/files/emoji/smile_210.png',
  },
  {
    name: 'smile_211',
    short_names: ['maximum_smile211'],
    imageUrl: '/files/emoji/smile_211.png',
  },
  {
    name: 'smile_212',
    short_names: ['maximum_smile212'],
    imageUrl: '/files/emoji/smile_212.png',
  },
  {
    name: 'smile_213',
    short_names: ['maximum_smile213'],
    imageUrl: '/files/emoji/smile_213.png',
  },
  {
    name: 'smile_214',
    short_names: ['maximum_smile214'],
    imageUrl: '/files/emoji/smile_214.png',
  },
  {
    name: 'smile_215',
    short_names: ['maximum_smile215'],
    imageUrl: '/files/emoji/smile_215.png',
  },
  {
    name: 'smile_216',
    short_names: ['maximum_smile216'],
    imageUrl: '/files/emoji/smile_216.png',
  },
  {
    name: 'smile_217',
    short_names: ['maximum_smile217'],
    imageUrl: '/files/emoji/smile_217.png',
  },
  {
    name: 'smile_218',
    short_names: ['maximum_smile218'],
    imageUrl: '/files/emoji/smile_218.png',
  },
  {
    name: 'smile_219',
    short_names: ['maximum_smile219'],
    imageUrl: '/files/emoji/smile_219.png',
  },
  {
    name: 'smile_220',
    short_names: ['maximum_smile220'],
    imageUrl: '/files/emoji/smile_220.png',
  },
  {
    name: 'smile_221',
    short_names: ['maximum_smile221'],
    imageUrl: '/files/emoji/smile_221.png',
  },
  {
    name: 'smile_222',
    short_names: ['maximum_smile222'],
    imageUrl: '/files/emoji/smile_222.png',
  },
  {
    name: 'smile_223',
    short_names: ['maximum_smile223'],
    imageUrl: '/files/emoji/smile_223.png',
  },
  {
    name: 'smile_224',
    short_names: ['maximum_smile224'],
    imageUrl: '/files/emoji/smile_224.png',
  },
  {
    name: 'smile_225',
    short_names: ['maximum_smile225'],
    imageUrl: '/files/emoji/smile_225.png',
  },
  {
    name: 'smile_226',
    short_names: ['maximum_smile226'],
    imageUrl: '/files/emoji/smile_226.png',
  },
  {
    name: 'smile_227',
    short_names: ['maximum_smile227'],
    imageUrl: '/files/emoji/smile_227.png',
  },
  {
    name: 'smile_228',
    short_names: ['maximum_smile228'],
    imageUrl: '/files/emoji/smile_228.png',
  },
  {
    name: 'smile_229',
    short_names: ['maximum_smile229'],
    imageUrl: '/files/emoji/smile_229.png',
  },
  {
    name: 'smile_230',
    short_names: ['maximum_smile230'],
    imageUrl: '/files/emoji/smile_230.png',
  },
  {
    name: 'smile_231',
    short_names: ['maximum_smile231'],
    imageUrl: '/files/emoji/smile_231.png',
  },
  {
    name: 'smile_232',
    short_names: ['maximum_smile232'],
    imageUrl: '/files/emoji/smile_232.png',
  },
  {
    name: 'smile_233',
    short_names: ['maximum_smile233'],
    imageUrl: '/files/emoji/smile_233.png',
  },
  {
    name: 'smile_234',
    short_names: ['maximum_smile234'],
    imageUrl: '/files/emoji/smile_234.png',
  },
  {
    name: 'smile_235',
    short_names: ['maximum_smile235'],
    imageUrl: '/files/emoji/smile_235.png',
  },
  {
    name: 'smile_236',
    short_names: ['maximum_smile236'],
    imageUrl: '/files/emoji/smile_236.png',
  },
  {
    name: 'smile_237',
    short_names: ['maximum_smile237'],
    imageUrl: '/files/emoji/smile_237.png',
  },
  {
    name: 'smile_238',
    short_names: ['maximum_smile238'],
    imageUrl: '/files/emoji/smile_238.png',
  },
  {
    name: 'smile_239',
    short_names: ['maximum_smile239'],
    imageUrl: '/files/emoji/smile_239.png',
  },
  {
    name: 'smile_240',
    short_names: ['maximum_smile240'],
    imageUrl: '/files/emoji/smile_240.png',
  },
  {
    name: 'smile_241',
    short_names: ['maximum_smile241'],
    imageUrl: '/files/emoji/smile_241.png',
  },
  {
    name: 'smile_242',
    short_names: ['maximum_smile242'],
    imageUrl: '/files/emoji/smile_242.png',
  },
  {
    name: 'smile_243',
    short_names: ['maximum_smile243'],
    imageUrl: '/files/emoji/smile_243.png',
  },
  {
    name: 'smile_244',
    short_names: ['maximum_smile244'],
    imageUrl: '/files/emoji/smile_244.png',
  },
  {
    name: 'smile_245',
    short_names: ['maximum_smile245'],
    imageUrl: '/files/emoji/smile_245.png',
  },
  {
    name: 'smile_246',
    short_names: ['maximum_smile246'],
    imageUrl: '/files/emoji/smile_246.png',
  },
  {
    name: 'smile_247',
    short_names: ['maximum_smile247'],
    imageUrl: '/files/emoji/smile_247.png',
  },
  {
    name: 'smile_248',
    short_names: ['maximum_smile248'],
    imageUrl: '/files/emoji/smile_248.png',
  },
  {
    name: 'smile_249',
    short_names: ['maximum_smile249'],
    imageUrl: '/files/emoji/smile_249.png',
  },
  {
    name: 'smile_250',
    short_names: ['maximum_smile250'],
    imageUrl: '/files/emoji/smile_250.png',
  },
  {
    name: 'smile_251',
    short_names: ['maximum_smile251'],
    imageUrl: '/files/emoji/smile_251.png',
  },
  {
    name: 'smile_252',
    short_names: ['maximum_smile252'],
    imageUrl: '/files/emoji/smile_252.png',
  },
  {
    name: 'smile_253',
    short_names: ['maximum_smile253'],
    imageUrl: '/files/emoji/smile_253.png',
  },
  {
    name: 'smile_254',
    short_names: ['maximum_smile254'],
    imageUrl: '/files/emoji/smile_254.png',
  },
  {
    name: 'smile_255',
    short_names: ['maximum_smile255'],
    imageUrl: '/files/emoji/smile_255.png',
  },
  {
    name: 'smile_256',
    short_names: ['maximum_smile256'],
    imageUrl: '/files/emoji/smile_256.png',
  },
  {
    name: 'smile_257',
    short_names: ['maximum_smile257'],
    imageUrl: '/files/emoji/smile_257.png',
  },
  {
    name: 'smile_258',
    short_names: ['maximum_smile258'],
    imageUrl: '/files/emoji/smile_258.png',
  },
  {
    name: 'smile_259',
    short_names: ['maximum_smile259'],
    imageUrl: '/files/emoji/smile_259.png',
  },
  {
    name: 'smile_260',
    short_names: ['maximum_smile260'],
    imageUrl: '/files/emoji/smile_260.png',
  },
  {
    name: 'smile_261',
    short_names: ['maximum_smile261'],
    imageUrl: '/files/emoji/smile_261.png',
  },
  {
    name: 'smile_262',
    short_names: ['maximum_smile262'],
    imageUrl: '/files/emoji/smile_262.png',
  },
  {
    name: 'smile_263',
    short_names: ['maximum_smile263'],
    imageUrl: '/files/emoji/smile_263.png',
  },
  {
    name: 'smile_264',
    short_names: ['maximum_smile264'],
    imageUrl: '/files/emoji/smile_264.png',
  },
  {
    name: 'smile_265',
    short_names: ['maximum_smile265'],
    imageUrl: '/files/emoji/smile_265.png',
  },
  {
    name: 'smile_266',
    short_names: ['maximum_smile266'],
    imageUrl: '/files/emoji/smile_266.png',
  },
  {
    name: 'smile_267',
    short_names: ['maximum_smile267'],
    imageUrl: '/files/emoji/smile_267.png',
  },
  {
    name: 'smile_268',
    short_names: ['maximum_smile268'],
    imageUrl: '/files/emoji/smile_268.png',
  },
  {
    name: 'smile_269',
    short_names: ['maximum_smile269'],
    imageUrl: '/files/emoji/smile_269.png',
  },
  {
    name: 'smile_270',
    short_names: ['maximum_smile270'],
    imageUrl: '/files/emoji/smile_270.png',
  },
  {
    name: 'smile_271',
    short_names: ['maximum_smile271'],
    imageUrl: '/files/emoji/smile_271.png',
  },
  {
    name: 'smile_272',
    short_names: ['maximum_smile272'],
    imageUrl: '/files/emoji/smile_272.png',
  },
  {
    name: 'smile_273',
    short_names: ['maximum_smile273'],
    imageUrl: '/files/emoji/smile_273.png',
  },
  {
    name: 'smile_274',
    short_names: ['maximum_smile274'],
    imageUrl: '/files/emoji/smile_274.png',
  },
  {
    name: 'smile_275',
    short_names: ['maximum_smile275'],
    imageUrl: '/files/emoji/smile_275.png',
  },
  {
    name: 'smile_276',
    short_names: ['maximum_smile276'],
    imageUrl: '/files/emoji/smile_276.png',
  },
  {
    name: 'smile_277',
    short_names: ['maximum_smile277'],
    imageUrl: '/files/emoji/smile_277.png',
  },
  {
    name: 'smile_278',
    short_names: ['maximum_smile278'],
    imageUrl: '/files/emoji/smile_278.png',
  },
  {
    name: 'smile_279',
    short_names: ['maximum_smile279'],
    imageUrl: '/files/emoji/smile_279.png',
  },
  {
    name: 'smile_280',
    short_names: ['maximum_smile280'],
    imageUrl: '/files/emoji/smile_280.png',
  },
  {
    name: 'smile_281',
    short_names: ['maximum_smile281'],
    imageUrl: '/files/emoji/smile_281.png',
  },
  {
    name: 'smile_282',
    short_names: ['maximum_smile282'],
    imageUrl: '/files/emoji/smile_282.png',
  },
  {
    name: 'smile_283',
    short_names: ['maximum_smile283'],
    imageUrl: '/files/emoji/smile_283.png',
  },
  {
    name: 'smile_284',
    short_names: ['maximum_smile284'],
    imageUrl: '/files/emoji/smile_284.png',
  },
  {
    name: 'smile_285',
    short_names: ['maximum_smile285'],
    imageUrl: '/files/emoji/smile_285.png',
  },
  {
    name: 'smile_286',
    short_names: ['maximum_smile286'],
    imageUrl: '/files/emoji/smile_286.png',
  },
  {
    name: 'smile_287',
    short_names: ['maximum_smile287'],
    imageUrl: '/files/emoji/smile_287.png',
  },
  {
    name: 'smile_288',
    short_names: ['maximum_smile288'],
    imageUrl: '/files/emoji/smile_288.png',
  },
  {
    name: 'smile_289',
    short_names: ['maximum_smile289'],
    imageUrl: '/files/emoji/smile_289.png',
  },
  {
    name: 'smile_290',
    short_names: ['maximum_smile290'],
    imageUrl: '/files/emoji/smile_290.png',
  },
  {
    name: 'smile_291',
    short_names: ['maximum_smile291'],
    imageUrl: '/files/emoji/smile_291.png',
  },
  {
    name: 'smile_292',
    short_names: ['maximum_smile292'],
    imageUrl: '/files/emoji/smile_292.png',
  },
  {
    name: 'smile_293',
    short_names: ['maximum_smile293'],
    imageUrl: '/files/emoji/smile_293.png',
  },
  {
    name: 'smile_294',
    short_names: ['maximum_smile294'],
    imageUrl: '/files/emoji/smile_294.png',
  },
  {
    name: 'smile_295',
    short_names: ['maximum_smile295'],
    imageUrl: '/files/emoji/smile_295.png',
  },
  {
    name: 'smile_296',
    short_names: ['maximum_smile296'],
    imageUrl: '/files/emoji/smile_296.png',
  },
  {
    name: 'smile_297',
    short_names: ['maximum_smile297'],
    imageUrl: '/files/emoji/smile_297.png',
  },
  {
    name: 'smile_298',
    short_names: ['maximum_smile298'],
    imageUrl: '/files/emoji/smile_298.png',
  },
  {
    name: 'smile_299',
    short_names: ['maximum_smile299'],
    imageUrl: '/files/emoji/smile_299.png',
  },
  {
    name: 'smile_300',
    short_names: ['maximum_smile300'],
    imageUrl: '/files/emoji/smile_300.png',
  },
  {
    name: 'smile_301',
    short_names: ['maximum_smile301'],
    imageUrl: '/files/emoji/smile_301.png',
  },
  {
    name: 'smile_302',
    short_names: ['maximum_smile302'],
    imageUrl: '/files/emoji/smile_302.png',
  },
  {
    name: 'smile_303',
    short_names: ['maximum_smile303'],
    imageUrl: '/files/emoji/smile_303.png',
  },
  {
    name: 'smile_304',
    short_names: ['maximum_smile304'],
    imageUrl: '/files/emoji/smile_304.png',
  },
  {
    name: 'smile_305',
    short_names: ['maximum_smile305'],
    imageUrl: '/files/emoji/smile_305.png',
  },
  {
    name: 'smile_306',
    short_names: ['maximum_smile306'],
    imageUrl: '/files/emoji/smile_306.png',
  },
  {
    name: 'smile_307',
    short_names: ['maximum_smile307'],
    imageUrl: '/files/emoji/smile_307.png',
  },
  {
    name: 'smile_308',
    short_names: ['maximum_smile308'],
    imageUrl: '/files/emoji/smile_308.png',
  },
  {
    name: 'smile_309',
    short_names: ['maximum_smile309'],
    imageUrl: '/files/emoji/smile_309.png',
  },
  {
    name: 'smile_310',
    short_names: ['maximum_smile310'],
    imageUrl: '/files/emoji/smile_310.png',
  },
  {
    name: 'smile_311',
    short_names: ['maximum_smile311'],
    imageUrl: '/files/emoji/smile_311.png',
  },
  {
    name: 'smile_312',
    short_names: ['maximum_smile312'],
    imageUrl: '/files/emoji/smile_312.png',
  },
  {
    name: 'smile_313',
    short_names: ['maximum_smile313'],
    imageUrl: '/files/emoji/smile_313.png',
  },
  {
    name: 'smile_314',
    short_names: ['maximum_smile314'],
    imageUrl: '/files/emoji/smile_314.png',
  },
  {
    name: 'smile_315',
    short_names: ['maximum_smile315'],
    imageUrl: '/files/emoji/smile_315.png',
  },
  {
    name: 'smile_316',
    short_names: ['maximum_smile316'],
    imageUrl: '/files/emoji/smile_316.png',
  },
  {
    name: 'smile_317',
    short_names: ['maximum_smile317'],
    imageUrl: '/files/emoji/smile_317.png',
  },
  {
    name: 'smile_318',
    short_names: ['maximum_smile318'],
    imageUrl: '/files/emoji/smile_318.png',
  },
  {
    name: 'smile_319',
    short_names: ['maximum_smile319'],
    imageUrl: '/files/emoji/smile_319.png',
  },
  {
    name: 'smile_320',
    short_names: ['maximum_smile320'],
    imageUrl: '/files/emoji/smile_320.png',
  },
  {
    name: 'smile_321',
    short_names: ['maximum_smile321'],
    imageUrl: '/files/emoji/smile_321.png',
  },
  {
    name: 'smile_322',
    short_names: ['maximum_smile322'],
    imageUrl: '/files/emoji/smile_322.png',
  },
  {
    name: 'smile_323',
    short_names: ['maximum_smile323'],
    imageUrl: '/files/emoji/smile_323.png',
  },
  {
    name: 'smile_324',
    short_names: ['maximum_smile324'],
    imageUrl: '/files/emoji/smile_324.png',
  },
  {
    name: 'smile_325',
    short_names: ['maximum_smile325'],
    imageUrl: '/files/emoji/smile_325.png',
  },
  {
    name: 'smile_326',
    short_names: ['maximum_smile326'],
    imageUrl: '/files/emoji/smile_326.png',
  },
  {
    name: 'smile_327',
    short_names: ['maximum_smile327'],
    imageUrl: '/files/emoji/smile_327.png',
  },
  {
    name: 'smile_328',
    short_names: ['maximum_smile328'],
    imageUrl: '/files/emoji/smile_328.png',
  },
  {
    name: 'smile_329',
    short_names: ['maximum_smile329'],
    imageUrl: '/files/emoji/smile_329.png',
  },
  {
    name: 'smile_330',
    short_names: ['maximum_smile330'],
    imageUrl: '/files/emoji/smile_330.png',
  },
  {
    name: 'smile_331',
    short_names: ['maximum_smile331'],
    imageUrl: '/files/emoji/smile_331.png',
  },
  {
    name: 'smile_332',
    short_names: ['maximum_smile332'],
    imageUrl: '/files/emoji/smile_332.png',
  },
  {
    name: 'smile_333',
    short_names: ['maximum_smile333'],
    imageUrl: '/files/emoji/smile_333.png',
  },
  {
    name: 'smile_334',
    short_names: ['maximum_smile334'],
    imageUrl: '/files/emoji/smile_334.png',
  },
  {
    name: 'smile_335',
    short_names: ['maximum_smile335'],
    imageUrl: '/files/emoji/smile_335.png',
  },
  {
    name: 'smile_336',
    short_names: ['maximum_smile336'],
    imageUrl: '/files/emoji/smile_336.png',
  },
  {
    name: 'smile_337',
    short_names: ['maximum_smile337'],
    imageUrl: '/files/emoji/smile_337.png',
  },
  {
    name: 'smile_338',
    short_names: ['maximum_smile338'],
    imageUrl: '/files/emoji/smile_338.png',
  },
  {
    name: 'smile_339',
    short_names: ['maximum_smile339'],
    imageUrl: '/files/emoji/smile_339.png',
  },
  {
    name: 'smile_340',
    short_names: ['maximum_smile340'],
    imageUrl: '/files/emoji/smile_340.png',
  },
  {
    name: 'smile_341',
    short_names: ['maximum_smile341'],
    imageUrl: '/files/emoji/smile_341.png',
  },
  {
    name: 'smile_342',
    short_names: ['maximum_smile342'],
    imageUrl: '/files/emoji/smile_342.png',
  },
  {
    name: 'smile_343',
    short_names: ['maximum_smile343'],
    imageUrl: '/files/emoji/smile_343.png',
  },
  {
    name: 'smile_344',
    short_names: ['maximum_smile344'],
    imageUrl: '/files/emoji/smile_344.png',
  },
  {
    name: 'smile_345',
    short_names: ['maximum_smile345'],
    imageUrl: '/files/emoji/smile_345.png',
  },
  {
    name: 'smile_346',
    short_names: ['maximum_smile346'],
    imageUrl: '/files/emoji/smile_346.png',
  },
  {
    name: 'smile_347',
    short_names: ['maximum_smile347'],
    imageUrl: '/files/emoji/smile_347.png',
  },
  {
    name: 'smile_348',
    short_names: ['maximum_smile348'],
    imageUrl: '/files/emoji/smile_348.png',
  },
  {
    name: 'smile_349',
    short_names: ['maximum_smile349'],
    imageUrl: '/files/emoji/smile_349.png',
  },
  {
    name: 'smile_350',
    short_names: ['maximum_smile350'],
    imageUrl: '/files/emoji/smile_350.png',
  },
  {
    name: 'smile_351',
    short_names: ['maximum_smile351'],
    imageUrl: '/files/emoji/smile_351.png',
  },
  {
    name: 'smile_352',
    short_names: ['maximum_smile352'],
    imageUrl: '/files/emoji/smile_352.png',
  },
  {
    name: 'smile_353',
    short_names: ['maximum_smile353'],
    imageUrl: '/files/emoji/smile_353.png',
  },
  {
    name: 'smile_354',
    short_names: ['maximum_smile354'],
    imageUrl: '/files/emoji/smile_354.png',
  },
  {
    name: 'smile_355',
    short_names: ['maximum_smile355'],
    imageUrl: '/files/emoji/smile_355.png',
  },
  {
    name: 'smile_356',
    short_names: ['maximum_smile356'],
    imageUrl: '/files/emoji/smile_356.png',
  },
  {
    name: 'smile_357',
    short_names: ['maximum_smile357'],
    imageUrl: '/files/emoji/smile_357.png',
  },
  {
    name: 'smile_358',
    short_names: ['maximum_smile358'],
    imageUrl: '/files/emoji/smile_358.png',
  },
  {
    name: 'smile_359',
    short_names: ['maximum_smile359'],
    imageUrl: '/files/emoji/smile_359.png',
  },
  {
    name: 'smile_360',
    short_names: ['maximum_smile360'],
    imageUrl: '/files/emoji/smile_360.png',
  },
  {
    name: 'smile_361',
    short_names: ['maximum_smile361'],
    imageUrl: '/files/emoji/smile_361.png',
  },
  {
    name: 'smile_362',
    short_names: ['maximum_smile362'],
    imageUrl: '/files/emoji/smile_362.png',
  },
  {
    name: 'smile_363',
    short_names: ['maximum_smile363'],
    imageUrl: '/files/emoji/smile_363.png',
  },
  {
    name: 'smile_364',
    short_names: ['maximum_smile364'],
    imageUrl: '/files/emoji/smile_364.png',
  },
  {
    name: 'smile_365',
    short_names: ['maximum_smile365'],
    imageUrl: '/files/emoji/smile_365.png',
  },
  {
    name: 'smile_366',
    short_names: ['maximum_smile366'],
    imageUrl: '/files/emoji/smile_366.png',
  },
  {
    name: 'smile_367',
    short_names: ['maximum_smile367'],
    imageUrl: '/files/emoji/smile_367.png',
  },
  {
    name: 'smile_368',
    short_names: ['maximum_smile368'],
    imageUrl: '/files/emoji/smile_368.png',
  },
  {
    name: 'smile_369',
    short_names: ['maximum_smile369'],
    imageUrl: '/files/emoji/smile_369.png',
  },
  {
    name: 'smile_370',
    short_names: ['maximum_smile370'],
    imageUrl: '/files/emoji/smile_370.png',
  },
  {
    name: 'smile_371',
    short_names: ['maximum_smile371'],
    imageUrl: '/files/emoji/smile_371.png',
  },
  {
    name: 'smile_372',
    short_names: ['maximum_smile372'],
    imageUrl: '/files/emoji/smile_372.png',
  },
  {
    name: 'smile_373',
    short_names: ['maximum_smile373'],
    imageUrl: '/files/emoji/smile_373.png',
  },
  {
    name: 'smile_374',
    short_names: ['maximum_smile374'],
    imageUrl: '/files/emoji/smile_374.png',
  },
  {
    name: 'smile_375',
    short_names: ['maximum_smile375'],
    imageUrl: '/files/emoji/smile_375.png',
  },
  {
    name: 'smile_376',
    short_names: ['maximum_smile376'],
    imageUrl: '/files/emoji/smile_376.png',
  },
  {
    name: 'smile_377',
    short_names: ['maximum_smile377'],
    imageUrl: '/files/emoji/smile_377.png',
  },
  {
    name: 'smile_378',
    short_names: ['maximum_smile378'],
    imageUrl: '/files/emoji/smile_378.png',
  },
  {
    name: 'smile_379',
    short_names: ['maximum_smile379'],
    imageUrl: '/files/emoji/smile_379.png',
  },
  {
    name: 'smile_380',
    short_names: ['maximum_smile380'],
    imageUrl: '/files/emoji/smile_380.png',
  },
  {
    name: 'smile_381',
    short_names: ['maximum_smile381'],
    imageUrl: '/files/emoji/smile_381.png',
  },
  {
    name: 'smile_382',
    short_names: ['maximum_smile382'],
    imageUrl: '/files/emoji/smile_382.png',
  },
  {
    name: 'smile_383',
    short_names: ['maximum_smile383'],
    imageUrl: '/files/emoji/smile_383.png',
  },
  {
    name: 'smile_384',
    short_names: ['maximum_smile384'],
    imageUrl: '/files/emoji/smile_384.png',
  },
  {
    name: 'smile_385',
    short_names: ['maximum_smile385'],
    imageUrl: '/files/emoji/smile_385.png',
  },
  {
    name: 'smile_386',
    short_names: ['maximum_smile386'],
    imageUrl: '/files/emoji/smile_386.png',
  },
  {
    name: 'smile_387',
    short_names: ['maximum_smile387'],
    imageUrl: '/files/emoji/smile_387.png',
  },
  {
    name: 'smile_388',
    short_names: ['maximum_smile388'],
    imageUrl: '/files/emoji/smile_388.png',
  },
  {
    name: 'smile_389',
    short_names: ['maximum_smile389'],
    imageUrl: '/files/emoji/smile_389.png',
  },
  {
    name: 'smile_390',
    short_names: ['maximum_smile390'],
    imageUrl: '/files/emoji/smile_390.png',
  },
  {
    name: 'smile_391',
    short_names: ['maximum_smile391'],
    imageUrl: '/files/emoji/smile_391.png',
  },
  {
    name: 'smile_392',
    short_names: ['maximum_smile392'],
    imageUrl: '/files/emoji/smile_392.png',
  },
  {
    name: 'smile_393',
    short_names: ['maximum_smile393'],
    imageUrl: '/files/emoji/smile_393.png',
  },
  {
    name: 'smile_394',
    short_names: ['maximum_smile394'],
    imageUrl: '/files/emoji/smile_394.png',
  },
  {
    name: 'smile_395',
    short_names: ['maximum_smile395'],
    imageUrl: '/files/emoji/smile_395.png',
  },
  {
    name: 'smile_396',
    short_names: ['maximum_smile396'],
    imageUrl: '/files/emoji/smile_396.png',
  },
  {
    name: 'smile_397',
    short_names: ['maximum_smile397'],
    imageUrl: '/files/emoji/smile_397.png',
  },
  {
    name: 'smile_398',
    short_names: ['maximum_smile398'],
    imageUrl: '/files/emoji/smile_398.png',
  },
  {
    name: 'smile_399',
    short_names: ['maximum_smile399'],
    imageUrl: '/files/emoji/smile_399.png',
  },
  {
    name: 'smile_400',
    short_names: ['maximum_smile400'],
    imageUrl: '/files/emoji/smile_400.png',
  },
  {
    name: 'smile_401',
    short_names: ['maximum_smile401'],
    imageUrl: '/files/emoji/smile_401.png',
  },
  {
    name: 'smile_402',
    short_names: ['maximum_smile402'],
    imageUrl: '/files/emoji/smile_402.png',
  },
  {
    name: 'smile_403',
    short_names: ['maximum_smile403'],
    imageUrl: '/files/emoji/smile_403.png',
  },
  {
    name: 'smile_404',
    short_names: ['maximum_smile404'],
    imageUrl: '/files/emoji/smile_404.png',
  },
  {
    name: 'smile_405',
    short_names: ['maximum_smile405'],
    imageUrl: '/files/emoji/smile_405.png',
  },
  {
    name: 'smile_406',
    short_names: ['maximum_smile406'],
    imageUrl: '/files/emoji/smile_406.png',
  },
  {
    name: 'smile_407',
    short_names: ['maximum_smile407'],
    imageUrl: '/files/emoji/smile_407.png',
  },
  {
    name: 'smile_408',
    short_names: ['maximum_smile408'],
    imageUrl: '/files/emoji/smile_408.png',
  },
  {
    name: 'smile_409',
    short_names: ['maximum_smile409'],
    imageUrl: '/files/emoji/smile_409.png',
  },
  {
    name: 'smile_410',
    short_names: ['maximum_smile410'],
    imageUrl: '/files/emoji/smile_410.png',
  },
  {
    name: 'smile_411',
    short_names: ['maximum_smile411'],
    imageUrl: '/files/emoji/smile_411.png',
  },
  {
    name: 'smile_412',
    short_names: ['maximum_smile412'],
    imageUrl: '/files/emoji/smile_412.png',
  },
  {
    name: 'smile_413',
    short_names: ['maximum_smile413'],
    imageUrl: '/files/emoji/smile_413.png',
  },
  {
    name: 'smile_414',
    short_names: ['maximum_smile414'],
    imageUrl: '/files/emoji/smile_414.png',
  },
  {
    name: 'smile_415',
    short_names: ['maximum_smile415'],
    imageUrl: '/files/emoji/smile_415.png',
  },
  {
    name: 'smile_416',
    short_names: ['maximum_smile416'],
    imageUrl: '/files/emoji/smile_416.png',
  },
  {
    name: 'smile_417',
    short_names: ['maximum_smile417'],
    imageUrl: '/files/emoji/smile_417.png',
  },
  {
    name: 'smile_418',
    short_names: ['maximum_smile418'],
    imageUrl: '/files/emoji/smile_418.png',
  },
  {
    name: 'smile_419',
    short_names: ['maximum_smile419'],
    imageUrl: '/files/emoji/smile_419.png',
  },
  {
    name: 'smile_420',
    short_names: ['maximum_smile420'],
    imageUrl: '/files/emoji/smile_420.png',
  },
  {
    name: 'smile_421',
    short_names: ['maximum_smile421'],
    imageUrl: '/files/emoji/smile_421.png',
  },
  {
    name: 'smile_422',
    short_names: ['maximum_smile422'],
    imageUrl: '/files/emoji/smile_422.png',
  },
  {
    name: 'smile_423',
    short_names: ['maximum_smile423'],
    imageUrl: '/files/emoji/smile_423.png',
  },
  {
    name: 'smile_424',
    short_names: ['maximum_smile424'],
    imageUrl: '/files/emoji/smile_424.png',
  },
  {
    name: 'smile_425',
    short_names: ['maximum_smile425'],
    imageUrl: '/files/emoji/smile_425.png',
  },
  {
    name: 'smile_426',
    short_names: ['maximum_smile426'],
    imageUrl: '/files/emoji/smile_426.png',
  },
  {
    name: 'smile_427',
    short_names: ['maximum_smile427'],
    imageUrl: '/files/emoji/smile_427.png',
  },
  {
    name: 'smile_428',
    short_names: ['maximum_smile428'],
    imageUrl: '/files/emoji/smile_428.png',
  },
  {
    name: 'smile_429',
    short_names: ['maximum_smile429'],
    imageUrl: '/files/emoji/smile_429.png',
  },
  {
    name: 'smile_430',
    short_names: ['maximum_smile430'],
    imageUrl: '/files/emoji/smile_430.png',
  },
  {
    name: 'smile_431',
    short_names: ['maximum_smile431'],
    imageUrl: '/files/emoji/smile_431.png',
  },
  {
    name: 'smile_432',
    short_names: ['maximum_smile432'],
    imageUrl: '/files/emoji/smile_432.png',
  },
  {
    name: 'symbols_1',
    short_names: ['maximum_symbols_1'],
    imageUrl: '/files/emoji/symbols_1.png',
  },
  {
    name: 'symbols_2',
    short_names: ['maximum_symbols_2'],
    imageUrl: '/files/emoji/symbols_2.png',
  },
  {
    name: 'symbols_3',
    short_names: ['maximum_symbols_3'],
    imageUrl: '/files/emoji/symbols_3.png',
  },
  {
    name: 'symbols_4',
    short_names: ['maximum_symbols_4'],
    imageUrl: '/files/emoji/symbols_4.png',
  },
  {
    name: 'symbols_5',
    short_names: ['maximum_symbols_5'],
    imageUrl: '/files/emoji/symbols_5.png',
  },
  {
    name: 'symbols_6',
    short_names: ['maximum_symbols_6'],
    imageUrl: '/files/emoji/symbols_6.png',
  },
  {
    name: 'symbols_7',
    short_names: ['maximum_symbols_7'],
    imageUrl: '/files/emoji/symbols_7.png',
  },
  {
    name: 'symbols_8',
    short_names: ['maximum_symbols_8'],
    imageUrl: '/files/emoji/symbols_8.png',
  },
  {
    name: 'symbols_9',
    short_names: ['maximum_symbols_9'],
    imageUrl: '/files/emoji/symbols_9.png',
  },
  {
    name: 'symbols_10',
    short_names: ['maximum_symbols_10'],
    imageUrl: '/files/emoji/symbols_10.png',
  },
  {
    name: 'symbols_11',
    short_names: ['maximum_symbols_11'],
    imageUrl: '/files/emoji/symbols_11.png',
  },
  {
    name: 'symbols_12',
    short_names: ['maximum_symbols_12'],
    imageUrl: '/files/emoji/symbols_12.png',
  },
  {
    name: 'symbols_13',
    short_names: ['maximum_symbols_13'],
    imageUrl: '/files/emoji/symbols_13.png',
  },
  {
    name: 'symbols_14',
    short_names: ['maximum_symbols_14'],
    imageUrl: '/files/emoji/symbols_14.png',
  },
  {
    name: 'symbols_15',
    short_names: ['maximum_symbols_15'],
    imageUrl: '/files/emoji/symbols_15.png',
  },
  {
    name: 'symbols_16',
    short_names: ['maximum_symbols_16'],
    imageUrl: '/files/emoji/symbols_16.png',
  },
  {
    name: 'symbols_17',
    short_names: ['maximum_symbols_17'],
    imageUrl: '/files/emoji/symbols_17.png',
  },
  {
    name: 'symbols_18',
    short_names: ['maximum_symbols_18'],
    imageUrl: '/files/emoji/symbols_18.png',
  },
  {
    name: 'symbols_19',
    short_names: ['maximum_symbols_19'],
    imageUrl: '/files/emoji/symbols_19.png',
  },
  {
    name: 'symbols_20',
    short_names: ['maximum_symbols_20'],
    imageUrl: '/files/emoji/symbols_20.png',
  },
  {
    name: 'symbols_21',
    short_names: ['maximum_symbols_21'],
    imageUrl: '/files/emoji/symbols_21.png',
  },
  {
    name: 'symbols_22',
    short_names: ['maximum_symbols_22'],
    imageUrl: '/files/emoji/symbols_22.png',
  },
  {
    name: 'symbols_23',
    short_names: ['maximum_symbols_23'],
    imageUrl: '/files/emoji/symbols_23.png',
  },
  {
    name: 'symbols_24',
    short_names: ['maximum_symbols_24'],
    imageUrl: '/files/emoji/symbols_24.png',
  },
  {
    name: 'symbols_25',
    short_names: ['maximum_symbols_25'],
    imageUrl: '/files/emoji/symbols_25.png',
  },
  {
    name: 'symbols_26',
    short_names: ['maximum_symbols_26'],
    imageUrl: '/files/emoji/symbols_26.png',
  },
  {
    name: 'symbols_27',
    short_names: ['maximum_symbols_27'],
    imageUrl: '/files/emoji/symbols_27.png',
  },
  {
    name: 'symbols_28',
    short_names: ['maximum_symbols_28'],
    imageUrl: '/files/emoji/symbols_28.png',
  },
  {
    name: 'symbols_29',
    short_names: ['maximum_symbols_29'],
    imageUrl: '/files/emoji/symbols_29.png',
  },
  {
    name: 'symbols_30',
    short_names: ['maximum_symbols_30'],
    imageUrl: '/files/emoji/symbols_30.png',
  },
  {
    name: 'symbols_31',
    short_names: ['maximum_symbols_31'],
    imageUrl: '/files/emoji/symbols_31.png',
  },
  {
    name: 'symbols_32',
    short_names: ['maximum_symbols_32'],
    imageUrl: '/files/emoji/symbols_32.png',
  },
  {
    name: 'symbols_33',
    short_names: ['maximum_symbols_33'],
    imageUrl: '/files/emoji/symbols_33.png',
  },
  {
    name: 'symbols_34',
    short_names: ['maximum_symbols_34'],
    imageUrl: '/files/emoji/symbols_34.png',
  },
  {
    name: 'symbols_35',
    short_names: ['maximum_symbols_35'],
    imageUrl: '/files/emoji/symbols_35.png',
  },
  {
    name: 'symbols_36',
    short_names: ['maximum_symbols_36'],
    imageUrl: '/files/emoji/symbols_36.png',
  },
  {
    name: 'symbols_37',
    short_names: ['maximum_symbols_37'],
    imageUrl: '/files/emoji/symbols_37.png',
  },
  {
    name: 'symbols_38',
    short_names: ['maximum_symbols_38'],
    imageUrl: '/files/emoji/symbols_38.png',
  },
  {
    name: 'symbols_39',
    short_names: ['maximum_symbols_39'],
    imageUrl: '/files/emoji/symbols_39.png',
  },
  {
    name: 'symbols_40',
    short_names: ['maximum_symbols_40'],
    imageUrl: '/files/emoji/symbols_40.png',
  },
  {
    name: 'symbols_41',
    short_names: ['maximum_symbols_41'],
    imageUrl: '/files/emoji/symbols_41.png',
  },
  {
    name: 'symbols_42',
    short_names: ['maximum_symbols_42'],
    imageUrl: '/files/emoji/symbols_42.png',
  },
  {
    name: 'symbols_43',
    short_names: ['maximum_symbols_43'],
    imageUrl: '/files/emoji/symbols_43.png',
  },
  {
    name: 'symbols_44',
    short_names: ['maximum_symbols_44'],
    imageUrl: '/files/emoji/symbols_44.png',
  },
  {
    name: 'symbols_45',
    short_names: ['maximum_symbols_45'],
    imageUrl: '/files/emoji/symbols_45.png',
  },
  {
    name: 'symbols_46',
    short_names: ['maximum_symbols_46'],
    imageUrl: '/files/emoji/symbols_46.png',
  },
  {
    name: 'symbols_47',
    short_names: ['maximum_symbols_47'],
    imageUrl: '/files/emoji/symbols_47.png',
  },
  {
    name: 'symbols_48',
    short_names: ['maximum_symbols_48'],
    imageUrl: '/files/emoji/symbols_48.png',
  },
  {
    name: 'symbols_49',
    short_names: ['maximum_symbols_49'],
    imageUrl: '/files/emoji/symbols_49.png',
  },
  {
    name: 'symbols_50',
    short_names: ['maximum_symbols_50'],
    imageUrl: '/files/emoji/symbols_50.png',
  },
  {
    name: 'symbols_51',
    short_names: ['maximum_symbols_51'],
    imageUrl: '/files/emoji/symbols_51.png',
  },
  {
    name: 'symbols_52',
    short_names: ['maximum_symbols_52'],
    imageUrl: '/files/emoji/symbols_52.png',
  },
  {
    name: 'symbols_53',
    short_names: ['maximum_symbols_53'],
    imageUrl: '/files/emoji/symbols_53.png',
  },
  {
    name: 'symbols_54',
    short_names: ['maximum_symbols_54'],
    imageUrl: '/files/emoji/symbols_54.png',
  },
  {
    name: 'symbols_55',
    short_names: ['maximum_symbols_55'],
    imageUrl: '/files/emoji/symbols_55.png',
  },
  {
    name: 'symbols_56',
    short_names: ['maximum_symbols_56'],
    imageUrl: '/files/emoji/symbols_56.png',
  },
  {
    name: 'symbols_57',
    short_names: ['maximum_symbols_57'],
    imageUrl: '/files/emoji/symbols_57.png',
  },
  {
    name: 'symbols_58',
    short_names: ['maximum_symbols_58'],
    imageUrl: '/files/emoji/symbols_58.png',
  },
  {
    name: 'symbols_59',
    short_names: ['maximum_symbols_59'],
    imageUrl: '/files/emoji/symbols_59.png',
  },
  {
    name: 'symbols_60',
    short_names: ['maximum_symbols_60'],
    imageUrl: '/files/emoji/symbols_60.png',
  },
  {
    name: 'symbols_61',
    short_names: ['maximum_symbols_61'],
    imageUrl: '/files/emoji/symbols_61.png',
  },
  {
    name: 'symbols_62',
    short_names: ['maximum_symbols_62'],
    imageUrl: '/files/emoji/symbols_62.png',
  },
  {
    name: 'symbols_63',
    short_names: ['maximum_symbols_63'],
    imageUrl: '/files/emoji/symbols_63.png',
  },
  {
    name: 'symbols_64',
    short_names: ['maximum_symbols_64'],
    imageUrl: '/files/emoji/symbols_64.png',
  },
  {
    name: 'symbols_65',
    short_names: ['maximum_symbols_65'],
    imageUrl: '/files/emoji/symbols_65.png',
  },
  {
    name: 'symbols_66',
    short_names: ['maximum_symbols_66'],
    imageUrl: '/files/emoji/symbols_66.png',
  },
  {
    name: 'symbols_67',
    short_names: ['maximum_symbols_67'],
    imageUrl: '/files/emoji/symbols_67.png',
  },
  {
    name: 'symbols_68',
    short_names: ['maximum_symbols_68'],
    imageUrl: '/files/emoji/symbols_68.png',
  },
  {
    name: 'symbols_69',
    short_names: ['maximum_symbols_69'],
    imageUrl: '/files/emoji/symbols_69.png',
  },
  {
    name: 'symbols_70',
    short_names: ['maximum_symbols_70'],
    imageUrl: '/files/emoji/symbols_70.png',
  },
  {
    name: 'symbols_71',
    short_names: ['maximum_symbols_71'],
    imageUrl: '/files/emoji/symbols_71.png',
  },
  {
    name: 'symbols_72',
    short_names: ['maximum_symbols_72'],
    imageUrl: '/files/emoji/symbols_72.png',
  },
  {
    name: 'symbols_73',
    short_names: ['maximum_symbols_73'],
    imageUrl: '/files/emoji/symbols_73.png',
  },
  {
    name: 'symbols_74',
    short_names: ['maximum_symbols_74'],
    imageUrl: '/files/emoji/symbols_74.png',
  },
  {
    name: 'symbols_75',
    short_names: ['maximum_symbols_75'],
    imageUrl: '/files/emoji/symbols_75.png',
  },
  {
    name: 'symbols_76',
    short_names: ['maximum_symbols_76'],
    imageUrl: '/files/emoji/symbols_76.png',
  },
  {
    name: 'symbols_77',
    short_names: ['maximum_symbols_77'],
    imageUrl: '/files/emoji/symbols_77.png',
  },
  {
    name: 'symbols_78',
    short_names: ['maximum_symbols_78'],
    imageUrl: '/files/emoji/symbols_78.png',
  },
  {
    name: 'symbols_79',
    short_names: ['maximum_symbols_79'],
    imageUrl: '/files/emoji/symbols_79.png',
  },
  {
    name: 'symbols_80',
    short_names: ['maximum_symbols_80'],
    imageUrl: '/files/emoji/symbols_80.png',
  },
  {
    name: 'symbols_81',
    short_names: ['maximum_symbols_81'],
    imageUrl: '/files/emoji/symbols_81.png',
  },
  {
    name: 'symbols_82',
    short_names: ['maximum_symbols_82'],
    imageUrl: '/files/emoji/symbols_82.png',
  },
  {
    name: 'symbols_83',
    short_names: ['maximum_symbols_83'],
    imageUrl: '/files/emoji/symbols_83.png',
  },
  {
    name: 'symbols_84',
    short_names: ['maximum_symbols_84'],
    imageUrl: '/files/emoji/symbols_84.png',
  },
  {
    name: 'symbols_85',
    short_names: ['maximum_symbols_85'],
    imageUrl: '/files/emoji/symbols_85.png',
  },
  {
    name: 'symbols_86',
    short_names: ['maximum_symbols_86'],
    imageUrl: '/files/emoji/symbols_86.png',
  },
  {
    name: 'symbols_87',
    short_names: ['maximum_symbols_87'],
    imageUrl: '/files/emoji/symbols_87.png',
  },
  {
    name: 'symbols_88',
    short_names: ['maximum_symbols_88'],
    imageUrl: '/files/emoji/symbols_88.png',
  },
  {
    name: 'symbols_89',
    short_names: ['maximum_symbols_89'],
    imageUrl: '/files/emoji/symbols_89.png',
  },
  {
    name: 'symbols_90',
    short_names: ['maximum_symbols_90'],
    imageUrl: '/files/emoji/symbols_90.png',
  },
  {
    name: 'symbols_91',
    short_names: ['maximum_symbols_91'],
    imageUrl: '/files/emoji/symbols_91.png',
  },
  {
    name: 'symbols_92',
    short_names: ['maximum_symbols_92'],
    imageUrl: '/files/emoji/symbols_92.png',
  },
  {
    name: 'symbols_93',
    short_names: ['maximum_symbols_93'],
    imageUrl: '/files/emoji/symbols_93.png',
  },
  {
    name: 'symbols_94',
    short_names: ['maximum_symbols_94'],
    imageUrl: '/files/emoji/symbols_94.png',
  },
  {
    name: 'symbols_95',
    short_names: ['maximum_symbols_95'],
    imageUrl: '/files/emoji/symbols_95.png',
  },
  {
    name: 'symbols_96',
    short_names: ['maximum_symbols_96'],
    imageUrl: '/files/emoji/symbols_96.png',
  },
  {
    name: 'symbols_97',
    short_names: ['maximum_symbols_97'],
    imageUrl: '/files/emoji/symbols_97.png',
  },
  {
    name: 'symbols_98',
    short_names: ['maximum_symbols_98'],
    imageUrl: '/files/emoji/symbols_98.png',
  },
  {
    name: 'symbols_99',
    short_names: ['maximum_symbols_99'],
    imageUrl: '/files/emoji/symbols_99.png',
  },
  {
    name: 'symbols_100',
    short_names: ['maximum_symbols_100'],
    imageUrl: '/files/emoji/symbols_100.png',
  },
  {
    name: 'symbols_101',
    short_names: ['maximum_symbols_101'],
    imageUrl: '/files/emoji/symbols_101.png',
  },
  {
    name: 'symbols_102',
    short_names: ['maximum_symbols_102'],
    imageUrl: '/files/emoji/symbols_102.png',
  },
  {
    name: 'symbols_103',
    short_names: ['maximum_symbols_103'],
    imageUrl: '/files/emoji/symbols_103.png',
  },
  {
    name: 'symbols_104',
    short_names: ['maximum_symbols_104'],
    imageUrl: '/files/emoji/symbols_104.png',
  },
  {
    name: 'symbols_105',
    short_names: ['maximum_symbols_105'],
    imageUrl: '/files/emoji/symbols_105.png',
  },
  {
    name: 'symbols_106',
    short_names: ['maximum_symbols_106'],
    imageUrl: '/files/emoji/symbols_106.png',
  },
  {
    name: 'symbols_107',
    short_names: ['maximum_symbols_107'],
    imageUrl: '/files/emoji/symbols_107.png',
  },
  {
    name: 'symbols_108',
    short_names: ['maximum_symbols_108'],
    imageUrl: '/files/emoji/symbols_108.png',
  },
  {
    name: 'symbols_109',
    short_names: ['maximum_symbols_109'],
    imageUrl: '/files/emoji/symbols_109.png',
  },
  {
    name: 'symbols_110',
    short_names: ['maximum_symbols_110'],
    imageUrl: '/files/emoji/symbols_110.png',
  },
  {
    name: 'symbols_111',
    short_names: ['maximum_symbols_111'],
    imageUrl: '/files/emoji/symbols_111.png',
  },
  {
    name: 'symbols_112',
    short_names: ['maximum_symbols_112'],
    imageUrl: '/files/emoji/symbols_112.png',
  },
  {
    name: 'symbols_113',
    short_names: ['maximum_symbols_113'],
    imageUrl: '/files/emoji/symbols_113.png',
  },
  {
    name: 'symbols_114',
    short_names: ['maximum_symbols_114'],
    imageUrl: '/files/emoji/symbols_114.png',
  },
  {
    name: 'symbols_115',
    short_names: ['maximum_symbols_115'],
    imageUrl: '/files/emoji/symbols_115.png',
  },
  {
    name: 'symbols_116',
    short_names: ['maximum_symbols_116'],
    imageUrl: '/files/emoji/symbols_116.png',
  },
  {
    name: 'symbols_117',
    short_names: ['maximum_symbols_117'],
    imageUrl: '/files/emoji/symbols_117.png',
  },
  {
    name: 'symbols_118',
    short_names: ['maximum_symbols_118'],
    imageUrl: '/files/emoji/symbols_118.png',
  },
  {
    name: 'symbols_119',
    short_names: ['maximum_symbols_119'],
    imageUrl: '/files/emoji/symbols_119.png',
  },
  {
    name: 'symbols_120',
    short_names: ['maximum_symbols_120'],
    imageUrl: '/files/emoji/symbols_120.png',
  },
  {
    name: 'symbols_121',
    short_names: ['maximum_symbols_121'],
    imageUrl: '/files/emoji/symbols_121.png',
  },
  {
    name: 'symbols_122',
    short_names: ['maximum_symbols_122'],
    imageUrl: '/files/emoji/symbols_122.png',
  },
  {
    name: 'symbols_123',
    short_names: ['maximum_symbols_123'],
    imageUrl: '/files/emoji/symbols_123.png',
  },
  {
    name: 'symbols_124',
    short_names: ['maximum_symbols_124'],
    imageUrl: '/files/emoji/symbols_124.png',
  },
  {
    name: 'symbols_125',
    short_names: ['maximum_symbols_125'],
    imageUrl: '/files/emoji/symbols_125.png',
  },
  {
    name: 'symbols_126',
    short_names: ['maximum_symbols_126'],
    imageUrl: '/files/emoji/symbols_126.png',
  },
  {
    name: 'symbols_127',
    short_names: ['maximum_symbols_127'],
    imageUrl: '/files/emoji/symbols_127.png',
  },
  {
    name: 'symbols_128',
    short_names: ['maximum_symbols_128'],
    imageUrl: '/files/emoji/symbols_128.png',
  },
  {
    name: 'symbols_129',
    short_names: ['maximum_symbols_129'],
    imageUrl: '/files/emoji/symbols_129.png',
  },
  {
    name: 'symbols_130',
    short_names: ['maximum_symbols_130'],
    imageUrl: '/files/emoji/symbols_130.png',
  },
  {
    name: 'symbols_131',
    short_names: ['maximum_symbols_131'],
    imageUrl: '/files/emoji/symbols_131.png',
  },
  {
    name: 'symbols_132',
    short_names: ['maximum_symbols_132'],
    imageUrl: '/files/emoji/symbols_132.png',
  },
  {
    name: 'symbols_133',
    short_names: ['maximum_symbols_133'],
    imageUrl: '/files/emoji/symbols_133.png',
  },
  {
    name: 'symbols_134',
    short_names: ['maximum_symbols_134'],
    imageUrl: '/files/emoji/symbols_134.png',
  },
  {
    name: 'symbols_135',
    short_names: ['maximum_symbols_135'],
    imageUrl: '/files/emoji/symbols_135.png',
  },
  {
    name: 'symbols_136',
    short_names: ['maximum_symbols_136'],
    imageUrl: '/files/emoji/symbols_136.png',
  },
  {
    name: 'symbols_137',
    short_names: ['maximum_symbols_137'],
    imageUrl: '/files/emoji/symbols_137.png',
  },
  {
    name: 'symbols_138',
    short_names: ['maximum_symbols_138'],
    imageUrl: '/files/emoji/symbols_138.png',
  },
  {
    name: 'symbols_139',
    short_names: ['maximum_symbols_139'],
    imageUrl: '/files/emoji/symbols_139.png',
  },
  {
    name: 'symbols_140',
    short_names: ['maximum_symbols_140'],
    imageUrl: '/files/emoji/symbols_140.png',
  },
  {
    name: 'symbols_141',
    short_names: ['maximum_symbols_141'],
    imageUrl: '/files/emoji/symbols_141.png',
  },
  {
    name: 'symbols_142',
    short_names: ['maximum_symbols_142'],
    imageUrl: '/files/emoji/symbols_142.png',
  },
  {
    name: 'symbols_143',
    short_names: ['maximum_symbols_143'],
    imageUrl: '/files/emoji/symbols_143.png',
  },
  {
    name: 'symbols_144',
    short_names: ['maximum_symbols_144'],
    imageUrl: '/files/emoji/symbols_144.png',
  },
  {
    name: 'symbols_145',
    short_names: ['maximum_symbols_145'],
    imageUrl: '/files/emoji/symbols_145.png',
  },
  {
    name: 'symbols_146',
    short_names: ['maximum_symbols_146'],
    imageUrl: '/files/emoji/symbols_146.png',
  },
  {
    name: 'symbols_147',
    short_names: ['maximum_symbols_147'],
    imageUrl: '/files/emoji/symbols_147.png',
  },
  {
    name: 'symbols_148',
    short_names: ['maximum_symbols_148'],
    imageUrl: '/files/emoji/symbols_148.png',
  },
  {
    name: 'symbols_149',
    short_names: ['maximum_symbols_149'],
    imageUrl: '/files/emoji/symbols_149.png',
  },
  {
    name: 'symbols_150',
    short_names: ['maximum_symbols_150'],
    imageUrl: '/files/emoji/symbols_150.png',
  },
  {
    name: 'symbols_151',
    short_names: ['maximum_symbols_151'],
    imageUrl: '/files/emoji/symbols_151.png',
  },
  {
    name: 'symbols_152',
    short_names: ['maximum_symbols_152'],
    imageUrl: '/files/emoji/symbols_152.png',
  },
  {
    name: 'symbols_153',
    short_names: ['maximum_symbols_153'],
    imageUrl: '/files/emoji/symbols_153.png',
  },
  {
    name: 'symbols_154',
    short_names: ['maximum_symbols_154'],
    imageUrl: '/files/emoji/symbols_154.png',
  },
  {
    name: 'symbols_155',
    short_names: ['maximum_symbols_155'],
    imageUrl: '/files/emoji/symbols_155.png',
  },
  {
    name: 'symbols_156',
    short_names: ['maximum_symbols_156'],
    imageUrl: '/files/emoji/symbols_156.png',
  },
  {
    name: 'symbols_157',
    short_names: ['maximum_symbols_157'],
    imageUrl: '/files/emoji/symbols_157.png',
  },
  {
    name: 'symbols_158',
    short_names: ['maximum_symbols_158'],
    imageUrl: '/files/emoji/symbols_158.png',
  },
  {
    name: 'symbols_159',
    short_names: ['maximum_symbols_159'],
    imageUrl: '/files/emoji/symbols_159.png',
  },
  {
    name: 'symbols_160',
    short_names: ['maximum_symbols_160'],
    imageUrl: '/files/emoji/symbols_160.png',
  },
  {
    name: 'symbols_161',
    short_names: ['maximum_symbols_161'],
    imageUrl: '/files/emoji/symbols_161.png',
  },
  {
    name: 'symbols_162',
    short_names: ['maximum_symbols_162'],
    imageUrl: '/files/emoji/symbols_162.png',
  },
  {
    name: 'symbols_163',
    short_names: ['maximum_symbols_163'],
    imageUrl: '/files/emoji/symbols_163.png',
  },
  {
    name: 'symbols_164',
    short_names: ['maximum_symbols_164'],
    imageUrl: '/files/emoji/symbols_164.png',
  },
  {
    name: 'symbols_165',
    short_names: ['maximum_symbols_165'],
    imageUrl: '/files/emoji/symbols_165.png',
  },
  {
    name: 'symbols_166',
    short_names: ['maximum_symbols_166'],
    imageUrl: '/files/emoji/symbols_166.png',
  },
  {
    name: 'symbols_167',
    short_names: ['maximum_symbols_167'],
    imageUrl: '/files/emoji/symbols_167.png',
  },
  {
    name: 'symbols_168',
    short_names: ['maximum_symbols_168'],
    imageUrl: '/files/emoji/symbols_168.png',
  },
  {
    name: 'symbols_169',
    short_names: ['maximum_symbols_169'],
    imageUrl: '/files/emoji/symbols_169.png',
  },
  {
    name: 'symbols_170',
    short_names: ['maximum_symbols_170'],
    imageUrl: '/files/emoji/symbols_170.png',
  },
  {
    name: 'symbols_171',
    short_names: ['maximum_symbols_171'],
    imageUrl: '/files/emoji/symbols_171.png',
  },
  {
    name: 'symbols_172',
    short_names: ['maximum_symbols_172'],
    imageUrl: '/files/emoji/symbols_172.png',
  },
  {
    name: 'symbols_173',
    short_names: ['maximum_symbols_173'],
    imageUrl: '/files/emoji/symbols_173.png',
  },
  {
    name: 'symbols_174',
    short_names: ['maximum_symbols_174'],
    imageUrl: '/files/emoji/symbols_174.png',
  },
  {
    name: 'symbols_175',
    short_names: ['maximum_symbols_175'],
    imageUrl: '/files/emoji/symbols_175.png',
  },
  {
    name: 'symbols_176',
    short_names: ['maximum_symbols_176'],
    imageUrl: '/files/emoji/symbols_176.png',
  },
  {
    name: 'symbols_177',
    short_names: ['maximum_symbols_177'],
    imageUrl: '/files/emoji/symbols_177.png',
  },
  {
    name: 'symbols_178',
    short_names: ['maximum_symbols_178'],
    imageUrl: '/files/emoji/symbols_178.png',
  },
  {
    name: 'symbols_179',
    short_names: ['maximum_symbols_179'],
    imageUrl: '/files/emoji/symbols_179.png',
  },
  {
    name: 'symbols_180',
    short_names: ['maximum_symbols_180'],
    imageUrl: '/files/emoji/symbols_180.png',
  },
  {
    name: 'symbols_181',
    short_names: ['maximum_symbols_181'],
    imageUrl: '/files/emoji/symbols_181.png',
  },
  {
    name: 'symbols_182',
    short_names: ['maximum_symbols_182'],
    imageUrl: '/files/emoji/symbols_182.png',
  },
  {
    name: 'symbols_183',
    short_names: ['maximum_symbols_183'],
    imageUrl: '/files/emoji/symbols_183.png',
  },
  {
    name: 'symbols_184',
    short_names: ['maximum_symbols_184'],
    imageUrl: '/files/emoji/symbols_184.png',
  },
  {
    name: 'symbols_185',
    short_names: ['maximum_symbols_185'],
    imageUrl: '/files/emoji/symbols_185.png',
  },
  {
    name: 'symbols_186',
    short_names: ['maximum_symbols_186'],
    imageUrl: '/files/emoji/symbols_186.png',
  },
  {
    name: 'symbols_187',
    short_names: ['maximum_symbols_187'],
    imageUrl: '/files/emoji/symbols_187.png',
  },
  {
    name: 'symbols_188',
    short_names: ['maximum_symbols_188'],
    imageUrl: '/files/emoji/symbols_188.png',
  },
  {
    name: 'symbols_189',
    short_names: ['maximum_symbols_189'],
    imageUrl: '/files/emoji/symbols_189.png',
  },
  {
    name: 'symbols_190',
    short_names: ['maximum_symbols_190'],
    imageUrl: '/files/emoji/symbols_190.png',
  },
  {
    name: 'symbols_191',
    short_names: ['maximum_symbols_191'],
    imageUrl: '/files/emoji/symbols_191.png',
  },
  {
    name: 'symbols_192',
    short_names: ['maximum_symbols_192'],
    imageUrl: '/files/emoji/symbols_192.png',
  },
  {
    name: 'symbols_193',
    short_names: ['maximum_symbols_193'],
    imageUrl: '/files/emoji/symbols_193.png',
  },
  {
    name: 'symbols_194',
    short_names: ['maximum_symbols_194'],
    imageUrl: '/files/emoji/symbols_194.png',
  },
  {
    name: 'symbols_195',
    short_names: ['maximum_symbols_195'],
    imageUrl: '/files/emoji/symbols_195.png',
  },
  {
    name: 'symbols_196',
    short_names: ['maximum_symbols_196'],
    imageUrl: '/files/emoji/symbols_196.png',
  },
  {
    name: 'symbols_197',
    short_names: ['maximum_symbols_197'],
    imageUrl: '/files/emoji/symbols_197.png',
  },
  {
    name: 'symbols_198',
    short_names: ['maximum_symbols_198'],
    imageUrl: '/files/emoji/symbols_198.png',
  },
  {
    name: 'symbols_199',
    short_names: ['maximum_symbols_199'],
    imageUrl: '/files/emoji/symbols_199.png',
  },
  {
    name: 'symbols_200',
    short_names: ['maximum_symbols_200'],
    imageUrl: '/files/emoji/symbols_200.png',
  },
  {
    name: 'symbols_201',
    short_names: ['maximum_symbols_201'],
    imageUrl: '/files/emoji/symbols_201.png',
  },
  {
    name: 'symbols_202',
    short_names: ['maximum_symbols_202'],
    imageUrl: '/files/emoji/symbols_202.png',
  },
  {
    name: 'symbols_203',
    short_names: ['maximum_symbols_203'],
    imageUrl: '/files/emoji/symbols_203.png',
  },
  {
    name: 'symbols_204',
    short_names: ['maximum_symbols_204'],
    imageUrl: '/files/emoji/symbols_204.png',
  },
  {
    name: 'symbols_205',
    short_names: ['maximum_symbols_205'],
    imageUrl: '/files/emoji/symbols_205.png',
  },
  {
    name: 'symbols_206',
    short_names: ['maximum_symbols_206'],
    imageUrl: '/files/emoji/symbols_206.png',
  },
  {
    name: 'symbols_207',
    short_names: ['maximum_symbols_207'],
    imageUrl: '/files/emoji/symbols_207.png',
  },
  {
    name: 'symbols_208',
    short_names: ['maximum_symbols_208'],
    imageUrl: '/files/emoji/symbols_208.png',
  },
  {
    name: 'symbols_209',
    short_names: ['maximum_symbols_209'],
    imageUrl: '/files/emoji/symbols_209.png',
  },
  {
    name: 'symbols_210',
    short_names: ['maximum_symbols_210'],
    imageUrl: '/files/emoji/symbols_210.png',
  },
  {
    name: 'symbols_211',
    short_names: ['maximum_symbols_211'],
    imageUrl: '/files/emoji/symbols_211.png',
  },
  {
    name: 'symbols_212',
    short_names: ['maximum_symbols_212'],
    imageUrl: '/files/emoji/symbols_212.png',
  },
  {
    name: 'symbols_213',
    short_names: ['maximum_symbols_213'],
    imageUrl: '/files/emoji/symbols_213.png',
  },
  {
    name: 'symbols_214',
    short_names: ['maximum_symbols_214'],
    imageUrl: '/files/emoji/symbols_214.png',
  },
  {
    name: 'symbols_215',
    short_names: ['maximum_symbols_215'],
    imageUrl: '/files/emoji/symbols_215.png',
  },
  {
    name: 'symbols_216',
    short_names: ['maximum_symbols_216'],
    imageUrl: '/files/emoji/symbols_216.png',
  },
  {
    name: 'symbols_217',
    short_names: ['maximum_symbols_217'],
    imageUrl: '/files/emoji/symbols_217.png',
  },
  {
    name: 'symbols_218',
    short_names: ['maximum_symbols_218'],
    imageUrl: '/files/emoji/symbols_218.png',
  },
  {
    name: 'symbols_219',
    short_names: ['maximum_symbols_219'],
    imageUrl: '/files/emoji/symbols_219.png',
  },
  {
    name: 'symbols_220',
    short_names: ['maximum_symbols_220'],
    imageUrl: '/files/emoji/symbols_220.png',
  },
  {
    name: 'symbols_221',
    short_names: ['maximum_symbols_221'],
    imageUrl: '/files/emoji/symbols_221.png',
  },
  {
    name: 'symbols_222',
    short_names: ['maximum_symbols_222'],
    imageUrl: '/files/emoji/symbols_222.png',
  },
  {
    name: 'symbols_223',
    short_names: ['maximum_symbols_223'],
    imageUrl: '/files/emoji/symbols_223.png',
  },
  {
    name: 'symbols_224',
    short_names: ['maximum_symbols_224'],
    imageUrl: '/files/emoji/symbols_224.png',
  },
  {
    name: 'symbols_225',
    short_names: ['maximum_symbols_225'],
    imageUrl: '/files/emoji/symbols_225.png',
  },
  {
    name: 'symbols_226',
    short_names: ['maximum_symbols_226'],
    imageUrl: '/files/emoji/symbols_226.png',
  },
  {
    name: 'symbols_227',
    short_names: ['maximum_symbols_227'],
    imageUrl: '/files/emoji/symbols_227.png',
  },
  {
    name: 'symbols_228',
    short_names: ['maximum_symbols_228'],
    imageUrl: '/files/emoji/symbols_228.png',
  },
  {
    name: 'symbols_229',
    short_names: ['maximum_symbols_229'],
    imageUrl: '/files/emoji/symbols_229.png',
  },
  {
    name: 'symbols_230',
    short_names: ['maximum_symbols_230'],
    imageUrl: '/files/emoji/symbols_230.png',
  },
  {
    name: 'symbols_231',
    short_names: ['maximum_symbols_231'],
    imageUrl: '/files/emoji/symbols_231.png',
  },
  {
    name: 'symbols_232',
    short_names: ['maximum_symbols_232'],
    imageUrl: '/files/emoji/symbols_232.png',
  },
  {
    name: 'symbols_233',
    short_names: ['maximum_symbols_233'],
    imageUrl: '/files/emoji/symbols_233.png',
  },
  {
    name: 'symbols_234',
    short_names: ['maximum_symbols_234'],
    imageUrl: '/files/emoji/symbols_234.png',
  },
  {
    name: 'symbols_235',
    short_names: ['maximum_symbols_235'],
    imageUrl: '/files/emoji/symbols_235.png',
  },
  {
    name: 'symbols_236',
    short_names: ['maximum_symbols_236'],
    imageUrl: '/files/emoji/symbols_236.png',
  },
  {
    name: 'symbols_237',
    short_names: ['maximum_symbols_237'],
    imageUrl: '/files/emoji/symbols_237.png',
  },
  {
    name: 'symbols_238',
    short_names: ['maximum_symbols_238'],
    imageUrl: '/files/emoji/symbols_238.png',
  },
  {
    name: 'symbols_239',
    short_names: ['maximum_symbols_239'],
    imageUrl: '/files/emoji/symbols_239.png',
  },
  {
    name: 'symbols_240',
    short_names: ['maximum_symbols_240'],
    imageUrl: '/files/emoji/symbols_240.png',
  },
  {
    name: 'symbols_241',
    short_names: ['maximum_symbols_241'],
    imageUrl: '/files/emoji/symbols_241.png',
  },
  {
    name: 'symbols_242',
    short_names: ['maximum_symbols_242'],
    imageUrl: '/files/emoji/symbols_242.png',
  },
  {
    name: 'symbols_243',
    short_names: ['maximum_symbols_243'],
    imageUrl: '/files/emoji/symbols_243.png',
  },
  {
    name: 'symbols_244',
    short_names: ['maximum_symbols_244'],
    imageUrl: '/files/emoji/symbols_244.png',
  },
  {
    name: 'symbols_245',
    short_names: ['maximum_symbols_245'],
    imageUrl: '/files/emoji/symbols_245.png',
  },
  {
    name: 'symbols_246',
    short_names: ['maximum_symbols_246'],
    imageUrl: '/files/emoji/symbols_246.png',
  },
  {
    name: 'symbols_247',
    short_names: ['maximum_symbols_247'],
    imageUrl: '/files/emoji/symbols_247.png',
  },
  {
    name: 'symbols_248',
    short_names: ['maximum_symbols_248'],
    imageUrl: '/files/emoji/symbols_248.png',
  },
  {
    name: 'symbols_249',
    short_names: ['maximum_symbols_249'],
    imageUrl: '/files/emoji/symbols_249.png',
  },
  {
    name: 'symbols_250',
    short_names: ['maximum_symbols_250'],
    imageUrl: '/files/emoji/symbols_250.png',
  },
  {
    name: 'symbols_251',
    short_names: ['maximum_symbols_251'],
    imageUrl: '/files/emoji/symbols_251.png',
  },
  {
    name: 'symbols_252',
    short_names: ['maximum_symbols_252'],
    imageUrl: '/files/emoji/symbols_252.png',
  },
  {
    name: 'symbols_253',
    short_names: ['maximum_symbols_253'],
    imageUrl: '/files/emoji/symbols_253.png',
  },
  {
    name: 'symbols_254',
    short_names: ['maximum_symbols_254'],
    imageUrl: '/files/emoji/symbols_254.png',
  },
  {
    name: 'symbols_255',
    short_names: ['maximum_symbols_255'],
    imageUrl: '/files/emoji/symbols_255.png',
  },
  {
    name: 'symbols_256',
    short_names: ['maximum_symbols_256'],
    imageUrl: '/files/emoji/symbols_256.png',
  },
  {
    name: 'symbols_257',
    short_names: ['maximum_symbols_257'],
    imageUrl: '/files/emoji/symbols_257.png',
  },
  {
    name: 'symbols_258',
    short_names: ['maximum_symbols_258'],
    imageUrl: '/files/emoji/symbols_258.png',
  },
  {
    name: 'symbols_259',
    short_names: ['maximum_symbols_259'],
    imageUrl: '/files/emoji/symbols_259.png',
  },
  {
    name: 'symbols_260',
    short_names: ['maximum_symbols_260'],
    imageUrl: '/files/emoji/symbols_260.png',
  },
  {
    name: 'symbols_261',
    short_names: ['maximum_symbols_261'],
    imageUrl: '/files/emoji/symbols_261.png',
  },
  {
    name: 'symbols_262',
    short_names: ['maximum_symbols_262'],
    imageUrl: '/files/emoji/symbols_262.png',
  },
  {
    name: 'symbols_263',
    short_names: ['maximum_symbols_263'],
    imageUrl: '/files/emoji/symbols_263.png',
  },
  {
    name: 'symbols_264',
    short_names: ['maximum_symbols_264'],
    imageUrl: '/files/emoji/symbols_264.png',
  },
  {
    name: 'symbols_265',
    short_names: ['maximum_symbols_265'],
    imageUrl: '/files/emoji/symbols_265.png',
  },
  {
    name: 'symbols_266',
    short_names: ['maximum_symbols_266'],
    imageUrl: '/files/emoji/symbols_266.png',
  },
  {
    name: 'symbols_267',
    short_names: ['maximum_symbols_267'],
    imageUrl: '/files/emoji/symbols_267.png',
  },
  {
    name: 'symbols_268',
    short_names: ['maximum_symbols_268'],
    imageUrl: '/files/emoji/symbols_268.png',
  },
  {
    name: 'symbols_269',
    short_names: ['maximum_symbols_269'],
    imageUrl: '/files/emoji/symbols_269.png',
  },
  {
    name: 'symbols_270',
    short_names: ['maximum_symbols_270'],
    imageUrl: '/files/emoji/symbols_270.png',
  },
  {
    name: 'symbols_271',
    short_names: ['maximum_symbols_271'],
    imageUrl: '/files/emoji/symbols_271.png',
  },
  {
    name: 'symbols_272',
    short_names: ['maximum_symbols_272'],
    imageUrl: '/files/emoji/symbols_272.png',
  },
  {
    name: 'symbols_273',
    short_names: ['maximum_symbols_273'],
    imageUrl: '/files/emoji/symbols_273.png',
  },
  {
    name: 'symbols_274',
    short_names: ['maximum_symbols_274'],
    imageUrl: '/files/emoji/symbols_274.png',
  },
  {
    name: 'symbols_275',
    short_names: ['maximum_symbols_275'],
    imageUrl: '/files/emoji/symbols_275.png',
  },
  {
    name: 'symbols_276',
    short_names: ['maximum_symbols_276'],
    imageUrl: '/files/emoji/symbols_276.png',
  },
  {
    name: 'symbols_277',
    short_names: ['maximum_symbols_277'],
    imageUrl: '/files/emoji/symbols_277.png',
  },
  {
    name: 'symbols_278',
    short_names: ['maximum_symbols_278'],
    imageUrl: '/files/emoji/symbols_278.png',
  },
  {
    name: 'symbols_279',
    short_names: ['maximum_symbols_279'],
    imageUrl: '/files/emoji/symbols_279.png',
  },
  {
    name: 'symbols_280',
    short_names: ['maximum_symbols_280'],
    imageUrl: '/files/emoji/symbols_280.png',
  },
  {
    name: 'symbols_281',
    short_names: ['maximum_symbols_281'],
    imageUrl: '/files/emoji/symbols_281.png',
  },
  {
    name: 'symbols_282',
    short_names: ['maximum_symbols_282'],
    imageUrl: '/files/emoji/symbols_282.png',
  },
  {
    name: 'symbols_283',
    short_names: ['maximum_symbols_283'],
    imageUrl: '/files/emoji/symbols_283.png',
  },
  {
    name: 'symbols_284',
    short_names: ['maximum_symbols_284'],
    imageUrl: '/files/emoji/symbols_284.png',
  },
  {
    name: 'symbols_285',
    short_names: ['maximum_symbols_285'],
    imageUrl: '/files/emoji/symbols_285.png',
  },
  {
    name: 'symbols_286',
    short_names: ['maximum_symbols_286'],
    imageUrl: '/files/emoji/symbols_286.png',
  },
  {
    name: 'symbols_287',
    short_names: ['maximum_symbols_287'],
    imageUrl: '/files/emoji/symbols_287.png',
  },
  {
    name: 'symbols_288',
    short_names: ['maximum_symbols_288'],
    imageUrl: '/files/emoji/symbols_288.png',
  },
  {
    name: 'symbols_289',
    short_names: ['maximum_symbols_289'],
    imageUrl: '/files/emoji/symbols_289.png',
  },
  {
    name: 'symbols_290',
    short_names: ['maximum_symbols_290'],
    imageUrl: '/files/emoji/symbols_290.png',
  },
  {
    name: 'symbols_291',
    short_names: ['maximum_symbols_291'],
    imageUrl: '/files/emoji/symbols_291.png',
  },
  {
    name: 'symbols_292',
    short_names: ['maximum_symbols_292'],
    imageUrl: '/files/emoji/symbols_292.png',
  },
  {
    name: 'symbols_293',
    short_names: ['maximum_symbols_293'],
    imageUrl: '/files/emoji/symbols_293.png',
  },
  {
    name: 'symbols_294',
    short_names: ['maximum_symbols_294'],
    imageUrl: '/files/emoji/symbols_294.png',
  },
  {
    name: 'symbols_295',
    short_names: ['maximum_symbols_295'],
    imageUrl: '/files/emoji/symbols_295.png',
  },
  {
    name: 'symbols_296',
    short_names: ['maximum_symbols_296'],
    imageUrl: '/files/emoji/symbols_296.png',
  },
  {
    name: 'symbols_297',
    short_names: ['maximum_symbols_297'],
    imageUrl: '/files/emoji/symbols_297.png',
  },
  {
    name: 'symbols_298',
    short_names: ['maximum_symbols_298'],
    imageUrl: '/files/emoji/symbols_298.png',
  },
  {
    name: 'symbols_299',
    short_names: ['maximum_symbols_299'],
    imageUrl: '/files/emoji/symbols_299.png',
  },
  {
    name: 'symbols_300',
    short_names: ['maximum_symbols_300'],
    imageUrl: '/files/emoji/symbols_300.png',
  },
  {
    name: 'symbols_301',
    short_names: ['maximum_symbols_301'],
    imageUrl: '/files/emoji/symbols_301.png',
  },
  {
    name: 'symbols_302',
    short_names: ['maximum_symbols_302'],
    imageUrl: '/files/emoji/symbols_302.png',
  },
  {
    name: 'symbols_303',
    short_names: ['maximum_symbols_303'],
    imageUrl: '/files/emoji/symbols_303.png',
  },
  {
    name: 'symbols_304',
    short_names: ['maximum_symbols_304'],
    imageUrl: '/files/emoji/symbols_304.png',
  },
  {
    name: 'symbols_305',
    short_names: ['maximum_symbols_305'],
    imageUrl: '/files/emoji/symbols_305.png',
  },
  {
    name: 'symbols_306',
    short_names: ['maximum_symbols_306'],
    imageUrl: '/files/emoji/symbols_306.png',
  },
  {
    name: 'symbols_307',
    short_names: ['maximum_symbols_307'],
    imageUrl: '/files/emoji/symbols_307.png',
  },
  {
    name: 'symbols_308',
    short_names: ['maximum_symbols_308'],
    imageUrl: '/files/emoji/symbols_308.png',
  },
  {
    name: 'symbols_309',
    short_names: ['maximum_symbols_309'],
    imageUrl: '/files/emoji/symbols_309.png',
  },
  {
    name: 'symbols_310',
    short_names: ['maximum_symbols_310'],
    imageUrl: '/files/emoji/symbols_310.png',
  },
  {
    name: 'symbols_311',
    short_names: ['maximum_symbols_311'],
    imageUrl: '/files/emoji/symbols_311.png',
  },
  {
    name: 'symbols_312',
    short_names: ['maximum_symbols_312'],
    imageUrl: '/files/emoji/symbols_312.png',
  },
  {
    name: 'symbols_313',
    short_names: ['maximum_symbols_313'],
    imageUrl: '/files/emoji/symbols_313.png',
  },
  {
    name: 'symbols_314',
    short_names: ['maximum_symbols_314'],
    imageUrl: '/files/emoji/symbols_314.png',
  },
  {
    name: 'symbols_315',
    short_names: ['maximum_symbols_315'],
    imageUrl: '/files/emoji/symbols_315.png',
  },
  {
    name: 'symbols_316',
    short_names: ['maximum_symbols_316'],
    imageUrl: '/files/emoji/symbols_316.png',
  },
  {
    name: 'symbols_317',
    short_names: ['maximum_symbols_317'],
    imageUrl: '/files/emoji/symbols_317.png',
  },
  {
    name: 'symbols_318',
    short_names: ['maximum_symbols_318'],
    imageUrl: '/files/emoji/symbols_318.png',
  },
  {
    name: 'symbols_319',
    short_names: ['maximum_symbols_319'],
    imageUrl: '/files/emoji/symbols_319.png',
  },
  {
    name: 'symbols_320',
    short_names: ['maximum_symbols_320'],
    imageUrl: '/files/emoji/symbols_320.png',
  },
  {
    name: 'symbols_321',
    short_names: ['maximum_symbols_321'],
    imageUrl: '/files/emoji/symbols_321.png',
  },
  {
    name: 'symbols_322',
    short_names: ['maximum_symbols_322'],
    imageUrl: '/files/emoji/symbols_322.png',
  },
  {
    name: 'symbols_323',
    short_names: ['maximum_symbols_323'],
    imageUrl: '/files/emoji/symbols_323.png',
  },
  {
    name: 'symbols_324',
    short_names: ['maximum_symbols_324'],
    imageUrl: '/files/emoji/symbols_324.png',
  },
  {
    name: 'symbols_325',
    short_names: ['maximum_symbols_325'],
    imageUrl: '/files/emoji/symbols_325.png',
  },
  {
    name: 'symbols_326',
    short_names: ['maximum_symbols_326'],
    imageUrl: '/files/emoji/symbols_326.png',
  },
  {
    name: 'symbols_327',
    short_names: ['maximum_symbols_327'],
    imageUrl: '/files/emoji/symbols_327.png',
  },
  {
    name: 'symbols_328',
    short_names: ['maximum_symbols_328'],
    imageUrl: '/files/emoji/symbols_328.png',
  },
  {
    name: 'symbols_329',
    short_names: ['maximum_symbols_329'],
    imageUrl: '/files/emoji/symbols_329.png',
  },
  {
    name: 'symbols_330',
    short_names: ['maximum_symbols_330'],
    imageUrl: '/files/emoji/symbols_330.png',
  },
  {
    name: 'symbols_331',
    short_names: ['maximum_symbols_331'],
    imageUrl: '/files/emoji/symbols_331.png',
  },
  {
    name: 'symbols_332',
    short_names: ['maximum_symbols_332'],
    imageUrl: '/files/emoji/symbols_332.png',
  },
  {
    name: 'symbols_333',
    short_names: ['maximum_symbols_333'],
    imageUrl: '/files/emoji/symbols_333.png',
  },
  {
    name: 'symbols_334',
    short_names: ['maximum_symbols_334'],
    imageUrl: '/files/emoji/symbols_334.png',
  },
  {
    name: 'symbols_335',
    short_names: ['maximum_symbols_335'],
    imageUrl: '/files/emoji/symbols_335.png',
  },
  {
    name: 'symbols_336',
    short_names: ['maximum_symbols_336'],
    imageUrl: '/files/emoji/symbols_336.png',
  },
  {
    name: 'travel_1',
    short_names: ['maximum_travel_1'],
    imageUrl: '/files/emoji/travel_1.png',
  },
  {
    name: 'travel_2',
    short_names: ['maximum_travel_2'],
    imageUrl: '/files/emoji/travel_2.png',
  },
  {
    name: 'travel_3',
    short_names: ['maximum_travel_3'],
    imageUrl: '/files/emoji/travel_3.png',
  },
  {
    name: 'travel_4',
    short_names: ['maximum_travel_4'],
    imageUrl: '/files/emoji/travel_4.png',
  },
  {
    name: 'travel_5',
    short_names: ['maximum_travel_5'],
    imageUrl: '/files/emoji/travel_5.png',
  },
  {
    name: 'travel_6',
    short_names: ['maximum_travel_6'],
    imageUrl: '/files/emoji/travel_6.png',
  },
  {
    name: 'travel_7',
    short_names: ['maximum_travel_7'],
    imageUrl: '/files/emoji/travel_7.png',
  },
  {
    name: 'travel_8',
    short_names: ['maximum_travel_8'],
    imageUrl: '/files/emoji/travel_8.png',
  },
  {
    name: 'travel_9',
    short_names: ['maximum_travel_9'],
    imageUrl: '/files/emoji/travel_9.png',
  },
  {
    name: 'travel_10',
    short_names: ['maximum_travel_10'],
    imageUrl: '/files/emoji/travel_10.png',
  },
  {
    name: 'travel_11',
    short_names: ['maximum_travel_11'],
    imageUrl: '/files/emoji/travel_11.png',
  },
  {
    name: 'travel_12',
    short_names: ['maximum_travel_12'],
    imageUrl: '/files/emoji/travel_12.png',
  },
  {
    name: 'travel_13',
    short_names: ['maximum_travel_13'],
    imageUrl: '/files/emoji/travel_13.png',
  },
  {
    name: 'travel_14',
    short_names: ['maximum_travel_14'],
    imageUrl: '/files/emoji/travel_14.png',
  },
  {
    name: 'travel_15',
    short_names: ['maximum_travel_15'],
    imageUrl: '/files/emoji/travel_15.png',
  },
  {
    name: 'travel_16',
    short_names: ['maximum_travel_16'],
    imageUrl: '/files/emoji/travel_16.png',
  },
  {
    name: 'travel_17',
    short_names: ['maximum_travel_17'],
    imageUrl: '/files/emoji/travel_17.png',
  },
  {
    name: 'travel_18',
    short_names: ['maximum_travel_18'],
    imageUrl: '/files/emoji/travel_18.png',
  },
  {
    name: 'travel_19',
    short_names: ['maximum_travel_19'],
    imageUrl: '/files/emoji/travel_19.png',
  },
  {
    name: 'travel_20',
    short_names: ['maximum_travel_20'],
    imageUrl: '/files/emoji/travel_20.png',
  },
  {
    name: 'travel_21',
    short_names: ['maximum_travel_21'],
    imageUrl: '/files/emoji/travel_21.png',
  },
  {
    name: 'travel_22',
    short_names: ['maximum_travel_22'],
    imageUrl: '/files/emoji/travel_22.png',
  },
  {
    name: 'travel_23',
    short_names: ['maximum_travel_23'],
    imageUrl: '/files/emoji/travel_23.png',
  },
  {
    name: 'travel_24',
    short_names: ['maximum_travel_24'],
    imageUrl: '/files/emoji/travel_24.png',
  },
  {
    name: 'travel_25',
    short_names: ['maximum_travel_25'],
    imageUrl: '/files/emoji/travel_25.png',
  },
  {
    name: 'travel_26',
    short_names: ['maximum_travel_26'],
    imageUrl: '/files/emoji/travel_26.png',
  },
  {
    name: 'travel_27',
    short_names: ['maximum_travel_27'],
    imageUrl: '/files/emoji/travel_27.png',
  },
  {
    name: 'travel_28',
    short_names: ['maximum_travel_28'],
    imageUrl: '/files/emoji/travel_28.png',
  },
  {
    name: 'travel_29',
    short_names: ['maximum_travel_29'],
    imageUrl: '/files/emoji/travel_29.png',
  },
  {
    name: 'travel_30',
    short_names: ['maximum_travel_30'],
    imageUrl: '/files/emoji/travel_30.png',
  },
  {
    name: 'travel_31',
    short_names: ['maximum_travel_31'],
    imageUrl: '/files/emoji/travel_31.png',
  },
  {
    name: 'travel_32',
    short_names: ['maximum_travel_32'],
    imageUrl: '/files/emoji/travel_32.png',
  },
  {
    name: 'travel_33',
    short_names: ['maximum_travel_33'],
    imageUrl: '/files/emoji/travel_33.png',
  },
  {
    name: 'travel_34',
    short_names: ['maximum_travel_34'],
    imageUrl: '/files/emoji/travel_34.png',
  },
  {
    name: 'travel_35',
    short_names: ['maximum_travel_35'],
    imageUrl: '/files/emoji/travel_35.png',
  },
  {
    name: 'travel_36',
    short_names: ['maximum_travel_36'],
    imageUrl: '/files/emoji/travel_36.png',
  },
  {
    name: 'travel_37',
    short_names: ['maximum_travel_37'],
    imageUrl: '/files/emoji/travel_37.png',
  },
  {
    name: 'travel_38',
    short_names: ['maximum_travel_38'],
    imageUrl: '/files/emoji/travel_38.png',
  },
  {
    name: 'travel_39',
    short_names: ['maximum_travel_39'],
    imageUrl: '/files/emoji/travel_39.png',
  },
  {
    name: 'travel_40',
    short_names: ['maximum_travel_40'],
    imageUrl: '/files/emoji/travel_40.png',
  },
  {
    name: 'travel_41',
    short_names: ['maximum_travel_41'],
    imageUrl: '/files/emoji/travel_41.png',
  },
  {
    name: 'travel_42',
    short_names: ['maximum_travel_42'],
    imageUrl: '/files/emoji/travel_42.png',
  },
  {
    name: 'travel_43',
    short_names: ['maximum_travel_43'],
    imageUrl: '/files/emoji/travel_43.png',
  },
  {
    name: 'travel_44',
    short_names: ['maximum_travel_44'],
    imageUrl: '/files/emoji/travel_44.png',
  },
  {
    name: 'travel_45',
    short_names: ['maximum_travel_45'],
    imageUrl: '/files/emoji/travel_45.png',
  },
  {
    name: 'travel_46',
    short_names: ['maximum_travel_46'],
    imageUrl: '/files/emoji/travel_46.png',
  },
  {
    name: 'travel_47',
    short_names: ['maximum_travel_47'],
    imageUrl: '/files/emoji/travel_47.png',
  },
  {
    name: 'travel_48',
    short_names: ['maximum_travel_48'],
    imageUrl: '/files/emoji/travel_48.png',
  },
  {
    name: 'travel_49',
    short_names: ['maximum_travel_49'],
    imageUrl: '/files/emoji/travel_49.png',
  },
  {
    name: 'travel_50',
    short_names: ['maximum_travel_50'],
    imageUrl: '/files/emoji/travel_50.png',
  },
  {
    name: 'travel_51',
    short_names: ['maximum_travel_51'],
    imageUrl: '/files/emoji/travel_51.png',
  },
  {
    name: 'travel_52',
    short_names: ['maximum_travel_52'],
    imageUrl: '/files/emoji/travel_52.png',
  },
  {
    name: 'travel_53',
    short_names: ['maximum_travel_53'],
    imageUrl: '/files/emoji/travel_53.png',
  },
  {
    name: 'travel_54',
    short_names: ['maximum_travel_54'],
    imageUrl: '/files/emoji/travel_54.png',
  },
  {
    name: 'travel_55',
    short_names: ['maximum_travel_55'],
    imageUrl: '/files/emoji/travel_55.png',
  },
  {
    name: 'travel_56',
    short_names: ['maximum_travel_56'],
    imageUrl: '/files/emoji/travel_56.png',
  },
  {
    name: 'travel_57',
    short_names: ['maximum_travel_57'],
    imageUrl: '/files/emoji/travel_57.png',
  },
  {
    name: 'travel_58',
    short_names: ['maximum_travel_58'],
    imageUrl: '/files/emoji/travel_58.png',
  },
  {
    name: 'travel_59',
    short_names: ['maximum_travel_59'],
    imageUrl: '/files/emoji/travel_59.png',
  },
  {
    name: 'travel_60',
    short_names: ['maximum_travel_60'],
    imageUrl: '/files/emoji/travel_60.png',
  },
  {
    name: 'travel_61',
    short_names: ['maximum_travel_61'],
    imageUrl: '/files/emoji/travel_61.png',
  },
  {
    name: 'travel_62',
    short_names: ['maximum_travel_62'],
    imageUrl: '/files/emoji/travel_62.png',
  },
  {
    name: 'travel_63',
    short_names: ['maximum_travel_63'],
    imageUrl: '/files/emoji/travel_63.png',
  },
  {
    name: 'travel_64',
    short_names: ['maximum_travel_64'],
    imageUrl: '/files/emoji/travel_64.png',
  },
  {
    name: 'travel_65',
    short_names: ['maximum_travel_65'],
    imageUrl: '/files/emoji/travel_65.png',
  },
  {
    name: 'travel_66',
    short_names: ['maximum_travel_66'],
    imageUrl: '/files/emoji/travel_66.png',
  },
  {
    name: 'travel_67',
    short_names: ['maximum_travel_67'],
    imageUrl: '/files/emoji/travel_67.png',
  },
  {
    name: 'travel_68',
    short_names: ['maximum_travel_68'],
    imageUrl: '/files/emoji/travel_68.png',
  },
  {
    name: 'travel_69',
    short_names: ['maximum_travel_69'],
    imageUrl: '/files/emoji/travel_69.png',
  },
  {
    name: 'travel_70',
    short_names: ['maximum_travel_70'],
    imageUrl: '/files/emoji/travel_70.png',
  },
  {
    name: 'travel_71',
    short_names: ['maximum_travel_71'],
    imageUrl: '/files/emoji/travel_71.png',
  },
  {
    name: 'travel_72',
    short_names: ['maximum_travel_72'],
    imageUrl: '/files/emoji/travel_72.png',
  },
  {
    name: 'travel_73',
    short_names: ['maximum_travel_73'],
    imageUrl: '/files/emoji/travel_73.png',
  },
  {
    name: 'travel_74',
    short_names: ['maximum_travel_74'],
    imageUrl: '/files/emoji/travel_74.png',
  },
  {
    name: 'travel_75',
    short_names: ['maximum_travel_75'],
    imageUrl: '/files/emoji/travel_75.png',
  },
  {
    name: 'travel_76',
    short_names: ['maximum_travel_76'],
    imageUrl: '/files/emoji/travel_76.png',
  },
  {
    name: 'travel_77',
    short_names: ['maximum_travel_77'],
    imageUrl: '/files/emoji/travel_77.png',
  },
  {
    name: 'travel_78',
    short_names: ['maximum_travel_78'],
    imageUrl: '/files/emoji/travel_78.png',
  },
  {
    name: 'travel_79',
    short_names: ['maximum_travel_79'],
    imageUrl: '/files/emoji/travel_79.png',
  },
  {
    name: 'travel_80',
    short_names: ['maximum_travel_80'],
    imageUrl: '/files/emoji/travel_80.png',
  },
  {
    name: 'travel_81',
    short_names: ['maximum_travel_81'],
    imageUrl: '/files/emoji/travel_81.png',
  },
  {
    name: 'travel_82',
    short_names: ['maximum_travel_82'],
    imageUrl: '/files/emoji/travel_82.png',
  },
  {
    name: 'travel_83',
    short_names: ['maximum_travel_83'],
    imageUrl: '/files/emoji/travel_83.png',
  },
  {
    name: 'travel_84',
    short_names: ['maximum_travel_84'],
    imageUrl: '/files/emoji/travel_84.png',
  },
  {
    name: 'travel_85',
    short_names: ['maximum_travel_85'],
    imageUrl: '/files/emoji/travel_85.png',
  },
  {
    name: 'travel_86',
    short_names: ['maximum_travel_86'],
    imageUrl: '/files/emoji/travel_86.png',
  },
  {
    name: 'travel_87',
    short_names: ['maximum_travel_87'],
    imageUrl: '/files/emoji/travel_87.png',
  },
  {
    name: 'travel_88',
    short_names: ['maximum_travel_88'],
    imageUrl: '/files/emoji/travel_88.png',
  },
  {
    name: 'travel_89',
    short_names: ['maximum_travel_89'],
    imageUrl: '/files/emoji/travel_89.png',
  },
  {
    name: 'travel_90',
    short_names: ['maximum_travel_90'],
    imageUrl: '/files/emoji/travel_90.png',
  },
  {
    name: 'travel_91',
    short_names: ['maximum_travel_91'],
    imageUrl: '/files/emoji/travel_91.png',
  },
  {
    name: 'travel_92',
    short_names: ['maximum_travel_92'],
    imageUrl: '/files/emoji/travel_92.png',
  },
  {
    name: 'travel_93',
    short_names: ['maximum_travel_93'],
    imageUrl: '/files/emoji/travel_93.png',
  },
  {
    name: 'travel_94',
    short_names: ['maximum_travel_94'],
    imageUrl: '/files/emoji/travel_94.png',
  },
  {
    name: 'travel_95',
    short_names: ['maximum_travel_95'],
    imageUrl: '/files/emoji/travel_95.png',
  },
  {
    name: 'travel_96',
    short_names: ['maximum_travel_96'],
    imageUrl: '/files/emoji/travel_96.png',
  },
  {
    name: 'travel_97',
    short_names: ['maximum_travel_97'],
    imageUrl: '/files/emoji/travel_97.png',
  },
  {
    name: 'travel_98',
    short_names: ['maximum_travel_98'],
    imageUrl: '/files/emoji/travel_98.png',
  },
  {
    name: 'travel_99',
    short_names: ['maximum_travel_99'],
    imageUrl: '/files/emoji/travel_99.png',
  },
  {
    name: 'travel_100',
    short_names: ['maximum_travel_100'],
    imageUrl: '/files/emoji/travel_100.png',
  },
  {
    name: 'travel_101',
    short_names: ['maximum_travel_101'],
    imageUrl: '/files/emoji/travel_101.png',
  },
  {
    name: 'travel_102',
    short_names: ['maximum_travel_102'],
    imageUrl: '/files/emoji/travel_102.png',
  },
  {
    name: 'travel_103',
    short_names: ['maximum_travel_103'],
    imageUrl: '/files/emoji/travel_103.png',
  },
  {
    name: 'travel_104',
    short_names: ['maximum_travel_104'],
    imageUrl: '/files/emoji/travel_104.png',
  },
  {
    name: 'travel_105',
    short_names: ['maximum_travel_105'],
    imageUrl: '/files/emoji/travel_105.png',
  },
  {
    name: 'travel_106',
    short_names: ['maximum_travel_106'],
    imageUrl: '/files/emoji/travel_106.png',
  },
  {
    name: 'travel_107',
    short_names: ['maximum_travel_107'],
    imageUrl: '/files/emoji/travel_107.png',
  },
  {
    name: 'travel_108',
    short_names: ['maximum_travel_108'],
    imageUrl: '/files/emoji/travel_108.png',
  },
  {
    name: 'travel_109',
    short_names: ['maximum_travel_109'],
    imageUrl: '/files/emoji/travel_109.png',
  },
  {
    name: 'travel_110',
    short_names: ['maximum_travel_110'],
    imageUrl: '/files/emoji/travel_110.png',
  },
  {
    name: 'travel_111',
    short_names: ['maximum_travel_111'],
    imageUrl: '/files/emoji/travel_111.png',
  },
  {
    name: 'travel_112',
    short_names: ['maximum_travel_112'],
    imageUrl: '/files/emoji/travel_112.png',
  },
  {
    name: 'travel_113',
    short_names: ['maximum_travel_113'],
    imageUrl: '/files/emoji/travel_113.png',
  },
  {
    name: 'travel_114',
    short_names: ['maximum_travel_114'],
    imageUrl: '/files/emoji/travel_114.png',
  },
  {
    name: 'travel_115',
    short_names: ['maximum_travel_115'],
    imageUrl: '/files/emoji/travel_115.png',
  },
  {
    name: 'travel_116',
    short_names: ['maximum_travel_116'],
    imageUrl: '/files/emoji/travel_116.png',
  },
  {
    name: 'travel_117',
    short_names: ['maximum_travel_117'],
    imageUrl: '/files/emoji/travel_117.png',
  },
  {
    name: 'travel_118',
    short_names: ['maximum_travel_118'],
    imageUrl: '/files/emoji/travel_118.png',
  },
  {
    name: 'travel_119',
    short_names: ['maximum_travel_119'],
    imageUrl: '/files/emoji/travel_119.png',
  },
  {
    name: 'travel_120',
    short_names: ['maximum_travel_120'],
    imageUrl: '/files/emoji/travel_120.png',
  },
  {
    name: 'travel_121',
    short_names: ['maximum_travel_121'],
    imageUrl: '/files/emoji/travel_121.png',
  },
  {
    name: 'travel_122',
    short_names: ['maximum_travel_122'],
    imageUrl: '/files/emoji/travel_122.png',
  },
  {
    name: 'travel_123',
    short_names: ['maximum_travel_123'],
    imageUrl: '/files/emoji/travel_123.png',
  },
  {
    name: 'travel_124',
    short_names: ['maximum_travel_124'],
    imageUrl: '/files/emoji/travel_124.png',
  },
  {
    name: 'travel_125',
    short_names: ['maximum_travel_125'],
    imageUrl: '/files/emoji/travel_125.png',
  },
  {
    name: 'travel_126',
    short_names: ['maximum_travel_126'],
    imageUrl: '/files/emoji/travel_126.png',
  },
  {
    name: 'travel_127',
    short_names: ['maximum_travel_127'],
    imageUrl: '/files/emoji/travel_127.png',
  },
  {
    name: 'travel_128',
    short_names: ['maximum_travel_128'],
    imageUrl: '/files/emoji/travel_128.png',
  },
  {
    name: 'travel_129',
    short_names: ['maximum_travel_129'],
    imageUrl: '/files/emoji/travel_129.png',
  },
  {
    name: 'travel_130',
    short_names: ['maximum_travel_130'],
    imageUrl: '/files/emoji/travel_130.png',
  },
  {
    name: 'travel_131',
    short_names: ['maximum_travel_131'],
    imageUrl: '/files/emoji/travel_131.png',
  },
  {
    name: 'travel_132',
    short_names: ['maximum_travel_132'],
    imageUrl: '/files/emoji/travel_132.png',
  },
  {
    name: 'travel_133',
    short_names: ['maximum_travel_133'],
    imageUrl: '/files/emoji/travel_133.png',
  },
  {
    name: 'travel_134',
    short_names: ['maximum_travel_134'],
    imageUrl: '/files/emoji/travel_134.png',
  },
  {
    name: 'travel_135',
    short_names: ['maximum_travel_135'],
    imageUrl: '/files/emoji/travel_135.png',
  },
  {
    name: 'travel_136',
    short_names: ['maximum_travel_136'],
    imageUrl: '/files/emoji/travel_136.png',
  },
  {
    name: 'travel_137',
    short_names: ['maximum_travel_137'],
    imageUrl: '/files/emoji/travel_137.png',
  },
  {
    name: 'travel_138',
    short_names: ['maximum_travel_138'],
    imageUrl: '/files/emoji/travel_138.png',
  },
  {
    name: 'travel_139',
    short_names: ['maximum_travel_139'],
    imageUrl: '/files/emoji/travel_139.png',
  },
  {
    name: 'travel_140',
    short_names: ['maximum_travel_140'],
    imageUrl: '/files/emoji/travel_140.png',
  },
  {
    name: 'travel_141',
    short_names: ['maximum_travel_141'],
    imageUrl: '/files/emoji/travel_141.png',
  },
  {
    name: 'travel_142',
    short_names: ['maximum_travel_142'],
    imageUrl: '/files/emoji/travel_142.png',
  },
  {
    name: 'travel_143',
    short_names: ['maximum_travel_143'],
    imageUrl: '/files/emoji/travel_143.png',
  },
  {
    name: 'travel_144',
    short_names: ['maximum_travel_144'],
    imageUrl: '/files/emoji/travel_144.png',
  },
  {
    name: 'travel_145',
    short_names: ['maximum_travel_145'],
    imageUrl: '/files/emoji/travel_145.png',
  },
  {
    name: 'travel_146',
    short_names: ['maximum_travel_146'],
    imageUrl: '/files/emoji/travel_146.png',
  },
  {
    name: 'travel_147',
    short_names: ['maximum_travel_147'],
    imageUrl: '/files/emoji/travel_147.png',
  },
  {
    name: 'travel_148',
    short_names: ['maximum_travel_148'],
    imageUrl: '/files/emoji/travel_148.png',
  },
  {
    name: 'travel_149',
    short_names: ['maximum_travel_149'],
    imageUrl: '/files/emoji/travel_149.png',
  },
  {
    name: 'travel_150',
    short_names: ['maximum_travel_150'],
    imageUrl: '/files/emoji/travel_150.png',
  },
  {
    name: 'travel_151',
    short_names: ['maximum_travel_151'],
    imageUrl: '/files/emoji/travel_151.png',
  },
  {
    name: 'travel_152',
    short_names: ['maximum_travel_152'],
    imageUrl: '/files/emoji/travel_152.png',
  },
  {
    name: 'travel_153',
    short_names: ['maximum_travel_153'],
    imageUrl: '/files/emoji/travel_153.png',
  },
  {
    name: 'travel_154',
    short_names: ['maximum_travel_154'],
    imageUrl: '/files/emoji/travel_154.png',
  },
  {
    name: 'travel_155',
    short_names: ['maximum_travel_155'],
    imageUrl: '/files/emoji/travel_155.png',
  },
  {
    name: 'travel_156',
    short_names: ['maximum_travel_156'],
    imageUrl: '/files/emoji/travel_156.png',
  },
  {
    name: 'travel_157',
    short_names: ['maximum_travel_157'],
    imageUrl: '/files/emoji/travel_157.png',
  },
  {
    name: 'travel_158',
    short_names: ['maximum_travel_158'],
    imageUrl: '/files/emoji/travel_158.png',
  },
  {
    name: 'travel_159',
    short_names: ['maximum_travel_159'],
    imageUrl: '/files/emoji/travel_159.png',
  },
  {
    name: 'travel_160',
    short_names: ['maximum_travel_160'],
    imageUrl: '/files/emoji/travel_160.png',
  },
  {
    name: 'travel_161',
    short_names: ['maximum_travel_161'],
    imageUrl: '/files/emoji/travel_161.png',
  },
  {
    name: 'travel_162',
    short_names: ['maximum_travel_162'],
    imageUrl: '/files/emoji/travel_162.png',
  },
  {
    name: 'travel_163',
    short_names: ['maximum_travel_163'],
    imageUrl: '/files/emoji/travel_163.png',
  },
  {
    name: 'travel_164',
    short_names: ['maximum_travel_164'],
    imageUrl: '/files/emoji/travel_164.png',
  },
  {
    name: 'travel_165',
    short_names: ['maximum_travel_165'],
    imageUrl: '/files/emoji/travel_165.png',
  },
  {
    name: 'travel_166',
    short_names: ['maximum_travel_166'],
    imageUrl: '/files/emoji/travel_166.png',
  },
  {
    name: 'travel_167',
    short_names: ['maximum_travel_167'],
    imageUrl: '/files/emoji/travel_167.png',
  },
  {
    name: 'travel_168',
    short_names: ['maximum_travel_168'],
    imageUrl: '/files/emoji/travel_168.png',
  },
  {
    name: 'travel_169',
    short_names: ['maximum_travel_169'],
    imageUrl: '/files/emoji/travel_169.png',
  },
  {
    name: 'travel_170',
    short_names: ['maximum_travel_170'],
    imageUrl: '/files/emoji/travel_170.png',
  },
  {
    name: 'travel_171',
    short_names: ['maximum_travel_171'],
    imageUrl: '/files/emoji/travel_171.png',
  },
  {
    name: 'travel_172',
    short_names: ['maximum_travel_172'],
    imageUrl: '/files/emoji/travel_172.png',
  },
  {
    name: 'travel_173',
    short_names: ['maximum_travel_173'],
    imageUrl: '/files/emoji/travel_173.png',
  },
  {
    name: 'travel_174',
    short_names: ['maximum_travel_174'],
    imageUrl: '/files/emoji/travel_174.png',
  },
  {
    name: 'travel_175',
    short_names: ['maximum_travel_175'],
    imageUrl: '/files/emoji/travel_175.png',
  },
  {
    name: 'travel_176',
    short_names: ['maximum_travel_176'],
    imageUrl: '/files/emoji/travel_176.png',
  },
  {
    name: 'travel_177',
    short_names: ['maximum_travel_177'],
    imageUrl: '/files/emoji/travel_177.png',
  },
  {
    name: 'travel_178',
    short_names: ['maximum_travel_178'],
    imageUrl: '/files/emoji/travel_178.png',
  },
  {
    name: 'travel_179',
    short_names: ['maximum_travel_179'],
    imageUrl: '/files/emoji/travel_179.png',
  },
  {
    name: 'travel_180',
    short_names: ['maximum_travel_180'],
    imageUrl: '/files/emoji/travel_180.png',
  },
  {
    name: 'travel_181',
    short_names: ['maximum_travel_181'],
    imageUrl: '/files/emoji/travel_181.png',
  },
  {
    name: 'travel_182',
    short_names: ['maximum_travel_182'],
    imageUrl: '/files/emoji/travel_182.png',
  },
  {
    name: 'travel_183',
    short_names: ['maximum_travel_183'],
    imageUrl: '/files/emoji/travel_183.png',
  },
  {
    name: 'travel_184',
    short_names: ['maximum_travel_184'],
    imageUrl: '/files/emoji/travel_184.png',
  },
  {
    name: 'travel_185',
    short_names: ['maximum_travel_185'],
    imageUrl: '/files/emoji/travel_185.png',
  },
  {
    name: 'travel_186',
    short_names: ['maximum_travel_186'],
    imageUrl: '/files/emoji/travel_186.png',
  },
  {
    name: 'travel_187',
    short_names: ['maximum_travel_187'],
    imageUrl: '/files/emoji/travel_187.png',
  },
  {
    name: 'travel_188',
    short_names: ['maximum_travel_188'],
    imageUrl: '/files/emoji/travel_188.png',
  },
  {
    name: 'travel_189',
    short_names: ['maximum_travel_189'],
    imageUrl: '/files/emoji/travel_189.png',
  },
  {
    name: 'travel_190',
    short_names: ['maximum_travel_190'],
    imageUrl: '/files/emoji/travel_190.png',
  },
  {
    name: 'travel_191',
    short_names: ['maximum_travel_191'],
    imageUrl: '/files/emoji/travel_191.png',
  },
  {
    name: 'travel_192',
    short_names: ['maximum_travel_192'],
    imageUrl: '/files/emoji/travel_192.png',
  },
  {
    name: 'travel_193',
    short_names: ['maximum_travel_193'],
    imageUrl: '/files/emoji/travel_193.png',
  },
  {
    name: 'travel_194',
    short_names: ['maximum_travel_194'],
    imageUrl: '/files/emoji/travel_194.png',
  },
  {
    name: 'travel_195',
    short_names: ['maximum_travel_195'],
    imageUrl: '/files/emoji/travel_195.png',
  },
  {
    name: 'travel_196',
    short_names: ['maximum_travel_196'],
    imageUrl: '/files/emoji/travel_196.png',
  },
  {
    name: 'travel_197',
    short_names: ['maximum_travel_197'],
    imageUrl: '/files/emoji/travel_197.png',
  },
  {
    name: 'travel_198',
    short_names: ['maximum_travel_198'],
    imageUrl: '/files/emoji/travel_198.png',
  },
  {
    name: 'travel_199',
    short_names: ['maximum_travel_199'],
    imageUrl: '/files/emoji/travel_199.png',
  },
  {
    name: 'travel_200',
    short_names: ['maximum_travel_200'],
    imageUrl: '/files/emoji/travel_200.png',
  },
  {
    name: 'travel_201',
    short_names: ['maximum_travel_201'],
    imageUrl: '/files/emoji/travel_201.png',
  },
  {
    name: 'travel_202',
    short_names: ['maximum_travel_202'],
    imageUrl: '/files/emoji/travel_202.png',
  },
  {
    name: 'travel_203',
    short_names: ['maximum_travel_203'],
    imageUrl: '/files/emoji/travel_203.png',
  },
  {
    name: 'travel_204',
    short_names: ['maximum_travel_204'],
    imageUrl: '/files/emoji/travel_204.png',
  },
  {
    name: 'travel_205',
    short_names: ['maximum_travel_205'],
    imageUrl: '/files/emoji/travel_205.png',
  },
  {
    name: 'travel_206',
    short_names: ['maximum_travel_206'],
    imageUrl: '/files/emoji/travel_206.png',
  },
  {
    name: 'travel_207',
    short_names: ['maximum_travel_207'],
    imageUrl: '/files/emoji/travel_207.png',
  },
  {
    name: 'travel_208',
    short_names: ['maximum_travel_208'],
    imageUrl: '/files/emoji/travel_208.png',
  },
  {
    name: 'travel_209',
    short_names: ['maximum_travel_209'],
    imageUrl: '/files/emoji/travel_209.png',
  },
  {
    name: 'travel_210',
    short_names: ['maximum_travel_210'],
    imageUrl: '/files/emoji/travel_210.png',
  },
  {
    name: 'travel_211',
    short_names: ['maximum_travel_211'],
    imageUrl: '/files/emoji/travel_211.png',
  },
  {
    name: 'travel_212',
    short_names: ['maximum_travel_212'],
    imageUrl: '/files/emoji/travel_212.png',
  },
]
