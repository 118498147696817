import React from 'react'
import { Field } from 'redux-form'
import styled from 'styled-components'
import { Button, withRole } from '../commons'
import { InputLabel } from '@material-ui/core'
import LessonSelect from './LessonSelect'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  width: 100%;
`

const Label = styled(InputLabel)`
  margin-right: 15px;
`

const Index = styled.span`
  margin-right: 15px;
`

const ButtonWithRole = withRole(Button, true)

const renderField = ({ input, index, options }) => (
  <Container>
    <Index>{index + 1}</Index>
    <LessonSelect options={options} input={input} />
  </Container>
)

const LessonsForm = ({ fields, options, label, selectedLessons }) => (
  <div>
    <Label>{label}</Label>
    {fields.map((lesson, i) => (
      <Container>
        <Field
          index={i}
          name={lesson}
          options={options}
          component={renderField}
        />
        <ButtonWithRole name={'X'} onClick={() => fields.remove(i)} />
      </Container>
    ))}
    {selectedLessons.length !== options.length && (
      <ButtonWithRole onClick={() => fields.push()}>
        Добавить урок
      </ButtonWithRole>
    )}
  </div>
)

export default LessonsForm
