import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { withHandlers } from 'recompose'
import { Grid, Table, TableHead, TableRow, TableBody } from '@material-ui/core'
import {
  EditButton,
  DeleteButton,
  TableCell,
  Button,
  withRole,
} from '../commons'
import {
  getFillGapsTable,
  selectStage,
  deleteFillGaps,
} from '../../../modules/stageTwo'

const AddTaskButton = styled(Button)`
  margin: 20px auto;
`

const Cell = styled(TableCell)`
  text-align: start;
`

const EditButtonWithRole = withRole(EditButton, true)
const AddTaskButtonWithRole = withRole(AddTaskButton, true)

const TableFillGapsDamp = ({ active, fillGaps = [], openModal, remove }) => (
  <Grid container spacing={16} justify="space-around">
    <Grid item sm={11}>
      <Table>
        <TableHead>
          <TableRow>
            <Cell>Текст</Cell>
            <Cell>Варианты ответов</Cell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {fillGaps.map(({ testType, text, variants }, index) => (
            <TableRow key={index}>
              <Cell style={{ 'white-space': 'pre' }}>{text}</Cell>
              <Cell>{variants}</Cell>
              <TableCell>
                <EditButtonWithRole
                  onClick={() => openModal({ index, testType })}
                />
              </TableCell>
              <TableCell>
                <DeleteButton onClick={() => remove(index)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>

    <Grid item sm={2}>
      <AddTaskButtonWithRole onClick={() => openModal(null)} type="submit">
        Добавить задание
      </AddTaskButtonWithRole>
    </Grid>
  </Grid>
)

const TableFillGaps = R.compose(
  connect(
    R.applySpec({ fillGaps: getFillGapsTable }),
    { selectStage, deleteFillGaps },
  ),
  withHandlers({
    remove: ({ deleteFillGaps }) => index => deleteFillGaps(index),
    openModal: ({ selectStage, enableModal }) => payload => {
      selectStage(payload)
      enableModal()
    },
  }),
)(TableFillGapsDamp)

export default TableFillGaps
