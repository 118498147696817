import React from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import styled from 'styled-components'

const Container = styled(FormControl)`
  height: 61px;
  width: 20%;
`

export const SelectInputField = ({ input, items = [], label, ...rest }) => (
  <Container>
    <InputLabel>{label}</InputLabel>
    <Select {...input} {...rest} inputProps={{ name: label }}>
      {items.map(item => (
        <MenuItem value={item.value}>{item.text}</MenuItem>
      ))}
    </Select>
  </Container>
)

export default SelectInputField
