import { takeLatest, put, call, select } from 'redux-saga/effects'
import { getToken } from '../auth'
import { fetchProfile, ProfileManager, setProfile } from '.'

const fetchProfileSaga = function*() {
  const token = yield select(getToken)
  const {
    lastName,
    firstName,
    user: { role },
  } = yield call(ProfileManager.getProfile, token)
  yield put(setProfile({ lastName, firstName, role }))
}

const profileSaga = function*() {
  yield takeLatest(fetchProfile, fetchProfileSaga)
}

export default profileSaga
