import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withHandlers, withStateHandlers } from 'recompose'
import { Grid } from '@material-ui/core'
import { ROUTES } from '../../../constants'
import {
  TableSort,
  EditButton,
  DeleteButton,
  CopyButton,
  Confirm,
} from '../commons'
import {
  fetchWordSets,
  getWordSetItems,
  getWordSetCount,
  deleteWordSet,
  copyWordSet,
} from '../../../modules/wordSets'

const dataHeader = [
  {
    name: 'Название',
    keySort: 'wordSet.title',
  },
  {
    name: 'Язык',
    keySort: 'wordSet.language',
  },
  {
    name: 'Тип набора',
    keySort: 'wordSet.testType',
  },
  {
    name: 'Количество слов',
    keySort: 'wordCount',
  },
  {
    name: 'Курсы',
    keySort: 'courses',
  },
  {
    name: 'Готов',
    keySort: 'wordSet.active',
  },
  {
    name: 'Ред.',
    keySort: '',
  },
  {
    name: 'Копировать',
    keySort: '',
  },
  {
    name: 'Удалить',
    keySort: '',
  },
]

const TableWordSetsDamp = ({
  search,
  fetchWordSets,
  wordSets,
  count,
  copy,
  remove,
  select,
  isDeleting,
  isCopying,
  onDelete,
  onCopy,
  onClose,
}) => (
  <Grid item>
    <TableSort
      data={wordSets}
      dataHeader={dataHeader}
      onChange={fetchWordSets}
      count={count}
      search={search}
      map={wordSet =>
        R.pipe(
          R.props([
            'title',
            'language',
            'testType',
            'wordCount',
            'courses',
            'active',
          ]),
          R.append(<EditButton onClick={() => select(wordSet.id)} />),
          R.append(<CopyButton onClick={() => copy(wordSet.id)} />),
          R.append(<DeleteButton onClick={() => remove(wordSet.id)} />),
        )(wordSet)
      }
    />
    {isDeleting && <Confirm onCancelClick={onClose} onDoneClick={onDelete} />}
    {isCopying && <Confirm onCancelClick={onClose} onDoneClick={onCopy} />}
  </Grid>
)

const TableWordSets = R.compose(
  withRouter,
  connect(
    R.applySpec({ wordSets: getWordSetItems, count: getWordSetCount }),
    { fetchWordSets, deleteWordSet, copyWordSet },
  ),
  withStateHandlers(
    { isDeleting: false, isCopying: false, selectedId: null },
    {
      onClose: () => () => ({ isDeleting: false, isCopying: false }),
      remove: () => id => ({ isDeleting: true, selectedId: id }),
      copy: () => id => ({ isCopying: true, selectedId: id }),
      onDelete: ({ selectedId }, { deleteWordSet }) => () => {
        deleteWordSet(selectedId)
        return { isDeleting: false }
      },
      onCopy: ({ selectedId }, { copyWordSet }) => () => {
        copyWordSet(selectedId)
        return { isCopying: false }
      },
    },
  ),
  withHandlers({
    select: ({ history: { push: navigate } }) => id => {
      navigate(`${ROUTES.WORD_SETS}/${id}`)
    },
  }),
)(TableWordSetsDamp)

export default TableWordSets
