import { reducer as form } from 'redux-form'
import audio from './audio'
import auth from './auth'
import users from './users'
import courses from './courses'
import groups from './groups'
import statistic from './statistic'
import profile from './profile'
import wordSets from './wordSets'
import stageTwo from './stageTwo'
import ui from './ui'
import { combineReducers } from 'redux'

const rootReducer = combineReducers({
  auth,
  form,
  users,
  groups,
  courses,
  profile,
  wordSets,
  stageTwo,
  statistic,
  audio,
  ui,
})

export default rootReducer
