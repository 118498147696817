import React from 'react'
import * as R from 'ramda'
import { withRouter } from 'react-router-dom'
import {
  EditButton,
  PlayButton,
  DeleteButton,
  Confirm,
  withRole,
} from '../commons'
import { withState, withHandlers, withStateHandlers } from 'recompose'
import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import {
  getAudioTable,
  deleteAudio,
  fetchWordCount,
  getIsFetchingWordCount,
  getWordCount,
} from '../../../modules/audio'
import { Player } from '../../../services'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { FORMS } from '../../../constants'

const ContentMessage = ({ isFetchingWordCount, wordCount }) => (
  <>
    Вы уверены?{' '}
    {isFetchingWordCount ? (
      <CircularProgress size={12} />
    ) : (
      <Typography>
        {wordCount !== 0 && 'Эта аудиозапись используется в'}
        {wordCount !== 0 && (
          <span style={{ fontWeight: 800 }}> ({wordCount})</span>
        )}
        {wordCount !== 0 && (wordCount % 10 === 1 ? ' слове' : ' словах')}
      </Typography>
    )}
  </>
)

const EditButtonWithRole = withRole(EditButton, true)
const DeleteButtonWithRole = withRole(DeleteButton, true)

const TableAudioDamp = ({
  audio = [],
  onDelete,
  isDeleting,
  renameAudio,
  onClose,
  playAudio,
  removeAudio,
  isFetchingWordCount,
  wordCount,
}) => (
  <Grid item sm={12}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{'Название'}</TableCell>
          <TableCell>{''}</TableCell>
          <TableCell>{''}</TableCell>
          <TableCell>{''}</TableCell>
          <TableCell>{'Аудио'}</TableCell>
          <TableCell>{'Изменить'}</TableCell>
          <TableCell>{'Удалить'}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {audio.map((a, i) => (
          <TableRow key={i}>
            <TableCell>{a.name}</TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell>
              <PlayButton onClick={() => playAudio(a.url)} />
            </TableCell>
            <TableCell>
              <EditButtonWithRole onClick={() => renameAudio(a)} />
            </TableCell>
            <TableCell>
              <DeleteButtonWithRole onClick={() => removeAudio(a.url)} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    {isDeleting && (
      <Confirm
        content={
          <ContentMessage
            wordCount={wordCount}
            isFetchingWordCount={isFetchingWordCount}
          />
        }
        onCancelClick={onClose}
        onDoneClick={onDelete}
      />
    )}
  </Grid>
)

const TableAudio = R.compose(
  withRouter,
  withState('currentTrack', 'setCurrentTrack', null),
  connect(
    R.applySpec({
      audio: getAudioTable,
      wordCount: getWordCount,
      isFetchingWordCount: getIsFetchingWordCount,
    }),
    { deleteAudio, fetchWordCount, initialize },
  ),
  withStateHandlers(
    { isDeleting: false, selectedAudio: null },
    {
      onDelete: ({ selectedAudio }, { deleteAudio }) => () => {
        deleteAudio(selectedAudio)
        return { isDeleting: false }
      },
      removeAudio: (_, { fetchWordCount }) => url => {
        fetchWordCount(url)
        return {
          isDeleting: true,
          selectedAudio: url,
        }
      },
      onClose: () => () => ({ isDeleting: false }),
    },
  ),
  withHandlers({
    renameAudio: ({ initialize, onOpen }) => audio => {
      initialize(FORMS.AUDIO, audio)
      onOpen()
    },
    playAudio: ({ currentTrack, setCurrentTrack }) => url => {
      if (currentTrack !== url || Player.paused) {
        Player.src = url
        Player.play()
        setCurrentTrack(url)
      } else {
        Player.pause()
        setCurrentTrack(null)
      }
    },
  }),
)(TableAudioDamp)

export default TableAudio
