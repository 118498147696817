export const data = {
  mainStatistics: [
    { id: 1, name: 'Набор 1', completed: 80, countRepeat: 10, time: '21:06' },
    { id: 2, name: 'Набор 2', completed: 50, countRepeat: 10, time: '21:06' },
    { id: 3, name: 'Набор 3', completed: 1, countRepeat: 2, time: '21:06' },
  ],
  stageOneStatistics: [
    {
      word: 'Слово 1',
      task1: 1,
      task2: 2,
      task3: 3,
      task4: 0,
      task5: 1,
      task6: 0,
    },
    {
      word: 'Слово 2',
      task1: 0,
      task2: 1,
      task3: 1,
      task4: 4,
      task5: 0,
      task6: 2,
    },
    {
      word: 'Слово 3',
      task1: 1,
      task2: 1,
      task3: 3,
      task4: 1,
      task5: 0,
      task6: 3,
    },
  ],
  stageTwoStatistics: {
    '1.1': 1,
    '2.1': 3,
    '2.2': 4,
    '3.1': 1,
    '3.2': 0,
    '3.3': 0,
  },
  rating: [
    { type: 'a', count: 1, sum: 0 },
    { type: 'b', count: 4, sum: 1 },
    { type: 'c', count: 40, sum: 10 },
    { type: 'd', count: 92, sum: 12 },
    { type: 'e', count: 2, sum: 72 },
    { type: 'f', count: 221, sum: 2 },
    { type: 'g', count: 21, sum: 2 },
  ],
}
