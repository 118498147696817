import React from 'react'
import * as R from 'ramda'
import { withRouter } from 'react-router-dom'
import { withTitle } from './withTitle'
import styled from 'styled-components'
import { Paper, Grid } from '@material-ui/core'
import { withStateHandlers, withHandlers } from 'recompose'
import { ROUTES } from '../../constants'
import { Header } from '../components/commons'
import { TableWordSets } from '../components/wordSets'
import { searchToObject } from '../../utils'

const Container = styled(Paper)`
  padding: 20px;
`

const WordSetsDamp = ({ setSearch, search, addWordSet }) => (
  <Container>
    <Grid container direction="column">
      <Header
        title="Наборы слов"
        searchName="Поиск по набором"
        buttonName="Добавить набор"
        onChange={setSearch}
        value={search}
        onClick={addWordSet}
      />
      <TableWordSets search={search} />
    </Grid>
  </Container>
)

const WordSets = R.compose(
  withRouter,
  withTitle('Наборы слов'),
  withStateHandlers(
    () =>
      R.pipe(
        () => new URLSearchParams(window.location.search),
        searchToObject,
        ({ search }) => ({ search: search || '' }),
      )(),
    { setSearch: () => ({ target: { value: search } }) => ({ search }) },
  ),
  withHandlers({
    addWordSet: ({ history: { push: navigate } }) => () =>
      navigate(ROUTES.WORD_SETS_FORM),
  }),
)(WordSetsDamp)

export default WordSets
