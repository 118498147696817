import * as R from 'ramda'

const getWordSets = R.prop('wordSets')

const MEANING = {
  1: 'Да',
  0: 'Нет',
  STUDY: 'Изучение',
  REPEAT: 'Повторение',
  ENGLISH: 'Английский',
}

export const getWordSetItems = R.pipe(
  getWordSets,
  R.pathOr([], ['wordSets', 'items']),
  R.map(x => ({
    ...x,
    testType: MEANING[x.testType],
    language: MEANING[x.language],
    active: MEANING[x.active],
  })),
)

export const getWordSetCount = R.pipe(
  getWordSets,
  R.pathOr(0, ['wordSets', 'count']),
)

export const getSelectedWordSets = R.pipe(
  getWordSets,
  R.prop('selectedWordSets'),
)

export const getWordSetsStudy = R.pipe(
  getWordSets,
  R.prop('wordSetsStudy'),
)

export const getWords = R.pipe(
  getWordSets,
  R.prop('words'),
)

export const getRules = R.pipe(
  getWordSets,
  R.prop('rules'),
  R.map(x => ({ value: x.id, label: x.name })),
)

export const getRulesTable = R.pipe(
  getWordSets,
  R.prop('rules'),
)

export const selectWordSetCount = R.pipe(
  getWordSets,
  R.prop('wordSetCount'),
)

export const getDumpCourses = R.pipe(
  getWordSets,
  R.prop('dumpCourses'),
  R.map(x => ({ value: x.id, label: x.name })),
  R.append({ value: undefined, label: 'Все наборы' }),
)

export const getIsFetchingWordSetCount = R.pipe(
  getWordSets,
  R.prop('isFetchingWordSetCount'),
)

export const getWordSet = R.pipe(
  getWordSets,
  R.prop('currentWordSet'),
  x => ({
    ...x,
    creator: `${R.pathOr('', ['creator', 'lastName'], x)} ${R.pathOr(
      '',
      ['creator', 'firstName'],
      x,
    )}`,
    editor: `${R.pathOr('', ['editor', 'lastName'], x)} ${R.pathOr(
      '',
      ['editor', 'firstName'],
      x,
    )}`,
  }),
)

export const getIsFetching = R.pipe(
  getWordSets,
  R.prop('isFetching'),
)

export const getWordSetId = R.pipe(
  getWordSet,
  R.prop('id'),
)

export const getWordSetActive = R.pipe(
  getWordSet,
  R.prop('active'),
)
