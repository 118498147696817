import * as R from 'ramda'
import * as React from 'react'
import { connect } from 'react-redux'
import { getUserRole } from '../../../modules/profile'

const withRole = (BaseComponent, disabled) => {
  const ComponentWithRoleDamp = ({ role, ...rest }) =>
    role === 'ADMIN' ? (
      <BaseComponent {...rest} />
    ) : disabled ? (
      <BaseComponent disable={!!disabled} disabled={!!disabled} {...rest} />
    ) : role !== 'TEACHER' ? (
      <BaseComponent {...rest} />
    ) : null

  const ComponentWithRole = connect(R.applySpec({ role: getUserRole }))(
    ComponentWithRoleDamp,
  )
  return ComponentWithRole
}

export default withRole
