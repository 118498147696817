import React from 'react'
import styled from 'styled-components'
import { Button } from '../commons'

const Container = styled.div`
  margin-bottom: 20px;
`

const ToggleGroup = styled.div``

const ToggleButton = styled(Button)`
  margin: 0 10px;
  background-color: ${({ selected }) => (selected ? undefined : '#fff')};
  color: ${({ selected }) => (selected ? '#fff' : '#000')};
`

const Text = styled.span`
  font-size: 18px;
  margin-right: 20px;
  color: rgb(100, 100, 100);
`

const ToogleUsers = ({ setRole, role }) => (
  <Container>
    <ToggleGroup>
      <Text>Фильтры</Text>
      <ToggleButton
        name={'Ученики'}
        onClick={() => setRole('STUDENT')}
        selected={role === 'STUDENT'}
      />
      <ToggleButton
        name={'Системные'}
        onClick={() => setRole('SYSTEM')}
        selected={role === 'SYSTEM'}
      />
      <ToggleButton
        name={'Триальные'}
        onClick={() => setRole('TRIAL')}
        selected={role === 'TRIAL'}
      />
    </ToggleGroup>
  </Container>
)

export default ToogleUsers
