import React from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { withAuthorization } from '../components/commons'
import { fetchProfile } from '../../modules/profile'
import styled from 'styled-components'
import { Redirect, withRouter } from 'react-router-dom'
import { withProps, branch, lifecycle, withStateHandlers } from 'recompose'
import { ROUTES } from '../../constants'

import { AppBar } from '../components/main'
import Drawer from './Drawer'
import Routes from './Routes'

const ScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const MainDamp = ({ handleDrawerOpen, handleDrawerClose, isShowDrawer }) => (
  <ScreenWrapper>
    <AppBar title={'Заголовок'} onClick={handleDrawerOpen} />
    <Routes />
    <Drawer
      open={isShowDrawer}
      handleDrawerClose={handleDrawerClose}
      handleDrawerOpen={handleDrawerOpen}
    />
  </ScreenWrapper>
)

const Main = R.compose(
  withAuthorization,
  withRouter,
  connect(
    undefined,
    { fetchProfile },
  ),
  withProps(({ location: { pathname: currentRoute } }) => ({ currentRoute })),
  branch(R.propEq('currentRoute', ROUTES.BASE), () => () => (
    <Redirect to={ROUTES.GROUPS} />
  )),
  withStateHandlers(
    { isShowDrawer: false },
    {
      handleDrawerOpen: () => () => ({ isShowDrawer: true }),
      handleDrawerClose: () => () => ({ isShowDrawer: false }),
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchProfile()
    },
  }),
)(MainDamp)

export default Main
