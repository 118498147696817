import * as R from 'ramda'

export const query = data => (!!data ? `?${new URLSearchParams(data)}` : '')

export const auth = token => ({
  headers: { Authorization: `Bearer ${token}` },
})

export const createFormData = obj => {
  let data = new FormData()
  R.forEachObjIndexed(
    (value, key) =>
      key === 'image' && typeof value === 'string'
        ? undefined
        : data.append(key, value),
    obj,
  )
  return data
}

export const createArrayFormData = arr => {
  let data = new FormData()
  R.forEach(value => data.append(`files`, value), arr)
  return data
}
