import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { reduxForm } from 'redux-form'
import { withTitle } from './withTitle'
import { FORMS } from '../../constants'
import { connect } from 'react-redux'
import { lifecycle, withProps } from 'recompose'
import { Paper, Grid } from '@material-ui/core'
import { fetchSaveGroup, fetchGroup } from '../../modules/groups'
import { Students, GroupForm as Form } from '../components/groups'
import {
  Header,
  Button,
  withNavigateGroups,
  withRole,
} from '../components/commons'

const Container = styled(Paper)`
  padding-top: 20px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`

const ButtonCancel = styled(Button).attrs({
  name: 'Отменить',
})`
  margin-right: 10px;
`

const ButtonSave = styled(ButtonCancel).attrs({ name: 'Cохранить' })``

const ButtonSaveWithRole = withRole(ButtonSave, true)

const GroupFormDamp = ({
  groupId,
  submitting,
  handleSubmit,
  fetchSaveGroup,
  navigateGroups,
}) => (
  <Container>
    <Header title="Создание группы">
      <ButtonContainer>
        <ButtonCancel onClick={navigateGroups} loading={submitting} />
        <ButtonSaveWithRole
          loading={submitting}
          onClick={handleSubmit(data => fetchSaveGroup({ groupId, ...data }))}
        />
      </ButtonContainer>
    </Header>
    <Grid container spacing={16} justify="space-around">
      <Form groupId={groupId} />
      <Grid item sm={5}>
        <Students groupId={groupId} />
      </Grid>
    </Grid>
  </Container>
)

const GroupForm = R.compose(
  withNavigateGroups,
  withTitle('Группа'),
  reduxForm({ form: FORMS.GROUP_FROM }),
  connect(
    undefined,
    { fetchSaveGroup, fetchGroup },
  ),
  withProps(({ match: { params: { id } } }) => ({ groupId: id })),
  lifecycle({
    componentDidMount() {
      const { fetchGroup, groupId } = this.props
      fetchGroup(groupId)
    },
  }),
)(GroupFormDamp)

export default GroupForm
