import * as R from 'ramda'
import { handleStatuses } from '../../aspects'
import API from '../../api'
import { auth, query } from '../../utils/query'

export const getAudio = R.pipeP(
  (token, data) =>
    fetch(`${API.AUDIO}${data ? query({ search: data }) : ''}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const addAudio = R.pipeP(
  (token, body) =>
    fetch(`${API.AUDIO}`, {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      body,
    }),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const uploadAudios = R.pipeP(
  (token, body) =>
    fetch(`${API.MULTIPLE_AUDIO}`, {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      body,
    }),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const renameAudio = R.pipeP(
  (token, body) =>
    fetch(`${API.AUDIO}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const deleteAudio = R.pipeP(
  (token, body) =>
    fetch(`${API.AUDIO}${query(body)}`, {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${token}` },
    }),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const getWordCountByUrl = R.pipeP(
  (token, body) =>
    fetch(`${API.COUNT_WORD_BY_AUDIO}${query(body)}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)
