import React from 'react'
import * as R from 'ramda'
import { Button as ButtonMaterial, CircularProgress } from '@material-ui/core'

const Button = ({ children, name, disabled, loading, ...props }) => (
  <ButtonMaterial
    disabled={R.isNil(disabled) ? loading : disabled}
    variant="contained"
    color="primary"
    {...props}
  >
    {loading && <CircularProgress size={20} />}
    {!loading && (children || name)}
  </ButtonMaterial>
)

export default Button
