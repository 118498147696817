import * as R from 'ramda'

const phoneNormiliser = R.pipe(
  R.insert(1, ' '),
  R.insert(5, ' '),
  R.insert(9, ' '),
  R.insert(12, ' '),
  R.join(''),
)

export default phoneNormiliser
