import React from 'react'
import * as R from 'ramda'
import { Grid } from '@material-ui/core'
import { withStateHandlers } from 'recompose'
import { FillGapsForm, TableFillGaps } from '.'

const FillGapsDamp = ({ active, isOpenModal, disableModal, enableModal }) => (
  <Grid container justify="center">
    <TableFillGaps active={active} enableModal={enableModal} />
    <FillGapsForm open={isOpenModal} onClose={disableModal} />
  </Grid>
)

const FillGaps = R.compose(
  withStateHandlers(
    { isOpenModal: false },
    {
      disableModal: () => () => ({ isOpenModal: false }),
      enableModal: () => () => ({ isOpenModal: true }),
    },
  ),
)(FillGapsDamp)

export default FillGaps
