import React from 'react'
import { withRouter } from 'react-router'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import {
  getGroupItems,
  getGroupCount,
  fetchGroups,
  deleteGroup,
} from '../../../modules/groups'
import {
  TableSort,
  EditButton,
  DeleteButton,
  InfoButton,
  Confirm,
  Checkbox,
} from '../commons'
import { withHandlers, withStateHandlers } from 'recompose'
import { ROUTES } from '../../../constants'

const dataHeader = [
  {
    name: 'Кодификатор',
    keySort: 'group.name',
  },
  {
    name: 'Курс',
    keySort: 'course.name',
  },
  {
    name: 'Преподаватель',
    keySort: 'lastName',
  },
  {
    name: 'Человек в группе',
    keySort: 'studentCount',
  },
  {
    name: 'Средний процент выполнения',
    keySort: 'percentCompletion',
  },
  {
    name: 'Наборов доступно ученикам',
    keySort: 'activeWordSets',
  },
  {
    name: 'Статистика',
    keySort: '',
  },
  {
    name: 'Ред.',
    keySort: '',
  },
  {
    name: 'Удалить',
    keySort: '',
  },
]

const TableGroups = ({
  count,
  groups,
  search,
  editGroup,
  fetchGroups,
  selectGroup,
  remove,
  onClose,
  onDelete,
  isDeleting,
}) => {
  const [checked, setChecked] = React.useState(false)
  const [tableSort, setTableSort] = React.useState({})
  return (
    <Grid item>
      <Checkbox
        label="актуальность"
        input={{
          onChange: event => {
            setChecked(event.target.checked)
            if (event.target.checked) {
              fetchGroups({ ...tableSort, urgency: true })
            } else {
              fetchGroups(tableSort)
            }
          },
          checked,
        }}
      />
      <TableSort
        data={groups}
        search={search}
        count={count}
        dataHeader={dataHeader}
        onChange={value => {
          setTableSort(value)
          if (checked) {
            fetchGroups({ ...value, urgency: true })
          } else {
            fetchGroups(value)
          }
        }}
        percentIndexes={[4]}
        map={group =>
          R.pipe(
            R.props([
              'name',
              'course',
              'teacher',
              'studentCount',
              'percentCompletion',
              'countWordSet',
            ]),
            R.append(<InfoButton onClick={() => selectGroup(group.id)} />),
            R.append(<EditButton onClick={() => editGroup(group.id)} />),
            R.append(<DeleteButton onClick={() => remove(group.id)} />),
          )(group)
        }
      />
      {isDeleting && <Confirm onCancelClick={onClose} onDoneClick={onDelete} />}
    </Grid>
  )
}

export default R.compose(
  withRouter,
  connect(
    R.applySpec({ groups: getGroupItems, count: getGroupCount }),
    { fetchGroups, deleteGroup },
  ),
  withStateHandlers(
    { isDeleting: false, selectedId: null },
    {
      onClose: () => () => ({ isDeleting: false, isCopying: false }),
      remove: () => id => ({ isDeleting: true, selectedId: id }),
      onDelete: ({ selectedId }, { deleteGroup }) => () => {
        deleteGroup(selectedId)
        return { isDeleting: false }
      },
    },
  ),
  withHandlers({
    editGroup: ({ history: { push: navigate } }) => id => {
      navigate(`${ROUTES.GROUPS}/${id}`)
    },
    selectGroup: ({ history: { push: navigate } }) => id => {
      navigate(`${ROUTES.DETAILS}${ROUTES.GROUPS}/${id}`)
    },
  }),
)(TableGroups)
