import React from 'react'
import * as R from 'ramda'
import * as Yup from 'yup'
import styled from 'styled-components'
import { Modal } from '@material-ui/core'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { withHandlers } from 'recompose'
import { FORMS } from '../../../constants'
import { saveAudition, loadAudition } from '../../../modules/stageTwo'
import { toArray } from '../../../utils/stringFormater'
import { Button, TextInputField } from '../commons'
import { reduxFormYup } from '../../../utils'

const Form = styled.form`
  display: flex;
  margin: auto;
  margin-top: 10%;
  padding: 25px;
  border-radius: 10px;
  width: 50%;
  flex-direction: column;
  background-color: #fff;
`

const AuditionFormDamp = ({ submitAudition, open, onClose, loadAudition }) => (
  <Modal open={open} onClose={onClose}>
    <Form>
      <Field
        name="aRightAnswer"
        label="Предложение"
        multiline
        component={TextInputField}
      />
      <Field name="aVariants" label="Лишние слова" component={TextInputField} />
      <Field
        name="file"
        label="Добавить аудио"
        type="file"
        onChange={loadAudition}
        accept=".mp3"
        component={TextInputField}
      />
      <Button type="submit" onClick={submitAudition}>
        Отправить
      </Button>
    </Form>
  </Modal>
)

const AuditionFormScheme = Yup.object().shape({
  aRightAnswer: Yup.string()
    .max(250)
    .required(),
  aVariants: Yup.string()
    .max(250)
    .required(),
  audio: Yup.string().required(),
})

const AuditionForm = R.compose(
  connect(
    undefined,
    { saveAudition, loadAudition },
  ),
  reduxForm({
    form: FORMS.STAGE_TWO,
    validate: reduxFormYup(AuditionFormScheme),
  }),
  withHandlers({
    submitAudition: ({ handleSubmit, saveAudition, onClose }) =>
      handleSubmit(
        ({ aRightAnswer, aVariants, audio }) =>
          audio &&
          saveAudition({
            audio,
            testType: '9',
            rightAnswer: toArray(aRightAnswer),
            variants: R.concat(toArray(aRightAnswer), toArray(aVariants)),
          }),
        onClose(),
      ),
  }),
)(AuditionFormDamp)

export default AuditionForm
