import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { TableCell } from '../commons'
import { withState, lifecycle, withHandlers } from 'recompose'
import { getTime } from '../../../utils/formatDate'
import { getStagesStat } from '../../../modules/statistic'
import styled from 'styled-components'
import {
  Table,
  TableBody,
  TableRow,
  TableHead,
  Grid,
  CircularProgress,
} from '@material-ui/core'

const ProgressCell = styled(CircularProgress)`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  padding: 10px;
  margintop: 5px;
`

const WordSetInfoDamp = ({
  statistic = [],
  currentWordSet,
  getStagesStat,
  isFetchingStages,
}) => (
  <Grid container justify="center">
    <Grid sm={9}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Имя</TableCell>
            <TableCell>Выполнено</TableCell>
            <TableCell>Кол-во повторений</TableCell>
            <TableCell>Время</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {statistic.map(
            ({ id, title, progress, repeatWordSetCount, time }) => (
              <TableRow
                selected={currentWordSet === id}
                onClick={() => getStagesStat(id)}
                key={id}
              >
                <TableCell>{title}</TableCell>
                <TableCell>{progress}%</TableCell>
                <TableCell>{repeatWordSetCount}</TableCell>
                <TableCell>{getTime(time)}</TableCell>
                <TableCell>
                  <ProgressCell
                    isVisible={currentWordSet === id && isFetchingStages}
                  />
                </TableCell>
              </TableRow>
            ),
          )}
        </TableBody>
      </Table>
    </Grid>
  </Grid>
)

const WordSetInfo = R.compose(
  connect(
    undefined,
    { getStagesStat },
  ),
  withState('currentWordSet', 'setCurrentWordSet', null),
  withHandlers({
    getStagesStat: ({
      statistic,
      setCurrentWordSet,
      userId: profileId,
      getStagesStat,
    }) => id => {
      const wordSetId = id || R.pathOr(null, [0, 'id'], statistic)
      setCurrentWordSet(wordSetId)
      !!wordSetId && getStagesStat({ wordSetId, profileId })
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getStagesStat()
    },
    componentDidUpdate(prev) {
      const { statistic, getStagesStat } = this.props
      prev.statistic !== statistic && getStagesStat()
    },
  }),
)(WordSetInfoDamp)

export default WordSetInfo
