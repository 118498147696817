import React from 'react'
import * as R from 'ramda'
import { withRouter } from 'react-router-dom'
import { withTitle } from './withTitle'
import { initialize } from 'redux-form'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Paper, Grid, Input, InputLabel, FormControl } from '@material-ui/core'
import { withStateHandlers, withHandlers, lifecycle } from 'recompose'
import { FORMS } from '../../constants'
import { TableAudio } from '../components/audio'
import { getAudio, uploadAudios } from '../../modules/audio'
import { Button, FileInput, Divider, withRole } from '../components/commons'
import AudioForm from '../components/audio/AudioForm'

const Container = styled(Paper)`
  padding: 20px;
`

const LoadAudios = styled(FileInput)`
  margin: 0 auto;
`

const SearchInput = styled(Input)``

const SearchLabel = styled(InputLabel)``

const AddAudioButton = styled(Button)`
  margin: 20px auto;
`

const LoadAudiosWithRole = withRole(LoadAudios, true)
const AddWithRole = withRole(AddAudioButton, true)

const AudioDamp = ({
  openCleanModal,
  onOpen,
  onClose,
  isOpenModal,
  uploadAudios,
  setSearch,
  search,
}) => (
  <Container>
    <Grid container direction="column">
      <FormControl style={{ width: '30%' }}>
        <SearchLabel htmlFor="search">Поиск по аудио</SearchLabel>
        <SearchInput
          type="search"
          id="search"
          value={search}
          onChange={setSearch}
        />
      </FormControl>
      <Divider />
      <TableAudio onOpen={onOpen} />
      <AddWithRole name="Добавить аудио" onClick={() => openCleanModal()} />
      <LoadAudiosWithRole
        input={{}}
        multiple={true}
        label="Загрузить несколько аудио"
        accept=".mp3"
        onChange={uploadAudios}
      />
      <AudioForm isOpen={isOpenModal} onClose={onClose} />
    </Grid>
  </Container>
)

const Audio = R.compose(
  withRouter,
  withTitle('База аудио'),
  connect(
    undefined,
    { initialize, getAudio, uploadAudios },
  ),
  withStateHandlers(
    { isOpenModal: false, search: '' },
    {
      onClose: () => () => ({ isOpenModal: false }),
      onOpen: () => () => ({ isOpenModal: true }),
      setSearch: (_, { getAudio }) => ({ target: { value: search } }) => {
        getAudio(search)
        return { search }
      },
    },
  ),
  withHandlers({
    openCleanModal: ({ onOpen, initialize }) => () => {
      initialize(FORMS.AUDIO, {})
      onOpen()
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.getAudio()
    },
  }),
)(AudioDamp)

export default Audio
