import React from 'react'
import styled from 'styled-components'
import withRole from './withRole'
import { TableCell as TableCellMaterial, ButtonBase } from '@material-ui/core'
import {
  Delete,
  Edit,
  FileCopy,
  PlayCircleOutline,
  AddCircleOutline,
  InfoRounded,
} from '@material-ui/icons'

import { Button } from '.'

export const Indent = styled.div`
  width: 100%;
  height: 20px;
`

export const Separate = styled.div`
  width: 20px;
  height: 100%;
`

export const TableCell = styled(TableCellMaterial)`
  padding: 5px 0;
`

export const IconAdd = styled(AddCircleOutline)`
  margin-right: 10px;
`

export const withButtonBase = BaseComponent => props => (
  <ButtonBase {...props}>
    <BaseComponent />
  </ButtonBase>
)

export const AddButton = ({ name, ...props }) => (
  <Button {...props}>
    <IconAdd />
    {name}
  </Button>
)

export const EditButton = withRole(withButtonBase(Edit))
export const DeleteButton = withRole(withButtonBase(Delete), true)
export const CopyButton = withRole(withButtonBase(FileCopy), true)
export const InfoButton = withButtonBase(InfoRounded)
export const PlayButton = withButtonBase(PlayCircleOutline)
