import React from 'react'
import { CircularProgress } from '@material-ui/core'
import styled from 'styled-components'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { withProps, lifecycle } from 'recompose'
import { fetchUserRequest } from '../../modules/users/duck'
import {
  getIsUserFetching,
  getSelectedUserRoles,
} from '../../modules/users/selectors'
import UserForm from './UserForm'
import UserSystemForm from './UserSystemForm'

const Preloader = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
`

const UserDamp = ({ isFetching, roles, editMode }) =>
  isFetching ? (
    <Preloader />
  ) : roles === 'MOBILE' || editMode === 'STUDENT' ? (
    <UserForm />
  ) : (
    <UserSystemForm />
  )

const User = R.compose(
  connect(
    R.applySpec({
      isFetching: getIsUserFetching,
      roles: getSelectedUserRoles,
    }),
    { fetchUserRequest },
  ),
  withProps(
    ({
      match: {
        params: { id: currentUserId },
      },
      location: { search },
    }) => {
      return {
        currentUserId,
        editMode: new URLSearchParams(search).get('type'),
      }
    },
  ),
  lifecycle({
    componentDidMount() {
      const { fetchUserRequest, currentUserId } = this.props
      currentUserId && fetchUserRequest(currentUserId)
    },
  }),
)(UserDamp)

export default User
