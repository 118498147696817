import React from 'react'
import { withRouter } from 'react-router-dom'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { ROUTES } from '../../constants'
import { withTitle } from './withTitle'
import { Paper, Grid } from '@material-ui/core'
import { TableGroups } from '../components/groups'
import { setSchedule } from '../../modules/groups'
import { setRelativeUsers } from '../../modules/users'
import { withStateHandlers, withHandlers } from 'recompose'
import { Header } from '../components/commons'
import styled from 'styled-components'
import { searchToObject } from '../../utils'

const Container = styled(Paper)`
  padding: 20px;
`

const GroupsDamp = ({ addGroup, setSearch, search }) => (
  <Container>
    <Grid container direction="column">
      <Header
        searchName="Поиск по группе"
        buttonName="Добавить группу"
        onChange={setSearch}
        value={search}
        onClick={addGroup}
      />
      <TableGroups search={search} />
    </Grid>
  </Container>
)

const Groups = R.compose(
  withTitle('Группы'),
  connect(
    undefined,
    { setSchedule, setRelativeUsers },
  ),
  withRouter,
  withStateHandlers(
    () =>
      R.pipe(
        () => new URLSearchParams(window.location.search),
        searchToObject,
        ({ search }) => ({ search: search || '' }),
      )(),
    { setSearch: () => ({ target: { value: search } }) => ({ search }) },
  ),
  withHandlers({
    addGroup: ({ history: { push: navigate }, setRelativeUsers }) => () => {
      setRelativeUsers([])
      navigate(ROUTES.GROUP_FORM)
    },
  }),
)(GroupsDamp)

export default Groups
