import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  getMakeSentenceTable,
  selectStage,
  deleteMakeSentence,
} from '../../../modules/stageTwo'
import { Grid, Table, TableHead, TableRow, TableBody } from '@material-ui/core'
import {
  DeleteButton,
  EditButton,
  TableCell,
  Button,
  withRole,
} from '../commons'
import { withHandlers } from 'recompose'

const AddTaskButton = styled(Button)`
  margin: 20px auto;
`

const Cell = styled(TableCell)`
  text-align: start;
`

const EditButtonWithRole = withRole(EditButton, true)
const AddTaskButtonWithRole = withRole(AddTaskButton, true)

const TableMakeSetence = ({ active, makeSentence = [], remove, openModal }) => (
  <Grid container spacing={16} justify="space-around">
    <Grid item sm={11}>
      <Table>
        <TableHead>
          <TableRow>
            <Cell>Русское предложение</Cell>
            <Cell>Английское предложение</Cell>
            <Cell>Лишние слова</Cell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {makeSentence.map(
            ({ testType, rusText, enText, variants }, index) => (
              <TableRow key={index}>
                <Cell>{rusText}</Cell>
                <Cell>{enText}</Cell>
                <Cell>{variants}</Cell>
                <TableCell>
                  <EditButtonWithRole
                    onClick={() => openModal({ testType, index })}
                  />
                </TableCell>

                <TableCell>
                  <DeleteButton onClick={() => remove(index)} />
                </TableCell>
              </TableRow>
            ),
          )}
        </TableBody>
      </Table>
    </Grid>

    <Grid item sm={2}>
      <AddTaskButtonWithRole onClick={() => openModal(null)}>
        Добавить задание
      </AddTaskButtonWithRole>
    </Grid>
  </Grid>
)

export default R.compose(
  connect(
    R.applySpec({ makeSentence: getMakeSentenceTable }),
    { selectStage, deleteMakeSentence },
  ),
  withHandlers({
    remove: ({ deleteMakeSentence }) => index => deleteMakeSentence(index),
    openModal: ({ selectStage, enableModal }) => payload => {
      selectStage(payload)
      enableModal()
    },
  }),
)(TableMakeSetence)
