import React from 'react'
import { Table, TableBody, TableRow, TableHead, Grid } from '@material-ui/core'
import { TableCell } from '../commons'

const events = {
  learnedWord: 'Слово прошедшее Этап 1',
  learnedStage1: 'Правильное выполнение задания Этапа 1',
  learnedStage2: 'Правильное выполнение задания Этапа 2',
  learnedWordSet: 'Изучил набор',
  learnedWordSetInTime: 'Изучил набор вовремя',
  learnedWordSetWithoutError: 'Изучил набор без ошибок',
  learnedRepeat: 'Прошел набор повторения',
  repeatWord: 'Повтороил слово правильно',
  repeatInTime: 'Повторил набор "Повторения" вовремя',
  repeatWithoutError: 'Повторил набор "Повторения" без ошибок',
}

const MainStatistic = ({ rating }) => (
  <Grid container justify="center">
    <Grid sm={11} item>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>№</TableCell>
            <TableCell>Событие</TableCell>
            <TableCell>Количество</TableCell>
            <TableCell>Суммарный балл</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rating.map(({ type, count, score }, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{events[type]}</TableCell>
              <TableCell>{count}</TableCell>
              <TableCell>{score}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  </Grid>
)

export default MainStatistic
