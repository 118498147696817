import React from 'react'
import { withRouter } from 'react-router-dom'
import { withHandlers, withStateHandlers } from 'recompose'
import * as R from 'ramda'
import { connect } from 'react-redux'
import {
  fetchStudents,
  getStudents,
  getStudentCount,
  fetchDeleteUser,
} from '../../../modules/users'
import { TableSort, EditButton, DeleteButton, Confirm } from '../commons'
import { ROUTES } from '../../../constants'

const dataHeader = [
  {
    name: 'id',
    keySort: 'profile.id',
  },
  {
    name: 'ФИ',
    keySort: 'profile.lastName',
    style: { width: 150 },
  },
  {
    name: 'Группа',
    keySort: '',
  },
  {
    name: 'Рейтинг',
    keySort: 'profileStatistics.rating',
  },
  {
    name: ['Процент', <br />, 'выполнения'],
    keySort: 'profileStatistics.percentCompletion',
  },
  {
    name: ['Количество', <br />, 'выученных наборов'],
    keySort: 'profileStatistics.learned',
  },
  {
    name: ['Количество', <br />, 'просроченных', <br />, 'наборов'],
    keySort: 'profileStatistics.overdue',
  },
  {
    name: 'Был Триал',
    keySort: '',
  },
  {
    name: 'Ред.',
    keySort: '',
  },
  {
    name: 'Удалить',
    keySort: '',
  },
]

const TableStudents = ({
  search,
  students,
  remove,
  onClose,
  onDelete,
  countStudents,
  fetchStudents,
  selectUser,
  isDeleting,
}) => (
  <>
    <TableSort
      search={search}
      data={students}
      count={countStudents}
      dataHeader={dataHeader}
      onChange={fetchStudents}
      percentIndexes={[4]}
      map={student =>
        R.pipe(
          R.props([
            'id',
            'fullName',
            'groups',
            'rating',
            'percentCompletion',
            'learned',
            'overdue',
          ]),
          R.append(student.wasTrial ? 'Да' : 'Нет'),
          R.append(<EditButton onClick={() => selectUser(student.id)} />),
          R.append(<DeleteButton onClick={() => remove(student.id)} />),
        )(student)
      }
    />
    {isDeleting && <Confirm onCancelClick={onClose} onDoneClick={onDelete} />}
  </>
)

export default R.compose(
  withRouter,
  connect(
    R.applySpec({ students: getStudents, countStudents: getStudentCount }),
    { fetchStudents, fetchDeleteUser },
  ),
  withHandlers({
    selectUser: ({ history: { push: navigate } }) => id =>
      navigate(ROUTES.USERS + '/' + id),
  }),
  withStateHandlers(
    { isDeleting: false, selectedId: null },
    {
      onClose: () => () => ({ isDeleting: false }),
      remove: () => id => ({ isDeleting: true, selectedId: id }),
      onDelete: ({ selectedId }, { fetchDeleteUser }) => () => {
        fetchDeleteUser(selectedId)
        return { isDeleting: false }
      },
    },
  ),
)(TableStudents)
