import { takeLatest, put, call, all, select } from 'redux-saga/effects'
import { initialize, change } from 'redux-form'
import * as R from 'ramda'
import {
  saveAudition,
  saveFillGaps,
  saveMakeSentence,
  addAudition,
  addFillGaps,
  addMakeSentence,
  updateAudition,
  updateFillGaps,
  updateMakeSentence,
  selectStage,
  setIndexStage,
  saveStage,
  loadAudition,
} from './duck'
import { getToken } from '../auth'
import { getWordSetId, getWordSetActive } from '../wordSets'
import { FORMS } from '../../constants'
import * as WordSetManager from '../wordSets/WordSetManager'
import {
  getSelectedAudition,
  getSelectedFillGaps,
  getSelectedMakeSentence,
  getSelectedIndex,
  composeStageTwo,
} from './selectors'
import { NotificationSuccess } from '../../services'
import { createFormData } from '../../utils/query'

const saveStageSaga = function*() {
  const token = yield select(getToken)
  const id = yield select(getWordSetId)
  const active = yield select(getWordSetActive)
  const stage2 = JSON.stringify(yield select(composeStageTwo))
  yield call(WordSetManager.saveStage2, token, { id, stage2, active })
  yield call(NotificationSuccess())
}

const saveAuditionSaga = function*({ payload }) {
  const index = yield select(getSelectedIndex)
  yield put(
    index !== null
      ? updateAudition({ index, ...payload })
      : addAudition(payload),
  )
}

const saveFillGapsSaga = function*({ payload }) {
  const index = yield select(getSelectedIndex)
  yield put(
    index !== null
      ? updateFillGaps({ index, ...payload })
      : addFillGaps(payload),
  )
}

const saveMakeSentenceSaga = function*({ payload }) {
  const index = yield select(getSelectedIndex)
  yield put(
    index !== null
      ? updateMakeSentence({ index, ...payload })
      : addMakeSentence(payload),
  )
}

const selectStageSaga = function*({ payload }) {
  let selected = {}
  if (payload === null) {
    yield put(setIndexStage(null))
  } else {
    yield put(setIndexStage(payload.index))
    selected = yield select(
      R.cond([
        [R.propEq('testType', '9'), () => getSelectedAudition],
        [R.propEq('testType', '10'), () => getSelectedFillGaps],
        [R.propEq('testType', '11'), () => getSelectedMakeSentence],
      ])(payload),
    )
  }
  yield put(initialize(FORMS.STAGE_TWO, selected))
}

const loadAuditionSaga = function*({ payload: audio }) {
  const token = yield select(getToken)
  const url = yield call(
    WordSetManager.loadAudition,
    token,
    createFormData({ audio }),
  )
  yield put(change(FORMS.STAGE_TWO, 'audio', url))
}

const stageTwoSaga = function*() {
  yield all([
    takeLatest(saveStage, saveStageSaga),
    takeLatest(saveAudition, saveAuditionSaga),
    takeLatest(saveFillGaps, saveFillGapsSaga),
    takeLatest(saveMakeSentence, saveMakeSentenceSaga),
    takeLatest(selectStage, selectStageSaga),
    takeLatest(loadAudition, loadAuditionSaga),
  ])
}

export default stageTwoSaga
