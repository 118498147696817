import React from 'react'
import { FormControl, FormHelperText, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { Field as FieldForm } from 'redux-form'
import { TextInputField, Button } from '../commons'

const Container = styled(FormControl)``

const ContainerItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`

const Id = styled(Typography)`
  margin-right: 20px;
`

const Field = styled(FieldForm)`
  margin-right: 10px;
`

const AddPupilButton = styled(Button)`
  width: 30%;
  margin-left: 20px;
  margin-top: 20px;
`

export const PupilForm = ({ fields, meta: { touched, error } }) => (
  <Container error={touched && !!error}>
    {fields.map((pupil, i) => (
      <ContainerItem key={i}>
        <Id>{i + 1}. </Id>
        <Field
          name={`${pupil}.fullName`}
          label={'ФИ'}
          component={TextInputField}
        />
        <Field
          name={`${pupil}.email`}
          label={'Почта'}
          component={TextInputField}
        />
        <Field
          name={`${pupil}.phone`}
          label={'Телефон'}
          type="phone"
          component={TextInputField}
        />
        <Button name="X" onClick={() => fields.remove(i)} />
      </ContainerItem>
    ))}
    {error && <FormHelperText>{error}</FormHelperText>}
    <AddPupilButton name="Добавить ученика" onClick={() => fields.push({})} />
  </Container>
)

export default PupilForm
