import * as R from 'ramda'

const reduxFormYup = schema => values => {
  try {
    schema.validateSync(values, { abortEarly: false })
  } catch (errors) {
    return R.pipe(
      R.prop('inner'),
      R.reduce(
        (acc, { path, message }) =>
          R.assocPath(
            R.pipe(
              R.split('.'),
              R.chain(
                R.pipe(
                  R.split('['),
                  R.adjust(parseInt, 1),
                ),
              ),
            )(path),
            message,
            acc,
          ),
        {},
      ),
    )(errors)
  }
  return {}
}

export default reduxFormYup
