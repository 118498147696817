import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Typography } from '@material-ui/core/'
import { getUserFullName, getUserRole } from '../../../modules/profile'

const Container = styled.div`
  background-color: #e0e0e0;
  padding: 25px;
`

const Logo = styled.span`
  font-size: 44px;
  font-style: italic;
  font-weight: bold;
  color: rgb(241, 77, 27);
`

const DrawerHeader = ({ fullName, role }) => (
  <Container>
    <Logo>MAXIMUM</Logo>
    <Typography variant="h6">{fullName}</Typography>
    <Typography>{role}</Typography>
  </Container>
)

const mapStateToProps = R.applySpec({
  fullName: getUserFullName,
  role: getUserRole,
})

const DrawerHeaderContainer = connect(mapStateToProps)(DrawerHeader)

export default DrawerHeaderContainer
