import * as React from 'react'
import * as R from 'ramda'
import { withStateHandlers } from 'recompose'
import styled from 'styled-components'
import { Button } from '../commons'
import {
  Modal as MaterialModal,
  Paper,
  Typography,
  Input,
} from '@material-ui/core'

const Modal = styled(MaterialModal)`
  display: flex;
  z-index: 99999;
`

const Container = styled(Paper)`
  display: flex;
  width: 500px;
  margin: auto;
  padding: 40px;
  flex-direction: column;
  justify-content: space-between;
`

const ButtonsContainer = styled.div`
  margin-top: 20px;
`

const ButtonCancel = styled(Button)`
  margin-left: 20px;
`

const QR = styled.img`
  display: flex;
  margin: auto;
  padding-top: 30px;
`

const TestUserModal = ({
  onDoneClick,
  onCancelClick,
  email,
  userId,
  onChange,
  qr,
}) => (
  <Modal open={true} onClose={onCancelClick}>
    <Container>
      <Typography variant="h6">
        Введите email, либо отсканируйте QR код
      </Typography>
      <Typography>Введите email</Typography>
      <Input onChange={onChange} />
      <ButtonsContainer>
        <Button
          name={'Отправить'}
          onClick={() => {
            onDoneClick({ email, userId })
            onCancelClick()
          }}
        />
        <ButtonCancel name={'Закрыть'} onClick={onCancelClick} />
      </ButtonsContainer>
      <QR src={qr} />
    </Container>
  </Modal>
)

export default R.compose(
  withStateHandlers(
    { email: '' },
    { onChange: () => ({ target: { value } }) => ({ email: value }) },
  ),
)(TestUserModal)
