import * as R from 'ramda'

const getProfile = R.prop('profile')

export const getCurrentPage = R.pipe(
  getProfile,
  R.prop('currentPage'),
)

// User
export const getUser = R.pipe(
  getProfile,
  R.prop('user'),
)

export const getUserFirstName = R.pipe(
  getUser,
  R.propOr('', 'firstName'),
)

export const getUserLastName = R.pipe(
  getUser,
  R.propOr('', 'lastName'),
)

export const getUserFullName = R.pipe(
  R.converge((firstName, lastName) => `${firstName} ${lastName}`, [
    getUserFirstName,
    getUserLastName,
  ]),
)

export const getUserRole = R.pipe(
  getUser,
  R.propOr('', 'role'),
)
