import * as React from 'react'
import styled from 'styled-components'
import { Button } from '.'
import { Modal, Paper, Typography } from '@material-ui/core'

const Container = styled(Paper)`
  display: flex;
  width: 500px;
  margin: auto;
  padding: 40px;
  margin-top: 200px;
  flex-direction: column;
  justify-content: space-between;
`

const ButtonsContainer = styled.div`
  margin-top: 20px;
`

const ButtonCancel = styled(Button)`
  margin-left: 20px;
`

const ConfirmModal = ({ onDoneClick, onCancelClick, children, content }) => (
  <Modal open={true} onClose={onCancelClick}>
    <Container>
      <Typography variant="h6">Подтверждение</Typography>
      {children || <Typography>{content || 'Вы уверены?'}</Typography>}
      <ButtonsContainer>
        <Button name={'Да'} onClick={onDoneClick} />
        <ButtonCancel name={'Нет'} onClick={onCancelClick} />
      </ButtonsContainer>
    </Container>
  </Modal>
)

export default ConfirmModal
