import * as R from 'ramda'
import API from '../../api'
import { handleStatuses } from '../../aspects'
import { auth, query } from '../../utils/query'

export const getCourses = R.pipeP(
  (token, data) => fetch(`${API.COURSES}${query(data)}`, auth(token)),
  handleStatuses(),
  res => Promise.all([res.json(), parseInt(res.headers.get('count'))]),
  R.adjust(R.prop('data'), 0),
)

export const getCoursesDumb = R.pipeP(
  token => fetch(`${API.COURSES}/dumb?filled=true`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const getCourse = R.pipeP(
  (token, id) => fetch(API.getCourse(id), auth(token)),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const saveCourse = R.pipeP(
  (token, data) =>
    fetch(API.COURSES, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
      method: data.id ? 'PUT' : 'POST',
      body: JSON.stringify(data),
    }),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const copyCourse = R.pipeP(
  (token, data) =>
    fetch(`${API.COPY_COURSES}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    }),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const deleteCourse = R.pipeP(
  (token, id) =>
    fetch(`${API.COURSES}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json',
      },
      method: 'DELETE',
    }),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const getLessons = R.pipeP(
  token => fetch(API.LESSONS, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)
