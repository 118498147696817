import React from 'react'
import styled from 'styled-components'
import { FormLabel, Checkbox as CheckboxBase } from '@material-ui/core'

const CheckboxBaseStyle = styled(CheckboxBase)`
  padding-left: 0;
`

const Checkbox = ({ input, name, label }) => (
  <FormLabel>
    <CheckboxBaseStyle {...input} name={name} />
    {label}
  </FormLabel>
)

export default Checkbox
