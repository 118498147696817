import React from 'react'
import styled from 'styled-components'
import { formatDate } from '../../../utils/formatDate'
import { Grid, Typography } from '@material-ui/core'

const WordSetType = styled(Typography)`
  font-weight: bold;
`

const WordSets = styled(Typography)`
  margin-top: 20px;
  font-weight: bold;
`

const Text = styled(Typography)``

const WordSetInfo = ({ wordSet }) => (
  <Grid item sm={5}>
    <WordSetType>{`Тип набора: ${
      wordSet.testType === 'STUDY' ? 'Изучение' : 'Повторение'
    }`}</WordSetType>
    <Text>{`Опубликован: ${wordSet.active ? 'Да' : 'Нет'}`}</Text>
    <Text>{`Набор создан: ${formatDate(wordSet.createdAt)} by ${
      wordSet.creator
    }`}</Text>
    <Text>{`Набор обновлен: ${formatDate(wordSet.updatedAt)} by ${
      wordSet.editor
    }`}</Text>
    {wordSet.testType !== 'STUDY' && (
      <>
        <WordSets>Наборы изучения:</WordSets>
        {wordSet &&
          wordSet.wordSets &&
          wordSet.active &&
          wordSet.wordSets.map(x => <Text>{x.title}</Text>)}
      </>
    )}
  </Grid>
)

export default WordSetInfo
