import * as R from 'ramda'
import API from '../../api'
import { handleStatuses } from '../../aspects'
import { auth, query } from '../../utils/query'

export const getGroups = R.pipeP(
  (token, data) => fetch(`${API.GET_GROUPS_STAT}${query(data)}`, auth(token)),
  handleStatuses(),
  res => Promise.all([res.json(), parseInt(res.headers.get('count'))]),
  R.adjust(R.prop('data'), 0),
)

export const getGroup = R.pipeP(
  (token, id) => fetch(API.getGroup(id), auth(token)),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const saveGroup = R.pipeP(
  (token, data) =>
    fetch(`${API.GROUP}`, {
      method: data.id ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const getSchedule = R.pipeP(
  (token, data) => fetch(`${API.SCHEDULE}${query(data)}`, auth(token)),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)

export const deleteGroup = R.pipeP(
  (token, id) =>
    fetch(`${API.GROUP}/${id}`, {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${token}` },
    }),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)
