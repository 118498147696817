import { MODULES } from '../../constants'
import { handleAction, createAction } from 'redux-actions'
import { combineReducers } from 'redux'

export const fetchGroup = createAction(`${MODULES.GROUPS}/FETCH_GROUP`)
export const fetchGroups = createAction(`${MODULES.GROUPS}/FETCH_GROUPS`)

export const fetchSaveGroup = createAction(`${MODULES.GROUPS}/FETCH_SAVE_GROUP`)
export const fetchGroupSchedule = createAction(
  `${MODULES.GROUPS}/FETCH_GROUP_SCHEDULE`,
)
export const deleteGroup = createAction(`${MODULES.GROUPS}/DELETE_GROUP`)

export const setGroups = createAction(`${MODULES.GROUPS}/SET_GROUPS`)
export const setSchedule = createAction(`${MODULES.GROUPS}/SET_SCHEDULE`)

const groups = handleAction(setGroups, (_, { payload }) => payload, {})
const schedule = handleAction(setSchedule, (_, { payload }) => payload, [])

const groupsReducer = combineReducers({ groups, schedule })

export default groupsReducer
