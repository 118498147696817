import * as R from 'ramda'
import { combineReducers } from 'redux'
import { createAction, handleActions, handleAction } from 'redux-actions'

const prefix = 'STAGE_TWO'

export const setStageTwo = createAction(`${prefix}/SET_STAGE`)
export const saveStage = createAction(`${prefix}/SAVE_STAGE`)

export const saveAudition = createAction(`${prefix}/SAVE_AUDITION`)
export const addAudition = createAction(`${prefix}/ADD_AUDITION`)
export const updateAudition = createAction(`${prefix}/UPDATE_AUDITION`)
export const deleteAudition = createAction(`${prefix}/DELETE_AUDITION`)
export const loadAudition = createAction(`${prefix}/LOAD_AUDITION`)

export const saveFillGaps = createAction(`${prefix}/SAVE_FILL_GAPS`)
export const addFillGaps = createAction(`${prefix}/ADD_FILL_GAPS`)
export const updateFillGaps = createAction(`${prefix}/UPDATE_FILL_GAPS`)
export const deleteFillGaps = createAction(`${prefix}/DELETE_FILL_GAPS`)

export const saveMakeSentence = createAction(`${prefix}/SAVE_MAKE_SENTENCE`)
export const addMakeSentence = createAction(`${prefix}/ADD_MAKE_SENTENCE`)
export const updateMakeSentence = createAction(`${prefix}/UPDATE_MAKE_SENTENCE`)
export const deleteMakeSentence = createAction(`${prefix}/DELETE_MAKE_SENTENCE`)

export const selectStage = createAction(`${prefix}/SELECT_STAGE`)
export const setIndexStage = createAction(`${prefix}/SET_INDEX_STAGE`)

const audition = handleActions(
  {
    [addAudition]: (state, { payload }) => R.append(payload, state),
    [updateAudition]: (state, { payload }) =>
      R.update(payload.index, payload, state),
    [deleteAudition]: (state, { payload }) => R.remove(payload, 1, state),
    [setStageTwo]: (_, { payload }) =>
      R.filter(R.propEq('testType', '9'), payload),
  },
  [],
)

const fillGaps = handleActions(
  {
    [addFillGaps]: (state, { payload }) => R.append(payload, state),
    [updateFillGaps]: (state, { payload }) =>
      R.update(payload.index, payload, state),
    [deleteFillGaps]: (state, { payload }) => R.remove(payload, 1, state),
    [setStageTwo]: (_, { payload }) =>
      R.filter(R.propEq('testType', '10'), payload),
  },
  [],
)

const makeSentence = handleActions(
  {
    [addMakeSentence]: (state, { payload }) => R.append(payload, state),
    [updateMakeSentence]: (state, { payload }) =>
      R.update(payload.index, payload, state),
    [deleteMakeSentence]: (state, { payload }) => R.remove(payload, 1, state),
    [setStageTwo]: (_, { payload }) =>
      R.filter(R.propEq('testType', '11'), payload),
  },
  [],
)

const selectedIndex = handleAction(
  setIndexStage,
  (_, { payload }) => payload,
  null,
)

const stageTwo = combineReducers({
  audition,
  fillGaps,
  makeSentence,
  selectedIndex,
})

export default stageTwo
