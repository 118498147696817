import React from 'react'
import * as R from 'ramda'
import * as Yup from 'yup'
import styled from 'styled-components'
import { Modal } from '@material-ui/core'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { withHandlers } from 'recompose'
import { FORMS } from '../../../constants'
import { saveFillGaps } from '../../../modules/stageTwo'
import { toArray } from '../../../utils/stringFormater'
import { Button, TextInputField } from '../commons'
import { reduxFormYup } from '../../../utils'

const Form = styled.form`
  display: flex;
  margin: auto;
  margin-top: 10%;
  padding: 25px;
  border-radius: 10px;
  width: 50%;
  flex-direction: column;
  background-color: #fff;
`

const FillGapsFormDamp = ({ submitFillGaps, open, onClose }) => (
  <Modal open={open} onClose={onClose}>
    <Form>
      <Field
        name="fgText"
        label="Предложение"
        multiline
        component={TextInputField}
      />
      <Field
        name="fgVariants"
        label="Лишние слова"
        component={TextInputField}
      />
      <Button type="submit" onClick={submitFillGaps}>
        Отправить
      </Button>
    </Form>
  </Modal>
)

const FillGapsFormScheme = Yup.object().shape({
  fgText: Yup.string()
    .max(350)
    .required(),
  fgVariants: Yup.string()
    .max(350)
    .required(),
})

const FillGapsForm = R.compose(
  connect(
    R.applySpec({}),
    { saveFillGaps },
  ),
  reduxForm({
    form: FORMS.STAGE_TWO,
    validate: reduxFormYup(FillGapsFormScheme),
  }),
  withHandlers({
    submitFillGaps: ({ handleSubmit, saveFillGaps, initialize, onClose }) =>
      handleSubmit(
        ({ fgText, fgVariants }) =>
          saveFillGaps({
            text: fgText,
            testType: '10',
            rightAnswer: fgText
              .match(/\/[^/]+\//g)
              .map(x => x.replace(/\//g, '')),
            variants: R.concat(
              fgText.match(/\/[^/]+\//g).map(x => x.replace(/\//g, '')),
              toArray(fgVariants),
            ),
          }),
        onClose(),
      ),
  }),
)(FillGapsFormDamp)

export default FillGapsForm
