import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import { loadRule } from '../../../modules/wordSets'
import { Field, reduxForm, change } from 'redux-form'
import * as Yup from 'yup'
import { withRouter } from 'react-router-dom'
import { FORMS, EMOJI } from '../../../constants'
import { reduxFormYup } from '../../../utils'
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import { withStateHandlers } from 'recompose'
import { TextInputField, FileInput, EmojiPicker, withRole } from '../commons'

const Form = styled.form`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
`

const FieldWithRole = withRole(Field, true)

const WordSetScheme = Yup.object().shape({
  title: Yup.string()
    .max(50)
    .required(),
  emoji: Yup.string().required(),
  language: Yup.string().required(),
  difficulty: Yup.string().required(),
  testType: Yup.string().required(),
})

const WordSetFormDamp = ({
  rules,
  wordSetId,
  wordSet,
  setEmoji,
  open,
  isOpen,
  close,
  loadRule,
  onSubmit,
}) => (
  <Grid item sm={4}>
    <Form onSubmit={onSubmit}>
      <FieldWithRole
        name="title"
        label="Название набора"
        component={TextInputField}
      />
      <FieldWithRole
        name="emoji"
        label="Выбрать аватарку"
        onClick={() => open()}
        component={EmojiPicker}
      />
      {isOpen && (
        <Picker
          custom={EMOJI}
          title={'MAXIMUM'}
          include={['custom']}
          onSelect={emoji => {
            setEmoji(emoji)
            close()
          }}
        />
      )}
      <FieldWithRole
        name="language"
        label="Язык"
        select
        options={[{ value: 'ENGLISH', label: 'Английский' }]}
        component={TextInputField}
      />
      <FieldWithRole
        name="difficulty"
        label="Cложность набора"
        select
        options={[{ value: 'A', label: 'A' }, { value: 'B', label: 'B' }]}
        component={TextInputField}
      />
      {!wordSetId && (
        <FieldWithRole
          name="testType"
          label="Тип набора"
          select
          options={[
            { value: 'STUDY', label: 'Изучение' },
            { value: 'REPEAT', label: 'Повторение' },
          ]}
          component={TextInputField}
        />
      )}
      {wordSetId && wordSet.testType === 'STUDY' && (
        <FieldWithRole
          name="ruleId"
          label="Порядок заданий"
          select
          noOptionsMessage={() =>
            'Нет порядка заданий для текущего кол-ва слов'
          }
          options={rules}
          component={TextInputField}
          disable={wordSet.active}
        />
      )}
    </Form>
  </Grid>
)

const WordSetForm = R.compose(
  withRouter,
  reduxForm({
    form: FORMS.WORD_SET_FORM,
    validate: reduxFormYup(WordSetScheme),
  }),
  connect(
    undefined,
    { change, loadRule },
  ),
  withStateHandlers(
    { isOpen: false, emoji: '' },
    {
      open: () => () => ({ isOpen: true }),
      close: () => () => ({ isOpen: false }),
      setEmoji: (_, { change }) => emoji => {
        change(FORMS.WORD_SET_FORM, 'emoji', emoji.imageUrl)
      },
    },
  ),
)(WordSetFormDamp)

export default WordSetForm
