import React from 'react'
import * as R from 'ramda'
import { withStateHandlers } from 'recompose'
import { Grid } from '@material-ui/core'
import { TableAudition, AuditionForm } from '.'

const AuditionDamp = ({ active, enableModal, disableModal, isOpenModal }) => (
  <Grid container justify="center">
    <TableAudition active={active} enableModal={enableModal} />
    <AuditionForm open={isOpenModal} onClose={disableModal} />
  </Grid>
)

const Audition = R.compose(
  withStateHandlers(
    { isOpenModal: false },
    {
      disableModal: () => () => ({ isOpenModal: false }),
      enableModal: () => () => ({ isOpenModal: true }),
    },
  ),
)(AuditionDamp)

export default Audition
