import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import JssProvider from 'react-jss/lib/JssProvider'
import { create } from 'jss'
import 'normalize.css'
import 'react-notifications/lib/notifications.css'
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles'
import { createGlobalStyle } from 'styled-components'

// localization should up all component (yup)
import './localization'
import { Root } from './view/layout'
import store from './modules'

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Roboto;
  }
  .react-datetime-picker__inputGroup__amPm {
    display: none;
  }
`

const generateClassName = createGenerateClassName()
const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point'),
})

const App = () => (
  <React.Fragment>
    <GlobalStyle />
    <JssProvider jss={jss} generateClassName={generateClassName}>
      <Provider store={store}>
        <Router>
          <Root />
        </Router>
      </Provider>
    </JssProvider>
  </React.Fragment>
)

export default App
