import React from 'react'
import * as R from 'ramda'
import * as Yup from 'yup'
import styled from 'styled-components'
import { Modal } from '@material-ui/core'
import { connect } from 'react-redux'
import { withHandlers } from 'recompose'
import { FORMS } from '../../../constants'
import { reduxFormYup } from '../../../utils'
import { Field, reduxForm, change } from 'redux-form'
import { Button, TextInputField } from '../commons'
import { addAudio, renameAudio, getAudioForm } from '../../../modules/audio'

const Form = styled.form`
  display: flex;
  margin: auto;
  margin-top: 10%;
  padding: 25px;
  border-radius: 10px;
  width: 50%;
  flex-direction: column;
  background-color: #fff;
`
const AudioFormScheme = Yup.object().shape({
  name: Yup.string()
    .max(50)
    .required(),
  audio: Yup.object().required(),
})

const AudioFormDamp = ({ submitAudio, isOpen, onClose, audio }) => (
  <Modal open={isOpen} onClose={onClose}>
    <Form>
      <Field name="name" label="Название аудио" component={TextInputField} />
      {audio && !audio.url && (
        <Field
          name="audio"
          type="file"
          label="Добавить аудио"
          accept=".mp3"
          component={TextInputField}
        />
      )}
      <Button type="submit" onClick={submitAudio}>
        Отправить
      </Button>
    </Form>
  </Modal>
)

const AudioForm = R.compose(
  reduxForm({ form: FORMS.AUDIO, validate: reduxFormYup(AudioFormScheme) }),
  connect(
    R.applySpec({ audio: getAudioForm }),
    { change, addAudio, renameAudio },
  ),
  withHandlers({
    submitAudio: ({ handleSubmit, addAudio, onClose, audio, renameAudio }) =>
      handleSubmit(
        data => (audio.url ? renameAudio(data) : addAudio(data), onClose()),
      ),
  }),
)(AudioFormDamp)

export default AudioForm
