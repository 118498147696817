import { all, put, call, select, takeLatest } from 'redux-saga/effects'
import * as StatisticManager from './StatisticManager'
import {
  setUserStisticsByGroup,
  getUserStatisticsByGroup,
  getCourseStatistic,
  getGroupStatistic,
  setCourseStatistic,
  setGroupStatistic,
  getWordSetStatistic,
  getStagesStat,
  setStageStatistics,
  setWordSetStatistic,
} from './duck'
import { getToken } from '../auth'

const userStatisticsByGroupSaga = function*({ payload }) {
  const token = yield select(getToken)
  const ratingTable = yield call(
    StatisticManager.getRatingTable,
    token,
    payload,
  )
  const wordSetStats = yield call(
    StatisticManager.getWordSetStatByUser,
    token,
    payload,
  )
  const detailedProfile = yield call(
    StatisticManager.getDetailedProfile,
    token,
    payload,
  )

  yield put(
    setUserStisticsByGroup({ ratingTable, wordSetStats, detailedProfile }),
  )
}

const stagesStatSaga = function*({ payload }) {
  const token = yield select(getToken)
  const headers = yield call(
    StatisticManager.getTasks,
    token,
    payload.wordSetId,
  )
  const statistics = yield call(StatisticManager.getStage1Stat, token, payload)
  const stage2 = yield call(StatisticManager.getStage2Stat, token, payload)
  yield put(setStageStatistics({ stage1: { headers, statistics }, stage2 }))
}

const wordSetStatisticSaga = function*({ payload }) {
  const token = yield select(getToken)
  const wordSetInfo = yield call(
    StatisticManager.getDetailedWordSet,
    token,
    payload,
  )
  yield put(setWordSetStatistic({ wordSetInfo }))
  yield call(stagesStatSaga, { payload })
}

const groupStatisticSaga = function*({ payload }) {
  const token = yield select(getToken)
  const groupInfo = yield call(
    StatisticManager.getGroupMainInfo,
    token,
    payload,
  )
  const testUser = yield call(
    StatisticManager.getGroupMagicLink,
    token,
    payload,
  )
  const groupStudents = yield call(
    StatisticManager.getGroupStudents,
    token,
    payload,
  )
  const groupWordSets = yield call(
    StatisticManager.getGroupWordSets,
    token,
    payload,
  )
  yield put(
    setGroupStatistic({
      groupInfo: {
        ...groupInfo,
        qr: testUser && testUser.qr,
        userId: testUser && testUser.id,
      },
      groupStudents,
      groupWordSets,
    }),
  )
}

const courseStatisticSaga = function*({ payload }) {
  const token = yield select(getToken)
  const courseInfo = yield call(
    StatisticManager.getCourseMainInfo,
    token,
    payload,
  )
  const courseGroups = yield call(
    StatisticManager.getCourseGroup,
    token,
    payload,
  )
  const courseWordSets = yield call(
    StatisticManager.getCourseWordSets,
    token,
    payload,
  )
  yield put(setCourseStatistic({ courseInfo, courseGroups, courseWordSets }))
}

const statisticSaga = function*() {
  yield all([
    takeLatest(getGroupStatistic, groupStatisticSaga),
    takeLatest(getCourseStatistic, courseStatisticSaga),
    takeLatest(getWordSetStatistic, wordSetStatisticSaga),
    takeLatest(getUserStatisticsByGroup, userStatisticsByGroupSaga),
    takeLatest(getStagesStat, stagesStatSaga),
  ])
}

export default statisticSaga
