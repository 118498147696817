import React from 'react'
import * as R from 'ramda'
import { withRouter } from 'react-router-dom'
import { withHandlers } from 'recompose'
import { ROUTES, TEST_TYPE } from '../../../constants'
import { Table, TableBody, TableRow, TableHead, Grid } from '@material-ui/core'
import { TableCell } from '../commons'
import { getTime } from '../../../utils/formatDate'

const WordSetStatisctic = ({ data, navigateToWordSet }) => (
  <Grid container justify="center">
    <Grid sm={11} item>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Название</TableCell>
            <TableCell>Тип</TableCell>
            <TableCell>Выполняемость</TableCell>
            <TableCell>Среднее время выполнения</TableCell>
            <TableCell>Повторяемость набора</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(
            (
              { id, title, testType, executability, time, repeatability },
              index,
            ) => (
              <TableRow onClick={() => navigateToWordSet(id)} key={index}>
                <TableCell>{title}</TableCell>
                <TableCell>{TEST_TYPE[testType]}</TableCell>
                <TableCell>{executability || 0}%</TableCell>
                <TableCell>{getTime(time || 0)}</TableCell>
                <TableCell>{repeatability.toFixed(0) || 0}%</TableCell>
              </TableRow>
            ),
          )}
        </TableBody>
      </Table>
    </Grid>
  </Grid>
)

export default R.compose(
  withRouter,
  withHandlers({
    navigateToWordSet: ({
      history: { push: navigate },
      courseId,
      groupId,
    }) => id =>
      navigate(
        `${ROUTES.DETAILS}${
          courseId ? ROUTES.COURSES : ROUTES.GROUPS
        }/${courseId || groupId}${ROUTES.WORD_SETS}/${id}`,
      ),
  }),
)(WordSetStatisctic)
