import React from 'react'
import * as R from 'ramda'
import { FormControl } from '@material-ui/core'
import styled from 'styled-components'
import ReactSelect from 'react-select'
import { withHandlers, withProps } from 'recompose'

const Container = styled(FormControl)`
  width: 100%;
`

const Select = styled(ReactSelect)``

const LessonSelectDamp = ({
  input,
  onChangeSelect,
  disable,
  selectValue,
  ...rest
}) => (
  <Container>
    <Select
      isDisabled={disable}
      onChange={onChangeSelect}
      value={selectValue}
      placeholder="Выбрать"
      {...rest}
    />
  </Container>
)

const LessonSelect = R.compose(
  withHandlers({
    onChangeSelect: ({ input: { onChange } }) => ({ value }) => onChange(value),
  }),
  withProps(({ input: { value }, options }) => ({
    selectValue: R.find(R.propEq('value', value), options),
  })),
)(LessonSelectDamp)

export default LessonSelect
