import React from 'react'
import * as R from 'ramda'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Field, reduxForm } from 'redux-form'
import { reduxFormYup } from '../../../utils'
import { TextInputField, withRole } from '../commons'
import { FORMS } from '../../../constants'
import { getUserRole } from '../../../modules/profile'
import { getSeletedGroups } from '../../../modules/users'
import { getUserStatisticsByGroup } from '../../../modules/statistic'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  z-index: 99;
`

const Input = styled(TextInputField)`
  width: 400px;
`

const FieldWithRole = withRole(Field, true)

const UserFormScheme = Yup.object().shape({
  lastName: Yup.string()
    .max(50)
    .required(),
  firstName: Yup.string()
    .max(50)
    .required(),
  group: Yup.string(),
  email: Yup.string()
    .email()
    .required(),
  course: Yup.number()
    .max(50)
    .required(),
})

const UserFrom = ({
  groups,
  selectedGroups,
  userId: profileId,
  getUserStatisticsByGroup,
}) => (
  <Form>
    <FieldWithRole name="lastName" label="Фамилия" component={Input} />
    <FieldWithRole name="firstName" label="Имя" component={Input} />
    <FieldWithRole
      name="phone"
      type="phone"
      label="Телефон"
      component={Input}
    />
    <FieldWithRole name="email" label="Почта" component={Input} />
    {profileId && (
      <Field name="courses" label="Курсы" component={Input} disabled={true} />
    )}
    <FieldWithRole
      name="groups"
      label="Группа"
      select
      isMulti
      options={groups}
      component={Input}
      placeholder="Выбрать группу"
    />
    {profileId && (
      <Field
        name="group"
        label="Статистика по группе"
        select
        options={selectedGroups}
        component={Input}
        onChangeInput={groupId =>
          getUserStatisticsByGroup({ profileId, groupId })
        }
        placeholder="Выбрать группу"
      />
    )}
  </Form>
)

export default R.compose(
  connect(
    R.applySpec({ role: getUserRole, selectedGroups: getSeletedGroups }),
    { getUserStatisticsByGroup },
  ),
  reduxForm({ form: FORMS.USER_FORM, validate: reduxFormYup(UserFormScheme) }),
)(UserFrom)
