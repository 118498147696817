import * as R from 'ramda'
import { getSelectedUser } from '../users'

const getGroups = R.prop('groups')

export const getGroupItems = R.pipe(
  getGroups,
  R.pathOr([], ['groups', 'items']),
  R.map(x =>
    R.merge(x, {
      course: x.course ? x.course.name : 'Без курса',
      teacher: x.lastName
        ? `${x.lastName} ${x.firstName}`
        : 'Без преподавателя',
    }),
  ),
)

export const getGroupCount = R.pipe(
  getGroups,
  R.pathOr(0, ['groups', 'count']),
)

export const getGroupsForSystemSelect = R.converge(
  (groups, user) =>
    groups
      .filter(({ lastName }) => !lastName || lastName === user.lastName)
      .map(({ id, name }) => ({ value: id, label: name })), // without teacher
  [getGroupItems, getSelectedUser],
)

export const getGroupsForSelect = R.pipe(
  getGroupItems,
  R.map(({ id, name }) => ({ value: id, label: name })),
)

export const getSchedule = R.pipe(
  getGroups,
  R.prop('schedule'),
)
