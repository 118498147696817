import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'

const Preloader = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
`

export default Preloader
