import React from 'react'
import * as R from 'ramda'
import { ROUTES } from '../../../constants'
import { withHandlers } from 'recompose'
import { withRouter } from 'react-router-dom'
import { TableCell } from '../commons'
import { Table, TableBody, TableRow, TableHead, Grid } from '@material-ui/core'

const StudentStatiscticDamp = ({ data = [], navigateToUser }) => (
  <Grid container justify="center">
    <Grid sm={11} item>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell style={{ width: 200 }}>ФИО</TableCell>
            <TableCell>Рейтинг</TableCell>
            <TableCell>Процент выполнения</TableCell>
            <TableCell>Кол-во выученных наборов</TableCell>
            <TableCell>Кол-во просроченных наборов</TableCell>
            <TableCell>Кол-во повторенных наборов</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(
            (
              {
                id,
                firstName,
                lastName,
                rating,
                percentCompletion,
                learned,
                overdue,
                repeated,
              },
              index,
            ) => (
              <TableRow onClick={() => navigateToUser(id)} key={index}>
                <TableCell>{id}</TableCell>
                <TableCell>{`${lastName} ${firstName}`}</TableCell>
                <TableCell>{rating}</TableCell>
                <TableCell>{percentCompletion || 0}%</TableCell>
                <TableCell>{learned || 0}</TableCell>
                <TableCell>{overdue || 0}</TableCell>
                <TableCell>{repeated || 0}</TableCell>
              </TableRow>
            ),
          )}
        </TableBody>
      </Table>
    </Grid>
  </Grid>
)

const StudentStatisctic = R.compose(
  withRouter,
  withHandlers({
    navigateToUser: ({ history: { push: navigate } }) => id =>
      navigate(`${ROUTES.USERS}/${id}`),
  }),
)(StudentStatiscticDamp)

export default StudentStatisctic
