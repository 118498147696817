import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import {
  Modal,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  FormControl,
  Input,
  InputLabel,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { withHandlers, withStateHandlers } from 'recompose'
import { Button, TextInputField } from '../commons'
import { Field } from 'redux-form'
import {
  getSelectedWordSets,
  toggleWordSet,
  getWordSetsStudy,
  getDumpCourses,
  getWordSetByCourseId,
} from '../../../modules/wordSets'

const Container = styled.div`
  display: flex;
  margin: auto;
  margin-top: 10%;
  padding: 0 25px 25px 25px;
  border-radius: 10px;
  width: 50%;
  flex-direction: column;
  background-color: #fff;
`

const WordSetsList = styled(List)`
  width: 100%;
  height: 300px;
  maxwidth: 360px;
  position: relative;
  overflow: auto;
  maxheight: 300px;
`

const WordSetsDamp = ({
  open,
  onClose,
  setSearch,
  selectedWordSets,
  toggleWordSet,
  wordSets = [],
  searchString,
  filteredWordSets,
  clearSearch,
  onSubmit,
  courses,
  getWordSetByCourseId,
}) => (
  <Modal open={open} onClose={onClose}>
    <Container>
      <Field
        name="courseId"
        label="Курс"
        select
        options={courses}
        component={TextInputField}
        onChangeInput={getWordSetByCourseId}
      />
      <FormControl>
        <InputLabel htmlFor="search">Поиск по наборам изучения</InputLabel>
        <Input type="search" id="search" onChange={setSearch} />
      </FormControl>
      <WordSetsList>
        {(!!searchString ? filteredWordSets : wordSets).map(value => (
          <ListItem
            onClick={() => toggleWordSet(value.id)}
            key={value.id}
            dense
            button
          >
            <Checkbox
              checked={R.contains(value.id, selectedWordSets)}
              disableRipple
            />
            <ListItemText>{value.title}</ListItemText>
          </ListItem>
        ))}
      </WordSetsList>
      <Button onClick={() => (onClose(), clearSearch(), onSubmit())}>
        {'Сохранить'}
      </Button>
    </Container>
  </Modal>
)

const WordSets = R.compose(
  connect(
    R.applySpec({
      selectedWordSets: getSelectedWordSets,
      wordSets: getWordSetsStudy,
      courses: getDumpCourses,
    }),
    { toggleWordSet, getWordSetByCourseId },
  ),
  withStateHandlers(
    { filteredWordSets: [], searchString: '' },
    {
      setSearch: (_, { wordSets }) => ({ target: { value } }) => ({
        searchString: value,
        filteredWordSets: R.filter(x =>
          R.contains(value.toLowerCase(), x.title.toLowerCase()),
        )(wordSets),
      }),
      clearSearch: () => () => ({ searchString: '' }),
    },
  ),
  withHandlers({}),
)(WordSetsDamp)

export default WordSets
