import React from 'react'
import * as R from 'ramda'
import { Signin } from '../screens'
import { lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { ROUTES } from '../../constants'
import { checkToken } from '../../modules/auth'
import { setHistory } from '../../modules/navigation'
import { NotificationContainer } from 'react-notifications'
import { withRouter, Switch, Route } from 'react-router-dom'
import Main from './Main'

const RootDamp = () => (
  <>
    <NotificationContainer />
    <Switch>
      <Route path={ROUTES.SIGNIN} component={Signin} />
      <Route path={ROUTES.BASE} component={Main} />
    </Switch>
  </>
)

const Root = R.compose(
  withRouter,
  connect(
    undefined,
    { checkToken },
  ),
  lifecycle({
    componentDidMount() {
      this.props.checkToken()
      setHistory(this.props.history)
    },
  }),
)(RootDamp)

export default Root
