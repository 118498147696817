import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as R from 'ramda'
import styled from 'styled-components'
import { Paper, Grid } from '@material-ui/core'
import { withTitle } from './withTitle'
import { Header } from '../components/commons'
import { withStateHandlers, withHandlers } from 'recompose'
import {
  ToggleUsers,
  TableStudents,
  TableSystemUsers,
  TableTrialUsers,
} from '../components/users'
import { ROUTES } from '../../constants'
import { resetSelectedUser } from '../../modules/users/duck'
import { searchToObject, mergeSearch } from '../../utils'

const Container = styled(Paper)`
  padding: 20px;
`

const SYSTEM = 'SYSTEM'
const STUDENT = 'STUDENT'
const TRIAL = 'TRIAL'
const roleSet = new Set([SYSTEM, STUDENT, TRIAL])

const UsersDamp = ({
  role,
  search,
  setRole,
  setSearch,
  navigateToUserForm,
}) => (
  <Container>
    <Grid container direction="column">
      <Header
        title="Пользователи"
        searchName="Поиск по пользователю"
        buttonName={role === TRIAL ? undefined : 'Добавить пользователя'}
        onChange={({ target: { value } }) => setSearch(value)}
        value={search}
        onClick={navigateToUserForm}
      />
      <ToggleUsers role={role} setRole={setRole} />
      <Grid item>
        {role === STUDENT && <TableStudents search={search} />}
        {role === SYSTEM && <TableSystemUsers search={search} />}
        {role === TRIAL && <TableTrialUsers search={search} />}
      </Grid>
    </Grid>
  </Container>
)

const Users = R.compose(
  withRouter,
  withTitle('Пользователи'),
  connect(
    undefined,
    { resetSelectedUser },
  ),
  withStateHandlers(
    () =>
      R.pipe(
        () => new URLSearchParams(window.location.search),
        searchToObject,
        ({ search, role }) => ({
          role: roleSet.has(role) ? role : STUDENT,
          search: search || '',
        }),
      )(),
    {
      setSearch: () => search => ({ search }),
      setRole: (state, { history }) => role => {
        mergeSearch(history)({ role })
        return { role }
      },
    },
  ),
  withHandlers({
    navigateToUserForm: ({
      history: { push: navigate },
      resetSelectedUser,
      role,
    }) => () => {
      resetSelectedUser()
      navigate(ROUTES.USER_FORM + `?type=${role}`)
    },
  }),
)(UsersDamp)

export default Users
