import * as R from 'ramda'

import { handleStatuses } from '../../aspects'
import API from '../../api'

export const signin = R.pipeP(
  data => fetch(`${API.SIGNIN}?${new URLSearchParams(data)}`),
  handleStatuses(),
  res => res.json(),
  R.prop('data'),
)
