import { NotificationManager } from 'react-notifications'
import { call } from 'redux-saga/effects'

export const NotificationError = name =>
  function*() {
    yield call(
      [NotificationManager, NotificationManager.error],
      name || 'Ошибка сервера',
    )
  }

export const NotificationSuccess = name =>
  function*() {
    yield call(
      [NotificationManager, NotificationManager.success],
      name || 'Успешно',
    )
  }
