import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { Paper, Grid } from '@material-ui/core'
import { reduxForm, initialize } from 'redux-form'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  withProps,
  withHandlers,
  withStateHandlers,
  lifecycle,
} from 'recompose'
import { withTitle } from './withTitle'
import { FORMS, ROUTES } from '../../constants'
import {
  saveWordSet,
  fetchWordSet,
  setWords,
  setWordSet,
  getRules,
  getWordSet,
  getIsFetching,
  publishWordSet,
} from '../../modules/wordSets'
import { Words, WordSetInfo, WordSetForm as Form } from '../components/wordSets'
import {
  Divider,
  Button as Btn,
  Preloader,
  Confirm,
  withRole,
} from '../components/commons'

const Container = styled(Paper)`
  padding-top: 20px;
`

const ContainerUnderline = styled.div`
  margin-left: auto;
`

const Button = styled(Btn)`
  margin-right: 20px;
`

const ButtonWithRole = withRole(Button, true)

const WordSetFormDamp = ({
  id,
  save,
  wordSet,
  rules,
  onClose,
  publish,
  onPublish,
  isFetching,
  isPublishing,
  navigateStageTwo,
}) =>
  isFetching ? (
    <Preloader />
  ) : (
    <Container>
      <Divider>
        <ContainerUnderline>
          {id && !wordSet.active && wordSet.testType !== 'STUDY' && (
            <ButtonWithRole name="Опубликовать" onClick={() => publish()} />
          )}
          <ButtonWithRole
            name={id ? 'Сохранить' : 'Далее'}
            onClick={() => save()}
          />
          {id && wordSet.testType === 'STUDY' && (
            <Button
              name="Этап 2"
              onClick={() => (save(), navigateStageTwo())}
            />
          )}
        </ContainerUnderline>
      </Divider>
      <Grid container direction="column">
        <Grid style={{ paddingLeft: 30 }} container justify="space-between">
          <Form
            onSubmit={save}
            wordSetId={id}
            rules={rules}
            wordSet={wordSet}
          />
          {id && <WordSetInfo wordSet={wordSet} />}
        </Grid>
        {id && (
          <Words
            wordSetId={id}
            onSubmit={save}
            active={wordSet.active}
            abilityToAdd={wordSet.testType === 'STUDY'}
          />
        )}
      </Grid>
      {isPublishing && (
        <Confirm onCancelClick={onClose} onDoneClick={onPublish} />
      )}
    </Container>
  )

const WordSetForm = R.compose(
  withRouter,
  withTitle('Набор слов'),
  connect(
    R.applySpec({
      rules: getRules,
      wordSet: getWordSet,
      isFetching: getIsFetching,
    }),
    {
      saveWordSet,
      fetchWordSet,
      setWords,
      initialize,
      setWordSet,
      publishWordSet,
    },
  ),
  reduxForm({ form: FORMS.WORD_SET_FORM }),
  withProps(({ match: { params: { id } } }) => ({ id })),
  withStateHandlers(
    { isPublishing: false },
    {
      onPublish: (_, { id, publishWordSet }) => () => {
        publishWordSet(id)
        return { isPublishing: false }
      },
      publish: () => () => ({ isPublishing: true }),
      onClose: () => () => ({ isPublishing: false }),
    },
  ),
  withHandlers({
    save: ({ handleSubmit, saveWordSet, id }) =>
      handleSubmit(data =>
        saveWordSet({
          ...data,
          id,
          rule: data.ruleId ? { id: data.ruleId } : undefined,
        }),
      ),
    navigateStageTwo: ({ history: { push: navigate }, id }) => () =>
      navigate(`${ROUTES.WORD_SET_STAGE_TWO}/${id}`),
  }),
  lifecycle({
    componentDidMount() {
      const { id, fetchWordSet, setWords, setWordSet } = this.props
      setWords([])
      setWordSet({})
      fetchWordSet(id)
    },
  }),
)(WordSetFormDamp)

export default WordSetForm
