import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import { withHandlers, withStateHandlers } from 'recompose'
import { withRouter } from 'react-router'
import { ROUTES } from '../../../constants'
import {
  fetchCourses,
  copyCourse,
  deleteCourse,
  getCourseItems,
  getCourseCount,
} from '../../../modules/courses'
import {
  TableSort,
  EditButton,
  DeleteButton,
  CopyButton,
  InfoButton,
  Confirm,
} from '../commons'
import { TRIAL } from '../../../modules/profile'

const dataHeader = [
  {
    name: 'Код',
    keySort: 'course.internalName',
  },
  {
    name: 'Название',
    keySort: 'course.name',
  },
  {
    name: 'Триальный',
    keySort: 'course.role',
  },
  {
    name: 'Кол-во опубликованных наборов',
    keySort: 'activeWordSets',
  },
  {
    name: 'Кол-во групп на курсе',
    keySort: 'countGroup',
  },
  {
    name: 'Средний процент выполнения',
    keySort: 'percentCompletion',
  },
  {
    name: 'Статистика',
    keySort: '',
  },
  {
    name: 'Ред.',
    keySort: '',
  },
  {
    name: 'Копировать',
    keySort: '',
  },
  {
    name: 'Удалить',
    keySort: '',
  },
]

const TableCourse = ({
  courses,
  copy,
  remove,
  count,
  search,
  onDelete,
  onCopy,
  onClose,
  isCopying,
  isDeleting,
  fetchCourses,
  selectCourse,
  editCourse,
}) => (
  <Grid item>
    <TableSort
      data={courses}
      count={count}
      search={search}
      dataHeader={dataHeader}
      percentIndexes={[4]}
      onChange={fetchCourses}
      map={course => {
        const isTrial = course.role === TRIAL
        return [
          course.internalName,
          course.name,
          isTrial ? 'trial' : 'no trial',
          course.countWordSet,
          isTrial ? '' : course.countGroup,
          isTrial ? '' : course.percentCompletion,
          isTrial ? '' : <InfoButton onClick={() => selectCourse(course.id)} />,
          <EditButton onClick={() => editCourse(course.id)} />,
          <CopyButton onClick={() => copy(course.id)} />,
          <DeleteButton onClick={() => remove(course.id)} />,
        ]
      }}
    />
    {isDeleting && <Confirm onCancelClick={onClose} onDoneClick={onDelete} />}
    {isCopying && <Confirm onCancelClick={onClose} onDoneClick={onCopy} />}
  </Grid>
)

export default R.compose(
  withRouter,
  connect(
    R.applySpec({ courses: getCourseItems, count: getCourseCount }),
    { fetchCourses, copyCourse, deleteCourse },
  ),
  withStateHandlers(
    { isDeleting: false, isCopying: false, selectedId: null },
    {
      onClose: () => () => ({ isDeleting: false, isCopying: false }),
      remove: () => id => ({ isDeleting: true, selectedId: id }),
      copy: () => id => ({ isCopying: true, selectedId: id }),
      onDelete: ({ selectedId }, { deleteCourse }) => () => {
        deleteCourse(selectedId)
        return { isDeleting: false }
      },
      onCopy: ({ selectedId }, { copyCourse }) => () => {
        copyCourse(selectedId)
        return { isCopying: false }
      },
    },
  ),
  withHandlers({
    editCourse: ({ history: { push: navigate } }) => id => {
      navigate(`${ROUTES.COURSES}/${id}`)
    },
    selectCourse: ({ history: { push: navigate } }) => id => {
      navigate(`${ROUTES.DETAILS}${ROUTES.COURSES}/${id}`)
    },
  }),
)(TableCourse)
