import React from 'react'
import * as R from 'ramda'
import * as Yup from 'yup'
import { Field, FieldArray, reduxForm } from 'redux-form'
import styled from 'styled-components'
import { FORMS } from '../../../constants'
import { reduxFormYup } from '../../../utils'
import { Checkbox, TextInputField, withRole } from '../commons'
import LessonsForm from './LessonsForm'

const AddCourseContainer = styled.div`
  display: flex;
  margin-top: 30px;
  flex-direction: row;
  justify-content: space-around;
`

const MainInfoCourse = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
`

const LessonContainer = styled.div`
  display: flex;
  width: 30%;
  margin-top: 15px;
  flex-direction: column;
`

const FieldWithRole = withRole(Field, true)

const CourseFormScheme = Yup.object().shape({
  name: Yup.string()
    .max(50)
    .required(),
  language: Yup.string().required(),
  lessons: Yup.array(),
  theme: Yup.string(),
  content: Yup.string(),
})

const CourseFormDamp = ({ lessons, selectedLessons }) => (
  <AddCourseContainer>
    <MainInfoCourse>
      <FieldWithRole
        name="internalName"
        label="Код"
        component={TextInputField}
      />
      <FieldWithRole
        name="name"
        label="Название курса"
        component={TextInputField}
      />
      <FieldWithRole
        name="language"
        label="Язык курса"
        select
        options={[{ value: 'ENGLISH', label: 'Английский' }]}
        component={TextInputField}
      />
      <FieldWithRole
        name="role"
        label="для триального пользователя"
        type="checkbox"
        component={Checkbox}
      />
    </MainInfoCourse>
    <LessonContainer>
      <FieldArray
        name="lessons"
        label="Уроки"
        options={lessons}
        selectedLessons={selectedLessons}
        component={LessonsForm}
      />
    </LessonContainer>
  </AddCourseContainer>
)

const CourseForm = R.compose(
  reduxForm({
    form: FORMS.COURSE_FORM,
    validate: reduxFormYup(CourseFormScheme),
  }),
)(CourseFormDamp)

export default CourseForm
