import React from 'react'
import styled from 'styled-components'
import { Modal } from '@material-ui/core'

const Container = styled(Modal)`
  display: flex;
  margin: auto;
  overflow: scroll;
`

const Image = styled.img`
  display: flex;
  margin: auto;
`

const WordImageDamp = ({ src, open, onClose }) => (
  <Container open={open} onClose={onClose}>
    <Image src={src} />
  </Container>
)

const WordImage = WordImageDamp

export default WordImage
