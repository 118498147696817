import { TRIAL } from '../profile'

export const courseFormatting = ({
  id,
  internalName,
  name,
  language,
  lessons,
  role,
}) => {
  const course = {
    id,
    name,
    internalName,
    language,
    lessons: lessons ? lessons.map((id, order) => ({ id, order })) : undefined,
  }

  if (id) {
    course.id = id
  }
  course.role = role ? TRIAL : null
  return course
}
