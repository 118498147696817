import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { signout } from '../../modules/auth'
import {
  Drawer as DrawerMaterial,
  List as ListMaterial,
} from '@material-ui/core/'

import { DrawerHeader, DrawerButton } from '../components/main'
import { withRole } from '../components/commons'
import { ROUTES } from '../../constants'

const List = styled(ListMaterial)`
  width: auto;
`

const DraweButtonWithRole = withRole(DrawerButton)

const DrawerContainer = styled(DrawerMaterial).attrs({})`
  z-index: 999999;
  margin: 20px;
`

const DrawerDamp = ({ handleDrawerClose, handleDrawerOpen, open, signout }) => (
  <DrawerContainer
    onClose={handleDrawerClose}
    onOpen={handleDrawerOpen}
    open={open}
  >
    <DrawerHeader onClick={handleDrawerClose} />
    <List>
      <DraweButtonWithRole
        title={'Пользователи'}
        path={ROUTES.USERS}
        onClick={handleDrawerClose}
      />
      <DrawerButton
        title={'Группы'}
        path={ROUTES.GROUPS}
        onClick={handleDrawerClose}
      />
      <DraweButtonWithRole
        title={'Курсы'}
        path={ROUTES.COURSES}
        onClick={handleDrawerClose}
      />
      <DraweButtonWithRole
        title={'Наборы'}
        path={ROUTES.WORD_SETS}
        onClick={handleDrawerClose}
      />
      <DraweButtonWithRole
        title={'Аудио'}
        path={ROUTES.AUDIO}
        onClick={handleDrawerClose}
      />
      <DraweButtonWithRole
        title={'Порядки заданий'}
        path={ROUTES.RULES}
        onClick={handleDrawerClose}
      />
      <DrawerButton title={'Выход'} path={ROUTES.BASE} onClick={signout} />
    </List>
  </DrawerContainer>
)

const Drawer = R.compose(
  connect(
    undefined,
    { signout },
  ),
  withHandlers({
    signout: ({ signout, handleDrawerClose }) => () => {
      handleDrawerClose()
      signout()
    },
  }),
)(DrawerDamp)

export default Drawer
