import { combineReducers } from 'redux'
import { MODULES } from '../../constants'
import { createAction, handleAction } from 'redux-actions'

const prefix = MODULES.PROFILE

export const fetchProfile = createAction(`${prefix}/FETCH_PROFILE`)
export const setProfile = createAction(`${prefix}/SET_PROFILE`)

export const setPage = createAction(`${MODULES.NAVIGATION}/SET_PAGE`)

const user = handleAction(setProfile, (_, { payload }) => payload, {})

const currentPage = handleAction(setPage, (_, { payload }) => payload, {})

const profile = combineReducers({ user, currentPage })

export default profile
