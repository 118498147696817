import React from 'react'
import styled from 'styled-components'
import {
  Grid,
  Tab,
  AppBar as AppBarMaterial,
  Tabs as TabsMaterial,
} from '@material-ui/core'
import { withState } from 'recompose'
import { StudentStatisctic } from '.'
import { WordSetStatisctic } from '../wordSets'

const AppBar = styled(AppBarMaterial).attrs({
  position: 'static',
})`
  background-color: rgb(241, 77, 27);
  margin-bottom: 10px;
`

const Tabs = styled(TabsMaterial).attrs({
  indicatorColor: 'primary',
  fullWidth: true,
})`
  color: #fff;
`

const GroupStatisticsDamp = ({
  tab,
  setCurrentTab,
  students,
  wordSets,
  groupId,
}) => (
  <Grid sm={12} container>
    <AppBar>
      <Tabs value={tab} onChange={(_, numberTab) => setCurrentTab(numberTab)}>
        <Tab label="Ученики группы" />
        <Tab label="Наборы группы" />
      </Tabs>
    </AppBar>
    {tab === 0 && <StudentStatisctic data={students} />}
    {tab === 1 && <WordSetStatisctic groupId={groupId} data={wordSets} />}
  </Grid>
)

const GroupStatistics = withState('tab', 'setCurrentTab', 0)(
  GroupStatisticsDamp,
)

export default GroupStatistics
